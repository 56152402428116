import { Fragment, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Worksheet } from "../../models/Worksheet"
import { trimLongString, getDateString, isArrayUnavailable, breakUpString } from "../../utils/HelperFunctions"
import menuIcon from '../../images/menu_list_icon.png'
import lookupIcon from '../../images/lookup-icon.png'
import styles from './List.module.scss'
import { LIST_TYPE } from "./List"
import { TimeSpent } from "../../models/TimeSpent"
import { ExternalWork } from "../../models/ExternalWork"
import { EditItemIdContext, DeviceContext, SelectedWorksheetContext, SettingsContext } from "../../context/Contexts"
import { getRequest, BACKEND_ROUTE } from "../../request"
import { Tool } from "../../models/Tool"
import { ToolData } from "../../models/ToolData"
import { ServiceData } from "../../models/ServiceData"
import { MODAL_TYPE } from "../modal/BaseModal"
import { store } from "../../App"
import { showErrorMessages } from "../../reducers/rootReducer"
import ListItemCard from "./ListItemCard"
import i18n from "../../i18n"
import WorksheetStatusIcons from "./WorksheetStatusIcons"
import InputField, { WIDTH, INPUT_ALIGN, INPUT_TYPE, LABEL } from "../elements/InputField"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"

export interface Props {
    selectedId: number
    setSelectedId: any
    item: Worksheet | TimeSpent | ExternalWork | Tool | ToolData | ServiceData
    listType: LIST_TYPE
    openModal?: (type: MODAL_TYPE) => void
    showExportColumn?: boolean
    checkedIds: number[]
    setCheckedIds: any
    setIsPaneOpen?: React.Dispatch<React.SetStateAction<boolean>>
    listLength: number
}

export const ListItem = ({ checkedIds, setCheckedIds, item, selectedId, setSelectedId, listType, 
    openModal, showExportColumn, setIsPaneOpen, listLength }: Props) => {
    const [ isSelected, setIsSelected ] = useState(false)
    const history = useHistory()
    const { settings, setSettings } = useContext(SettingsContext)
    const maxChecked = listType === LIST_TYPE.SERVICEHISTORY ? listLength : 10
    const { editItemId, setEditItemId } = useContext(EditItemIdContext)
    const [ checked, setChecked ] = useState(checkedIds.indexOf(item.Id ?? -1) > -1)
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const trimStringAt = isPortrait ? 25 : 30
    
    
    useEffect(() =>{
        if(item.Id) setChecked(checkedIds.indexOf(item.Id) > -1)
    }, [checkedIds])
    
    
    const handleCheckedChange = () => {
        
            let shouldCheck = !checked
            let updateIds = checkedIds
            const index = item.Id ? updateIds.indexOf(item.Id) : -1
            if (index > -1) 
                updateIds.splice(index, 1)
            else if(updateIds.length + 1 <= maxChecked && item.Id)
                updateIds.push(item.Id)
            else {
                store.dispatch(showErrorMessages([`A maximálisan kijelölhető mennyiség ${maxChecked}.`]))
                shouldCheck = checked
            }
            setChecked(shouldCheck)
            setCheckedIds(updateIds)
        
    }
    
    const getWorker = (timeSpentItem: TimeSpent) => {
        let workerId = parseInt(timeSpentItem?.WorkerId)
        return settings.Workers.find(worker => worker.Id === workerId)?.Name
    }

    const getStatus = (worksheetItem: Worksheet) => {
        let statusId = worksheetItem?.StatusId
        return settings.Statuses.find(status => status.Id === statusId)?.Name
    }

    const getDriver = (externalWorkItem: ExternalWork) => {
        let driverId = externalWorkItem?.DriverId
        return settings.Drivers.find(driver => driver.Id === driverId)?.Name
    }

    const getLicensePlate = (externalWorkItem: ExternalWork) => {
        let licensePlateId = externalWorkItem?.LicensePlateNumberId
        return settings.LicensePlates.find(licensePlate => licensePlate.Id === licensePlateId)?.Name
    }

    useEffect(() => {
        setIsSelected(selectedId === item.Id)
    }) 

    useEffect(() => {
        if(isArrayUnavailable(settings.Drivers)) {
            const getSettings = async () => {
                await getRequest(BACKEND_ROUTE.SETTINGS, null)
                        .then((res) => setSettings(res))
                        .catch((e) => store.dispatch(showErrorMessages([e])))    
            }
            getSettings()
        }
    }, [])
    
    const handleRowClick = async (id: number | undefined) => {
        await setSelectedId(id)
    }

    const handleLookUpClick = (id: number | undefined) => {
        history.push(`/worksheet/${id}`)
        window.location.reload()
    }

    const handleLookUpToolClick = async (id: number | undefined) => {
        await setEditItemId(id ?? -1)
        if(openModal !== undefined) 
            openModal(MODAL_TYPE.EDITTOOL)
    }
        

    const getRowClasses = `${styles['row']} ${isSelected ? styles['selected'] : ''} 
        ${listType === LIST_TYPE.WORKSHEET ? styles['normal-height'] : styles['tall']}`

    const getRow = () => {
        switch(listType) {
            case LIST_TYPE.WORKSHEET:
                return getWorksheetRow()
            case LIST_TYPE.TIMESPENT:
                return getTimeSpentRow()
            case LIST_TYPE.EXTERNALWORK:
                return getExternalWorkRow()
            case LIST_TYPE.TOOL:
                return getToolRow()
            case LIST_TYPE.SERVICEHISTORY:
                return getServiceHistoryRow()
        }
    }

    const getWorksheetRow = () => {
        let worksheetItem = item as Worksheet
        
        if(!isMobile) return (
            <Fragment>
                <TableCell component="th" scope="row" align="left"  style={{padding: '0px', paddingTop: '8px', paddingBottom: '8px', width: '25%'}}>
                    <WorksheetStatusIcons ws={worksheetItem} />
                </TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}} className={styles['table-cell']}>{worksheetItem?.VoucherNumber}</TableCell>
                <TableCell component="th" scope="row" align="left"  style={{padding: '8px', width: '35%'}} className={styles['table-cell']}>{trimLongString(worksheetItem?.Customer?.Name, trimStringAt) ?? ''}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}} className={styles['table-cell']}>{getDateString(new Date(worksheetItem?.VoucherDate))}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>
                    <img src={menuIcon} alt='' onClick={() => {
                        setWorksheet(worksheetItem)
                        if(setIsPaneOpen)
                            setIsPaneOpen(true)}}  />
                </TableCell>
                {showExportColumn &&
                <TableCell component="th" scope="row" style={{padding: '8px', width: '15%'}}>
                    <InputField 
                        labelWidth={WIDTH.NONE}
                        name="checked"
                        value={checked}
                        changed={handleCheckedChange}
                        barAlignment={INPUT_ALIGN.MIDDLE}
                        labelPlace={LABEL.FILTER}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.CHECKBOX} />   
                        
                </TableCell>}
            </Fragment>
        )
        else return (
            <ListItemCard 
                worksheet={worksheetItem}
                handleArrowClick={() => {
                    setWorksheet(worksheetItem)
                    if (setIsPaneOpen)
                        setIsPaneOpen(true)
                } }
                topLeft={worksheetItem?.VoucherNumber ?? '-'}
                topRight={getDateString(new Date(worksheetItem?.VoucherDate)) ?? '-'}
                bottomLeft={trimLongString(worksheetItem?.Customer?.Name, trimStringAt) ?? '-'}
                bottomRight={getStatus(worksheetItem) ?? '-'} 
                topRightHeader={i18n('listItemCard.header.worksheet.date')} 
                bottomLeftHeader={i18n('listItemCard.header.worksheet.customer')} 
                bottomRightHeader={i18n('listItemCard.header.worksheet.state')}                 
            />
        )
    }
        
    const getTimeSpentRow = () => {
        let timeSpentItem = item as TimeSpent
        if(!isMobile) return (
            <Fragment>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px', width: 0}}>{timeSpentItem?.WorksheetNumber}</TableCell>
                <TableCell component="th" scope="row" align="left" style={{padding: '8px', width: 0}}>{trimLongString(timeSpentItem?.Label, trimStringAt) ?? ''}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px', width: 0}}>{trimLongString(getWorker(timeSpentItem) ?? '-', 15)}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px', width: 0}}>{getDateString(timeSpentItem?.Date)}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px', width: 0}}>{timeSpentItem?.Duration}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px', width: 0}}>
                    <img src={lookupIcon} className={styles['icon']} alt='' onClick={() => handleLookUpClick(timeSpentItem?.WorksheetId)}  />
                </TableCell>
            </Fragment>
        )
        else return (
            <ListItemCard 
                handleArrowClick={() => handleLookUpClick(timeSpentItem?.WorksheetId)}
                topLeft={timeSpentItem?.WorksheetNumber ?? '-'}
                topRight={getDateString(timeSpentItem?.Date ? new Date(timeSpentItem?.Date) : new Date()) ?? '-'}
                bottomLeft={trimLongString(getWorker(timeSpentItem) ?? '-', 15)}
                bottomRight={(timeSpentItem?.Duration).toString() ?? '-' + ' óra'} 
                topRightHeader={i18n('listItemCard.header.timeSpent.date')} 
                bottomLeftHeader={i18n('listItemCard.header.timeSpent.worker')} 
                bottomRightHeader={i18n('listItemCard.header.timeSpent.duration')}              
            />
        )
    }

    const getExternalWorkRow = () => {
        let externalWorkItem = item as ExternalWork
        if(!isMobile) return (
            <Fragment>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{externalWorkItem?.WorksheetNumber}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{trimLongString(getLicensePlate(externalWorkItem) ?? '_', 15)}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{trimLongString(getDriver(externalWorkItem) ?? '_', 15)}</TableCell>
                <TableCell component="th" scope="row" align="left" style={{padding: '8px'}}>{trimLongString(externalWorkItem?.Address, trimStringAt) ?? '_'}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{externalWorkItem?.Distance}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{getDateString(externalWorkItem?.Date ? new Date(externalWorkItem?.Date) : new Date())}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>
                    <img src={lookupIcon} className={styles['icon']} alt='' onClick={() => handleLookUpClick(externalWorkItem?.WorksheetId)} />
                </TableCell>
            </Fragment>
        )
        else return (
            <ListItemCard 
                handleArrowClick={() => handleLookUpClick(externalWorkItem?.WorksheetId)}
                topLeft={externalWorkItem?.WorksheetNumber ?? '-'}
                topRight={trimLongString(getLicensePlate(externalWorkItem) ?? '_', 15)}
                bottomLeft={trimLongString(getDriver(externalWorkItem) ?? '_', 15)}
                bottomRight={externalWorkItem?.Distance + ' km' ?? '-'} 
                topRightHeader={i18n('listItemCard.header.externalWork.licensePlateNumber')}
                bottomLeftHeader={i18n('listItemCard.header.externalWork.address')} 
                bottomRightHeader={i18n('listItemCard.header.externalWork.distance')}              
            />
        )
    }

    const getToolRow = () => {
        let toolItem = item as Tool
        if (!isMobile) return (
            <Fragment>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{breakUpString(toolItem?.Code, 9)}</TableCell>
                <TableCell component="th" scope="row" align="left" style={{padding: '8px', width: '25%'}}>{trimLongString(toolItem?.Name, trimStringAt) ?? ''}</TableCell>
                <TableCell component="th" scope="row" align="left" style={{padding: '8px', width: '25%'}}>{trimLongString(toolItem?.CustomerNameDisplay, trimStringAt) ?? ''}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{toolItem?.WorksheetCount}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{toolItem.LastWorksheetDate != null ? getDateString(new Date(toolItem.LastWorksheetDate)) : 'Nincs munkalap kiállítva.'}</TableCell>
                <td className={`${styles[`column-${listType}`]} ${styles['clearfix']} ${styles[`column-tool-six`]}`}>
                    <img src={menuIcon} className={styles['icon']} alt='' onClick={() => handleLookUpToolClick(toolItem?.Id)}  />
                </td>
            </Fragment>
        )
        else return (
            <ListItemCard 
                handleArrowClick={() => handleLookUpToolClick(toolItem?.Id)}
                topLeft={breakUpString(toolItem?.Code, 9) ?? '-'}
                topRight={trimLongString(toolItem?.CustomerNameDisplay, trimStringAt) ?? '-'}
                bottomLeft={trimLongString(toolItem?.Name, trimStringAt) ?? '-'}
                bottomRight={toolItem?.WorksheetCount + ' db' ?? '-'} 
                topRightHeader={i18n('listItemCard.header.tool.customer')}  
                bottomLeftHeader={i18n('listItemCard.header.tool.name')}  
                bottomRightHeader={i18n('listItemCard.header.tool.count')}                  
            />
        )
    }

    const getServiceHistoryRow = () => {
        let serviceHistoryItem = item as ServiceData
        if(!isMobile) return (
            <Fragment>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{serviceHistoryItem?.VoucherNumber}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{getDateString(new Date(serviceHistoryItem?.VoucherDate))}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>{getDateString(new Date(serviceHistoryItem?.FulfillmentDate))}</TableCell>
                <TableCell component="th" scope="row" align="center" style={{padding: '8px'}}>
                    <img src={lookupIcon} className={styles['icon']} alt='' onClick={() => handleLookUpClick(serviceHistoryItem?.Id)}  />
                </TableCell>
                {showExportColumn &&
                <TableCell component="th" scope="row" align="center" style={{padding: '8px', width: '15%'}}>
                    <InputField 
                        labelWidth={WIDTH.NONE}
                        name="checked"
                        value={checked}
                        changed={handleCheckedChange}
                        barAlignment={INPUT_ALIGN.MIDDLE}
                        labelPlace={LABEL.FILTER}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.CHECKBOX} />   
                        
                </TableCell>}
            </Fragment>
        )
        else return (
            <ListItemCard 
                handleArrowClick={() => handleLookUpClick(serviceHistoryItem?.Id)}
                topLeft={serviceHistoryItem?.VoucherNumber ?? '-'}
                topRight={getDateString(new Date(serviceHistoryItem?.VoucherDate)) ?? '-'}
                bottomLeft={getDateString(new Date(serviceHistoryItem?.FulfillmentDate)) ?? '-'}
                bottomRight={''} 
                topRightHeader={i18n('listItemCard.header.serviceHistory.date')}
                bottomLeftHeader={i18n('listItemCard.header.serviceHistory.fulfillment')}
                bottomRightHeader=''                
            />
        )
    }

    if(!isMobile) return (
        <TableRow key={item.Id} className={getRowClasses} 
            onClick={() => handleRowClick(item.Id)}
            onDoubleClick={() => listType === LIST_TYPE.TOOL ? handleLookUpToolClick(item.Id) : (listType === LIST_TYPE.WORKSHEET || listType === LIST_TYPE.SERVICEHISTORY) ? handleLookUpClick(item.Id) : handleLookUpClick((item as any).WorksheetId)} >
            {getRow()}
        </TableRow>
    )
    else return (
        <div key={item.Id} onClick={() => handleRowClick(item.Id)}
        onDoubleClick={() => listType === LIST_TYPE.TOOL ? handleLookUpToolClick(item.Id) : (listType === LIST_TYPE.WORKSHEET || listType === LIST_TYPE.SERVICEHISTORY) ? handleLookUpClick(item.Id) : handleLookUpClick((item as any).WorksheetId)} >
            {getRow()}
        </div>
    )
}
