export const findCity = (zip: number) => {
    switch(zip){
        case 2000:
            return "Szentendre";
        case 2001:
            return "Szentendre";
        case 2002:
            return "Szentendre";
        case 2003:
            return "Szentendre";
        case 2009:
            return "Pilisszentlászló";
        case 2011:
            return "Budakalász";
        case 2012:
            return "Budakalász";
        case 2013:
            return "Pomáz ";
        case 2014:
            return "Csobánka";
        case 2015:
            return "Szigetmonostor";
        case 2016:
            return "Leányfalu";
        case 2017:
            return "Pócsmegyer";
        case 2019:
            return "Pócsmegyer";
        case 2021:
            return "Tahitótfalu ";
        case 2022:
            return "Tahitótfalu ";
        case 2023:
            return "Dunabogdány";
        case 2024:
            return "Kisoroszi";
        case 2025:
            return "Visegrád ";
        case 2026:
            return "Visegrád ";
        case 2027:
            return "Dömös";
        case 2028:
            return "Pilismarót";
        case 2030:
            return "Érd";
        case 2031:
            return "Érd";
        case 2032:
            return "Érd";
        case 2033:
            return "Érd";
        case 2035:
            return "Érd";
        case 2036:
            return "Érd";
        case 2038:
            return "Sóskút";
        case 2039:
            return "Pusztazámor";
        case 2040:
            return "Budaörs";
        case 2041:
            return "Budaörs";
        case 2042:
            return "Budaörs";
        case 2043:
            return "Budaörs";
        case 2044:
            return "Budaörs";
        case 2045:
            return "Törökbálint ";
        case 2046:
            return "Törökbálint ";
        case 2047:
            return "Törökbálint ";
        case 2049:
            return "Diósd";
        case 2050:
            return "Diósd";
        case 2051:
            return "Biatorbágy";
        case 2052:
            return "Biatorbágy";
        case 2053:
            return "Herceghalom";
        case 2055:
            return "Herceghalom";
        case 2056:
            return "Herceghalom";
        case 2060:
            return "Bicske";
        case 2061:
            return "Bicske";
        case 2063:
            return "Bicske";
        case 2064:
            return "Csabdi";
        case 2065:
            return "Mány";
        case 2066:
            return "Szár";
        // case 2066:
        //     return "Újbarok";
        case 2067:
            return "Szárliget";
        case 2071:
            return "Páty";
        case 2072:
            return "Zsámbék";
        case 2073:
            return "Tök";
        case 2074:
            return "Perbál";
        case 2080:
            return "Pilisjászfalu ";
        case 2081:
            return "Piliscsaba ";
        case 2082:
            return "Piliscsaba ";
        case 2083:
            return "Solymár";
        case 2084:
            return "Pilisszentiván";
        case 2085:
            return "Pilisvörösvár";
        case 2086:
            return "Tinnye";
        case 2087:
            return "Tinnye";
        case 2089:
            return "Telki ";
        case 2090:
            return "Remeteszőlős";
        case 2091:
            return "Etyek";
        case 2092:
            return "Budakeszi";
        case 2093:
            return "Budajenő";
        case 2094:
            return "Nagykovácsi";
        case 2095:
            return "Pilisszántó";
        case 2096:
            return "Üröm";
        case 2097:
            return "Pilisborosjenő";
        case 2098:
            return "Pilisszentkereszt";
        case 2099:
            return "Dobogókő";
        case 2100:
            return "Gödöllő";
        case 2101:
            return "Gödöllő";
        case 2102:
            return "Gödöllő";
        case 2103:
            return "Gödöllő";
        case 2105:
            return "Gödöllő";
        case 2111:
            return "Szada";
        case 2112:
            return "Veresegyház";
        case 2113:
            return "Erdőkertes";
        case 2114:
            return "Valkó";
        case 2115:
            return "Vácszentlászló";
        case 2116:
            return "Zsámbok";
        case 2117:
            return "Isaszeg";
        case 2118:
            return "Dány";
        case 2119:
            return "Pécel";
        case 2120:
            return "Dunakeszi";
        case 2121:
            return "Dunakeszi";
        case 2122:
            return "Dunakeszi";
        case 2123:
            return "Dunakeszi";
        case 2131:
            return "Göd ";
        case 2132:
            return "Göd ";
        case 2133:
            return "Sződliget";
        case 2134:
            return "Sződ";
        case 2135:
            return "Csörög";
        case 2141:
            return "Csömör";
        case 2142:
            return "Nagytarcsa";
        case 2143:
            return "Kistarcsa";
        case 2144:
            return "Kerepes ";
        case 2145:
            return "Kerepes ";
        case 2146:
            return "Mogyoród";
        case 2151:
            return "Fót";
        case 2152:
            return "Fót";
        case 2153:
            return "Fót";
        case 2161:
            return "Csomád";
        case 2162:
            return "Őrbottyán";
        case 2163:
            return "Vácrátót";
        case 2164:
            return "Váchartyán";
        case 2165:
            return "Kisnémedi";
        case 2166:
            return "Püspökszilágy";
        case 2167:
            return "Vácduka";
        case 2170:
            return "Aszód";
        case 2173:
            return "Kartal";
        case 2174:
            return "Verseg";
        case 2175:
            return "Kálló";
        case 2176:
            return "Erdőkürt";
        case 2177:
            return "Erdőtarcsa";
        case 2181:
            return "Iklad";
        case 2182:
            return "Domony";
        case 2183:
            return "Galgamácsa";
        case 2184:
            return "Vácegres";
        case 2185:
            return "Váckisújfalu";
        case 2191:
            return "Bag";
        case 2192:
            return "Hévízgyörk";
        case 2193:
            return "Galgahévíz";
        case 2194:
            return "Tura";
        case 2200:
            return "Monor";
        case 2201:
            return "Monor";
        case 2202:
            return "Monor";
        case 2209:
            return "Péteri";
        case 2211:
            return "Vasad";
        case 2212:
            return "Csévharaszt";
        case 2213:
            return "Csévharaszt";
        case 2214:
            return "Pánd";
        case 2215:
            return "Káva";
        case 2216:
            return "Bénye";
        case 2217:
            return "Gomba";
        case 2220:
            return "Vecsés";
        case 2221:
            return "Vecsés";
        case 2222:
            return "Vecsés";
        case 2225:
            return "Üllő";
        case 2230:
            return "Gyömrő";
        case 2233:
            return "Ecser";
        case 2234:
            return "Maglód";
        case 2235:
            return "Mende";
        case 2241:
            return "Sülysáp  ";
        case 2242:
            return "Sülysáp  ";
        case 2243:
            return "Kóka";
        case 2244:
            return "Úri";
        case 2251:
            return "Tápiószecső";
        case 2252:
            return "Tóalmás";
        case 2253:
            return "Tápióság";
        case 2254:
            return "Szentmártonkáta";
        case 2255:
            return "Szentlőrinckáta";
        case 2300:
            return "Ráckeve";
        case 2301:
            return "Ráckeve";
        case 2309:
            return "Lórév";
        case 2310:
            return "Szigetszentmiklós";
        case 2311:
            return "Szigetszentmiklós";
        case 2313:
            return "Szigetszentmiklós";
        case 2314:
            return "Halásztelek";
        case 2315:
            return "Szigethalom";
        case 2316:
            return "Tököl";
        case 2317:
            return "Szigetcsép";
        case 2318:
            return "Szigetszentmárton";
        case 2319:
            return "Szigetújfalu";
        case 2321:
            return "Szigetbecse";
        case 2322:
            return "Makád";
        case 2330:
            return "Dunaharaszti";
        case 2331:
            return "Dunaharaszti";
        case 2332:
            return "Dunaharaszti";
        case 2335:
            return "Taksony";
        case 2336:
            return "Dunavarsány";
        case 2337:
            return "Délegyháza";
        case 2338:
            return "Áporka";
        case 2339:
            return "Majosháza";
        case 2340:
            return "Kiskunlacháza";
        case 2341:
            return "Kiskunlacháza";
        case 2342:
            return "Kiskunlacháza";
        case 2344:
            return "Dömsöd";
        case 2345:
            return "Apaj";
        case 2347:
            return "Bugyi";
        case 2351:
            return "Alsónémedi";
        case 2360:
            return "Gyál";
        case 2363:
            return "Felsőpakony";
        case 2364:
            return "Ócsa";
        case 2365:
            return "Inárcs";
        case 2366:
            return "Kakucs";
        case 2367:
            return "Újhartyán";
        case 2370:
            return "Dabas";
        case 2371:
            return "Dabas";
        case 2372:
            return "Dabas";
        case 2373:
            return "Dabas";
        case 2374:
            return "Dabas";
        case 2375:
            return "Tatárszentgyörgy";
        case 2376:
            return "Hernád";
        case 2377:
            return "Örkény";
        case 2378:
            return "Pusztavacs";
        case 2381:
            return "Táborfalva ";
        case 2400:
            return "Dunaújváros";
        case 2401:
            return "Dunaújváros";
        case 2402:
            return "Dunaújváros";
        case 2403:
            return "Dunaújváros";
        case 2404:
            return "Dunaújváros";
        case 2405:
            return "Dunaújváros";
        case 2406:
            return "Dunaújváros";
        case 2407:
            return "Dunaújváros";
        case 2408:
            return "Dunaújváros";
        case 2421:
            return "Nagyvenyim";
        case 2422:
            return "Mezőfalva";
        case 2423:
            return "Daruszentmiklós";
        case 2424:
            return "Előszállás";
        case 2425:
            return "Nagykarácsony";
        case 2426:
            return "Baracs ";
        case 2427:
            return "Baracs ";
        case 2428:
            return "Kisapostag";
        case 2431:
            return "Perkáta";
        case 2432:
            return "Szabadegyháza";
        case 2433:
            return "Sárosd";
        case 2434:
            return "Hantos";
        case 2435:
            return "Nagylók";
        case 2440:
            return "Százhalombatta";
        case 2441:
            return "Százhalombatta";
        case 2442:
            return "Százhalombatta";
        case 2443:
            return "Százhalombatta";
        case 2444:
            return "Százhalombatta";
        case 2451:
            return "Ercsi ";
        case 2453:
            return "Ercsi ";
        case 2454:
            return "Iváncsa";
        case 2455:
            return "Beloiannisz";
        case 2456:
            return "Besnyő";
        case 2457:
            return "Adony";
        case 2458:
            return "Kulcs";
        case 2459:
            return "Rácalmás";
        case 2461:
            return "Tárnok";
        case 2462:
            return "Martonvásár";
        case 2463:
            return "Tordas";
        case 2464:
            return "Gyúró";
        case 2465:
            return "Ráckeresztúr";
        case 2471:
            return "Baracska";
        case 2472:
            return "Kajászó";
        case 2473:
            return "Vál";
        case 2475:
            return "Kápolnásnyék";
        case 2476:
            return "Pázmánd";
        case 2477:
            return "Vereb";
        case 2481:
            return "Velence ";
        case 2482:
            return "Velence ";
        case 2483:
            return "Gárdony ";
        case 2484:
            return "Gárdony ";
        case 2485:
            return "Gárdony ";
        case 2490:
            return "Pusztaszabolcs";
        case 2500:
            return "Esztergom";
        case 2501:
            return "Esztergom";
        case 2503:
            return "Esztergom";
        case 2509:
            return "Esztergom";
        case 2510:
            return "Dorog";
        case 2511:
            return "Dorog";
        case 2512:
            return "Dorog";
        case 2517:
            return "Kesztölc";
        case 2518:
            return "Leányvár";
        case 2519:
            return "Piliscsév";
        case 2521:
            return "Csolnok";
        case 2522:
            return "Dág";
        case 2523:
            return "Sárisáp";
        case 2524:
            return "Nagysáp";
        case 2525:
            return "Bajna";
        case 2526:
            return "Epöl";
        case 2527:
            return "Máriahalom";
        case 2528:
            return "Úny";
        case 2529:
            return "Annavölgy";
        case 2531:
            return "Tokod ";
        case 2532:
            return "Tokodaltáró";
        case 2533:
            return "Bajót";
        case 2534:
            return "Tát";
        case 2535:
            return "Mogyorósbánya";
        case 2536:
            return "Nyergesújfalu ";
        case 2537:
            return "Nyergesújfalu ";
        case 2541:
            return "Lábatlan ";
        case 2542:
            return "Lábatlan ";
        case 2543:
            return "Süttő";
        case 2544:
            return "Neszmély";
        case 2545:
            return "Dunaalmás";
        case 2600:
            return "Vác";
        case 2601:
            return "Vác";
        case 2602:
            return "Vác";
        case 2603:
            return "Vác";
        case 2610:
            return "Nőtincs";
        case 2611:
            return "Felsőpetény";
        case 2612:
            return "Kosd";
        case 2613:
            return "Rád";
        case 2614:
            return "Penc";
        case 2615:
            return "Csővár";
        case 2616:
            return "Keszeg";
        // case 2616:
        //     return "Ősagárd";
        case 2617:
            return "Alsópetény";
        case 2618:
            return "Nézsa";
        case 2619:
            return "Legénd";
        case 2621:
            return "Verőce";
        case 2623:
            return "Kismaros";
        case 2624:
            return "Szokolya";
        case 2625:
            return "Kóspallag";
        case 2626:
            return "Nagymaros";
        case 2627:
            return "Zebegény";
        case 2628:
            return "Szob";
        case 2629:
            return "Márianosztra";
        case 2631:
            return "Ipolydamásd";
        case 2632:
            return "Letkés";
        case 2633:
            return "Ipolytölgyes";
        case 2634:
            return "Nagybörzsöny";
        case 2635:
            return "Vámosmikola";
        case 2636:
            return "Tésa";
        case 2637:
            return "Perőcsény";
        case 2638:
            return "Kemence";
        case 2639:
            return "Bernecebaráti";
        case 2640:
            return "Szendehely";
        case 2641:
            return "Berkenye";
        case 2642:
            return "Nógrád";
        case 2643:
            return "Diósjenő";
        case 2644:
            return "Borsosberény";
        case 2645:
            return "Nagyoroszi";
        case 2646:
            return "Drégelypalánk";
        case 2647:
            return "Hont";
        case 2648:
            return "Patak";
        case 2649:
            return "Dejtár";
        case 2651:
            return "Rétság";
        case 2652:
            return "Tereske";
        case 2653:
            return "Bánk";
        case 2654:
            return "Romhány";
        case 2655:
            return "Kétbodony";
        // case 2655:
        //     return "Kisecset";
        // case 2655:
        //     return "Szente";
        case 2656:
            return "Szátok";
        case 2657:
            return "Tolmács";
        case 2658:
            return "Horpács";
        // case 2658:
        //     return "Pusztaberki";
        case 2659:
            return "Érsekvadkert";
        case 2660:
            return "Balassagyarmat";
        case 2661:
            return "Balassagyarmat";
        case 2662:
            return "Balassagyarmat";
        case 2668:
            return "Patvarc";
        case 2669:
            return "Ipolyvece";
        case 2671:
            return "Őrhalom";
        case 2672:
            return "Hugyag";
        case 2673:
            return "Csitár";
        case 2675:
            return "Nógrádmarcal";
        // case 2675:
        //     return "Iliny";
        case 2676:
            return "Cserhátsurány";
        case 2677:
            return "Herencsény";
        case 2678:
            return "Csesztve";
        case 2681:
            return "Galgagyörk";
        case 2682:
            return "Püspökhatvan";
        case 2683:
            return "Acsa";
        case 2685:
            return "Nógrádsáp";
        case 2686:
            return "Galgaguta";
        case 2687:
            return "Bercel";
        case 2688:
            return "Vanyarc";
        case 2691:
            return "Nógrádkövesd";
        case 2692:
            return "Szécsénke";
        case 2693:
            return "Becske";
        case 2694:
            return "Magyarnándor";
        // case 2694:
        //     return "Cserháthaláp";
        // case 2694:
        //     return "Debercsény";
        case 2696:
            return "Terény";
        case 2697:
            return "Szanda";
        case 2698:
            return "Mohora";
        case 2699:
            return "Szügy";
        case 2700:
            return "Cegléd";
        case 2701:
            return "Cegléd";
        case 2702:
            return "Cegléd";
        case 2703:
            return "Cegléd";
        case 2704:
            return "Cegléd";
        case 2711:
            return "Tápiószentmárton";
        case 2712:
            return "Nyársapát";
        case 2713:
            return "Csemő";
        case 2721:
            return "Pilis";
        case 2723:
            return "Nyáregyháza";
        case 2724:
            return "Újlengyel";
        case 2730:
            return "Albertirsa";
        case 2735:
            return "Dánszentmiklós";
        case 2736:
            return "Mikebuda";
        case 2737:
            return "Ceglédbercel";
        case 2738:
            return "Ceglédbercel";
        case 2740:
            return "Abony";
        case 2745:
            return "Kőröstetétlen";
        case 2746:
            return "Jászkarajenő";
        case 2747:
            return "Törtel";
        case 2750:
            return "Nagykőrös";
        case 2751:
            return "Nagykőrös";
        case 2752:
            return "Nagykőrös";
        case 2755:
            return "Kocsér";
        case 2760:
            return "Nagykáta";
        case 2761:
            return "Nagykáta";
        case 2764:
            return "Tápióbicske";
        case 2765:
            return "Farmos";
        case 2766:
            return "Tápiószele";
        case 2767:
            return "Tápiógyörgye";
        case 2768:
            return "Újszilvás";
        case 2769:
            return "Tápiószőlős";
        case 2800:
            return "Tatabánya";
        case 2801:
            return "Tatabánya";
        case 2802:
            return "Tatabánya";
        case 2803:
            return "Tatabánya";
        case 2804:
            return "Tatabánya";
        case 2805:
            return "Tatabánya";
        case 2806:
            return "Tatabánya";
        case 2807:
            return "Tatabánya";
        case 2808:
            return "Tatabánya";
        case 2809:
            return "Tatabánya";
        case 2821:
            return "Gyermely";
        case 2822:
            return "Szomor";
        case 2823:
            return "Vértessomló";
        case 2824:
            return "Várgesztes";
        case 2831:
            return "Tarján";
        case 2832:
            return "Héreg";
        case 2833:
            return "Vértestolna";
        case 2834:
            return "Tardos";
        case 2835:
            return "Tardos";
        case 2836:
            return "Baj";
        case 2837:
            return "Vértesszőlős";
        case 2840:
            return "Oroszlány";
        case 2841:
            return "Oroszlány";
        case 2842:
            return "Oroszlány";
        case 2851:
            return "Környe";
        case 2852:
            return "Kecskéd";
        case 2853:
            return "Kömlőd";
        case 2854:
            return "Dad";
        case 2855:
            return "Bokod";
        case 2856:
            return "Szákszend";
        case 2858:
            return "Császár";
        case 2859:
            return "Vérteskethely";
        case 2861:
            return "Bakonysárkány";
        case 2862:
            return "Aka";
        case 2870:
            return "Kisbér";
        case 2879:
            return "Kisbér";
        case 2881:
            return "Ászár";
        case 2882:
            return "Kerékteleki";
        case 2883:
            return "Bársonyos";
        case 2884:
            return "Bakonyszombathely";
        case 2885:
            return "Bakonybánk";
        case 2886:
            return "Réde";
        case 2887:
            return "Ácsteszér";
        case 2888:
            return "Csatka";
        case 2889:
            return "Súr";
        case 2890:
            return "Tata";
        case 2891:
            return "Tata";
        case 2892:
            return "Tata";
        case 2893:
            return "Tata";
        case 2894:
            return "Tata";
        case 2896:
            return "Szomód";
        case 2897:
            return "Dunaszentmiklós";
        case 2898:
            return "Kocs";
        case 2899:
            return "Naszály";
        case 2900:
            return "Komárom";
        case 2901:
            return "Komárom";
        case 2902:
            return "Komárom";
        case 2903:
            return "Komárom";
        case 2904:
            return "Komárom";
        case 2911:
            return "Mocsa";
        case 2921:
            return "Mocsa";
        case 2922:
            return "Mocsa";
        case 2931:
            return "Almásfüzitő ";
        case 2932:
            return "Almásfüzitő ";
        case 2941:
            return "Ács";
        case 2942:
            return "Nagyigmánd";
        case 2943:
            return "Bábolna";
        case 2944:
            return "Bana";
        case 2945:
            return "Tárkány";
        case 2946:
            return "Csép";
        case 2947:
            return "Ete";
        case 2948:
            return "Kisigmánd";
        case 2949:
            return "Csém";
        case 3000:
            return "Hatvan";
        case 3001:
            return "Hatvan";
        case 3002:
            return "Hatvan";
        case 3003:
            return "Hatvan";
        case 3009:
            return "Hatvan";
        case 3011:
            return "Heréd";
        case 3012:
            return "Nagykökényes";
        case 3013:
            return "Ecséd";
        case 3014:
            return "Hort";
        case 3015:
            return "Csány";
        case 3016:
            return "Boldog";
        case 3021:
            return "Lőrinci ";
        case 3022:
            return "Lőrinci ";
        case 3023:
            return "Petőfibánya";
        case 3024:
            return "Petőfibánya";
        case 3031:
            return "Zagyvaszántó";
        case 3032:
            return "Apc";
        case 3033:
            return "Rózsaszentmárton";
        case 3034:
            return "Szűcsi";
        case 3035:
            return "Gyöngyöspata";
        case 3036:
            return "Gyöngyöstarján";
        case 3041:
            return "Héhalom";
        case 3042:
            return "Palotás";
        case 3043:
            return "Egyházasdengeleg";
        case 3044:
            return "Szirák";
        case 3045:
            return "Bér";
        case 3046:
            return "Kisbágyon";
        case 3047:
            return "Buják";
        case 3051:
            return "Szarvasgede";
        case 3052:
            return "Csécse";
        case 3053:
            return "Ecseg";
        // case 3053:
        //     return "Kozárd";
        case 3060:
            return "Pásztó";
        case 3061:
            return "Pásztó";
        case 3062:
            return "Pásztó";
        case 3063:
            return "Jobbágyi";
        case 3064:
            return "Szurdokpüspöki";
        case 3065:
            return "Szurdokpüspöki";
        case 3066:
            return "Cserhátszentiván";
        // case 3066:
        //     return "Bokor";
        // case 3066:
        //     return "Kutasó";
        case 3067:
            return "Felsőtold";
        // case 3067:
        //     return "Garáb";
        case 3068:
            return "Mátraszőlős";
        case 3069:
            return "Alsótold";
        case 3070:
            return "Bátonyterenye";
        case 3071:
            return "Bátonyterenye";
        case 3072:
            return "Bátonyterenye";
        case 3073:
            return "Tar";
        case 3074:
            return "Sámsonháza";
        case 3075:
            return "Nagybárkány";
        // case 3075:
        //     return "Kisbárkány";
        // case 3075:
        //     return "Márkháza";
        case 3077:
            return "Mátraverebély";
        case 3078:
            return "Mátraverebély";
        case 3082:
            return "Mátraverebély";
        case 3100:
            return "Salgótarján";
        case 3101:
            return "Salgótarján";
        case 3102:
            return "Salgótarján";
        case 3103:
            return "Salgótarján";
        case 3104:
            return "Salgótarján";
        case 3107:
            return "Salgótarján";
        case 3109:
            return "Salgótarján";
        case 3110:
            return "Salgótarján";
        case 3121:
            return "Salgótarján";
        case 3123:
            return "Cered";
        case 3124:
            return "Zabar";
        case 3125:
            return "Szilaspogony";
        case 3126:
            return "Bárna";
        case 3127:
            return "Kazár";
        case 3128:
            return "Vizslás";
        case 3129:
            return "Lucfalva";
        case 3131:
            return "Sóshartyán";
        case 3132:
            return "Nógrádmegyer";
        case 3133:
            return "Magyargéc";
        case 3134:
            return "Piliny";
        case 3135:
            return "Szécsényfelfalu";
        case 3136:
            return "Etes";
        case 3137:
            return "Karancsberény";
        case 3138:
            return "Ipolytarnóc";
        case 3141:
            return "Ipolytarnóc";
        case 3142:
            return "Mátraszele";
        case 3143:
            return "Mátranovák";
        case 3144:
            return "Mátranovák";
        case 3145:
            return "Mátraterenye";
        case 3146:
            return "Mátraterenye";
        case 3147:
            return "Mátraterenye";
        case 3151:
            return "Rákóczibánya";
        case 3152:
            return "Nemti";
        case 3153:
            return "Dorogháza";
        case 3154:
            return "Szuha";
        case 3155:
            return "Mátramindszent";
        case 3161:
            return "Kishartyán";
        case 3162:
            return "Ságújfalu";
        case 3163:
            return "Karancsság";
        // case 3163:
        //     return "Szalmatercs";
        case 3165:
            return "Endrefalva";
        case 3170:
            return "Szécsény";
        case 3175:
            return "Nagylóc";
        case 3176:
            return "Hollókő";
        case 3177:
            return "Rimóc";
        case 3178:
            return "Varsány";
        case 3179:
            return "Nógrádsipek";
        case 3181:
            return "Karancsalja";
        case 3182:
            return "Karancslapujtő";
        case 3183:
            return "Karancskeszi";
        case 3184:
            return "Mihálygerge";
        case 3185:
            return "Egyházasgerge";
        case 3186:
            return "Litke";
        case 3187:
            return "Nógrádszakál";
        case 3188:
            return "Ludányhalászi";
        case 3200:
            return "Gyöngyös";
        case 3201:
            return "Gyöngyös";
        case 3202:
            return "Gyöngyös";
        case 3211:
            return "Gyöngyösoroszi";
        case 3212:
            return "Gyöngyöshalász";
        case 3213:
            return "Atkár";
        case 3214:
            return "Nagyréde";
        case 3221:
            return "Nagyréde";
        case 3231:
            return "Gyöngyössolymos";
        case 3232:
            return "Gyöngyössolymos";
        case 3233:
            return "Gyöngyössolymos";
        case 3234:
            return "Gyöngyössolymos";
        case 3235:
            return "Mátraszentimre";
        case 3240:
            return "Parád";
        case 3242:
            return "Parádsasvár";
        case 3243:
            return "Bodony";
        case 3244:
            return "Bodony";
        case 3245:
            return "Recsk";
        case 3246:
            return "Mátraderecske";
        case 3247:
            return "Mátraballa";
        case 3248:
            return "Ivád";
        case 3250:
            return "Pétervására";
        case 3252:
            return "Erdőkövesd";
        case 3253:
            return "Istenmezeje";
        case 3254:
            return "Váraszó";
        case 3255:
            return "Fedémes";
        case 3256:
            return "Kisfüzes";
        case 3257:
            return "Bükkszenterzsébet";
        case 3258:
            return "Tarnalelesz";
        case 3259:
            return "Szentdomonkos";
        case 3261:
            return "Abasár";
        case 3262:
            return "Markaz";
        case 3263:
            return "Domoszló";
        case 3264:
            return "Kisnána";
        case 3265:
            return "Vécs";
        case 3271:
            return "Visonta ";
        case 3272:
            return "Visonta ";
        case 3273:
            return "Halmajugra";
        case 3274:
            return "Ludas";
        case 3275:
            return "Detk";
        case 3281:
            return "Karácsond";
        case 3282:
            return "Nagyfüged";
        case 3283:
            return "Tarnazsadány";
        case 3284:
            return "Tarnaméra";
        case 3291:
            return "Vámosgyörk";
        case 3292:
            return "Adács";
        case 3293:
            return "Visznek";
        case 3294:
            return "Tarnaörs";
        case 3295:
            return "Erk";
        case 3296:
            return "Zaránk";
        case 3300:
            return "Eger";
        case 3301:
            return "Eger";
        case 3302:
            return "Eger";
        case 3303:
            return "Eger";
        case 3304:
            return "Eger";
        case 3305:
            return "Eger";
        case 3321:
            return "Egerbakta";
        case 3322:
            return "Hevesaranyos";
        case 3323:
            return "Szarvaskő";
        case 3324:
            return "Felsőtárkány";
        case 3325:
            return "Noszvaj";
        case 3326:
            return "Ostoros";
        case 3327:
            return "Novaj";
        case 3328:
            return "Egerszólát";
        case 3331:
            return "Tarnaszentmária";
        case 3332:
            return "Sirok";
        case 3334:
            return "Szajla";
        // case 3334:
        //     return "Terpes";
        case 3335:
            return "Bükkszék";
        case 3336:
            return "Bátor";
        case 3337:
            return "Egerbocs";
        case 3341:
            return "Egercsehi";
        // case 3341:
        //     return "Szúcs";
        case 3343:
            return "Bekölce";
        case 3344:
            return "Mikófalva";
        case 3345:
            return "Mónosbél";
        // case 3346:
        //     return "Bélapátfalva";
        case 3346:
            return "Bükkszentmárton";
        case 3347:
            return "Balaton";
        case 3348:
            return "Szilvásvárad";
        case 3349:
            return "Nagyvisnyó";
        case 3350:
            return "Kál";
        case 3351:
            return "Verpelét";
        case 3352:
            return "Feldebrő";
        case 3353:
            return "Aldebrő";
        case 3354:
            return "Tófalu";
        case 3355:
            return "Kápolna";
        case 3356:
            return "Kompolt";
        case 3357:
            return "Nagyút";
        case 3358:
            return "Erdőtelek";
        case 3359:
            return "Tenk";
        case 3360:
            return "Heves";
        case 3368:
            return "Boconád";
        case 3369:
            return "Tarnabod";
        case 3371:
            return "Átány";
        case 3372:
            return "Kömlő";
        case 3373:
            return "Besenyőtelek";
        case 3374:
            return "Dormánd";
        case 3375:
            return "Mezőtárkány";
        case 3377:
            return "Szihalom";
        case 3378:
            return "Mezőszemere";
        case 3379:
            return "Egerfarmos";
        case 3381:
            return "Pély";
        case 3382:
            return "Tarnaszentmiklós";
        case 3383:
            return "Hevesvezekény";
        case 3384:
            return "Kisköre";
        case 3385:
            return "Tiszanána";
        case 3386:
            return "Sarud";
        case 3387:
            return "Újlőrincfalva";
        case 3388:
            return "Poroszló";
        case 3390:
            return "Füzesabony ";
        case 3392:
            return "Füzesabony ";
        case 3393:
            return "Füzesabony ";
        case 3394:
            return "Egerszalók";
        case 3395:
            return "Demjén";
        case 3396:
            return "Kerecsend";
        case 3397:
            return "Maklár";
        case 3398:
            return "Nagytálya";
        case 3399:
            return "Andornaktálya";
        case 3400:
            return "Mezőkövesd";
        case 3401:
            return "Mezőkövesd";
        case 3402:
            return "Mezőkövesd";
        case 3411:
            return "Szomolya";
        case 3412:
            return "Bogács";
        case 3413:
            return "Cserépfalu";
        case 3414:
            return "Bükkzsérc";
        case 3416:
            return "Tard";
        case 3417:
            return "Cserépváralja";
        case 3418:
            return "Szentistván";
        case 3421:
            return "Mezőnyárád";
        case 3422:
            return "Bükkábrány";
        case 3423:
            return "Tibolddaróc";
        case 3424:
            return "Kács";
        case 3425:
            return "Sály";
        case 3426:
            return "Borsodgeszt";
        case 3431:
            return "Vatta";
        case 3432:
            return "Emőd";
        case 3433:
            return "Nyékládháza ";
        case 3434:
            return "Mályi";
        case 3435:
            return "Mályi";
        case 3441:
            return "Mezőkeresztes ";
        case 3442:
            return "Csincse";
        case 3443:
            return "Mezőnagymihály";
        case 3444:
            return "Gelej";
        case 3450:
            return "Mezőcsát";
        case 3458:
            return "Tiszakeszi";
        case 3459:
            return "Igrici";
        case 3461:
            return "Egerlövő";
        case 3462:
            return "Borsodivánka";
        case 3463:
            return "Négyes";
        case 3464:
            return "Tiszavalk";
        case 3465:
            return "Tiszabábolna";
        case 3466:
            return "Tiszadorogma";
        case 3467:
            return "Ároktő";
        case 3500:
            return "Miskolc";
        case 3501:
            return "Miskolc";
        case 3502:
            return "Miskolc";
        case 3503:
            return "Miskolc";
        case 3504:
            return "Miskolc";
        case 3505:
            return "Miskolc";
        case 3506:
            return "Miskolc";
        case 3507:
            return "Miskolc";
        case 3508:
            return "Miskolc";
        case 3509:
            return "Miskolc";
        case 3510:
            return "Miskolc";
        case 3511:
            return "Miskolc";
        case 3513:
            return "Miskolc";
        case 3514:
            return "Miskolc";
        case 3515:
            return "Miskolc";
        case 3516:
            return "Miskolc";
        case 3517:
            return "Miskolc";
        case 3518:
            return "Miskolc";
        case 3519:
            return "Miskolc";
        case 3521:
            return "Miskolc";
        case 3523:
            return "Miskolc";
        case 3524:
            return "Miskolc";
        case 3525:
            return "Miskolc";
        case 3526:
            return "Miskolc";
        case 3527:
            return "Miskolc";
        case 3528:
            return "Miskolc";
        case 3529:
            return "Miskolc";
        case 3530:
            return "Miskolc";
        case 3531:
            return "Miskolc";
        case 3532:
            return "Miskolc";
        case 3533:
            return "Miskolc";
        case 3534:
            return "Miskolc";
        case 3535:
            return "Miskolc";
        case 3542:
            return "Miskolc";
        case 3543:
            return "Miskolc";
        case 3544:
            return "Miskolc";
        case 3545:
            return "Miskolc";
        case 3546:
            return "Miskolc";
        case 3547:
            return "Miskolc";
        case 3548:
            return "Miskolc";
        case 3549:
            return "Miskolc";
        case 3551:
            return "Ónod";
        case 3552:
            return "Muhi";
        case 3553:
            return "Kistokaj";
        case 3554:
            return "Bükkaranyos";
        case 3555:
            return "Harsány";
        case 3556:
            return "Kisgyőr";
        case 3557:
            return "Bükkszentkereszt";
        case 3559:
            return "Répáshuta";
        case 3561:
            return "Felsőzsolca";
        case 3562:
            return "Onga";
        case 3563:
            return "Hernádkak";
        case 3564:
            return "Hernádnémeti";
        case 3565:
            return "Tiszalúc";
        case 3571:
            return "Alsózsolca";
        case 3572:
            return "Sajólád";
        case 3573:
            return "Sajópetri";
        case 3574:
            return "Bőcs";
        case 3575:
            return "Berzék";
        case 3576:
            return "Sajóhidvég";
        case 3577:
            return "Köröm";
        case 3578:
            return "Girincs";
        // case 3578:
        //     return "Kiscsécs";
        case 3579:
            return "Kesznyéten";
        case 3580:
            return "Tiszaújváros";
        case 3581:
            return "Tiszaújváros";
        case 3582:
            return "Tiszaújváros";
        case 3585:
            return "Tiszaújváros";
        case 3586:
            return "Sajóörös";
        case 3587:
            return "Tiszapalkonya";
        case 3588:
            return "Hejőkürt";
        case 3589:
            return "Tiszatarján";
        case 3591:
            return "Oszlár";
        case 3592:
            return "Nemesbikk";
        case 3593:
            return "Hejőbába";
        case 3594:
            return "Hejőpapi";
        case 3595:
            return "Hejőszalonta";
        case 3596:
            return "Szakáld";
        case 3597:
            return "Hejőkeresztúr";
        case 3598:
            return "Nagycsécs";
        case 3599:
            return "Sajószöged";
        case 3600:
            return "Ózd";
        case 3601:
            return "Ózd";
        case 3603:
            return "Ózd";
        case 3604:
            return "Ózd";
        case 3607:
            return "Ózd";
        case 3608:
            return "Ózd";
        case 3621:
            return "Ózd";
        case 3622:
            return "Uppony";
        case 3623:
            return "Borsodszentgyörgy";
        case 3625:
            return "Borsodszentgyörgy";
        case 3626:
            return "Hangony";
        // case 3627:
        //     return "Domaháza";
        case 3627:
            return "Kissikátor";
        case 3630:
            return "Putnok";
        case 3635:
            return "Dubicsány";
        case 3636:
            return "Vadna";
        // case 3636:
        //     return "Sajógalgóc";
        case 3641:
            return "Nagybarca";
        case 3642:
            return "Bánhorváti";
        case 3643:
            return "Dédestapolcsány";
        case 3644:
            return "Tardona";
        case 3645:
            return "Mályinka";
        case 3646:
            return "Nekézseny";
        case 3647:
            return "Csokvaomány";
        case 3648:
            return "Csernely";
        // case 3648:
        //     return "Bükkmogyorósd";
        // case 3648:
        //     return "Lénárddaróc";
        case 3651:
            return "Lénárddaróc";
        case 3652:
            return "Sajónémeti";
        case 3653:
            return "Sajópüspöki";
        case 3654:
            return "Bánréve";
        case 3655:
            return "Hét";
        case 3656:
            return "Sajóvelezd";
        // case 3656:
        //     return "Sajómercse";
        case 3657:
            return "Királd";
        case 3658:
            return "Borsodbóta";
        case 3659:
            return "Sáta";
        case 3661:
            return "Sáta";
        case 3662:
            return "Sáta";
        case 3663:
            return "Arló";
        case 3664:
            return "Járdánháza";
        case 3671:
            return "Borsodnádasd ";
        case 3672:
            return "Borsodnádasd ";
        case 3700:
            return "Kazincbarcika";
        case 3701:
            return "Kazincbarcika";
        case 3702:
            return "Kazincbarcika";
        case 3703:
            return "Kazincbarcika";
        case 3704:
            return "Kazincbarcika";
        case 3705:
            return "Kazincbarcika";
        case 3711:
            return "Szirmabesenyő";
        case 3712:
            return "Sajóvámos";
        // case 3712:
        //     return "Sajósenye";
        case 3713:
            return "Arnót";
        case 3714:
            return "Sajópálfala";
        case 3715:
            return "Gesztely";
        case 3716:
            return "Újcsanálos";
        // case 3716:
        //     return "Sóstófalva";
        case 3717:
            return "Alsódobsza";
        case 3718:
            return "Megyaszó";
        case 3720:
            return "Sajókaza";
        // case 3720:
        //     return "Sajóivánka";
        case 3721:
            return "Felsőnyárád";
        // case 3721:
        //     return "Dövény";
        // case 3721:
        //     return "Jákfalva";
        case 3722:
            return "Felsőkelecsény";
        case 3723:
            return "Zubogy";
        case 3724:
            return "Ragály";
        // case 3724:
        //     return "Imola";
        // case 3724:
        //     return "Trizs";
        case 3726:
            return "Zádorfalva";
        // case 3726:
        //     return "Alsószuha";
        // case 3726:
        //     return "Szuhafő";
        case 3728:
            return "Kelemér";
        // case 3728:
        //     return "Gömörszőlős";
        case 3729:
            return "Serényfalva";
        case 3731:
            return "Szuhakálló";
        case 3732:
            return "Kurityán";
        case 3733:
            return "Rudabánya";
        case 3734:
            return "Szuhogy";
        case 3735:
            return "Felsőtelekes";
        // case 3735:
        //     return "Alsótelekes";
        // case 3735:
        //     return "Kánó";
        case 3741:
            return "Izsófalva";
        case 3742:
            return "Rudolftelep";
        case 3743:
            return "Ormosbánya";
        case 3744:
            return "Múcsony";
        case 3751:
            return "Szendrőlád";
        case 3752:
            return "Szendrő";
        // case 3752:
        //     return "Galvács";
        case 3753:
            return "Abod";
        case 3754:
            return "Szalonna";
        // case 3754:
        //     return "Meszes";
        case 3755:
            return "Martonyi";
        case 3756:
            return "Perkupa";
        // case 3756:
        //     return "Varbóc";
        case 3757:
            return "Szőlősardó";
        // case 3757:
        //     return "Égerszög";
        // case 3757:
        //     return "Teresztenye";
        case 3758:
            return "Jósvafő";
        case 3759:
            return "Aggtelek";
        case 3761:
            return "Szin";
        // case 3761:
        //     return "Szinpetri";
        // case 3761:
        //     return "Tornakápolna";
        case 3762:
            return "Szögliget";
        case 3763:
            return "Bódvaszilas";
        case 3764:
            return "Bódvarákó";
        case 3765:
            return "Komjáti";
        // case 3765:
        //     return "Tornabarakony";
        // case 3765:
        //     return "Tornaszentandrás";
        case 3767:
            return "Tornanádaska";
        case 3768:
            return "Hidvégardó";
        // case 3768:
        //     return "Becskeháza";
        // case 3768:
        //     return "Bódvalenke";
        case 3769:
            return "Tornaszentjakab";
        case 3770:
            return "Sajószentpéter";
        case 3773:
            return "Sajókápolna";
        // case 3773:
        //     return "Sajólászlófalva";
        case 3775:
            return "Kondó";
        case 3776:
            return "Radostyán";
        case 3777:
            return "Parasznya";
        case 3778:
            return "Varbó";
        case 3779:
            return "Alacska";
        case 3780:
            return "Edelény ";
        // case 3780:
        //     return "Balajt";
        // case 3780:
        //     return "Damak";
        // case 3780:
        //     return "Ládbesenyő";
        case 3783:
            return "Ládbesenyő";
        case 3786:
            return "Lak";
        // case 3786:
        //     return "Hegymeg";
        // case 3786:
        //     return "Irota";
        // case 3786:
        //     return "Szakácsi";
        case 3787:
            return "Tomor";
        case 3791:
            return "Sajókeresztúr";
        case 3792:
            return "Sajóbábony";
        case 3793:
            return "Sajóecseg";
        case 3794:
            return "Boldva";
        // case 3794:
        //     return "Ziliz";
        case 3795:
            return "Hangács";
        // case 3795:
        //     return "Nyomár";
        case 3796:
            return "Borsodszirák";
        case 3800:
            return "Szikszó";
        case 3809:
            return "Selyeb";
        // case 3809:
        //     return "Abaújszolnok";
        // case 3809:
        //     return "Nyésta";
        case 3811:
            return "Alsóvadász";
        case 3812:
            return "Homrogd";
        // case 3812:
        //     return "Monaj";
        case 3813:
            return "Kupa";
        case 3814:
            return "Felsővadász";
        case 3815:
            return "Gadna";
        // case 3815:
        //     return "Abaújlak";
        case 3816:
            return "Gagyvendégi";
        case 3817:
            return "Gagybátor";
        case 3821:
            return "Krasznokvajda";
        // case 3821:
        //     return "Büttös";
        // case 3821:
        //     return "Kány";
        // case 3821:
        //     return "Keresztéte";
        // case 3821:
        //     return "Pamlény";
        // case 3821:
        //     return "Perecse";
        // case 3821:
        //     return "Szászfa";
        case 3825:
            return "Rakaca";
        // case 3825:
        //     return "Debréte";
        // case 3825:
        //     return "Viszló";
        case 3826:
            return "Rakacaszend";
        case 3831:
            return "Kázsmárk";
        case 3832:
            return "Léh";
        case 3833:
            return "Rásonysápberencs";
        case 3834:
            return "Detek";
        // case 3834:
        //     return "Beret";
        case 3836:
            return "Baktakék";
        case 3837:
            return "Felsőgagy";
        // case 3837:
        //     return "Alsógagy";
        // case 3837:
        //     return "Csenyéte";
        // case 3837:
        //     return "Gagyapáti";
        case 3841:
            return "Aszaló";
        case 3842:
            return "Halmaj";
        case 3843:
            return "Kiskinizs";
        case 3844:
            return "Nagykinizs";
        // case 3844:
        //     return "Szentistvánbaksa";
        case 3846:
            return "Hernádkércs";
        case 3847:
            return "Felsődobsza";
        case 3848:
            return "Csobád";
        case 3849:
            return "Forró";
        case 3851:
            return "Ináncs";
        case 3852:
            return "Hernádszentandrás";
        case 3853:
            return "Pere";
        // case 3853:
        //     return "Hernádbűd";
        case 3854:
            return "Hernádbűd";
        case 3855:
            return "Fancsal";
        case 3860:
            return "Encs";
        case 3861:
            return "Encs";
        case 3863:
            return "Szalaszend";
        case 3864:
            return "Fulókércs";
        case 3865:
            return "Fáj";
        case 3866:
            return "Szemere";
        // case 3866:
        //     return "Litka";
        case 3871:
            return "Méra";
        case 3872:
            return "Novajidrány";
        case 3873:
            return "Garadna";
        case 3874:
            return "Hernádvécse";
        // case 3874:
        //     return "Hernádpetri";
        // case 3874:
        //     return "Pusztaradvány";
        case 3875:
            return "Hernádszurdok";
        case 3876:
            return "Hidasnémeti";
        case 3877:
            return "Tornyosnémeti";
        case 3881:
            return "Abaújszántó";
        // case 3881:
        //     return "Baskó";
        // case 3881:
        //     return "Sima";
        case 3882:
            return "Abaújkér";
        // case 3882:
        //     return "Abaújalpár";
        case 3884:
            return "Boldogkőújfalu";
        case 3885:
            return "Boldogkőváralja";
        // case 3885:
        //     return "Arka";
        case 3886:
            return "Korlát";
        case 3887:
            return "Hernádcéce";
        case 3888:
            return "Vizsoly";
        case 3891:
            return "Vilmány";
        case 3892:
            return "Hejce";
        // case 3893:
        //     return "Fony";
        // case 3893:
        //     return "Mogyoróska";
        case 3893:
            return "Regéc";
        case 3894:
            return "Göncruszka";
        case 3895:
            return "Gönc";
        case 3896:
            return "Telkibánya";
        case 3897:
            return "Zsujta";
        case 3898:
            return "Abaújvár";
        // case 3898:
        //     return "Pányok";
        case 3899:
            return "Kéked";
        case 3900:
            return "Szerencs";
        case 3901:
            return "Szerencs";
        case 3903:
            return "Bekecs";
        case 3904:
            return "Legyesbénye";
        case 3905:
            return "Monok";
        case 3906:
            return "Golop";
        case 3907:
            return "Tállya";
        case 3908:
            return "Rátka";
        case 3909:
            return "Mád";
        case 3910:
            return "Tokaj";
        case 3911:
            return "Tokaj";
        case 3915:
            return "Tarcal";
        case 3916:
            return "Bodrogkeresztúr";
        case 3917:
            return "Bodrogkisfalud";
        case 3918:
            return "Szegi";
        // case 3918:
        //     return "Szegilong";
        case 3921:
            return "Taktaszada";
        case 3922:
            return "Taktaharkány";
        case 3923:
            return "Taktaharkány";
        case 3924:
            return "Taktakenéz";
        case 3925:
            return "Prügy";
        case 3926:
            return "Taktabáj";
        case 3927:
            return "Csobaj";
        case 3928:
            return "Tiszatardos";
        case 3929:
            return "Tiszaladány";
        case 3931:
            return "Mezőzombor";
        case 3932:
            return "Erdőbénye";
        case 3933:
            return "Olaszliszka";
        case 3934:
            return "Tolcsva";
        case 3935:
            return "Erdőhorváti";
        case 3936:
            return "Háromhuta";
        case 3937:
            return "Komlóska";
        case 3941:
            return "Vámosújfalu";
        case 3942:
            return "Sárazsadány";
        case 3943:
            return "Bodrogolaszi";
        case 3944:
            return "Bodrogolaszi";
        case 3945:
            return "Bodrogolaszi";
        case 3950:
            return "Sárospatak";
        case 3954:
            return "Györgytarló";
        case 3955:
            return "Kenézlő";
        case 3956:
            return "Viss";
        case 3957:
            return "Zalkod";
        case 3958:
            return "Hercegkút";
        case 3959:
            return "Makkoshotyka";
        case 3961:
            return "Vajdácska";
        case 3962:
            return "Karos";
        case 3963:
            return "Karcsa";
        case 3964:
            return "Pácin";
        case 3965:
            return "Nagyrozvágy";
        // case 3965:
        //     return "Kisrozvágy";
        case 3967:
            return "Lácacséke";
        case 3971:
            return "Tiszakarád";
        case 3972:
            return "Tiszacsermely";
        case 3973:
            return "Cigánd";
        case 3974:
            return "Ricse";
        // case 3974:
        //     return "Semjén";
        case 3976:
            return "Révleányvár";
        case 3977:
            return "Zemplénagárd";
        case 3978:
            return "Dámóc";
        case 3980:
            return "Sátoraljaújhely";
        case 3981:
            return "Sátoraljaújhely";
        case 3985:
            return "Alsóberecki";
        // case 3985:
        //     return "Felsőberecki";
        case 3987:
            return "Bodroghalom";
        case 3988:
            return "Bodroghalom";
        case 3989:
            return "Mikóháza";
        // case 3989:
        //     return "Alsóregmec";
        // case 3989:
        //     return "Felsőregmec";
        case 3991:
            return "Vilyvitány";
        case 3992:
            return "Kovácsvágás";
        // case 3992:
        //     return "Vágáshuta";
        case 3993:
            return "Füzérradvány";
        case 3994:
            return "Pálháza";
        // case 3994:
        //     return "Bózsva";
        // case 3994:
        //     return "Filkeháza";
        // case 3994:
        //     return "Füzérkajata";
        // case 3994:
        //     return "Kishuta";
        // case 3994:
        //     return "Nagyhuta";
        case 3995:
            return "Pusztafalu";
        case 3996:
            return "Füzér";
        // case 3997:
        //     return "Füzérkomlós";
        case 3997:
            return "Nyíri";
        case 3999:
            return "Hollóháza";
        case 4000:
            return "Debrecen";
        case 4001:
            return "Debrecen";
        case 4002:
            return "Debrecen";
        case 4003:
            return "Debrecen";
        case 4004:
            return "Debrecen";
        case 4005:
            return "Debrecen";
        case 4006:
            return "Debrecen";
        case 4007:
            return "Debrecen";
        case 4008:
            return "Debrecen";
        case 4009:
            return "Debrecen";
        case 4010:
            return "Debrecen";
        case 4011:
            return "Debrecen";
        case 4012:
            return "Debrecen";
        case 4013:
            return "Debrecen";
        case 4014:
            return "Debrecen";
        case 4015:
            return "Debrecen";
        case 4017:
            return "Debrecen";
        case 4021:
            return "Debrecen";
        case 4022:
            return "Debrecen";
        case 4024:
            return "Debrecen";
        case 4025:
            return "Debrecen";
        case 4026:
            return "Debrecen";
        case 4027:
            return "Debrecen";
        case 4028:
            return "Debrecen";
        case 4029:
            return "Debrecen";
        case 4030:
            return "Debrecen";
        case 4031:
            return "Debrecen";
        case 4032:
            return "Debrecen";
        case 4033:
            return "Debrecen";
        case 4034:
            return "Debrecen";
        case 4040:
            return "Debrecen";
        case 4041:
            return "Debrecen";
        case 4042:
            return "Debrecen";
        case 4044:
            return "Debrecen";
        case 4045:
            return "Debrecen";
        case 4046:
            return "Debrecen";
        case 4047:
            return "Debrecen";
        case 4048:
            return "Debrecen";
        case 4060:
            return "Balmazújváros";
        case 4063:
            return "Balmazújváros";
        case 4064:
            return "Nagyhegyes";
        case 4065:
            return "Újszentmargita";
        case 4066:
            return "Tiszacsege";
        case 4067:
            return "Tiszacsege";
        case 4069:
            return "Egyek";
        case 4071:
            return "Hortobágy";
        case 4074:
            return "Hortobágy";
        case 4075:
            return "Görbeháza";
        case 4078:
            return "Görbeháza";
        case 4079:
            return "Görbeháza";
        case 4080:
            return "Hajdúnánás";
        case 4081:
            return "Hajdúnánás";
        case 4085:
            return "Hajdúnánás";
        case 4086:
            return "Hajdúnánás";
        case 4087:
            return "Hajdúdorog";
        case 4090:
            return "Polgár";
        // case 4090:
        //     return "Folyás";
        case 4096:
            return "Újtikos";
        case 4097:
            return "Tiszagyulaháza";
        case 4100:
            return "Berettyóújfalu";
        case 4101:
            return "Berettyóújfalu";
        case 4103:
            return "Berettyóújfalu";
        case 4110:
            return "Biharkeresztes";
        case 4114:
            return "Bojt";
        case 4115:
            return "Ártánd ";
        case 4116:
            return "Berekböszörmény";
        case 4117:
            return "Told ";
        case 4118:
            return "Mezőpeterd";
        case 4119:
            return "Váncsod";
        case 4121:
            return "Szentpéterszeg";
        case 4122:
            return "Gáborján";
        case 4123:
            return "Hencida";
        case 4124:
            return "Esztár";
        case 4125:
            return "Pocsaj";
        case 4126:
            return "Kismarja";
        case 4127:
            return "Nagykereki";
        case 4128:
            return "Bedő";
        case 4130:
            return "Derecske";
        case 4131:
            return "Derecske";
        case 4132:
            return "Tépe";
        case 4133:
            return "Konyár";
        case 4134:
            return "Mezősas";
        case 4135:
            return "Körösszegapáti";
        case 4136:
            return "Körösszakál";
        case 4137:
            return "Magyarhomorog";
        case 4138:
            return "Komádi";
        case 4141:
            return "Furta";
        case 4142:
            return "Zsáka";
        case 4143:
            return "Vekerd";
        case 4144:
            return "Darvas";
        case 4145:
            return "Csökmő";
        case 4146:
            return "Újiráz";
        case 4150:
            return "Püspökladány";
        case 4151:
            return "Püspökladány";
        case 4152:
            return "Püspökladány";
        case 4155:
            return "Püspökladány";
        case 4161:
            return "Báránd";
        case 4162:
            return "Báránd";
        case 4163:
            return "Szerep";
        case 4164:
            return "Bakonszeg";
        case 4171:
            return "Sárrétudvari";
        case 4172:
            return "Biharnagybajom";
        case 4173:
            return "Nagyrábé";
        case 4174:
            return "Bihartorda";
        case 4175:
            return "Bihardancsháza";
        case 4176:
            return "Sáp";
        case 4177:
            return "Földes";
        case 4181:
            return "Nádudvar";
        case 4183:
            return "Kaba";
        case 4184:
            return "Tetétlen";
        case 4200:
            return "Hajdúszoboszló";
        case 4201:
            return "Hajdúszoboszló";
        case 4202:
            return "Hajdúszoboszló";
        case 4211:
            return "Ebes";
        case 4212:
            return "Hajdúszovát";
        case 4220:
            return "Hajdúböszörmény";
        case 4221:
            return "Hajdúböszörmény";
        case 4224:
            return "Hajdúböszörmény";
        case 4225:
            return "Debrecen-Józsa";
        case 4231:
            return "Bököny";
        case 4232:
            return "Geszteréd";
        case 4233:
            return "Balkány";
        case 4234:
            return "Szakoly";
        case 4235:
            return "Biri";
        case 4241:
            return "Bocskaikert";
        case 4242:
            return "Hajdúhadház";
        case 4243:
            return "Téglás";
        case 4244:
            return "Újfehértó";
        case 4245:
            return "Érpatak";
        case 4246:
            return "Érpatak";
        case 4251:
            return "Hajdúsámson";
        case 4252:
            return "Hajdúsámson";
        case 4253:
            return "Hajdúsámson";
        case 4254:
            return "Nyíradony";
        case 4262:
            return "Nyíracsád";
        case 4263:
            return "Nyírmártonfalva";
        case 4264:
            return "Nyírábrány";
        case 4266:
            return "Fülöp";
        case 4267:
            return "Penészlek";
        case 4271:
            return "Mikepércs";
        case 4272:
            return "Sáránd";
        case 4273:
            return "Hajdúbagos";
        case 4274:
            return "Hosszúpályi";
        case 4275:
            return "Monostorpályi";
        case 4281:
            return "Létavértes ";
        case 4283:
            return "Létavértes ";
        case 4284:
            return "Kokad";
        case 4285:
            return "Álmosd";
        case 4286:
            return "Bagamér";
        case 4287:
            return "Vámospércs";
        case 4288:
            return "Újléta";
        case 4300:
            return "Nyírbátor";
        case 4301:
            return "Nyírbátor";
        case 4311:
            return "Nyírgyulaj";
        case 4320:
            return "Nagykálló";
        case 4321:
            return "Nagykálló";
        case 4324:
            return "Kállósemjén";
        case 4325:
            return "Kisléta";
        case 4326:
            return "Máriapócs";
        case 4327:
            return "Pócspetri";
        case 4331:
            return "Nyírcsászári";
        case 4332:
            return "Nyírderzs";
        case 4333:
            return "Nyírkáta";
        case 4334:
            return "Hodász";
        case 4335:
            return "Kántorjánosi";
        case 4336:
            return "Őr";
        case 4337:
            return "Jármi";
        case 4338:
            return "Papos ";
        case 4341:
            return "Nyírvasvári";
        case 4342:
            return "Terem";
        case 4343:
            return "Bátorliget";
        case 4351:
            return "Vállaj";
        case 4352:
            return "Mérk";
        case 4353:
            return "Tiborszállás";
        case 4354:
            return "Fábiánháza";
        case 4355:
            return "Nagyecsed";
        case 4356:
            return "Nyírcsaholy";
        case 4361:
            return "Nyírbogát";
        case 4362:
            return "Nyírgelse";
        case 4363:
            return "Nyírmihálydi";
        case 4371:
            return "Nyírlugos";
        case 4372:
            return "Nyírbéltek";
        case 4373:
            return "Ömböly";
        case 4374:
            return "Encsencs";
        case 4375:
            return "Piricse";
        case 4376:
            return "Nyírpilis";
        case 4400:
            return "Nyíregyháza";
        case 4401:
            return "Nyíregyháza";
        case 4402:
            return "Nyíregyháza";
        case 4403:
            return "Nyíregyháza";
        case 4404:
            return "Nyíregyháza";
        case 4405:
            return "Nyíregyháza";
        case 4406:
            return "Nyíregyháza";
        case 4407:
            return "Nyíregyháza";
        case 4410:
            return "Nyíregyháza";
        case 4411:
            return "Nyíregyháza";
        case 4412:
            return "Nyíregyháza";
        case 4431:
            return "Nyíregyháza";
        case 4432:
            return "Nyíregyháza";
        case 4433:
            return "Nyíregyháza";
        case 4434:
            return "Kálmánháza";
        case 4440:
            return "Tiszavasvári";
        case 4441:
            return "Szorgalmatos";
        case 4444:
            return "Szorgalmatos";
        case 4445:
            return "Nagycserkesz";
        case 4446:
            return "Nagycserkesz";
        case 4447:
            return "Nagycserkesz";
        case 4450:
            return "Tiszalök";
        case 4455:
            return "Tiszadada";
        case 4456:
            return "Tiszadob";
        case 4461:
            return "Nyírtelek";
        case 4463:
            return "Tiszanagyfalu";
        case 4464:
            return "Tiszaeszlár    ";
        case 4465:
            return "Rakamaz";
        case 4466:
            return "Timár";
        case 4467:
            return "Szabolcs";
        case 4468:
            return "Balsa";
        case 4471:
            return "Gávavencsellő   ";
        case 4472:
            return "Gávavencsellő   ";
        case 4474:
            return "Tiszabercel";
        case 4475:
            return "Paszab";
        case 4481:
            return "Paszab";
        case 4482:
            return "Kótaj";
        case 4483:
            return "Buj";
        case 4484:
            return "Ibrány";
        case 4485:
            return "Nagyhalász";
        case 4486:
            return "Nagyhalász";
        case 4487:
            return "Tiszatelek";
        case 4488:
            return "Beszterec";
        case 4491:
            return "Újdombrád";
        case 4492:
            return "Dombrád";
        case 4493:
            return "Tiszakanyár";
        case 4494:
            return "Kékcse";
        case 4495:
            return "Döge";
        case 4496:
            return "Szabolcsveresmart";
        case 4501:
            return "Kemecse";
        case 4502:
            return "Vasmegyer";
        case 4503:
            return "Tiszarád";
        case 4511:
            return "Nyírbogdány ";
        case 4512:
            return "Nyírbogdány ";
        case 4515:
            return "Kék";
        case 4516:
            return "Demecser";
        case 4517:
            return "Gégény";
        case 4521:
            return "Berkesz ";
        case 4522:
            return "Nyírtass";
        case 4523:
            return "Pátroha";
        case 4524:
            return "Ajak";
        case 4525:
            return "Rétközberencs";
        case 4531:
            return "Nyírpazony";
        case 4532:
            return "Nyírtura";
        case 4533:
            return "Sényő";
        case 4534:
            return "Székely";
        case 4535:
            return "Nyíribrony";
        case 4536:
            return "Ramocsaháza";
        case 4537:
            return "Nyírkércs";
        case 4541:
            return "Nyírjákó";
        case 4542:
            return "Petneháza";
        case 4543:
            return "Laskod";
        case 4544:
            return "Nyírkarász";
        case 4545:
            return "Gyulaháza";
        case 4546:
            return "Anarcs";
        case 4547:
            return "Szabolcsbáka";
        case 4551:
            return "Nyíregyháza";
        case 4552:
            return "Napkor";
        case 4553:
            return "Apagy";
        case 4554:
            return "Nyírtét";
        case 4555:
            return "Levelek";
        case 4556:
            return "Magy";
        case 4557:
            return "Besenyőd";
        case 4558:
            return "Ófehértó";
        case 4561:
            return "Baktalórántháza";
        case 4562:
            return "Vaja";
        case 4563:
            return "Rohod";
        case 4564:
            return "Nyírmada";
        case 4565:
            return "Pusztadobos";
        case 4566:
            return "Ilk";
        case 4567:
            return "Gemzse";
        case 4600:
            return "Kisvárda";
        case 4601:
            return "Kisvárda";
        case 4603:
            return "Kisvárda";
        case 4611:
            return "Jéke";
        case 4621:
            return "Fényeslitke";
        case 4622:
            return "Komoró";
        case 4623:
            return "Tuzsér";
        case 4624:
            return "Tiszabezdéd";
        case 4625:
            return "Záhony";
        // case 4625:
        //     return "Győröcske";
        case 4627:
            return "Zsurk";
        case 4628:
            return "Tiszaszentmárton";
        case 4631:
            return "Pap";
        case 4632:
            return "Nyírlövő";
        case 4633:
            return "Lövőpetri";
        case 4634:
            return "Aranyosapáti";
        case 4635:
            return "Újkenéz";
        case 4641:
            return "Mezőladány";
        case 4642:
            return "Tornyospálca";
        case 4643:
            return "Benk";
        case 4644:
            return "Mándok";
        case 4645:
            return "Tiszamogyorós";
        case 4646:
            return "Eperjeske";
        case 4700:
            return "Mátészalka";
        case 4701:
            return "Mátészalka";
        case 4702:
            return "Mátészalka";
        case 4721:
            return "Szamoskér";
        case 4722:
            return "Nyírmeggyes";
        case 4731:
            return "Tunyogmatolcs";
        case 4732:
            return "Cégénydányád";
        case 4733:
            return "Gyügye";
        case 4734:
            return "Szamosújlak";
        case 4735:
            return "Szamossályi";
        // case 4735:
        //     return "Hermánszeg";
        case 4737:
            return "Kisnamény";
        // case 4737:
        //     return "Darnó";
        case 4741:
            return "Jánkmajtis";
        case 4742:
            return "Csegöld";
        case 4743:
            return "Csengersima";
        case 4745:
            return "Szamosbecs";
        case 4746:
            return "Szamostatárfalva";
        case 4751:
            return "Kocsord";
        case 4752:
            return "Győrtelek";
        case 4754:
            return "Géberjén";
        // case 4754:
        //     return "Fülpösdaróc";
        case 4755:
            return "Ököritófülpös";
        case 4756:
            return "Rápolt";
        case 4761:
            return "Porcsalma";
        case 4762:
            return "Tyukod";
        case 4763:
            return "Ura";
        case 4764:
            return "Csengerújfalu";
        case 4765:
            return "Csenger";
        // case 4765:
        //     return "Komlódtótfalu";
        case 4766:
            return "Pátyod";
        case 4767:
            return "Szamosangyalos";
        case 4800:
            return "Vásárosnamény";
        case 4801:
            return "Vásárosnamény";
        case 4803:
            return "Vásárosnamény";
        case 4804:
            return "Vásárosnamény";
        case 4811:
            return "Kisvarsány";
        case 4812:
            return "Nagyvarsány";
        case 4813:
            return "Gyüre ";
        case 4821:
            return "Ópályi";
        case 4822:
            return "Nyírparasznya";
        case 4823:
            return "Nagydobos";
        case 4824:
            return "Szamosszeg";
        case 4826:
            return "Olcsva";
        case 4831:
            return "Tiszaszalka";
        case 4832:
            return "Tiszavid";
        case 4833:
            return "Tiszaadony";
        case 4834:
            return "Tiszakerecseny";
        case 4835:
            return "Mátyus";
        case 4836:
            return "Lónya";
        case 4841:
            return "Jánd";
        case 4842:
            return "Gulács";
        case 4843:
            return "Hetefejércse";
        case 4844:
            return "Csaroda";
        case 4845:
            return "Tákos";
        case 4900:
            return "Fehérgyarmat";
        case 4901:
            return "Fehérgyarmat";
        case 4911:
            return "Nábrád";
        case 4912:
            return "Kérsemjén";
        case 4913:
            return "Panyola";
        case 4914:
            return "Olcsvaapáti";
        case 4921:
            return "Kisar";
        // case 4921:
        //     return "Tivadar";
        case 4922:
            return "Nagyar";
        case 4931:
            return "Tarpa";
        case 4932:
            return "Márokpapi";
        case 4933:
            return "Beregsurány";
        case 4934:
            return "Beregdaróc";
        case 4935:
            return "Gelénes";
        case 4936:
            return "Vámosatya";
        case 4937:
            return "Barabás";
        case 4941:
            return "Penyige";
        case 4942:
            return "Mánd";
        // case 4942:
        //     return "Nemesborzova";
        case 4943:
            return "Kömörő";
        case 4944:
            return "Túristvándi";
        case 4945:
            return "Szatmárcseke";
        case 4946:
            return "Tiszakóród";
        case 4947:
            return "Tiszacsécse";
        case 4948:
            return "Milota";
        case 4951:
            return "Tiszabecs";
        case 4952:
            return "Uszka";
        case 4953:
            return "Magosliget";
        case 4954:
            return "Sonkád";
        case 4955:
            return "Botpalád";
        case 4956:
            return "Kispalád";
        case 4961:
            return "Zsarolyán";
        case 4962:
            return "Nagyszekeres";
        case 4963:
            return "Kisszekeres";
        case 4964:
            return "Fülesd";
        case 4965:
            return "Kölcse";
        case 4966:
            return "Vámosoroszi";
        case 4967:
            return "Csaholc";
        case 4968:
            return "Túrricse";
        case 4969:
            return "Tisztaberek";
        case 4971:
            return "Rozsály";
        case 4972:
            return "Gacsály";
        case 4973:
            return "Császló";
        case 4974:
            return "Zajta";
        case 4975:
            return "Méhtelek";
        case 4976:
            return "Garbolc";
        case 4977:
            return "Nagyhódos";
        // case 4977:
        //     return "Kishódos";
        case 5000:
            return "Szolnok";
        case 5001:
            return "Szolnok";
        case 5002:
            return "Szolnok";
        case 5003:
            return "Szolnok";
        case 5004:
            return "Szolnok";
        case 5005:
            return "Szolnok";
        case 5006:
            return "Szolnok";
        case 5007:
            return "Szolnok";
        case 5008:
            return "Szolnok";
        case 5009:
            return "Szolnok";
        case 5051:
            return "Zagyvarékas";
        case 5052:
            return "Újszász";
        case 5053:
            return "Szászberek";
        case 5054:
            return "Jászalsószentgyörgy";
        case 5055:
            return "Jászladány";
        case 5061:
            return "Tiszasüly";
        case 5062:
            return "Kőtelek";
        case 5063:
            return "Hunyadfalva";
        case 5064:
            return "Csataszög";
        case 5065:
            return "Nagykörű";
        case 5071:
            return "Besenyszög";
        case 5081:
            return "Szajol";
        case 5082:
            return "Tiszatenyő";
        case 5083:
            return "Kengyel";
        case 5084:
            return "Rákócziújfalu";
        case 5085:
            return "Rákóczifalva";
        case 5091:
            return "Tószeg";
        case 5092:
            return "Tiszavárkony";
        case 5093:
            return "Vezseny";
        case 5094:
            return "Tiszajenő";
        case 5095:
            return "Tiszajenő";
        case 5100:
            return "Jászberény";
        case 5101:
            return "Jászberény";
        case 5102:
            return "Jászberény";
        case 5111:
            return "Jászfelsőszentgyörgy";
        case 5121:
            return "Jászjákóhalma";
        case 5122:
            return "Jászdózsa";
        case 5123:
            return "Jászárokszállás";
        case 5124:
            return "Jászágó";
        case 5125:
            return "Pusztamonostor";
        case 5126:
            return "Jászfényszaru";
        case 5130:
            return "Jászapáti";
        case 5131:
            return "Jászapáti";
        case 5135:
            return "Jászivány";
        case 5136:
            return "Jászszentandrás";
        case 5137:
            return "Jászkisér";
        case 5141:
            return "Jásztelek";
        case 5142:
            return "Alattyán";
        case 5143:
            return "Jánoshida";
        case 5144:
            return "Jászboldogháza";
        case 5152:
            return "Jászboldogháza";
        case 5200:
            return "Törökszentmiklós";
        case 5201:
            return "Törökszentmiklós";
        case 5211:
            return "Tiszapüspöki";
        case 5212:
            return "Tiszapüspöki";
        case 5213:
            return "Tiszapüspöki";
        case 5222:
            return "Örményes";
        case 5231:
            return "Fegyvernek";
        case 5232:
            return "Tiszabő";
        case 5233:
            return "Tiszagyenda";
        case 5234:
            return "Tiszaroff";
        case 5235:
            return "Tiszabura";
        case 5241:
            return "Abádszalók";
        case 5243:
            return "Tiszaderzs";
        case 5244:
            return "Tiszaszőlős";
        case 5300:
            return "Karcag";
        case 5301:
            return "Karcag";
        case 5302:
            return "Karcag";
        case 5309:
            return "Berekfürdő";
        case 5310:
            return "Kisújszállás";
        case 5311:
            return "Kisújszállás";
        case 5321:
            return "Kunmadaras";
        case 5322:
            return "Tiszaszentimre";
        case 5323:
            return "Tiszaszentimre";
        case 5324:
            return "Tomajmonostora";
        case 5331:
            return "Kenderes";
        case 5340:
            return "Kunhegyes";
        case 5341:
            return "Kunhegyes";
        case 5349:
            return "Kunhegyes";
        case 5350:
            return "Tiszafüred";
        case 5351:
            return "Tiszafüred";
        case 5358:
            return "Tiszafüred";
        case 5359:
            return "Tiszafüred";
        case 5361:
            return "Tiszaigar";
        case 5362:
            return "Tiszaörs";
        case 5363:
            return "Nagyiván";
        case 5400:
            return "Mezőtúr";
        case 5401:
            return "Mezőtúr";
        case 5403:
            return "Mezőtúr";
        case 5404:
            return "Mezőtúr";
        case 5411:
            return "Kétpó";
        case 5412:
            return "Kuncsorba";
        case 5420:
            return "Túrkeve";
        case 5421:
            return "Túrkeve";
        case 5430:
            return "Tiszaföldvár";
        case 5431:
            return "Tiszaföldvár";
        case 5432:
            return "Tiszaföldvár";
        case 5435:
            return "Martfű";
        case 5440:
            return "Kunszentmárton";
        case 5441:
            return "Kunszentmárton";
        case 5449:
            return "Kunszentmárton";
        case 5451:
            return "Öcsöd";
        case 5452:
            return "Mesterszállás";
        case 5453:
            return "Mezőhék";
        case 5461:
            return "Mezőhék";
        case 5462:
            return "Cibakháza";
        case 5463:
            return "Nagyrév";
        case 5464:
            return "Tiszainoka";
        case 5465:
            return "Cserkeszőlő";
        case 5471:
            return "Tiszakürt";
        case 5473:
            return "Tiszaug";
        case 5474:
            return "Tiszasas";
        case 5475:
            return "Csépa";
        case 5476:
            return "Szelevény";
        case 5500:
            return "Gyomaendrőd    ";
        case 5501:
            return "Gyomaendrőd    ";
        case 5502:
            return "Gyomaendrőd    ";
        case 5510:
            return "Dévaványa";
        case 5515:
            return "Ecsegfalva";
        case 5516:
            return "Körösladány";
        case 5520:
            return "Szeghalom";
        case 5521:
            return "Szeghalom";
        case 5525:
            return "Füzesgyarmat";
        case 5526:
            return "Kertészsziget";
        case 5527:
            return "Bucsa";
        case 5530:
            return "Vésztő";
        case 5534:
            return "Okány";
        case 5536:
            return "Körösújfalu";
        case 5537:
            return "Zsadány";
        case 5538:
            return "Biharugra";
        case 5539:
            return "Körösnagyharsány";
        case 5540:
            return "Szarvas";
        case 5541:
            return "Szarvas";
        case 5551:
            return "Csabacsűd";
        case 5552:
            return "Kardos";
        case 5553:
            return "Kondoros";
        case 5555:
            return "Hunya";
        case 5556:
            return "Örménykút";
        case 5561:
            return "Békésszentandrás";
        case 5600:
            return "Békéscsaba";
        case 5601:
            return "Békéscsaba";
        case 5602:
            return "Békéscsaba";
        case 5603:
            return "Békéscsaba";
        case 5604:
            return "Békéscsaba";
        case 5605:
            return "Békéscsaba";
        case 5609:
            return "Csabaszabadi ";
        case 5621:
            return "Csárdaszállás";
        case 5622:
            return "Köröstarcsa";
        case 5623:
            return "Köröstarcsa";
        case 5624:
            return "Doboz";
        case 5630:
            return "Békés";
        case 5631:
            return "Békés";
        case 5641:
            return "Tarhos";
        case 5643:
            return "Bélmegyer";
        case 5650:
            return "Mezőberény";
        case 5661:
            return "Újkígyós";
        case 5662:
            return "Csanádapáca";
        case 5663:
            return "Medgyesbodzás";
        case 5664:
            return "Medgyesbodzás";
        case 5665:
            return "Pusztaottlaka";
        case 5666:
            return "Medgyesegyháza  ";
        case 5667:
            return "Magyarbánhegyes";
        case 5668:
            return "Nagybánhegyes";
        case 5671:
            return "Nagybánhegyes";
        case 5672:
            return "Murony";
        case 5673:
            return "Kamut";
        case 5674:
            return "Kétsoprony";
        case 5675:
            return "Telekgerendás";
        case 5700:
            return "Gyula";
        case 5701:
            return "Gyula";
        case 5702:
            return "Gyula";
        case 5703:
            return "Gyula";
        case 5711:
            return "Gyula";
        case 5712:
            return "Szabadkígyós";
        case 5720:
            return "Sarkad";
        case 5721:
            return "Sarkad";
        case 5722:
            return "Sarkad";
        case 5725:
            return "Kötegyán";
        case 5726:
            return "Méhkerék";
        case 5727:
            return "Újszalonta";
        case 5731:
            return "Sarkadkeresztúr";
        case 5732:
            return "Mezőgyán";
        case 5734:
            return "Geszt";
        case 5741:
            return "Kétegyháza";
        case 5742:
            return "Elek";
        case 5743:
            return "Lőkösháza";
        case 5744:
            return "Kevermes";
        case 5745:
            return "Dombiratos";
        case 5746:
            return "Kunágota";
        case 5747:
            return "Almáskamarás";
        case 5751:
            return "Nagykamarás";
        case 5752:
            return "Nagykamarás";
        case 5800:
            return "Mezőkovácsháza";
        case 5801:
            return "Mezőkovácsháza";
        case 5802:
            return "Mezőkovácsháza";
        case 5811:
            return "Végegyháza";
        case 5820:
            return "Mezőhegyes";
        case 5830:
            return "Battonya";
        case 5836:
            return "Dombegyház";
        case 5837:
            return "Kisdombegyház";
        case 5838:
            return "Magyardombegyház";
        case 5900:
            return "Orosháza";
        case 5901:
            return "Orosháza";
        case 5902:
            return "Orosháza";
        case 5903:
            return "Orosháza";
        case 5904:
            return "Orosháza";
        case 5905:
            return "Orosháza";
        case 5919:
            return "Pusztaföldvár";
        case 5920:
            return "Csorvás";
        case 5925:
            return "Gerendás";
        case 5931:
            return "Nagyszénás";
        case 5932:
            return "Gádoros";
        case 5940:
            return "Tótkomlós";
        case 5945:
            return "Kardoskút";
        case 5946:
            return "Békéssámson";
        case 5948:
            return "Kaszaper";
        case 6000:
            return "Kecskemét";
        case 6001:
            return "Kecskemét";
        case 6002:
            return "Kecskemét";
        case 6003:
            return "Kecskemét";
        case 6004:
            return "Kecskemét";
        case 6005:
            return "Kecskemét";
        case 6006:
            return "Kecskemét";
        case 6007:
            return "Kecskemét";
        case 6010:
            return "Kecskemét";
        case 6031:
            return "Szentkirály";
        case 6032:
            return "Nyárlőrinc";
        case 6033:
            return "Városföld";
        case 6034:
            return "Helvécia";
        case 6035:
            return "Ballószög";
        case 6041:
            return "Kerekegyháza";
        case 6042:
            return "Fülöpháza";
        case 6043:
            return "Kunbaracs";
        case 6044:
            return "Kunbaracs";
        case 6045:
            return "Ladánybene";
        case 6050:
            return "Lajosmizse";
        case 6055:
            return "Felsőlajos ";
        case 6060:
            return "Tiszakécske";
        case 6061:
            return "Tiszakécske";
        case 6062:
            return "Tiszakécske";
        case 6063:
            return "Tiszakécske";
        case 6065:
            return "Lakitelek";
        case 6066:
            return "Tiszaalpár ";
        case 6067:
            return "Tiszaalpár ";
        case 6070:
            return "Izsák";
        case 6075:
            return "Páhi";
        case 6076:
            return "Ágasegyháza";
        case 6077:
            return "Orgovány";
        case 6078:
            return "Jakabszállás";
        case 6080:
            return "Szabadszállás";
        case 6085:
            return "Fülöpszállás";
        case 6086:
            return "Szalkszentmárton";
        case 6087:
            return "Dunavecse";
        case 6088:
            return "Apostag";
        case 6090:
            return "Kunszentmiklós ";
        case 6093:
            return "Kunszentmiklós ";
        case 6096:
            return "Kunpeszér";
        case 6097:
            return "Kunadacs";
        case 6098:
            return "Tass";
        case 6100:
            return "Kiskunfélegyháza";
        case 6101:
            return "Kiskunfélegyháza";
        case 6102:
            return "Kiskunfélegyháza";
        case 6111:
            return "Gátér";
        case 6112:
            return "Pálmonostora";
        case 6113:
            return "Petőfiszállás";
        case 6114:
            return "Bugac";
        // case 6114:
        //     return "Bugacpusztaháza";
        case 6115:
            return "Kunszállás";
        case 6116:
            return "Fülöpjakab ";
        case 6120:
            return "Kiskunmajsa";
        case 6131:
            return "Szank";
        case 6132:
            return "Móricgát ";
        case 6133:
            return "Jászszentlászló";
        case 6134:
            return "Kömpöc";
        case 6135:
            return "Csólyospálos";
        case 6136:
            return "Harkakötöny";
        case 6200:
            return "Kiskőrös";
        case 6201:
            return "Kiskőrös";
        case 6211:
            return "Kaskantyú";
        case 6221:
            return "Akasztó";
        case 6222:
            return "Csengőd";
        case 6223:
            return "Soltszentimre";
        case 6224:
            return "Tabdi";
        case 6230:
            return "Soltvadkert";
        case 6235:
            return "Bócsa";
        case 6236:
            return "Tázlár";
        case 6237:
            return "Kecel";
        case 6238:
            return "Imrehegy";
        case 6239:
            return "Császártöltés";
        case 6300:
            return "Kalocsa";
        case 6301:
            return "Kalocsa";
        case 6311:
            return "Öregcsertő";
        case 6320:
            return "Solt";
        case 6321:
            return "Újsolt";
        case 6323:
            return "Dunaegyháza";
        case 6325:
            return "Dunatetétlen";
        case 6326:
            return "Harta ";
        case 6327:
            return "Harta ";
        case 6328:
            return "Dunapataj";
        case 6331:
            return "Foktő";
        case 6332:
            return "Uszód";
        case 6333:
            return "Dunaszentbenedek";
        case 6334:
            return "Géderlak";
        case 6335:
            return "Ordas";
        case 6336:
            return "Szakmár";
        case 6337:
            return "Újtelek";
        case 6341:
            return "Homokmégy";
        case 6342:
            return "Drágszél";
        case 6343:
            return "Miske";
        case 6344:
            return "Hajós";
        case 6345:
            return "Nemesnádudvar";
        case 6346:
            return "Sükösd";
        case 6347:
            return "Érsekcsanád";
        case 6348:
            return "Érsekhalma";
        case 6351:
            return "Bátya";
        case 6352:
            return "Fajsz";
        case 6353:
            return "Dusnok";
        case 6400:
            return "Kiskunhalas";
        case 6401:
            return "Kiskunhalas";
        case 6402:
            return "Kiskunhalas";
        case 6411:
            return "Zsana";
        case 6412:
            return "Balotaszállás";
        case 6413:
            return "Kunfehértó";
        case 6414:
            return "Pirtó";
        case 6421:
            return "Kisszállás";
        case 6422:
            return "Tompa";
        case 6423:
            return "Kelebia";
        case 6424:
            return "Csikéria";
        case 6425:
            return "Bácsszőlős";
        case 6430:
            return "Bácsalmás";
        case 6435:
            return "Kunbaja";
        case 6440:
            return "Jánoshalma";
        case 6444:
            return "Kéleshalom ";
        case 6445:
            return "Borota";
        case 6446:
            return "Rém";
        case 6447:
            return "Felsőszentiván";
        case 6448:
            return "Csávoly";
        case 6449:
            return "Mélykút";
        case 6451:
            return "Tataháza";
        case 6452:
            return "Mátételke";
        case 6453:
            return "Bácsbokod";
        case 6454:
            return "Bácsborsód";
        case 6455:
            return "Katymár";
        case 6456:
            return "Madaras";
        case 6500:
            return "Baja";
        case 6501:
            return "Baja";
        case 6502:
            return "Baja";
        case 6503:
            return "Baja";
        case 6511:
            return "Bácsszentgyörgy";
        case 6512:
            return "Szeremle";
        case 6513:
            return "Dunafalva";
        case 6521:
            return "Vaskút";
        case 6522:
            return "Gara";
        case 6523:
            return "Csátalja";
        case 6524:
            return "Dávod";
        case 6525:
            return "Hercegszántó";
        case 6527:
            return "Nagybaracska";
        case 6528:
            return "Bátmonostor";
        case 6600:
            return "Szentes";
        case 6601:
            return "Szentes";
        case 6602:
            return "Szentes";
        case 6603:
            return "Szentes";
        case 6612:
            return "Nagytőke";
        case 6621:
            return "Derekegyház";
        case 6622:
            return "Nagymágocs";
        case 6623:
            return "Árpádhalom";
        case 6624:
            return "Eperjes";
        case 6625:
            return "Fábiánsebestyén";
        case 6630:
            return "Mindszent";
        case 6635:
            return "Szegvár";
        case 6636:
            return "Mártély";
        case 6640:
            return "Csongrád";
        case 6641:
            return "Csongrád";
        case 6642:
            return "Csongrád";
        case 6645:
            return "Felgyő";
        case 6646:
            return "Tömörkény";
        case 6647:
            return "Csanytelek";
        case 6648:
            return "Csanytelek";
        case 6700:
            return "Szeged";
        case 6701:
            return "Szeged";
        case 6702:
            return "Szeged";
        case 6703:
            return "Szeged";
        case 6704:
            return "Szeged";
        case 6705:
            return "Szeged";
        case 6706:
            return "Szeged";
        case 6707:
            return "Szeged";
        case 6708:
            return "Szeged";
        case 6709:
            return "Szeged";
        case 6710:
            return "Szeged";
        case 6712:
            return "Szeged";
        case 6713:
            return "Szeged";
        case 6717:
            return "Szeged";
        case 6718:
            return "Szeged";
        case 6719:
            return "Szeged";
        case 6720:
            return "Szeged";
        case 6721:
            return "Szeged";
        case 6722:
            return "Szeged";
        case 6723:
            return "Szeged";
        case 6724:
            return "Szeged";
        case 6725:
            return "Szeged";
        case 6726:
            return "Szeged";
        case 6727:
            return "Szeged";
        case 6728:
            return "Szeged";
        case 6729:
            return "Szeged";
        case 6740:
            return "Szeged";
        case 6741:
            return "Szeged";
        case 6742:
            return "Szeged";
        case 6743:
            return "Szeged";
        case 6744:
            return "Szeged";
        case 6745:
            return "Szeged";
        case 6746:
            return "Szeged";
        case 6747:
            return "Szeged";
        case 6748:
            return "Szeged";
        case 6750:
            return "Algyő";
        case 6753:
            return "Algyő";
        case 6754:
            return "Újszentiván";
        case 6755:
            return "Kübekháza";
        case 6756:
            return "Tiszasziget";
        case 6757:
            return "Tiszasziget";
        case 6758:
            return "Röszke";
        case 6760:
            return "Kistelek";
        case 6762:
            return "Sándorfalva";
        case 6763:
            return "Szatymaz";
        case 6764:
            return "Balástya";
        case 6765:
            return "Csengele";
        case 6766:
            return "Dóc";
        case 6767:
            return "Ópusztaszer";
        case 6768:
            return "Baks";
        case 6769:
            return "Pusztaszer";
        case 6771:
            return "Szeged";
        case 6772:
            return "Deszk";
        case 6773:
            return "Klárafalva";
        case 6774:
            return "Ferencszállás";
        case 6775:
            return "Kiszombor";
        case 6781:
            return "Domaszék";
        case 6782:
            return "Mórahalom";
        case 6783:
            return "Ásotthalom";
        case 6784:
            return "Öttömös";
        case 6785:
            return "Pusztamérges";
        case 6786:
            return "Ruzsa";
        case 6787:
            return "Zákányszék";
        case 6791:
            return "Szeged";
        case 6792:
            return "Zsombó";
        case 6793:
            return "Forráskút";
        case 6794:
            return "Üllés";
        case 6795:
            return "Bordány";
        case 6800:
            return "Hódmezővásárhely";
        case 6801:
            return "Hódmezővásárhely";
        case 6802:
            return "Hódmezővásárhely";
        case 6803:
            return "Hódmezővásárhely";
        case 6804:
            return "Hódmezővásárhely";
        case 6806:
            return "Hódmezővásárhely";
        case 6808:
            return "Hódmezővásárhely";
        case 6811:
            return "Hódmezővásárhely";
        case 6821:
            return "Székkutas";
        case 6900:
            return "Makó";
        case 6901:
            return "Makó";
        case 6902:
            return "Makó";
        case 6911:
            return "Királyhegyes";
        case 6912:
            return "Kövegy";
        case 6913:
            return "Csanádpalota";
        case 6914:
            return "Pitvaros";
        case 6915:
            return "Csanádalberti";
        case 6916:
            return "Ambrózfalva";
        case 6917:
            return "Nagyér";
        case 6921:
            return "Maroslele";
        case 6922:
            return "Földeák";
        case 6923:
            return "Óföldeák";
        case 6931:
            return "Apátfalva";
        case 6932:
            return "Magyarcsanád";
        case 6933:
            return "Nagylak";
        case 7000:
            return "Sárbogárd";
        case 7001:
            return "Sárbogárd";
        case 7002:
            return "Sárbogárd";
        case 7003:
            return "Sárbogárd";
        case 7011:
            return "Alap";
        case 7012:
            return "Alsószentiván";
        case 7013:
            return "Cece";
        case 7014:
            return "Sáregres";
        case 7015:
            return "Igar";
        case 7016:
            return "Igar";
        case 7017:
            return "Mezőszilas";
        case 7018:
            return "Mezőszilas";
        case 7019:
            return "Mezőszilas";
        case 7020:
            return "Dunaföldvár";
        case 7021:
            return "Dunaföldvár";
        case 7025:
            return "Bölcske";
        case 7026:
            return "Madocsa";
        case 7027:
            return "Madocsa";
        case 7030:
            return "Paks";
        case 7031:
            return "Paks";
        case 7032:
            return "Paks";
        case 7038:
            return "Pusztahencse";
        case 7039:
            return "Németkér";
        case 7041:
            return "Vajta";
        case 7042:
            return "Pálfa";
        case 7043:
            return "Bikács";
        case 7044:
            return "Nagydorog";
        case 7045:
            return "Györköny";
        case 7047:
            return "Sárszentlőrinc";
        case 7051:
            return "Kajdacs";
        case 7052:
            return "Kölesd";
        case 7054:
            return "Tengelic";
        case 7056:
            return "Szedres";
        case 7057:
            return "Medina";
        case 7061:
            return "Belecska";
        case 7062:
            return "Keszőhidegkút";
        case 7063:
            return "Szárazd";
        case 7064:
            return "Gyönk";
        case 7065:
            return "Miszla";
        case 7066:
            return "Udvari";
        case 7067:
            return "Varsád";
        case 7068:
            return "Kistormás";
        case 7071:
            return "Szakadát";
        case 7072:
            return "Diósberény";
        case 7081:
            return "Simontornya";
        case 7082:
            return "Kisszékely";
        case 7083:
            return "Tolnanémedi";
        case 7084:
            return "Pincehely";
        case 7085:
            return "Nagyszékely";
        case 7086:
            return "Ozora";
        case 7087:
            return "Fürged";
        case 7090:
            return "Tamási";
        case 7091:
            return "Tamási";
        case 7092:
            return "Nagykónyi";
        case 7093:
            return "Értény";
        case 7094:
            return "Koppányszántó";
        case 7095:
            return "Iregszemcse";
        // case 7095:
        //     return "Újireg";
        case 7097:
            return "Nagyszokoly";
        case 7098:
            return "Magyarkeszi";
        case 7099:
            return "Felsőnyék";
        case 7100:
            return "Szekszárd";
        case 7101:
            return "Szekszárd";
        case 7102:
            return "Szekszárd";
        case 7103:
            return "Szekszárd";
        case 7121:
            return "Szálka";
        case 7122:
            return "Kakasd";
        case 7130:
            return "Tolna";
        case 7131:
            return "Tolna";
        case 7132:
            return "Bogyiszló";
        case 7133:
            return "Fadd";
        case 7134:
            return "Gerjen";
        case 7135:
            return "Dunaszentgyörgy";
        case 7136:
            return "Fácánkert";
        case 7140:
            return "Bátaszék";
        case 7142:
            return "Pörböly";
        case 7143:
            return "Őcsény";
        case 7144:
            return "Decs";
        case 7145:
            return "Sárpilis";
        case 7146:
            return "Várdomb";
        case 7147:
            return "Alsónána";
        case 7148:
            return "Alsónyék";
        case 7149:
            return "Báta";
        case 7150:
            return "Bonyhád";
        case 7151:
            return "Bonyhád";
        case 7153:
            return "Bonyhád";
        case 7158:
            return "Bonyhádvarasd";
        case 7159:
            return "Kisdorog";
        case 7161:
            return "Cikó";
        case 7162:
            return "Grábóc";
        case 7163:
            return "Mőcsény";
        case 7164:
            return "Bátaapáti";
        case 7165:
            return "Mórágy";
        case 7171:
            return "Sióagárd";
        case 7172:
            return "Harc";
        case 7173:
            return "Zomba";
        case 7174:
            return "Kéty";
        case 7175:
            return "Felsőnána";
        case 7176:
            return "Murga";
        case 7181:
            return "Tevel";
        case 7182:
            return "Závod";
        case 7183:
            return "Kisvejke";
        case 7184:
            return "Lengyel";
        // case 7184:
        //     return "Szárász";
        case 7185:
            return "Mucsfa";
        case 7186:
            return "Aparhant";
        // case 7186:
        //     return "Nagyvejke";
        case 7187:
            return "Nagyvejke";
        case 7191:
            return "Hőgyész";
        case 7192:
            return "Szakály";
        case 7193:
            return "Regöly";
        case 7194:
            return "Kalaznó";
        case 7195:
            return "Mucsi";
        case 7200:
            return "Dombóvár";
        case 7201:
            return "Dombóvár";
        case 7202:
            return "Dombóvár";
        case 7203:
            return "Dombóvár";
        case 7211:
            return "Dalmand";
        case 7212:
            return "Kocsola";
        case 7213:
            return "Szakcs";
        case 7214:
            return "Lápafő";
        // case 7214:
        //     return "Várong";
        case 7215:
            return "Nak";
        case 7224:
            return "Dúzs";
        case 7225:
            return "Csibrák";
        case 7226:
            return "Kurd";
        case 7227:
            return "Gyulaj";
        case 7228:
            return "Döbrököz";
        case 7251:
            return "Kapospula";
        case 7252:
            return "Attala";
        case 7253:
            return "Csoma";
        // case 7253:
        //     return "Szabadi";
        case 7255:
            return "Nagyberki";
        case 7256:
            return "Kercseliget";
        case 7257:
            return "Mosdós";
        case 7258:
            return "Baté";
        // case 7258:
        //     return "Kaposkeresztúr";
        case 7261:
            return "Taszár";
        // case 7261:
        //     return "Kaposhomok";
        case 7271:
            return "Fonó";
        case 7272:
            return "Gölle";
        case 7273:
            return "Büssü";
        case 7274:
            return "Kazsok";
        case 7275:
            return "Igal";
        case 7276:
            return "Somogyszil";
        // case 7276:
        //     return "Gadács";
        case 7279:
            return "Kisgyalán";
        case 7281:
            return "Bonnya";
        // case 7282:
        //     return "Kisbárapáti";
        case 7282:
            return "Fiad";
        case 7283:
            return "Somogyacsa";
        case 7284:
            return "Somogydöröcske";
        case 7285:
            return "Törökkoppány";
        // case 7285:
        //     return "Kára";
        // case 7285:
        //     return "Szorosad";
        case 7286:
            return "Miklósi";
        case 7300:
            return "Komló";
        // case 7300:
        //     return "Mecsekpölöske";
        case 7301:
            return "Mecsekpölöske";
        case 7302:
            return "Mecsekpölöske";
        case 7303:
            return "Mecsekpölöske";
        case 7304:
            return "Mánfa ";
        case 7305:
            return "Mánfa ";
        case 7306:
            return "Mánfa ";
        case 7309:
            return "Mánfa ";
        case 7331:
            return "Liget";
        case 7332:
            return "Magyaregregy";
        case 7333:
            return "Kárász";
        // case 7333:
        //     return "Vékény";
        case 7334:
            return "Szalatnak";
        // case 7334:
        //     return "Köblény";
        case 7341:
            return "Csikóstőttős";
        case 7342:
            return "Mágocs";
        case 7343:
            return "Nagyhajmás";
        case 7344:
            return "Mekényes";
        case 7345:
            return "Alsómocsolád";
        case 7346:
            return "Bikal";
        case 7347:
            return "Egyházaskozár";
        case 7348:
            return "Hegyhátmaróc";
        // case 7348:
        //     return "Tófű";
        case 7349:
            return "Szászvár";
        case 7351:
            return "Máza";
        case 7352:
            return "Györe";
        case 7353:
            return "Izmény";
        case 7354:
            return "Váralja";
        case 7355:
            return "Nagymányok";
        case 7356:
            return "Kismányok";
        case 7361:
            return "Kaposszekcső";
        // case 7362:
        //     return "Vásárosdombó";
        // case 7362:
        //     return "Gerényes";
        case 7362:
            return "Jágónak";
        // case 7362:
        //     return "Tarrós";
        // case 7370:
        //     return "Sásd";
        // case 7370:
        //     return "Felsőegerszeg";
        // case 7370:
        //     return "Meződ";
        case 7370:
            return "Oroszló";
        // case 7370:
        //     return "Palé";
        // case 7370:
        //     return "Varga";
        // case 7370:
        //     return "Vázsnok";
        case 7381:
            return "Kisvaszar";
        // case 7381:
        //     return "Ág";
        // case 7381:
        //     return "Tékes";
        case 7383:
            return "Tormás";
        // case 7383:
        //     return "Baranyaszentgyörgy";
        // case 7383:
        //     return "Szágy";
        case 7384:
            return "Baranyajenő";
        case 7385:
            return "Gödre   ";
        case 7386:
            return "Gödre   ";
        case 7391:
            return "Mindszentgodisa";
        // case 7391:
        //     return "Kisbeszterce";
        // case 7391:
        //     return "Kishajmás";
        case 7393:
            return "Bakóca";
        case 7394:
            return "Magyarhertelend";
        // case 7394:
        //     return "Bodolyabér";
        case 7396:
            return "Magyarszék";
        case 7400:
            return "Kaposvár";
        case 7401:
            return "Zselickislak";
        case 7402:
            return "Zselickislak";
        case 7403:
            return "Zselickislak";
        case 7404:
            return "Zselickislak";
        case 7405:
            return "Zselickislak";
        case 7406:
            return "Zselickislak";
        case 7407:
            return "Zselickislak";
        case 7408:
            return "Zselickislak";
        case 7409:
            return "Zselickislak";
        case 7431:
            return "Juta";
        case 7432:
            return "Hetes";
        // case 7432:
        //     return "Csombárd";
        case 7434:
            return "Mezőcsokonya";
        case 7435:
            return "Somogysárd";
        case 7436:
            return "Újvárfalva";
        case 7439:
            return "Bodrog";
        case 7441:
            return "Magyaregres";
        case 7442:
            return "Várda";
        case 7443:
            return "Somogyjád";
        // case 7443:
        //     return "Alsóbogát";
        // case 7443:
        //     return "Edde";
        case 7444:
            return "Osztopán";
        case 7452:
            return "Somogyaszaló";
        case 7453:
            return "Mernye";
        case 7454:
            return "Somodor";
        case 7455:
            return "Somogygeszti";
        case 7456:
            return "Felsőmocsolád";
        case 7457:
            return "Ecseny";
        case 7458:
            return "Polány";
        case 7463:
            return "Magyaratád";
        // case 7463:
        //     return "Patalom";
        case 7464:
            return "Ráksi";
        case 7465:
            return "Szentgáloskér";
        case 7471:
            return "Zimány";
        case 7472:
            return "Szentbalázs";
        // case 7472:
        //     return "Cserénfa";
        case 7473:
            return "Gálosfa";
        // case 7473:
        //     return "Hajmás";
        // case 7473:
        //     return "Kaposgyarmat";
        case 7474:
            return "Simonfa";
        // case 7474:
        //     return "Zselicszentpál";
        case 7475:
            return "Bőszénfa";
        case 7476:
            return "Kaposszerdahely";
        case 7477:
            return "Szenna";
        // case 7477:
        //     return "Patca";
        // case 7477:
        //     return "Szilvásszentmárton";
        // case 7477:
        //     return "Zselickisfalud";
        case 7478:
            return "Bárdudvarnok";
        case 7479:
            return "Sántos";
        case 7500:
            return "Nagyatád";
        case 7501:
            return "Nagyatád";
        case 7511:
            return "Ötvöskónyi";
        case 7512:
            return "Mike";
        case 7513:
            return "Rinyaszentkirály";
        case 7514:
            return "Tarany";
        case 7515:
            return "Somogyudvarhely";
        case 7516:
            return "Berzence";
        case 7517:
            return "Bolhás";
        case 7521:
            return "Kaposmérő";
        case 7522:
            return "Kaposújlak";
        case 7523:
            return "Kaposfő";
        // case 7523:
        //     return "Kisasszond";
        case 7524:
            return "Kiskorpád";
        case 7525:
            return "Jákó";
        case 7526:
            return "Csököly";
        case 7527:
            return "Gige";
        // case 7527:
        //     return "Rinyakovácsi";
        case 7530:
            return "Kőkút";
        // case 7530:
        //     return "Kadarkút";
        case 7532:
            return "Hencse";
        case 7533:
            return "Hedrehely";
        // case 7533:
        //     return "Visnye";
        case 7535:
            return "Lad";
        case 7536:
            return "Patosfa";
        case 7537:
            return "Homokszentgyörgy";
        case 7538:
            return "Kálmáncsa";
        case 7539:
            return "Szulok";
        case 7541:
            return "Kutas";
        case 7542:
            return "Kisbajom";
        case 7543:
            return "Beleg";
        case 7544:
            return "Szabás";
        case 7545:
            return "Nagykorpád";
        case 7551:
            return "Lábod";
        case 7552:
            return "Rinyabesenyő";
        case 7553:
            return "Görgeteg";
        case 7555:
            return "Csokonyavisonta";
        case 7556:
            return "Rinyaújlak";
        case 7557:
            return "Rinyaújlak";
        case 7561:
            return "Nagybajom";
        // case 7561:
        //     return "Pálmajor";
        case 7562:
            return "Segesd";
        case 7563:
            return "Somogyszob";
        case 7564:
            return "Kaszó";
        case 7570:
            return "Barcs";
        case 7571:
            return "Barcs";
        case 7582:
            return "Komlósd";
        // case 7582:
        //     return "Péterhida";
        // case 7584:
        //     return "Babócsa";
        case 7584:
            return "Rinyaújnép";
        // case 7584:
        //     return "Somogyaracs";
        case 7585:
            return "Háromfa";
        // case 7585:
        //     return "Bakháza";
        case 7586:
            return "Bolhó";
        case 7587:
            return "Heresznye";
        case 7588:
            return "Vízvár";
        case 7589:
            return "Bélavár";
        case 7600:
            return "Pécs";
        case 7601:
            return "Pécs";
        case 7602:
            return "Pécs";
        case 7603:
            return "Pécs";
        case 7604:
            return "Pécs";
        case 7605:
            return "Pécs";
        case 7606:
            return "Pécs";
        case 7607:
            return "Pécs";
        case 7608:
            return "Pécs";
        case 7610:
            return "Pécs";
        case 7611:
            return "Pécs";
        case 7612:
            return "Pécs";
        case 7613:
            return "Pécs";
        case 7614:
            return "Pécs";
        case 7615:
            return "Pécs";
        case 7616:
            return "Pécs";
        case 7617:
            return "Pécs";
        case 7618:
            return "Pécs";
        case 7619:
            return "Pécs";
        case 7621:
            return "Pécs";
        case 7622:
            return "Pécs";
        case 7623:
            return "Pécs";
        case 7624:
            return "Pécs";
        case 7625:
            return "Pécs";
        case 7626:
            return "Pécs";
        case 7627:
            return "Pécs";
        case 7628:
            return "Pécs";
        case 7629:
            return "Pécs";
        case 7630:
            return "Pécs";
        case 7631:
            return "Pécs";
        case 7632:
            return "Pécs";
        case 7633:
            return "Pécs";
        case 7634:
            return "Pécs";
        case 7635:
            return "Pécs";
        case 7636:
            return "Pécs";
        case 7639:
            return "Pécs";
        case 7643:
            return "Pécs";
        case 7644:
            return "Pécs";
        case 7645:
            return "Pécs";
        case 7646:
            return "Pécs";
        case 7647:
            return "Pécs";
        case 7648:
            return "Pécs";
        case 7650:
            return "Pécs";
        case 7661:
            return "Erzsébet";
        // case 7661:
        //     return "Kátoly";
        // case 7661:
        //     return "Kékesd";
        // case 7661:
        //     return "Szellő";
        case 7663:
            return "Máriakéménd";
        case 7664:
            return "Berkesd";
        // case 7664:
        //     return "Pereked";
        // case 7664:
        //     return "Szilágy";
        case 7666:
            return "Pogány";
        case 7668:
            return "Keszü";
        // case 7668:
        //     return "Gyód";
        // case 7668:
        //     return "Kökény";
        case 7671:
            return "Bicsérd";
        // case 7671:
        //     return "Aranyosgadány";
        // case 7671:
        //     return "Zók";
        case 7672:
            return "Boda";
        case 7673:
            return "Kővágószőlős";
        // case 7673:
        //     return "Cserkút";
        case 7675:
            return "Bakonya";
        // case 7675:
        //     return "Kővágótőttős";
        case 7677:
            return "Orfű ";
        case 7678:
            return "Abaliget";
        // case 7678:
        //     return "Husztót";
        // case 7678:
        //     return "Kovácsszénája";
        case 7681:
            return "Hetvehely";
        // case 7681:
        //     return "Okorvölgy";
        // case 7681:
        //     return "Szentkatalin";
        case 7682:
            return "Bükkösd";
        case 7683:
            return "Helesfa";
        // case 7683:
        //     return "Cserdi";
        // case 7683:
        //     return "Dinnyeberki";
        case 7691:
            return "Dinnyeberki";
        case 7693:
            return "Dinnyeberki";
        case 7694:
            return "Hosszúhetény";
        case 7695:
            return "Mecseknádasd";
        // case 7695:
        //     return "Óbánya";
        // case 7695:
        //     return "Ófalu";
        case 7696:
            return "Hidas";
        case 7700:
            return "Mohács";
        case 7701:
            return "Mohács";
        case 7702:
            return "Mohács";
        case 7711:
            return "Bár";
        case 7712:
            return "Dunaszekcső";
        case 7714:
            return "Dunaszekcső";
        case 7715:
            return "Dunaszekcső";
        case 7716:
            return "Homorúd";
        case 7717:
            return "Kölked";
        case 7718:
            return "Udvar";
        case 7720:
            return "Pécsvárad";
        // case 7720:
        //     return "Apátvarasd";
        // case 7720:
        //     return "Lovászhetény";
        // case 7720:
        //     return "Martonfa";
        // case 7720:
        //     return "Zengővárkony";
        case 7723:
            return "Erdősmecske";
        case 7724:
            return "Feked";
        case 7725:
            return "Szebény";
        case 7726:
            return "Véménd";
        case 7727:
            return "Palotabozsok";
        case 7728:
            return "Somberek";
        // case 7728:
        //     return "Görcsönydoboka";
        case 7731:
            return "Nagypall";
        case 7732:
            return "Fazekasboda";
        case 7733:
            return "Geresdlak";
        // case 7733:
        //     return "Maráza";
        case 7735:
            return "Hímesháza";
        // case 7735:
        //     return "Erdősmárok";
        // case 7735:
        //     return "Szűr";
        case 7737:
            return "Székelyszabar";
        case 7741:
            return "Nagykozár";
        // case 7741:
        //     return "Bogád";
        // case 7741:
        //     return "Romonya";
        case 7744:
            return "Ellend";
        case 7745:
            return "Olasz";
        // case 7745:
        //     return "Hásságy";
        case 7747:
            return "Belvárdgyula";
        // case 7747:
        //     return "Birján";
        case 7751:
            return "Szederkény";
        // case 7751:
        //     return "Monyoród";
        case 7752:
            return "Versend";
        case 7753:
            return "Szajk";
        case 7754:
            return "Bóly";
        case 7755:
            return "Töttös";
        case 7756:
            return "Borjád";
        // case 7756:
        //     return "Kisbudmér";
        // case 7756:
        //     return "Nagybudmér";
        // case 7756:
        //     return "Pócsa";
        case 7757:
            return "Babarc";
        // case 7757:
        //     return "Liptód";
        case 7759:
            return "Lánycsók";
        // case 7759:
        //     return "Kisnyárád";
        case 7761:
            return "Kozármisleny";
        // case 7761:
        //     return "Lothárd";
        // case 7761:
        //     return "Magyarsarlós";
        case 7762:
            return "Pécsudvard";
        case 7763:
            return "Egerág";
        // case 7763:
        //     return "Áta";
        // case 7763:
        //     return "Kisherend";
        // case 7763:
        //     return "Szemely";
        // case 7763:
        //     return "Szőkéd";
        case 7766:
            return "Újpetre";
        // case 7766:
        //     return "Kiskassa";
        // case 7766:
        //     return "Peterd";
        // case 7766:
        //     return "Pécsdevecser";
        case 7768:
            return "Vokány";
        // case 7768:
        //     return "Kistótfalu";
        case 7771:
            return "Palkonya";
        case 7772:
            return "Villánykövesd";
        // case 7772:
        //     return "Ivánbattyán";
        case 7773:
            return "Villány";
        // case 7773:
        //     return "Kisjakabfalva";
        case 7774:
            return "Márok";
        case 7775:
            return "Magyarbóly";
        // case 7775:
        //     return "Illocska";
        // case 7775:
        //     return "Kislippó";
        // case 7775:
        //     return "Lapáncsa";
        case 7781:
            return "Lippó";
        // case 7781:
        //     return "Ivándárda";
        // case 7781:
        //     return "Sárok";
        case 7782:
            return "Bezedek";
        case 7783:
            return "Majs";
        case 7784:
            return "Nagynyárád";
        case 7785:
            return "Sátorhely";
        case 7800:
            return "Siklós";
        // case 7800:
        //     return "Kisharsány";
        // case 7800:
        //     return "Nagytótfalu";
        case 7801:
            return "Nagytótfalu";
        case 7802:
            return "Nagytótfalu";
        case 7811:
            return "Szalánta";
        // case 7811:
        //     return "Bisse";
        // case 7811:
        //     return "Bosta";
        // case 7811:
        //     return "Csarnóta";
        // case 7811:
        //     return "Szilvás";
        // case 7811:
        //     return "Túrony";
        case 7812:
            return "Garé";
        case 7813:
            return "Szava";
        case 7814:
            return "Ócsárd";
        // case 7814:
        //     return "Babarcszőlős";
        // case 7814:
        //     return "Kisdér";
        // case 7814:
        //     return "Siklósbodony";
        case 7815:
            return "Harkány";
        case 7817:
            return "Diósviszló";
        // case 7817:
        //     return "Márfa";
        // case 7817:
        //     return "Rádfalva";
        case 7822:
            return "Nagyharsány";
        case 7823:
            return "Siklósnagyfalu";
        // case 7823:
        //     return "Kistapolca";
        case 7824:
            return "Egyházasharaszti";
        // case 7824:
        //     return "Old";
        case 7826:
            return "Alsószentmárton";
        case 7827:
            return "Beremend";
        // case 7827:
        //     return "Kásád";
        case 7831:
            return "Pellérd";
        case 7833:
            return "Görcsöny";
        // case 7833:
        //     return "Regenye";
        // case 7833:
        //     return "Szőke";
        case 7834:
            return "Baksa";
        // case 7834:
        //     return "Tengeri";
        // case 7834:
        //     return "Téseny";
        case 7836:
            return "Bogádmindszent";
        // case 7836:
        //     return "Ózdfalu";
        case 7837:
            return "Hegyszentmárton";
        case 7838:
            return "Vajszló";
        // case 7838:
        //     return "Besence";
        // case 7838:
        //     return "Hirics";
        // case 7838:
        //     return "Lúzsok";
        // case 7838:
        //     return "Nagycsány";
        // case 7838:
        //     return "Páprád";
        // case 7838:
        //     return "Piskó";
        // case 7838:
        //     return "Vejti";
        case 7839:
            return "Zaláta";
        // case 7839:
        //     return "Kemse";
        case 7841:
            return "Sámod";
        // case 7841:
        //     return "Adorjás";
        // case 7841:
        //     return "Baranyahidvég";
        // case 7841:
        //     return "Kisszentmárton";
        // case 7841:
        //     return "Kórós";
        case 7843:
            return "Kémes";
        // case 7843:
        //     return "Cún";
        // case 7843:
        //     return "Drávapiski";
        // case 7843:
        //     return "Szaporca";
        // case 7843:
        //     return "Tésenfa";
        case 7846:
            return "Drávacsepely";
        case 7847:
            return "Kovácshida";
        // case 7847:
        //     return "Drávaszerdahely";
        // case 7847:
        //     return "Ipacsfa";
        case 7851:
            return "Drávaszabolcs";
        // case 7851:
        //     return "Drávacsehi";
        // case 7851:
        //     return "Drávapalkonya";
        case 7853:
            return "Gordisa";
        case 7854:
            return "Matty";
        case 7900:
            return "Szigetvár";
        // case 7900:
        //     return "Botykapeterd";
        // case 7900:
        //     return "Csertő";
        case 7901:
            return "Csertő";
        case 7912:
            return "Nagypeterd";
        // case 7912:
        //     return "Nyugotszenterzsébet";
        // case 7912:
        //     return "Nagyváty";
        case 7913:
            return "Szentdénes";
        case 7914:
            return "Rózsafa";
        // case 7914:
        //     return "Bánfa";
        // case 7914:
        //     return "Katádfa";
        case 7915:
            return "Dencsháza";
        // case 7915:
        //     return "Szentegát";
        case 7918:
            return "Lakócsa";
        // case 7918:
        //     return "Szentborbás";
        // case 7918:
        //     return "Tótújfalu";
        case 7921:
            return "Somogyhatvan";
        case 7922:
            return "Somogyapáti";
        // case 7922:
        //     return "Basal";
        // case 7922:
        //     return "Patapoklosi";
        // case 7922:
        //     return "Somogyviszló";
        case 7925:
            return "Somogyhárságy";
        // case 7925:
        //     return "Magyarlukafa";
        case 7926:
            return "Vásárosbéc";
        case 7932:
            return "Mozsgó";
        // case 7932:
        //     return "Almáskeresztúr";
        // case 7932:
        //     return "Szulimán";
        case 7934:
            return "Almamellék";
        case 7935:
            return "Ibafa";
        // case 7935:
        //     return "Csebény";
        // case 7935:
        //     return "Horváthertelend";
        case 7936:
            return "Szentlászló";
        case 7937:
            return "Boldogasszonyfa";
        case 7940:
            return "Szentlőrinc";
        // case 7940:
        //     return "Csonkamindszent";
        // case 7940:
        //     return "Kacsóta";
        case 7951:
            return "Szabadszentkirály";
        // case 7951:
        //     return "Gerde";
        // case 7951:
        //     return "Pécsbagota";
        // case 7951:
        //     return "Velény";
        case 7953:
            return "Királyegyháza";
        case 7954:
            return "Magyarmecske";
        // case 7954:
        //     return "Gilvánfa";
        // case 7954:
        //     return "Gyöngyfa";
        // case 7954:
        //     return "Kisasszonyfa";
        // case 7954:
        //     return "Magyartelek";
        case 7957:
            return "Okorág ";
        case 7958:
            return "Kákics ";
        case 7960:
            return "Sellye";
        // case 7960:
        //     return "Drávaiványi";
        // case 7960:
        //     return "Drávasztára";
        // case 7960:
        //     return "Marócsa";
        // case 7960:
        //     return "Sósvertike";
        // case 7960:
        //     return "Sumony";
        case 7964:
            return "Csányoszró";
        case 7966:
            return "Bogdása";
        case 7967:
            return "Drávafok";
        // case 7967:
        //     return "Drávakeresztúr";
        // case 7967:
        //     return "Markóc";
        case 7968:
            return "Felsőszentmárton";
        case 7971:
            return "Hobol";
        // case 7972:
        //     return "Gyöngyösmellék";
        // case 7972:
        //     return "Pettend";
        case 7973:
            return "Teklafalu";
        // case 7973:
        //     return "Bürüs";
        // case 7973:
        //     return "Endrőc";
        // case 7973:
        //     return "Várad";
        case 7975:
            return "Kétújfalu";
        case 7976:
            return "Zádor";
        // case 7976:
        //     return "Szörény";
        case 7977:
            return "Kastélyosdombó";
        // case 7977:
        //     return "Drávagárdony";
        // case 7977:
        //     return "Potony";
        case 7979:
            return "Drávatamási";
        case 7981:
            return "Nemeske";
        // case 7981:
        //     return "Kistamási";
        // case 7981:
        //     return "Merenye";
        // case 7981:
        //     return "Molvány";
        // case 7981:
        //     return "Tótszentgyörgy";
        case 7985:
            return "Nagydobsza";
        // case 7985:
        //     return "Kisdobsza";
        case 7987:
            return "Istvándi";
        case 7988:
            return "Darány";
        case 8000:
            return "Székesfehérvár";
        case 8001:
            return "Székesfehérvár";
        case 8002:
            return "Székesfehérvár";
        case 8003:
            return "Székesfehérvár";
        case 8004:
            return "Székesfehérvár";
        case 8005:
            return "Székesfehérvár";
        case 8006:
            return "Székesfehérvár";
        case 8007:
            return "Székesfehérvár";
        case 8008:
            return "Székesfehérvár";
        case 8011:
            return "Székesfehérvár";
        case 8019:
            return "Székesfehérvár";
        case 8020:
            return "Székesfehérvár";
        case 8041:
            return "Csór";
        case 8042:
            return "Moha";
        case 8043:
            return "Iszkaszentgyörgy";
        case 8044:
            return "Kincsesbánya";
        case 8045:
            return "Isztimér";
        case 8046:
            return "Bakonykúti";
        case 8051:
            return "Sárkeresztes";
        case 8052:
            return "Fehérvárcsurgó";
        case 8053:
            return "Bodajk";
        case 8054:
            return "Bodajk";
        case 8055:
            return "Balinka";
        case 8056:
            return "Bakonycsernye";
        case 8060:
            return "Mór";
        case 8061:
            return "Mór";
        case 8062:
            return "Mór";
        case 8065:
            return "Nagyveleg";
        case 8066:
            return "Pusztavám";
        case 8071:
            return "Magyaralmás";
        case 8072:
            return "Söréd";
        case 8073:
            return "Csákberény";
        case 8074:
            return "Csókakő";
        case 8080:
            return "Bodmér";
        case 8081:
            return "Zámoly";
        case 8082:
            return "Gánt";
        case 8083:
            return "Csákvár";
        case 8084:
            return "Csákvár";
        case 8085:
            return "Vértesboglár";
        case 8086:
            return "Felcsút";
        case 8087:
            return "Alcsútdoboz";
        case 8088:
            return "Tabajd";
        case 8089:
            return "Vértesacsa";
        case 8092:
            return "Pátka";
        case 8093:
            return "Lovasberény";
        case 8095:
            return "Pákozd";
        case 8096:
            return "Sukoró";
        case 8097:
            return "Nadap";
        case 8100:
            return "Várpalota";
        case 8101:
            return "Várpalota";
        case 8102:
            return "Várpalota";
        case 8103:
            return "Várpalota";
        case 8104:
            return "Várpalota";
        case 8105:
            return "Pétfürdő";
        case 8109:
            return "Tés";
        case 8111:
            return "Seregélyes";
        case 8112:
            return "Zichyújfalu";
        case 8121:
            return "Tác";
        case 8122:
            return "Csősz";
        case 8123:
            return "Soponya";
        case 8124:
            return "Káloz";
        case 8125:
            return "Sárkeresztúr";
        case 8126:
            return "Sárszentágota";
        case 8127:
            return "Aba";
        case 8130:
            return "Enying";
        case 8131:
            return "Enying";
        case 8132:
            return "Lepsény";
        case 8133:
            return "Mezőszentgyörgy";
        case 8134:
            return "Mátyásdomb";
        case 8135:
            return "Dég";
        case 8136:
            return "Lajoskomárom";
        case 8137:
            return "Mezőkomárom";
        case 8138:
            return "Szabadhidvég";
        case 8142:
            return "Úrhida";
        case 8143:
            return "Sárszentmihály";
        case 8144:
            return "Sárkeszi";
        case 8145:
            return "Nádasdladány";
        case 8146:
            return "Jenő";
        case 8151:
            return "Szabadbattyán";
        case 8152:
            return "Kőszárhegy";
        case 8153:
            return "Kőszárhegy";
        case 8154:
            return "Polgárdi";
        case 8156:
            return "Kisláng";
        case 8157:
            return "Füle";
        case 8161:
            return "Ősi";
        case 8162:
            return "Küngös";
        case 8163:
            return "Csajág";
        case 8164:
            return "Balatonfőkajár";
        case 8171:
            return "Balatonvilágos";
        case 8172:
            return "Balatonvilágos";
        case 8173:
            return "Balatonvilágos";
        case 8174:
            return "Balatonkenese";
        case 8175:
            return "Balatonfűzfő";
        case 8181:
            return "Berhida";
        case 8182:
            return "Berhida";
        case 8183:
            return "Papkeszi";
        case 8184:
            return "Papkeszi";
        case 8191:
            return "Öskü";
        case 8192:
            return "Hajmáskér";
        case 8193:
            return "Sóly";
        case 8194:
            return "Vilonya";
        case 8195:
            return "Királyszentistván";
        case 8196:
            return "Litér";
        case 8200:
            return "Veszprém";
        case 8201:
            return "Veszprém";
        case 8202:
            return "Veszprém";
        case 8204:
            return "Veszprém";
        case 8205:
            return "Veszprém";
        case 8206:
            return "Veszprém";
        case 8207:
            return "Veszprém";
        case 8220:
            return "Balatonalmádi";
        case 8221:
            return "Balatonalmádi";
        case 8222:
            return "Balatonalmádi";
        case 8225:
            return "Szentkirályszabadja";
        case 8226:
            return "Alsóörs";
        case 8227:
            return "Felsőörs";
        case 8228:
            return "Lovas";
        case 8229:
            return "Csopak";
        // case 8229:
        //     return "Paloznak";
        case 8230:
            return "Balatonfüred";
        // case 8230:
        //     return "Balatonszőlős";
        case 8231:
            return "Balatonszőlős";
        case 8232:
            return "Balatonszőlős";
        case 8236:
            return "Balatonszőlős";
        case 8237:
            return "Tihany";
        case 8241:
            return "Aszófő";
        case 8242:
            return "Balatonudvari";
        // case 8242:
        //     return "Örvényes";
        case 8243:
            return "Balatonakali";
        case 8244:
            return "Dörgicse";
        case 8245:
            return "Pécsely";
        // case 8245:
        //     return "Vászoly";
        case 8246:
            return "Tótvázsony";
        case 8247:
            return "Hidegkút";
        case 8248:
            return "Nemesvámos";
        // case 8248:
        //     return "Veszprémfajsz";
        case 8251:
            return "Zánka ";
        case 8252:
            return "Balatonszepezd";
        case 8253:
            return "Révfülöp";
        // case 8254:
        //     return "Kővágóörs";
        case 8254:
            return "Kékkút";
        case 8255:
            return "Balatonrendes";
        case 8256:
            return "Ábrahámhegy";
        // case 8256:
        //     return "Salföld";
        case 8257:
            return "Salföld";
        case 8258:
            return "Badacsonytomaj";
        case 8261:
            return "Badacsonytomaj";
        case 8263:
            return "Badacsonytördemic";
        case 8264:
            return "Szigliget";
        case 8265:
            return "Hegymagas";
        case 8271:
            return "Mencshely";
        case 8272:
            return "Szentantalfa";
        // case 8272:
        //     return "Balatoncsicsó";
        // case 8272:
        //     return "Óbudavár";
        // case 8272:
        //     return "Szentjakabfa";
        // case 8272:
        //     return "Tagyon";
        case 8273:
            return "Monoszló";
        case 8274:
            return "Köveskál";
        case 8275:
            return "Balatonhenye";
        case 8281:
            return "Szentbékkálla";
        case 8282:
            return "Mindszentkálla";
        case 8283:
            return "Káptalantóti";
        // case 8284:
        //     return "Nemesgulács";
        case 8284:
            return "Kisapáti";
        case 8286:
            return "Gyulakeszi";
        case 8291:
            return "Nagyvázsony";
        // case 8291:
        //     return "Barnag";
        // case 8291:
        //     return "Pula";
        // case 8291:
        //     return "Vöröstó";
        case 8292:
            return "Öcs";
        case 8294:
            return "Kapolcs";
        // case 8294:
        //     return "Vigántpetend";
        case 8295:
            return "Taliándörögd";
        case 8296:
            return "Monostorapáti";
        // case 8296:
        //     return "Hegyesd";
        case 8297:
            return "Hegyesd";
        case 8300:
            return "Tapolca";
        // case 8300:
        //     return "Raposka";
        case 8301:
            return "Raposka";
        case 8302:
            return "Raposka";
        case 8303:
            return "Raposka";
        case 8308:
            return "Zalahaláp";
        // case 8308:
        //     return "Sáska";
        case 8311:
            return "Nemesvita";
        case 8312:
            return "Balatonederics";
        case 8313:
            return "Balatongyörök";
        case 8314:
            return "Vonyarcvashegy";
        case 8315:
            return "Gyenesdiás";
        case 8316:
            return "Várvölgy";
        // case 8316:
        //     return "Vállus";
        case 8318:
            return "Lesencetomaj";
        // case 8318:
        //     return "Lesencefalu";
        case 8319:
            return "Lesenceistvánd";
        case 8321:
            return "Uzsa";
        case 8330:
            return "Sümeg";
        case 8331:
            return "Sümeg";
        case 8341:
            return "Mihályfa";
        // case 8341:
        //     return "Kisvásárhely";
        // case 8341:
        //     return "Szalapa";
        case 8342:
            return "Óhid";
        case 8344:
            return "Zalaerdőd";
        // case 8344:
        //     return "Hetyefő";
        case 8345:
            return "Dabronc";
        case 8346:
            return "Gógánfa";
        case 8347:
            return "Ukk";
        case 8348:
            return "Rigács";
        // case 8348:
        //     return "Megyer";
        // case 8348:
        //     return "Zalameggyes";
        case 8349:
            return "Zalagyömörő";
        case 8351:
            return "Sümegprága";
        case 8352:
            return "Bazsi";
        case 8353:
            return "Zalaszántó";
        // case 8353:
        //     return "Vindornyalak";
        case 8354:
            return "Karmacs";
        // case 8354:
        //     return "Vindornyafok";
        // case 8354:
        //     return "Zalaköveskút";
        case 8355:
            return "Vindornyaszőlős";
        case 8356:
            return "Kisgörbő";
        // case 8356:
        //     return "Nagygörbő";
        case 8357:
            return "Sümegcsehi";
        // case 8357:
        //     return "Döbröce";
        case 8360:
            return "Keszthely";
        case 8361:
            return "Keszthely";
        case 8362:
            return "Keszthely";
        case 8363:
            return "Keszthely";
        case 8364:
            return "Keszthely";
        case 8371:
            return "Nemesbük";
        case 8372:
            return "Cserszegtomaj";
        case 8373:
            return "Rezi";
        case 8380:
            return "Hévíz";
        // case 8380:
        //     return "Felsőpáhok";
        case 8381:
            return "Felsőpáhok";
        case 8391:
            return "Sármellék";
        case 8392:
            return "Zalavár";
        case 8393:
            return "Szentgyörgyvár";
        case 8394:
            return "Alsópáhok";
        case 8400:
            return "Ajka";
        case 8401:
            return "Ajka";
        case 8403:
            return "Ajka";
        case 8404:
            return "Ajka";
        case 8408:
            return "Ajka";
        case 8409:
            return "Úrkút";
        case 8411:
            return "Úrkút";
        case 8412:
            return "Úrkút";
        case 8413:
            return "Eplény";
        case 8414:
            return "Olaszfalu";
        case 8415:
            return "Nagyesztergár";
        case 8416:
            return "Dudar";
        case 8417:
            return "Csetény";
        case 8418:
            return "Bakonyoszlop";
        case 8419:
            return "Csesznek";
        case 8420:
            return "Zirc";
        case 8422:
            return "Bakonynána";
        case 8423:
            return "Szápár";
        case 8424:
            return "Jásd";
        case 8425:
            return "Lókút";
        case 8426:
            return "Pénzesgyőr";
        case 8427:
            return "Bakonybél";
        case 8428:
            return "Borzavár";
        case 8429:
            return "Porva";
        case 8430:
            return "Bakonyszentkirály";
        case 8431:
            return "Bakonyszentlászló";
        case 8432:
            return "Fenyőfő";
        case 8433:
            return "Bakonygyirót";
        case 8434:
            return "Románd";
        case 8435:
            return "Gic";
        case 8436:
            return "Bakonypéterd";
        case 8437:
            return "Lázi";
        case 8438:
            return "Veszprémvarsány";
        case 8439:
            return "Sikátor";
        case 8440:
            return "Herend";
        case 8441:
            return "Márkó";
        case 8442:
            return "Hárskút";
        case 8443:
            return "Bánd";
        case 8444:
            return "Szentgál";
        case 8445:
            return "Városlőd";
        // case 8445:
        //     return "Csehbánya";
        case 8446:
            return "Kislőd";
        case 8447:
            return "Kislőd";
        case 8448:
            return "Kislőd";
        case 8449:
            return "Magyarpolány";
        case 8451:
            return "Magyarpolány";
        case 8452:
            return "Halimba";
        // case 8452:
        //     return "Szőc";
        case 8454:
            return "Nyirád";
        case 8455:
            return "Pusztamiske";
        case 8456:
            return "Noszlop";
        case 8457:
            return "Bakonypölöske";
        case 8458:
            return "Oroszi";
        case 8460:
            return "Devecser";
        case 8461:
            return "Devecser";
        case 8468:
            return "Kolontár";
        case 8469:
            return "Kamond";
        case 8471:
            return "Káptalanfa";
        // case 8471:
        //     return "Bodorfa";
        // case 8471:
        //     return "Nemeshany";
        case 8473:
            return "Gyepükaján";
        case 8474:
            return "Csabrendek";
        case 8475:
            return "Veszprémgalsa";
        // case 8475:
        //     return "Hosztót";
        // case 8475:
        //     return "Szentimrefalva";
        case 8476:
            return "Zalaszegvár";
        case 8477:
            return "Tüskevár";
        // case 8477:
        //     return "Apácatorna";
        // case 8477:
        //     return "Kisberzseny";
        case 8478:
            return "Somlójenő";
        case 8479:
            return "Borszörcsök";
        case 8481:
            return "Somlóvásárhely";
        case 8482:
            return "Doba";
        case 8483:
            return "Somlószőlős";
        // case 8483:
        //     return "Kisszőlős";
        case 8484:
            return "Nagyalásony";
        // case 8484:
        //     return "Somlóvecse";
        // case 8484:
        //     return "Vid";
        case 8485:
            return "Dabrony";
        case 8491:
            return "Karakószörcsök";
        case 8492:
            return "Kerta";
        case 8493:
            return "Iszkáz";
        case 8494:
            return "Kiscsősz";
        case 8495:
            return "Csögle";
        case 8496:
            return "Nagypirit";
        // case 8496:
        //     return "Kispirit";
        case 8497:
            return "Adorjánháza";
        // case 8497:
        //     return "Egeralja";
        case 8500:
            return "Pápa";
        case 8501:
            return "Pápa";
        case 8502:
            return "Pápa";
        case 8503:
            return "Pápa";
        case 8505:
            return "Pápa";
        case 8510:
            return "Pápa";
        case 8511:
            return "Pápa";
        case 8512:
            return "Nyárád";
        case 8513:
            return "Mihályháza";
        case 8514:
            return "Mezőlak";
        case 8515:
            return "Békás";
        case 8516:
            return "Kemeneshőgyész";
        case 8517:
            return "Magyargencs";
        case 8518:
            return "Kemenesszentpéter";
        case 8521:
            return "Nagyacsád";
        case 8522:
            return "Nemesgörzsöny";
        case 8523:
            return "Egyházaskesző";
        // case 8523:
        //     return "Várkesző";
        case 8531:
            return "Várkesző";
        case 8532:
            return "Marcaltő";
        case 8533:
            return "Malomsok";
        case 8534:
            return "Csikvánd";
        case 8541:
            return "Takácsi";
        case 8542:
            return "Vaszar";
        case 8543:
            return "Gecse";
        case 8544:
            return "Szerecseny";
        case 8545:
            return "Gyarmat";
        case 8551:
            return "Nagygyimót";
        case 8552:
            return "Vanyola";
        case 8553:
            return "Lovászpatona";
        case 8554:
            return "Nagydém";
        case 8555:
            return "Bakonytamási";
        case 8556:
            return "Pápateszér";
        case 8557:
            return "Bakonyszentiván";
        // case 8557:
        //     return "Bakonyság";
        case 8558:
            return "Csót";
        case 8561:
            return "Adásztevel";
        case 8562:
            return "Nagytevel";
        case 8563:
            return "Homokbödöge";
        case 8564:
            return "Ugod";
        case 8565:
            return "Béb";
        case 8571:
            return "Bakonykoppány";
        case 8572:
            return "Bakonyszücs";
        case 8581:
            return "Bakonyjákó";
        // case 8581:
        //     return "Németbánya";
        case 8582:
            return "Farkasgyepű";
        case 8591:
            return "Farkasgyepű";
        // case 8591:
        //     return "Nóráp";
        case 8592:
            return "Dáka";
        case 8593:
            return "Pápadereske";
        case 8594:
            return "Pápasalamon";
        case 8595:
            return "Kup";
        case 8596:
            return "Pápakovácsi";
        // case 8597:
        //     return "Ganna";
        case 8597:
            return "Döbrönte";
        case 8598:
            return "Döbrönte";
        case 8600:
            return "Siófok";
        case 8601:
            return "Siófok";
        case 8602:
            return "Siófok";
        case 8604:
            return "Siófok";
        case 8609:
            return "Siófok";
        case 8611:
            return "Siófok";
        case 8612:
            return "Nyim";
        case 8613:
            return "Balatonendréd";
        case 8614:
            return "Bálványos";
        case 8617:
            return "Kőröshegy ";
        case 8618:
            return "Kereki";
        case 8619:
            return "Pusztaszemes";
        case 8621:
            return "Zamárdi";
        case 8622:
            return "Szántód";
        case 8623:
            return "Balatonföldvár";
        case 8624:
            return "Balatonszárszó";
        case 8625:
            return "Szólád";
        case 8626:
            return "Teleki";
        case 8627:
            return "Kötcse";
        case 8628:
            return "Nagycsepely";
        case 8630:
            return "Balatonboglár";
        case 8631:
            return "Balatonboglár";
        case 8634:
            return "Balatonboglár";
        case 8635:
            return "Ordacsehi";
        case 8636:
            return "Balatonszemes";
        case 8637:
            return "Balatonőszöd";
        case 8638:
            return "Balatonlelle";
        case 8640:
            return "Fonyód";
        case 8641:
            return "Fonyód";
        case 8642:
            return "Fonyód";
        case 8644:
            return "Fonyód";
        case 8646:
            return "Balatonfenyves";
        case 8647:
            return "Balatonmáriafürdő";
        // case 8647:
        //     return "Balatonkeresztúr ";
        case 8648:
            return "Balatonkeresztúr ";
        case 8649:
            return "Balatonberény";
        case 8651:
            return "Balatonszabadi";
        case 8652:
            return "Siójut";
        case 8653:
            return "Ádánd";
        case 8654:
            return "Ságvár";
        case 8655:
            return "Som";
        case 8656:
            return "Nagyberény";
        case 8658:
            return "Bábonymegyer";
        case 8660:
            return "Tab";
        // case 8660:
        //     return "Lulla";
        // case 8660:
        //     return "Sérsekszőlős";
        // case 8660:
        //     return "Torvaj";
        // case 8660:
        //     return "Zala";
        case 8666:
            return "Bedegkér";
        // case 8666:
        //     return "Somogyegres";
        case 8667:
            return "Kánya";
        case 8668:
            return "Tengőd";
        case 8671:
            return "Kapoly";
        case 8672:
            return "Zics";
        case 8673:
            return "Somogymeggyes";
        case 8674:
            return "Nágocs";
        case 8675:
            return "Andocs";
        case 8676:
            return "Karád";
        case 8681:
            return "Látrány";
        // case 8681:
        //     return "Visz";
        case 8683:
            return "Somogytúr";
        case 8684:
            return "Somogybabod";
        case 8685:
            return "Gamás";
        case 8691:
            return "Gamás";
        case 8692:
            return "Szőlősgyörök";
        // case 8692:
        //     return "Gyugy";
        case 8693:
            return "Lengyeltóti";
        // case 8693:
        //     return "Kisberény";
        case 8694:
            return "Hács";
        case 8695:
            return "Buzsák";
        case 8696:
            return "Táska";
        case 8697:
            return "Öreglak";
        case 8698:
            return "Somogyvár";
        // case 8698:
        //     return "Pamuk";
        case 8699:
            return "Somogyvámos";
        case 8700:
            return "Marcali";
        // case 8700:
        //     return "Csömend";
        case 8701:
            return "Csömend";
        case 8705:
            return "Somogyszentpál";
        case 8706:
            return "Nikla";
        case 8707:
            return "Pusztakovácsi";
        // case 8707:
        //     return "Libickozma";
        case 8708:
            return "Somogyfajsz";
        case 8709:
            return "Somogyfajsz";
        case 8710:
            return "Balatonszentgyörgy ";
        case 8711:
            return "Vörs";
        case 8712:
            return "Balatonújlak";
        case 8713:
            return "Kéthely";
        case 8714:
            return "Kéthely";
        // case 8714:
        //     return "Kelevíz";
        case 8716:
            return "Mesztegnyő";
        // case 8716:
        //     return "Hosszúvíz";
        // case 8716:
        //     return "Gadány";
        case 8717:
            return "Szenyér";
        // case 8717:
        //     return "Nemeskisfalud";
        case 8718:
            return "Tapsony";
        case 8719:
            return "Böhönye";
        case 8721:
            return "Vése";
        case 8722:
            return "Nemesdéd";
        case 8723:
            return "Varászló";
        case 8724:
            return "Inke";
        case 8725:
            return "Iharosberény";
        case 8726:
            return "Iharos";
        // case 8726:
        //     return "Somogycsicsó";
        case 8728:
            return "Pogányszentpéter";
        case 8731:
            return "Hollád";
        // case 8731:
        //     return "Tikos";
        case 8732:
            return "Sávoly";
        // case 8732:
        //     return "Főnyed";
        // case 8732:
        //     return "Szegerdő";
        case 8733:
            return "Somogysámson";
        case 8734:
            return "Somogyzsitfa";
        case 8735:
            return "Csákány";
        case 8736:
            return "Szőkedencs";
        case 8737:
            return "Somogysimonyi";
        case 8738:
            return "Nemesvid";
        case 8739:
            return "Nagyszakácsi";
        case 8741:
            return "Zalaapáti";
        // case 8741:
        //     return "Bókaháza";
        case 8742:
            return "Esztergályhorváti";
        case 8743:
            return "Zalaszabar";
        case 8744:
            return "Orosztony";
        case 8745:
            return "Kerecseny";
        case 8746:
            return "Nagyrada";
        case 8747:
            return "Garabonc";
        // case 8747:
        //     return "Zalamerenye";
        case 8749:
            return "Zalakaros";
        case 8751:
            return "Zalakomár";
        case 8752:
            return "Zalakomár";
        case 8753:
            return "Balatonmagyaród";
        case 8754:
            return "Galambok";
        case 8756:
            return "Nagyrécse";
        // case 8756:
        //     return "Csapi";
        // case 8756:
        //     return "Kisrécse";
        // case 8756:
        //     return "Zalasárszeg";
        case 8761:
            return "Pacsa";
        // case 8761:
        //     return "Zalaigrice";
        case 8762:
            return "Szentpéterúr";
        // case 8762:
        //     return "Gétye";
        case 8764:
            return "Dióskál";
        // case 8764:
        //     return "Zalaszentmárton";
        case 8765:
            return "Egeraracsa";
        case 8767:
            return "Felsőrajk";
        // case 8767:
        //     return "Alsórajk";
        // case 8767:
        //     return "Pötréte";
        case 8771:
            return "Hahót";
        case 8772:
            return "Zalaszentbalázs";
        // case 8772:
        //     return "Börzönce";
        // case 8773:
        //     return "Pölöskefő";
        case 8773:
            return "Kacorlak";
        case 8774:
            return "Gelse";
        // case 8774:
        //     return "Gelsesziget";
        // case 8774:
        //     return "Kilimán";
        case 8776:
            return "Magyarszerdahely";
        // case 8776:
        //     return "Bocska";
        // case 8776:
        //     return "Magyarszentmiklós";
        case 8777:
            return "Hosszúvölgy";
        // case 8777:
        //     return "Füzvölgy";
        // case 8777:
        //     return "Homokkomárom";
        case 8778:
            return "Újudvar";
        case 8782:
            return "Zalacsány";
        // case 8782:
        //     return "Ligetfalva";
        // case 8782:
        //     return "Tilaj";
        case 8784:
            return "Kehidakustány";
        case 8785:
            return "Kehidakustány";
        // case 8785:
        //     return "Kallósd";
        case 8788:
            return "Zalaszentlászló";
        // case 8788:
        //     return "Sénye";
        case 8789:
            return "Sénye";
        case 8790:
            return "Zalaszentgrót";
        case 8792:
            return "Zalavég";
        case 8793:
            return "Zalavég";
        case 8795:
            return "Zalavég";
        case 8796:
            return "Türje";
        case 8797:
            return "Batyk";
        case 8798:
            return "Zalabér";
        case 8799:
            return "Pakod";
        // case 8799:
        //     return "Dötk";
        case 8800:
            return "Nagykanizsa";
        case 8801:
            return "Nagykanizsa";
        case 8802:
            return "Nagykanizsa";
        case 8803:
            return "Nagykanizsa";
        case 8804:
            return "Nagykanizsa";
        case 8808:
            return "Nagykanizsa";
        case 8809:
            return "Nagykanizsa";
        case 8821:
            return "Nagybakónak";
        case 8822:
            return "Zalaújlak";
        case 8824:
            return "Sand";
        case 8825:
            return "Miháld";
        // case 8825:
        //     return "Pat";
        case 8827:
            return "Zalaszentjakab";
        case 8831:
            return "Zalaszentjakab";
        // case 8831:
        //     return "Liszó";
        case 8834:
            return "Murakeresztúr";
        case 8835:
            return "Fityeház";
        case 8840:
            return "Csurgó";
        // case 8840:
        //     return "Csurgónagymarton";
        case 8849:
            return "Szenta";
        case 8851:
            return "Gyékényes";
        case 8852:
            return "Gyékényes";
        // case 8852:
        //     return "Zákány";
        case 8853:
            return "Zákányfalu";
        case 8854:
            return "Őrtilos";
        case 8855:
            return "Belezna";
        case 8856:
            return "Surd";
        // case 8856:
        //     return "Nemespátró";
        case 8858:
            return "Porrog";
        // case 8858:
        //     return "Porrogszentkirály";
        // case 8858:
        //     return "Porrogszentpál";
        // case 8858:
        //     return "Somogybükkösd";
        case 8861:
            return "Szepetnek";
        case 8862:
            return "Semjénháza";
        case 8863:
            return "Molnári";
        case 8864:
            return "Tótszerdahely";
        case 8865:
            return "Tótszentmárton";
        case 8866:
            return "Becsehely";
        // case 8866:
        //     return "Petrivente";
        // case 8868:
        //     return "Letenye";
        // case 8868:
        //     return "Kistolmács";
        // case 8868:
        //     return "Murarátka";
        case 8868:
            return "Zajk";
        case 8872:
            return "Muraszemenye";
        // case 8872:
        //     return "Szentmargitfalva";
        case 8873:
            return "Csörnyeföld";
        case 8874:
            return "Dobri";
        // case 8874:
        //     return "Kerkaszentkirály";
        case 8876:
            return "Tormafölde";
        case 8877:
            return "Tornyiszentmiklós";
        case 8878:
            return "Lovászi";
        case 8879:
            return "Szécsisziget";
        // case 8879:
        //     return "Kerkateskánd";
        case 8881:
            return "Sormás";
        case 8882:
            return "Eszteregnye";
        case 8883:
            return "Rigyác";
        case 8885:
            return "Borsfa";
        // case 8885:
        //     return "Valkonya";
        case 8886:
            return "Oltárc";
        case 8887:
            return "Bázakerettye";
        // case 8887:
        //     return "Lasztonya";
        case 8888:
            return "Lispeszentadorján";
        // case 8888:
        //     return "Kiscsehi";
        // case 8888:
        //     return "Maróc";
        case 8891:
            return "Bánokszentgyörgy";
        // case 8891:
        //     return "Várfölde";
        case 8893:
            return "Szentliszló";
        // case 8893:
        //     return "Bucsuta";
        case 8895:
            return "Pusztamagyaród";
        case 8896:
            return "Pusztaszentlászló";
        case 8897:
            return "Söjtör";
        case 8900:
            return "Zalaegerszeg";
        case 8901:
            return "Zalaegerszeg";
        case 8902:
            return "Zalaegerszeg";
        case 8903:
            return "Zalaegerszeg";
        case 8904:
            return "Zalaegerszeg";
        case 8905:
            return "Zalaegerszeg";
        case 8906:
            return "Zalaegerszeg";
        case 8907:
            return "Zalaegerszeg";
        case 8908:
            return "Zalaegerszeg";
        case 8909:
            return "Zalaegerszeg";
        case 8911:
            return "Nagykutas";
        // case 8911:
        //     return "Kiskutas";
        case 8912:
            return "Kispáli";
        // case 8912:
        //     return "Nagypáli";
        case 8913:
            return "Egervár";
        // case 8913:
        //     return "Gősfa";
        // case 8913:
        //     return "Lakhegy";
        case 8914:
            return "Vasboldogasszony";
        case 8915:
            return "Nemesrádó";
        case 8917:
            return "Milejszeg";
        // case 8917:
        //     return "Dobronhegy";
        // case 8917:
        //     return "Pálfiszeg";
        case 8918:
            return "Csonkahegyhát";
        // case 8918:
        //     return "Németfalu";
        case 8919:
            return "Kustánszeg";
        case 8921:
            return "Zalaszentiván";
        // case 8921:
        //     return "Alibánfa";
        // case 8921:
        //     return "Pethőhenye";
        // case 8921:
        //     return "Zalaszentlőrinc";
        case 8923:
            return "Nemesapáti";
        case 8924:
            return "Alsónemesapáti";
        case 8925:
            return "Bucsuszentlászló";
        // case 8925:
        //     return "Kisbucsa";
        // case 8925:
        //     return "Nemeshetés";
        // case 8925:
        //     return "Nemessándorháza";
        // case 8925:
        //     return "Nemesszentandrás";
        case 8929:
            return "Pölöske";
        case 8931:
            return "Kemendollár";
        // case 8931:
        //     return "Vöckönd";
        case 8932:
            return "Pókaszepetk";
        // case 8932:
        //     return "Gyűrűs";
        // case 8932:
        //     return "Zalaistvánd";
        case 8934:
            return "Bezeréd";
        case 8935:
            return "Nagykapornak";
        // case 8935:
        //     return "Almásháza";
        // case 8935:
        //     return "Misefa";
        // case 8935:
        //     return "Orbányosfa";
        // case 8935:
        //     return "Padár";
        case 8936:
            return "Zalaszentmihály";
        case 8943:
            return "Bocfölde";
        // case 8943:
        //     return "Csatár";
        case 8944:
            return "Sárhida";
        case 8945:
            return "Bak";
        case 8946:
            return "Tófej";
        // case 8946:
        //     return "Baktüttös";
        // case 8946:
        //     return "Pusztaederics";
        case 8947:
            return "Zalatárnok";
        // case 8947:
        //     return "Szentkozmadombja";
        case 8948:
            return "Nova";
        // case 8948:
        //     return "Barlahida";
        case 8949:
            return "Mikekarácsonyfa";
        case 8951:
            return "Gutorfölde";
        // case 8951:
        //     return "Csertalakos";
        case 8953:
            return "Szentpéterfölde";
        case 8954:
            return "Ortaháza";
        case 8956:
            return "Páka";
        // case 8956:
        //     return "Kányavár";
        // case 8956:
        //     return "Pördefölde";
        case 8957:
            return "Csömödér";
        // case 8957:
        //     return "Hernyék";
        // case 8957:
        //     return "Kissziget";
        // case 8957:
        //     return "Zebecke";
        case 8958:
            return "Iklódbördőce";
        case 8960:
            return "Lenti";
        case 8961:
            return "Lenti";
        case 8966:
            return "Lenti";
        case 8969:
            return "Gáborjánháza";
        // case 8969:
        //     return "Bödeháza";
        // case 8969:
        //     return "Szijártóháza";
        // case 8969:
        //     return "Zalaszombatfa";
        case 8971:
            return "Zalabaksa";
        // case 8971:
        //     return "Kerkabarabás";
        case 8973:
            return "Csesztreg";
        // case 8973:
        //     return "Alsószenterzsébet";
        // case 8973:
        //     return "Felsőszenterzsébet";
        // case 8973:
        //     return "Kerkafalva";
        // case 8973:
        //     return "Kerkakutas";
        // case 8973:
        //     return "Magyarföld";
        // case 8973:
        //     return "Ramocsa";
        case 8975:
            return "Szentgyörgyvölgy";
        case 8976:
            return "Nemesnép";
        // case 8976:
        //     return "Márokföld";
        case 8977:
            return "Resznek";
        // case 8977:
        //     return "Baglad";
        // case 8977:
        //     return "Lendvajakabfa";
        case 8978:
            return "Rédics";
        // case 8978:
        //     return "Belsősárd";
        // case 8978:
        //     return "Gosztola";
        // case 8978:
        //     return "Külsősárd";
        // case 8978:
        //     return "Lendvadedes";
        case 8981:
            return "Gellénháza";
        // case 8981:
        //     return "Lickóvadamos";
        case 8983:
            return "Nagylengyel";
        // case 8983:
        //     return "Babosdöbréte";
        // case 8983:
        //     return "Ormándlak";
        case 8984:
            return "Petrikeresztúr";
        // case 8984:
        //     return "Gombosszeg";
        // case 8984:
        //     return "Iborfia";
        case 8985:
            return "Becsvölgye";
        case 8986:
            return "Pórszombat";
        // case 8986:
        //     return "Pusztaapáti";
        // case 8986:
        //     return "Szilvágy";
        case 8988:
            return "Kálócfa";
        // case 8988:
        //     return "Kozmadombja";
        case 8991:
            return "Teskánd";
        // case 8991:
        //     return "Böde";
        // case 8991:
        //     return "Hottó";
        case 8992:
            return "Bagod";
        // case 8992:
        //     return "Boncodfölde";
        // case 8992:
        //     return "Hagyárosbörönd";
        // case 8992:
        //     return "Zalaboldogfa";
        case 8994:
            return "Zalaszentgyörgy";
        // case 8994:
        //     return "Kávás";
        case 8995:
            return "Salomvár";
        // case 8995:
        //     return "Keménfa";
        case 8996:
            return "Zalacséb";
        case 8997:
            return "Zalaháshágy";
        case 8998:
            return "Vaspör";
        // case 8998:
        //     return "Ozmánbük";
        case 8999:
            return "Zalalövő";
        // case 8999:
        //     return "Csöde";
        case 9000:
            return "Győr";
        case 9001:
            return "Győr";
        case 9002:
            return "Győr";
        case 9003:
            return "Győr";
        case 9004:
            return "Győr";
        case 9005:
            return "Győr";
        case 9006:
            return "Győr";
        case 9007:
            return "Győr";
        case 9008:
            return "Győr";
        case 9009:
            return "Győr";
        case 9010:
            return "Győr";
        case 9011:
            return "Győrszentiván";
        case 9012:
            return "Ménfőcsanak";
        case 9013:
            return "Győr";
        case 9019:
            return "Gyirmót";
        case 9020:
            return "Győr";
        case 9021:
            return "Győr";
        case 9022:
            return "Győr";
        case 9023:
            return "Győr";
        case 9024:
            return "Győr";
        case 9025:
            return "Győr";
        case 9026:
            return "Győr";
        case 9027:
            return "Győr";
        case 9028:
            return "Győr";
        case 9029:
            return "Győr";
        case 9030:
            return "Győr";
        case 9061:
            return "Vámosszabadi";
        case 9062:
            return "Kisbajcs";
        // case 9062:
        //     return "Vének";
        case 9063:
            return "Nagybajcs";
        case 9071:
            return "Gönyű";
        case 9072:
            return "Nagyszentjános";
        case 9073:
            return "Bőny";
        case 9074:
            return "Rétalap";
        case 9081:
            return "Győrújbarát";
        case 9082:
            return "Nyúl";
        case 9083:
            return "Écs";
        case 9084:
            return "Győrság";
        case 9085:
            return "Pázmándfalu";
        case 9086:
            return "Töltéstava";
        case 9090:
            return "Pannonhalma";
        case 9091:
            return "Ravazd";
        case 9092:
            return "Tarjánpuszta";
        case 9093:
            return "Győrasszonyfa";
        case 9094:
            return "Tápszentmiklós";
        case 9095:
            return "Táp";
        case 9096:
            return "Nyalka";
        case 9097:
            return "Mezőörs";
        case 9098:
            return "Mezőörs";
        case 9099:
            return "Pér";
        case 9100:
            return "Tét";
        case 9111:
            return "Tényő";
        case 9112:
            return "Sokorópátka";
        case 9113:
            return "Koroncó";
        case 9121:
            return "Győrszemere";
        case 9122:
            return "Felpéc";
        case 9123:
            return "Kajárpéc";
        case 9124:
            return "Gyömöre";
        case 9131:
            return "Mórichida";
        case 9132:
            return "Árpás";
        case 9133:
            return "Kisbabot";
        // case 9133:
        //     return "Rábaszentmiklós";
        case 9134:
            return "Bodonhely";
        case 9135:
            return "Rábaszentmihály";
        case 9136:
            return "Rábacsécsény";
        // case 9136:
        //     return "Mérges";
        case 9141:
            return "Ikrény";
        case 9142:
            return "Rábapatona";
        case 9143:
            return "Enese";
        case 9144:
            return "Kóny";
        case 9145:
            return "Bágyogszovát";
        case 9146:
            return "Rábapordány";
        case 9147:
            return "Dör";
        case 9151:
            return "Abda";
        case 9152:
            return "Börcs";
        case 9153:
            return "Öttevény";
        case 9154:
            return "Mosonszentmiklós";
        case 9155:
            return "Lébény";
        case 9161:
            return "Győrsövényház";
        case 9162:
            return "Bezi";
        case 9163:
            return "Fehértó";
        case 9164:
            return "Markotabödöge";
        case 9165:
            return "Rábcakapi";
        // case 9165:
        //     return "Cakóháza";
        // case 9165:
        //     return "Tárnokréti";
        case 9167:
            return "Bősárkány";
        case 9168:
            return "Acsalag";
        case 9169:
            return "Barbacs";
        // case 9169:
        //     return "Maglóca";
        case 9171:
            return "Győrújfalu";
        case 9172:
            return "Győrzámoly";
        case 9173:
            return "Győrladamér";
        case 9174:
            return "Dunaszeg";
        case 9175:
            return "Dunaszentpál";
        case 9176:
            return "Mecsér";
        case 9177:
            return "Ásványráró";
        case 9178:
            return "Hédervár";
        case 9181:
            return "Kimle";
        case 9182:
            return "Károlyháza";
        case 9183:
            return "Károlyháza";
        case 9184:
            return "Kunsziget";
        case 9200:
            return "Mosonmagyaróvár";
        case 9201:
            return "Mosonmagyaróvár";
        case 9202:
            return "Mosonmagyaróvár";
        case 9203:
            return "Mosonmagyaróvár";
        case 9211:
            return "Feketeerdő";
        // case 9221:
        //     return "Levél";
        case 9222:
            return "Hegyeshalom";
        case 9223:
            return "Bezenye";
        case 9224:
            return "Rajka";
        case 9225:
            return "Dunakiliti";
        case 9226:
            return "Dunasziget";
        case 9228:
            return "Halászi";
        case 9231:
            return "Máriakálnok";
        case 9232:
            return "Darnózseli";
        case 9233:
            return "Lipót";
        case 9234:
            return "Kisbodak";
        case 9235:
            return "Püski";
        // case 9235:
        //     return "Dunaremete";
        case 9241:
            return "Jánossomorja    ";
        case 9242:
            return "Jánossomorja    ";
        case 9243:
            return "Várbalog";
        case 9244:
            return "Újrónafő";
        case 9245:
            return "Mosonszolnok";
        case 9300:
            return "Csorna";
        case 9301:
            return "Csorna";
        case 9311:
            return "Pásztori";
        case 9312:
            return "Szilsárkány";
        case 9313:
            return "Rábacsanak";
        case 9314:
            return "Egyed";
        case 9315:
            return "Sobor";
        case 9316:
            return "Rábaszentandrás";
        case 9317:
            return "Szany";
        case 9321:
            return "Farád";
        case 9322:
            return "Rábatamási";
        case 9323:
            return "Jobaháza";
        case 9324:
            return "Bogyoszló";
        // case 9324:
        //     return "Potyond";
        case 9325:
            return "Sopronnémeti";
        case 9326:
            return "Szil";
        case 9327:
            return "Vág";
        // case 9327:
        //     return "Rábasebes";
        case 9330:
            return "Kapuvár";
        case 9331:
            return "Kapuvár";
        case 9332:
            return "Kapuvár";
        case 9339:
            return "Kapuvár";
        case 9341:
            return "Kisfalud";
        case 9342:
            return "Mihályi";
        case 9343:
            return "Beled";
        // case 9343:
        //     return "Edve";
        // case 9343:
        //     return "Vásárosfalu";
        case 9344:
            return "Rábakecöl";
        case 9345:
            return "Páli";
        case 9346:
            return "Magyarkeresztúr";
        // case 9346:
        //     return "Vadosfa";
        // case 9346:
        //     return "Zsebeháza";
        case 9351:
            return "Babót";
        case 9352:
            return "Veszkény";
        case 9353:
            return "Szárföld";
        case 9354:
            return "Osli";
        case 9361:
            return "Hövej";
        case 9362:
            return "Himod";
        case 9363:
            return "Gyóró";
        case 9364:
            return "Cirák";
        case 9365:
            return "Dénesfa";
        case 9371:
            return "Vitnyéd";
        case 9372:
            return "Csapod";
        case 9373:
            return "Pusztacsalád";
        case 9374:
            return "Iván";
        case 9375:
            return "Répceszemere";
        // case 9375:
        //     return "Csáfordjánosfa";
        // case 9375:
        //     return "Csér";
        case 9400:
            return "Sopron";
        case 9401:
            return "Sopron";
        case 9402:
            return "Sopron";
        case 9403:
            return "Sopron";
        case 9404:
            return "Sopron";
        case 9405:
            return "Sopron";
        case 9406:
            return "Sopron";
        case 9407:
            return "Sopron";
        case 9408:
            return "Sopron";
        case 9409:
            return "Sopron";
        case 9421:
            return "Fertőrákos";
        case 9422:
            return "Harka";
        case 9423:
            return "Ágfalva";
        case 9431:
            return "Fertőd";
        case 9433:
            return "Fertőd";
        case 9434:
            return "Fertőd";
        case 9435:
            return "Sarród";
        case 9436:
            return "Fertőszéplak";
        case 9437:
            return "Hegykő";
        case 9441:
            return "Agyagosszergény";
        case 9442:
            return "Fertőendréd";
        case 9443:
            return "Petőháza";
        case 9444:
            return "Fertőszentmiklós";
        case 9451:
            return "Röjtökmuzsaj";
        // case 9451:
        //     return "Ebergőc";
        case 9461:
            return "Lövő";
        case 9462:
            return "Völcsej";
        case 9463:
            return "Sopronhorpács";
        case 9464:
            return "Und";
        case 9471:
            return "Nemeskér";
        case 9472:
            return "Újkér";
        case 9473:
            return "Egyházasfalu";
        case 9474:
            return "Szakony";
        // case 9474:
        //     return "Gyalóka";
        case 9475:
            return "Répcevis";
        case 9476:
            return "Zsira";
        case 9481:
            return "Pinnye";
        case 9482:
            return "Nagylózs";
        case 9483:
            return "Sopronkövesd";
        case 9484:
            return "Pereszteg";
        case 9485:
            return "Nagycenk";
        case 9491:
            return "Hidegség";
        case 9492:
            return "Fertőhomok";
        case 9493:
            return "Fertőboz";
        case 9494:
            return "Fertőboz";
        case 9495:
            return "Kópháza";
        case 9500:
            return "Celldömölk";
        case 9501:
            return "Celldömölk";
        case 9502:
            return "Celldömölk";
        case 9503:
            return "Celldömölk";
        case 9511:
            return "Kemenesmihályfa";
        case 9512:
            return "Ostffyasszonyfa";
        case 9513:
            return "Csönge";
        case 9514:
            return "Kenyeri";
        case 9515:
            return "Pápoc";
        case 9516:
            return "Vönöck";
        case 9517:
            return "Kemenessömjén";
        case 9521:
            return "Kemenesszentmárton";
        case 9522:
            return "Kemenesmagasi";
        case 9523:
            return "Szergény";
        case 9531:
            return "Mersevát";
        case 9532:
            return "Külsővat";
        case 9533:
            return "Nemesszalók";
        case 9534:
            return "Marcalgergelyi";
        // case 9534:
        //     return "Vinár";
        case 9541:
            return "Vinár";
        case 9542:
            return "Boba";
        // case 9542:
        //     return "Nemeskocs";
        case 9544:
            return "Kemenespálfa";
        case 9545:
            return "Jánosháza";
        case 9547:
            return "Karakó";
        case 9548:
            return "Nemeskeresztúr";
        case 9549:
            return "Keléd";
        case 9551:
            return "Mesteri";
        case 9552:
            return "Vásárosmiske";
        case 9553:
            return "Köcsk";
        // case 9553:
        //     return "Kemeneskápolna";
        case 9554:
            return "Egyházashetye";
        // case 9554:
        //     return "Borgáta";
        case 9555:
            return "Kissomlyó";
        case 9556:
            return "Duka";
        case 9561:
            return "Nagysimonyi";
        // case 9561:
        //     return "Tokorcs";
        case 9600:
            return "Sárvár";
        case 9601:
            return "Sárvár";
        case 9602:
            return "Sárvár";
        case 9608:
            return "Sárvár";
        case 9609:
            return "Sárvár";
        case 9611:
            return "Csénye";
        case 9612:
            return "Bögöt";
        // case 9612:
        //     return "Porpác";
        case 9621:
            return "Ölbő";
        case 9622:
            return "Szeleste";
        case 9623:
            return "Répceszentgyörgy";
        case 9624:
            return "Chernelházadamonya";
        case 9625:
            return "Bő";
        // case 9625:
        //     return "Gór";
        case 9631:
            return "Hegyfalu";
        case 9632:
            return "Sajtoskál";
        case 9633:
            return "Simaság";
        case 9634:
            return "Lócs";
        // case 9634:
        //     return "Iklanberény";
        case 9635:
            return "Zsédeny";
        case 9636:
            return "Pósfa";
        case 9641:
            return "Rábapaty";
        case 9643:
            return "Jákfa";
        case 9651:
            return "Uraiújfalu";
        case 9652:
            return "Nick";
        case 9653:
            return "Répcelak";
        case 9654:
            return "Csánig";
        case 9661:
            return "Vasegerszeg";
        case 9662:
            return "Tompaládony";
        // case 9662:
        //     return "Mesterháza";
        case 9663:
            return "Nemesládony";
        case 9664:
            return "Nagygeresd";
        case 9665:
            return "Vámoscsalád";
        case 9671:
            return "Sitke";
        case 9672:
            return "Gérce";
        case 9673:
            return "Káld";
        case 9674:
            return "Vashosszúfalu";
        case 9675:
            return "Bögöte";
        case 9676:
            return "Hosszúpereszteg";
        case 9681:
            return "Sótony";
        case 9682:
            return "Nyőgér";
        case 9683:
            return "Bejcgyertyános";
        case 9684:
            return "Egervölgy";
        case 9685:
            return "Szemenye";
        case 9700:
            return "Szombathely";
        case 9701:
            return "Szombathely";
        case 9702:
            return "Szombathely";
        case 9703:
            return "Szombathely";
        case 9704:
            return "Szombathely";
        case 9705:
            return "Szombathely";
        case 9706:
            return "Szombathely";
        case 9707:
            return "Szombathely";
        case 9708:
            return "Szombathely";
        case 9709:
            return "Szombathely";
        case 9710:
            return "Szombathely";
        case 9719:
            return "Szombathely";
        case 9721:
            return "Gencsapáti";
        case 9722:
            return "Perenye";
        case 9723:
            return "Lukácsháza ";
        case 9724:
            return "Gyöngyösfalu";
        case 9725:
            return "Kőszegszerdahely";
        // case 9725:
        //     return "Cák";
        // case 9725:
        //     return "Kőszegdoroszló";
        case 9726:
            return "Velem";
        case 9727:
            return "Bozsok";
        case 9730:
            return "Kőszeg";
        case 9731:
            return "Kőszeg";
        case 9732:
            return "Kőszeg";
        case 9733:
            return "Horvátzsidány";
        // case 9733:
        //     return "Kiszsidány";
        // case 9733:
        //     return "Ólmod";
        case 9734:
            return "Peresznye";
        case 9735:
            return "Csepreg";
        case 9736:
            return "Tormásliget";
        case 9737:
            return "Bük";
        case 9738:
            return "Tömörd";
        case 9739:
            return "Nemescsó";
        // case 9739:
        //     return "Kőszegpaty";
        // case 9739:
        //     return "Pusztacsó";
        case 9740:
            return "Pusztacsó";
        case 9741:
            return "Vassurány";
        case 9742:
            return "Salköveskút";
        case 9743:
            return "Söpte";
        case 9744:
            return "Vasasszonyfa";
        case 9745:
            return "Meszlen";
        case 9746:
            return "Acsád";
        case 9747:
            return "Vasszilvágy";
        case 9748:
            return "Vát";
        case 9749:
            return "Nemesbőd";
        case 9751:
            return "Vép";
        case 9752:
            return "Bozzai";
        // case 9752:
        //     return "Kenéz";
        case 9754:
            return "Pecöl";
        // case 9754:
        //     return "Megyehid";
        case 9756:
            return "Ikervár";
        case 9757:
            return "Meggyeskovácsi";
        case 9761:
            return "Táplánszentkereszt";
        case 9762:
            return "Tanakajd";
        case 9763:
            return "Vasszécseny";
        case 9764:
            return "Csempeszkopács";
        case 9766:
            return "Rum";
        // case 9766:
        //     return "Rábatöttös";
        // case 9766:
        //     return "Zsennye";
        case 9771:
            return "Balogunyom";
        case 9772:
            return "Kisunyom";
        case 9773:
            return "Sorokpolány";
        case 9774:
            return "Sorkifalud";
        // case 9774:
        //     return "Gyanógeregye";
        // case 9774:
        //     return "Sorkikápolna";
        case 9775:
            return "Nemeskolta";
        case 9776:
            return "Püspökmolnári";
        case 9777:
            return "Rábahidvég";
        case 9781:
            return "Egyházashollós";
        case 9782:
            return "Nemesrempehollós";
        case 9783:
            return "Egyházasrádóc";
        case 9784:
            return "Rádóckölked";
        // case 9784:
        //     return "Harasztifalu";
        // case 9784:
        //     return "Nagykölked";
        case 9789:
            return "Sé";
        case 9791:
            return "Torony";
        // case 9791:
        //     return "Dozmat";
        case 9792:
            return "Bucsu";
        case 9793:
            return "Narda";
        case 9794:
            return "Felsőcsatár";
        case 9795:
            return "Vaskeresztes";
        case 9796:
            return "Pornóapáti";
        // case 9796:
        //     return "Horvátlövő";
        case 9797:
            return "Nárai";
        case 9798:
            return "Ják";
        case 9799:
            return "Szentpéterfa";
        case 9800:
            return "Vasvár";
        case 9801:
            return "Vasvár";
        case 9811:
            return "Andrásfa";
        case 9812:
            return "Telekes";
        case 9813:
            return "Gersekarát";
        // case 9813:
        //     return "Sárfimizdó";
        case 9814:
            return "Halastó";
        case 9821:
            return "Győrvár";
        // case 9821:
        //     return "Hegyhátszentpéter";
        case 9823:
            return "Pácsony";
        case 9824:
            return "Olaszfa";
        case 9825:
            return "Oszkó";
        case 9826:
            return "Petőmihályfa";
        case 9831:
            return "Bérbaltavár";
        case 9832:
            return "Nagytilaj";
        case 9833:
            return "Csehi";
        case 9834:
            return "Csehimindszent";
        case 9835:
            return "Mikosszéplak";
        case 9836:
            return "Csipkerek";
        case 9841:
            return "Kám";
        case 9842:
            return "Alsóújlak";
        case 9900:
            return "Körmend";
        case 9901:
            return "Körmend";
        case 9909:
            return "Körmend";
        // case 9909:
        //     return "Magyarnádalja";
        case 9912:
            return "Molnaszecsőd";
        // case 9912:
        //     return "Magyarszecsőd";
        case 9913:
            return "Szarvaskend";
        // case 9913:
        //     return "Döröske";
        // case 9913:
        //     return "Nagymizdó";
        case 9914:
            return "Döbörhegy";
        case 9915:
            return "Nádasd";
        // case 9915:
        //     return "Hegyháthodász";
        // case 9915:
        //     return "Hegyhátsál";
        // case 9915:
        //     return "Katafa";
        case 9917:
            return "Halogy";
        // case 9917:
        //     return "Daraboshegy";
        case 9918:
            return "Felsőmarác";
        case 9919:
            return "Csákánydoroszló";
        case 9921:
            return "Vasalja";
        case 9922:
            return "Pinkamindszent";
        case 9923:
            return "Kemestaródfa";
        case 9931:
            return "Ivánc";
        // case 9931:
        //     return "Hegyhátszentmárton";
        case 9932:
            return "Viszák";
        case 9933:
            return "Őrimagyarósd";
        case 9934:
            return "Hegyhátszentjakab";
        // case 9934:
        //     return "Felsőjánosfa";
        // case 9934:
        //     return "Szaknyér";
        case 9935:
            return "Szőce";
        case 9936:
            return "Kisrákos";
        case 9937:
            return "Pankasz";
        case 9938:
            return "Nagyrákos";
        // case 9938:
        //     return "Szatta";
        case 9941:
            return "Őriszentpéter";
        // case 9941:
        //     return "Ispánk";
        case 9942:
            return "Szalafő";
        case 9943:
            return "Kondorfa";
        case 9944:
            return "Bajánsenye";
        // case 9944:
        //     return "Kerkáskápolna";
        case 9945:
            return "Kercaszomor";
        case 9946:
            return "Magyarszombatfa";
        // case 9946:
        //     return "Velemér";
        case 9951:
            return "Rátót";
        case 9952:
            return "Gasztony";
        case 9953:
            return "Vasszentmihály";
        // case 9953:
        //     return "Nemesmedves";
        case 9954:
            return "Rönök";
        case 9955:
            return "Rönök";
        case 9961:
            return "Rábagyarmat";
        case 9962:
            return "Csörötnek";
        // case 9962:
        //     return "Magyarlak";
        case 9970:
            return "Szentgotthárd";
        case 9971:
            return "Szentgotthárd";
        case 9981:
            return "Szentgotthárd";
        case 9982:
            return "Apátistvánfalva";
        // case 9982:
        //     return "Kétvölgy";
        // case 9982:
        //     return "Orfalu";
        case 9983:
            return "Alsószölnök";
        // case 9983:
        //     return "Szakonyfalu";
        case 9985:
            return "Felsőszölnök";
        case 1011:
            return "Budapest";
        case 1012:
            return "Budapest";
        case 1013:
            return "Budapest";
        case 1014:
            return "Budapest";
        case 1015:
            return "Budapest";
        case 1016:
            return "Budapest";
        case 1021:
            return "Budapest";
        case 1022:
            return "Budapest";
        case 1023:
            return "Budapest";
        case 1024:
            return "Budapest";
        case 1025:
            return "Budapest";
        case 1026:
            return "Budapest";
        case 1027:
            return "Budapest";
        case 1028:
            return "Budapest";
        case 1029:
            return "Budapest";
        case 1031:
            return "Budapest";
        case 1032:
            return "Budapest";
        case 1033:
            return "Budapest";
        case 1034:
            return "Budapest";
        case 1035:
            return "Budapest";
        case 1036:
            return "Budapest";
        case 1037:
            return "Budapest";
        case 1038:
            return "Budapest";
        case 1039:
            return "Budapest";
        case 1041:
            return "Budapest";
        case 1042:
            return "Budapest";
        case 1043:
            return "Budapest";
        case 1044:
            return "Budapest";
        case 1045:
            return "Budapest";
        case 1046:
            return "Budapest";
        case 1047:
            return "Budapest";
        case 1048:
            return "Budapest";
        case 1051:
            return "Budapest";
        case 1052:
            return "Budapest";
        case 1053:
            return "Budapest";
        case 1054:
            return "Budapest";
        case 1055:
            return "Budapest";
        case 1056:
            return "Budapest";
        case 1061:
            return "Budapest";
        case 1062:
            return "Budapest";
        case 1063:
            return "Budapest";
        case 1064:
            return "Budapest";
        case 1065:
            return "Budapest";
        case 1066:
            return "Budapest";
        case 1067:
            return "Budapest";
        case 1068:
            return "Budapest";
        case 1071:
            return "Budapest";
        case 1072:
            return "Budapest";
        case 1073:
            return "Budapest";
        case 1074:
            return "Budapest";
        case 1075:
            return "Budapest";
        case 1076:
            return "Budapest";
        case 1077:
            return "Budapest";
        case 1078:
            return "Budapest";
        case 1081:
            return "Budapest";
        case 1082:
            return "Budapest";
        case 1083:
            return "Budapest";
        case 1084:
            return "Budapest";
        case 1085:
            return "Budapest";
        case 1086:
            return "Budapest";
        case 1087:
            return "Budapest";
        case 1088:
            return "Budapest";
        case 1089:
            return "Budapest";
        case 1091:
            return "Budapest";
        case 1092:
            return "Budapest";
        case 1093:
            return "Budapest";
        case 1094:
            return "Budapest";
        case 1095:
            return "Budapest";
        case 1096:
            return "Budapest";
        case 1097:
            return "Budapest";
        case 1098:
            return "Budapest";
        case 1101:
            return "Budapest";
        case 1102:
            return "Budapest";
        case 1103:
            return "Budapest";
        case 1104:
            return "Budapest";
        case 1105:
            return "Budapest";
        case 1106:
            return "Budapest";
        case 1107:
            return "Budapest";
        case 1108:
            return "Budapest";
        case 1111:
            return "Budapest";
        case 1112:
            return "Budapest";
        case 1113:
            return "Budapest";
        case 1114:
            return "Budapest";
        case 1115:
            return "Budapest";
        case 1116:
            return "Budapest";
        case 1117:
            return "Budapest";
        case 1118:
            return "Budapest";
        case 1119:
            return "Budapest";
        case 1121:
            return "Budapest";
        case 1122:
            return "Budapest";
        case 1123:
            return "Budapest";
        case 1124:
            return "Budapest";
        case 1125:
            return "Budapest";
        case 1126:
            return "Budapest";
        case 1131:
            return "Budapest";
        case 1132:
            return "Budapest";
        case 1133:
            return "Budapest";
        case 1134:
            return "Budapest";
        case 1135:
            return "Budapest";
        case 1136:
            return "Budapest";
        case 1137:
            return "Budapest";
        case 1138:
            return "Budapest";
        case 1139:
            return "Budapest";
        case 1141:
            return "Budapest";
        case 1142:
            return "Budapest";
        case 1143:
            return "Budapest";
        case 1144:
            return "Budapest";
        case 1145:
            return "Budapest";
        case 1146:
            return "Budapest";
        case 1147:
            return "Budapest";
        case 1148:
            return "Budapest";
        case 1149:
            return "Budapest";
        case 1151:
            return "Budapest";
        case 1152:
            return "Budapest";
        case 1153:
            return "Budapest";
        case 1154:
            return "Budapest";
        case 1155:
            return "Budapest";
        case 1156:
            return "Budapest";
        case 1157:
            return "Budapest";
        case 1158:
            return "Budapest";
        case 1161:
            return "Budapest";
        case 1162:
            return "Budapest";
        case 1163:
            return "Budapest";
        case 1164:
            return "Budapest";
        case 1165:
            return "Budapest";
        case 1171:
            return "Budapest";
        case 1172:
            return "Budapest";
        case 1173:
            return "Budapest";
        case 1174:
            return "Budapest";
        case 1181:
            return "Budapest";
        case 1182:
            return "Budapest";
        case 1183:
            return "Budapest";
        case 1184:
            return "Budapest";
        case 1185:
            return "Budapest";
        case 1186:
            return "Budapest";
        case 1188:
            return "Budapest";
        case 1191:
            return "Budapest";
        case 1192:
            return "Budapest";
        case 1193:
            return "Budapest";
        case 1194:
            return "Budapest";
        case 1195:
            return "Budapest";
        case 1196:
            return "Budapest";
        case 1201:
            return "Budapest";
        case 1202:
            return "Budapest";
        case 1203:
            return "Budapest";
        case 1204:
            return "Budapest";
        case 1205:
            return "Budapest";
        case 1211:
            return "Budapest";
        case 1212:
            return "Budapest";
        case 1213:
            return "Budapest";
        case 1214:
            return "Budapest";
        case 1215:
            return "Budapest";
        case 1221:
            return "Budapest";
        case 1222:
            return "Budapest";
        case 1223:
            return "Budapest";
        case 1224:
            return "Budapest";
        case 1225:
            return "Budapest";
        case 1237:
            return "Budapest";
        case 1238:
            return "Budapest";
        case 1239:
            return "Budapest";
        case 1529:
            return "Budapest";
    }
}