
import i18n from '../../i18n'
import { ICON_TYPE } from '../../utils/global'
import { isArrayUnavailable } from '../../utils/HelperFunctions'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../elements/Button'
import { Separator } from '../elements/Separator'
import { MODAL_TYPE } from '../modal/BaseModal'
import mainPageStyles from '../../pages/WorksheetPage.module.scss'
import styles from './WorksheetProducts.module.scss'
import { useContext } from 'react'
import { DeviceContext, SelectedWorksheetContext } from '../../context/Contexts'
import { WorksheetProductHeaders } from './WorksheetProductHeaders'
import { WorksheetProduct } from './WorksheetProduct'
import { Worksheet } from '../../models/Worksheet'
import Grid from '@mui/material/Grid'
import InputField, { WIDTH, INPUT_ALIGN, LABEL, INPUT_TYPE, INPUT_LABEL_SIZE } from '../elements/InputField'

export interface Props {
    openModal: (type: MODAL_TYPE) => void
}

export const WorksheetProducts = ({ openModal }: Props) => {
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { isMobile } = useContext(DeviceContext)

    const handleBoolChange = (e: any) => {
        setWorksheet((prevState: Worksheet) => ({
            ...prevState,
            [e.target.name]: e.target.value === "true" ? false : true 
        }))
    }

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null

    if(isMobile) return (
        <div className={mainPageStyles['item']}>
            {/* Tételek */}
            <div className={mainPageStyles['item-header']}>
                <div className={mainPageStyles['item-header-text']}>{i18n('worksheet.products.header')}</div>
            </div>
            <Separator />
            {!isArrayUnavailable(worksheet.Items) &&
            <div className={mainPageStyles['content-normal']}>
                <Grid container spacing={2}>
                    <Grid item xs={8.5}>
                        <div></div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={styles['item-detail-header-mobile']}>{i18n('worksheet.products.details.showOnWorksheet')}</div>
                    </Grid>
                </Grid>
                {worksheet.Items.map((item, index) =>(
                    <div key={index}>
                        <WorksheetProduct handleBoolChange={handleBoolChange} index={index} />
                        <Separator light={true} />
                    </div>
                )) }
                
            </div> }
            <Button 
                text={i18n('button.worksheetPage.addProduct')}
                shade={BUTTON_SHADE.LIGHTBLUE} 
                size={BUTTON_SIZE.WITH_ICON}
                isExtraSpacious={isMobile}
                isDisabled={hasCustomerSigned()}
                handleAction={() => openModal(MODAL_TYPE.PRODUCT)}
                icon={ICON_TYPE.ADD} />
            
        </div>
    )

    return (
        <div className={mainPageStyles['item']}>
            {/* Tételek */}
            <div className={mainPageStyles['item-header']}>
                <div className={mainPageStyles['item-header-text']}>{i18n('worksheet.products.header')}</div>
            </div>
            <Separator />
            {!isArrayUnavailable(worksheet.Items) &&
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <div className={styles['item-detail-header']}>{i18n('worksheet.products.details.showOnWorksheet')}</div>
                    </Grid>
                    <Grid item xs={1.5}>
                        <div></div>
                    </Grid>
                    <Grid item xs={1.5}>
                        <div></div>
                    </Grid>
                    <Grid item xs={1.5}>
                        <InputField 
                            name='ShowUnitPrice'
                            value={worksheet.ShowUnitPrice}
                            isDisabled={hasCustomerSigned()}
                            changed={handleBoolChange}
                            labelWidth={WIDTH.NONE}
                            barAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                            barWidth={WIDTH.FULL_LINE}
                            type={INPUT_TYPE.CHECKBOX} />
                    </Grid>
                    <Grid item xs={1.5}>
                        <InputField 
                            name='ShowVat'
                            value={worksheet.ShowVat}
                            isDisabled={hasCustomerSigned()}
                            changed={handleBoolChange}
                            labelWidth={WIDTH.NONE}
                            barAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                            barWidth={WIDTH.FULL_LINE}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.CHECKBOX} />
                    </Grid>
                    <Grid item xs={1.5}>
                        <InputField 
                            name='ShowNetValue'
                            value={worksheet.ShowNetValue}
                            isDisabled={hasCustomerSigned()}
                            changed={handleBoolChange}
                            labelWidth={WIDTH.NONE}
                            barAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                            barWidth={WIDTH.FULL_LINE}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.CHECKBOX} />
                    </Grid>
                    <Grid item xs={1.5}>
                        <InputField 
                            name='ShowGrossValue'
                            value={worksheet.ShowGrossValue}
                            isDisabled={hasCustomerSigned()}
                            changed={handleBoolChange}
                            labelWidth={WIDTH.NONE}
                            barAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                            barWidth={WIDTH.FULL_LINE}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.CHECKBOX} />
                    </Grid>
                </Grid>
            
                {worksheet.Items.map((item, index) =>(
                    <div key={index}>
                        <WorksheetProduct handleBoolChange={handleBoolChange} index={index} />
                        <Separator light={true} />
                    </div>
                )) }
            </div> }
            <Button 
                text={i18n('button.worksheetPage.addProduct')}
                shade={BUTTON_SHADE.LIGHTBLUE} 
                size={BUTTON_SIZE.WITH_ICON}
                isExtraSpacious={isMobile}
                isDisabled={hasCustomerSigned()}
                handleAction={() => openModal(MODAL_TYPE.PRODUCT)}
                icon={ICON_TYPE.ADD} />
        </div>
    )
}