import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import styles from './Signature.module.scss'
import SignaturePad from 'react-signature-canvas'
import { DeviceContext, SelectedWorksheetContext } from '../../../context/Contexts'
import i18n from '../../../i18n'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../../elements/Button'
import { SignatureObj } from '../../../models/SignatureObj'
import { BACKEND_ROUTE, kulcsUzletRequest, REQUEST_METHOD } from '../../../request'
import LoadingAnimation from '../../elements/LoadingAnimation'
import { store } from '../../../App'
import { showErrorMessages } from '../../../reducers/rootReducer'

type Props = {
    setModalTitle: React.Dispatch<React.SetStateAction<string>> | undefined
    isShown: boolean
}

enum WINDOW {
    INITIAL = 'initial',
    CUSTOMER = 'customer',
    ISSUER = 'issuer'
}

const SignatureModal = ({ setModalTitle, isShown }: Props) => {
    const signCanvas = useRef() as React.MutableRefObject<any>
    const { isMobile } = useContext(DeviceContext)
    const [ windowState, setWindowState ] = useState(WINDOW.INITIAL)
    const { worksheet } = useContext(SelectedWorksheetContext)
    const [ isLoading, setIsLoading ] = useState(false)
    // const [ hasChange, setHasChange] = useState(false)

    const clear = () => signCanvas.current.clear()

    const save = async () => {
        if(signCanvas.current.isEmpty()) {
            store.dispatch(showErrorMessages(['A mentéshez aláírás megadása kötelező!']))
            return
        }
        setIsLoading(true)
        let canvasUrl = signCanvas.current.getCanvas().toDataURL("image/png").replace('data:image/png;base64,', '') ?? null
        let signatureDto = {
            WorksheetId: worksheet.Id,
            IssuerSignature: windowState === WINDOW.ISSUER ? canvasUrl : null,
            CustomerSignature: windowState === WINDOW.CUSTOMER ? canvasUrl : null 
        } as SignatureObj
        
        await kulcsUzletRequest(BACKEND_ROUTE.SIGNATURE, JSON.stringify(signatureDto), REQUEST_METHOD.POST).then(async function(res) {
            if (res != null || res[0] != null) {
                window.location.reload()
            }
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
            setIsLoading(false)
        })
    }

    const openNextWindow = (type: WINDOW) => {
        switch(type) {
            case WINDOW.CUSTOMER: {
                setWindowState(WINDOW.CUSTOMER)
                setModalTitle && setModalTitle(i18n('modal.listType.signature.customerTitle'))
                break
            }
            case WINDOW.ISSUER: {
                setWindowState(WINDOW.ISSUER)
                setModalTitle && setModalTitle(i18n('modal.listType.signature.issuerTitle'))
                break
            }
        }
    }

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null

    const hasIssuerSigned = () => worksheet?.IssuerSignedDateTime !== undefined && worksheet?.IssuerSignedDateTime !== null

    return (isLoading ? <LoadingAnimation /> : windowState === WINDOW.INITIAL ?
        <Fragment>
            <div className={styles[isMobile ? 'description-first-mobile' : 'description']}>{i18n('modal.listType.signature.description')}</div>
            <div className={styles[isMobile ? 'note-mobile' : 'note']}>{i18n('modal.listType.signature.note')}</div>
            <div className={styles['action-items']} >
                <Button 
                    isDisabled={hasCustomerSigned()}
                    text={i18n('button.signatureModal.customer')}
                    shade={BUTTON_SHADE.DARKBLUE} 
                    size={isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.MEDIUM}
                    handleAction={() => openNextWindow(WINDOW.CUSTOMER)} />
                {isMobile && <div className={styles['placeholder']}></div>}
                <Button 
                    isDisabled={hasIssuerSigned()}
                    text={i18n('button.signatureModal.issuer')}
                    shade={BUTTON_SHADE.LIGHTBLUE} 
                    size={isMobile ? BUTTON_SIZE.MEDIUM : BUTTON_SIZE.MEDIUM}
                    handleAction={() => openNextWindow(WINDOW.ISSUER)} />
            </div>
        </Fragment> : windowState === WINDOW.CUSTOMER ?
            <Fragment>
                <div className={styles[isMobile ? 'description-mobile' : 'description']}>{i18n('modal.listType.signature.customerDescription')}</div>
                <SignaturePad
                    ref={signCanvas}
                    canvasProps={{ height: isMobile ? 420 : 240, width: isMobile ? 332 : 715, className: styles['pad']}}
                    />
                <div className={styles['action-items']} >
                    <Button 
                        text={i18n('button.general.delete')}
                        shade={BUTTON_SHADE.DARKBLUE} 
                        size={BUTTON_SIZE.NORMAL}
                        handleAction={clear} />
                    <Button 
                        text={i18n('button.general.save')}
                        shade={BUTTON_SHADE.LIGHTBLUE} 
                        size={BUTTON_SIZE.NORMAL}
                        handleAction={save} />
                </div>
            </Fragment> :
            <Fragment>
                <div className={styles[isMobile ? 'description-mobile' : 'description']}>{i18n('modal.listType.signature.issuerDescription')}</div>
                <SignaturePad
                    ref={signCanvas}
                    canvasProps={{ height: isMobile ? 420 : 240, width: isMobile ? 332 : 715, className: styles['pad']}}
                    />
                <div className={styles['action-items']} >
                    <Button 
                        text={'Törlés'}
                        shade={BUTTON_SHADE.DARKBLUE} 
                        size={BUTTON_SIZE.NORMAL}
                        handleAction={clear} />
                    <Button 
                        text={i18n('button.general.save')}
                        shade={BUTTON_SHADE.LIGHTBLUE} 
                        size={BUTTON_SIZE.NORMAL}
                        handleAction={save} />
                </div>
        </Fragment>
    )
}

export default SignatureModal