import "react-sliding-pane/dist/react-sliding-pane.css";
import styles from './BaseModal.module.scss'

type Props = {
    onClick?: () => void
    buttonText: string
    buttonPicSrc: string
    isDisabled: boolean
}

const SideMenuButton = ({ onClick, buttonText, buttonPicSrc, isDisabled
    }: Props) => {
    
    return (
        <div className={isDisabled ? `${styles['pane-row-item']} ${styles['disabled']}` : styles['pane-row-item']}>
            <img className={styles['pane-menu-icon']} src={buttonPicSrc} alt=''/>
            <div className={styles['pane-menu-title']} onClick={onClick}>{buttonText}</div>
        </div>
    )
}
    
export default SideMenuButton