import styles from './WarningMessage.module.scss'
import infoIcon from '../../images/info_icon.png'
import warningIcon from '../../images/warning_icon.png'
import { useContext } from 'react'
import { DeviceContext } from '../../context/Contexts'
import i18n from '../../i18n'

type Props = {
    title?: string
    text: string
    isException?: boolean
}

const WarningMessage = ({ title, text, isException }: Props) => {
    const { isMobile } = useContext(DeviceContext)

    return (
        <div className={styles['message-board']} style={{textAlign: isMobile ? 'center' : 'inherit'}}>
            <img className={styles['icon']} src={isException ? warningIcon : infoIcon} alt='icon' />
            <div className={styles['text-container']}>
                <div className={styles['title']}>{title}</div>
                <div className={styles['text']}>{i18n('warnings.error')}</div>
                <div className={styles['text']}>{text}</div>
            </div>
            
        </div>
    )
}

export default WarningMessage