import { Grid } from '@mui/material'
import { title } from 'process'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { DeviceContext } from '../../context/Contexts'
import { Product } from '../../models/Product'
import { trimLongString } from '../../utils/HelperFunctions'
import styles from './ModalDetailContainer.module.scss'
import editIcon from '../../images/edit_icon.png'

interface Props {
    product: Product
    handleAction: (element: Product) => void
    selectedElementId: number
    handleSaveClick : () => void
    handleProductEditClick : () => void
}

export const ModalRowProduct = ({ handleSaveClick, product, handleAction, selectedElementId, handleProductEditClick }: Props ) => {
    const [ isSelected, setIsSelected ] = useState(false)
    const { isMobile } = useContext(DeviceContext)
    const trimStringAt = isMobile ? 45 : 90

    useEffect(() => {
        setIsSelected(selectedElementId === product.Id)
    })

    const handleClick = () => {
        handleAction(product)
        setIsSelected(true)
    }

    return (
        <Fragment>
            <Grid container spacing={2} 
                    className={styles[isMobile ? 'row-mobile' : 'row'] + ' ' + (isSelected ? styles.selected : '')}
                    onClick={() => handleClick()}
                    
                    onDoubleClick={ () => handleSaveClick()}
                    sx={{marginTop: '5px'}} >
                <Grid item xs={isMobile ? 2 : 2} className={styles.left}>{product?.Code ?? ''}</Grid>
                <Grid item xs={isMobile ? 5 : 7} className={styles.center}>
                    {trimLongString(product?.Name, trimStringAt) ?? ''}
                    <br/>
                    <div className={styles['second-row']}>{product?.QuantityUnitName ?? ''}</div>
                </Grid>
                <Grid item xs={isMobile ? 3 : 2} className={styles.right}>
                    <>
                        {product?.UnitPrice ?? ''}
                        <br/>
                        <div className={styles['second-row']}>{product?.GrossUnitPrice ?? ''}</div>
                    </>
                </Grid>
                <Grid item xs={isMobile ? 2 : 1} className={styles.right} style={{paddingTop: 0}}>
                    <div className={styles['right']}>
                        <img className={styles['edit-icon']} src={editIcon} alt=''
                            onClick={handleProductEditClick}/>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
}