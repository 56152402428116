import React, { Fragment, useContext, useState } from 'react'
import arrowDown from '../../../images/svg/arrow-down-normal.svg'
import arrowUp from '../../../images/svg/arrow-up-normal.svg'
// import { ReactComponent as Asss } from '../../../images/svg/arrow-down-normal.svg'
import styles from './ExpandableBar.module.scss'
import mainStyle from '../../../pages/WorksheetPage.module.scss'
import Collapsible from 'react-collapsible';
import ExpandableComplexChildren, { EXPANDABLE_CHILD_TYPE } from './ExpandableComplexChildren'
import { DeviceContext, SelectedWorksheetContext } from '../../../context/Contexts'
import i18n from '../../../i18n'
import { Worksheet } from '../../../models/Worksheet'
import { Separator } from '../Separator'
import { IIndexable } from '../../interfaces/IIndexible'
import { MODAL_TYPE } from '../../modal/BaseModal'
import Grid from '@mui/material/Grid'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../Button'
import InputField, { WIDTH, INPUT_TYPE, INPUT_ALIGN, LABEL, INPUT_HEIGHT } from '../InputField'
import { trimLongString } from '../../../utils/HelperFunctions'

type Props = {
  name: string
  open?: boolean
  title: string
  childrenType?: EXPANDABLE_CHILD_TYPE
  childrenName?: string
  type: EXPANDABLE_TYPE
  editableTitle: boolean
  size: EXPANDABLE_SIZE
  placeholder?: string
  childIndex?: number
  isLastChild?: boolean
  openModalAction?: (contentType: MODAL_TYPE) => void
}

export enum EXPANDABLE_SIZE {
  NORMAL = 'normal-size',
  HALF = 'half-size'
}

export enum EXPANDABLE_TYPE {
    SIMPLE,
    COMPLEX
}

const ExpandableBar = ({ name, open, title, childrenType, childrenName, type, editableTitle, size, placeholder, childIndex,
   isLastChild, openModalAction }: Props) => {
  const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
  const [ isExpanded, setIsExpanded ] = useState(false)
  const [ editTitle, setEditTitle ] = useState(false)
  const { isMobile, isPortrait } = useContext(DeviceContext)

  const getBarWidth = () => `${styles[size]}`

  const handleChangeSimple = (e: any) => {
    var propertyName = e.target.name === 'Name' ? 
        name + e.target.name 
        : e.target.name === 'Show' ? 
          e.target.name + name 
        : name
        setWorksheet((prevState: Worksheet) => ({
        ...prevState,
        [propertyName]: e.target.name === 'Show' ? !(prevState as IIndexable)['Show' + name] : e.target.value
    }))
  }

  const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null

  const getArrow = () => 
    !isExpanded || (!isLastChild && !isExpanded) ? <img src={arrowDown} alt='' className={styles['arrow-small']} /> : <img src={arrowUp} alt='' className={styles['arrow-small']} /> 
    
  const header = (
    childrenType === EXPANDABLE_CHILD_TYPE.TIMESPENT_ITEM ?
      <div className={mainStyle['item-header-item']}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <div className={mainStyle['item-header-text-small']}>
              {title ? <div className={styles['title']}>{trimLongString(title, 17)}</div>
                : <div className={styles['title-placeholder']}>{'Időráfordítás'}</div>}
            </div>
          </Grid>
            <Grid item xs={3}>
              <div style={{marginTop: '-5px'}}>
                {getArrow()}
              </div>
            </Grid>
        </Grid>
      </div>
      :
      childrenType === EXPANDABLE_CHILD_TYPE.EXTERNALWORK_ITEM ?
      <div className={mainStyle['item-header-item']}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
          <div className={mainStyle['item-header-text-small']}>
            {title ? <div className={styles['title']}>{trimLongString(title, 17)}</div>
            : <div className={styles['title-placeholder']}>{'Kiszállás'}</div>}
          </div>
          </Grid>
            <Grid item xs={3}>
              <div style={{marginTop: '-5px'}}>
                {getArrow()}
              </div>
            </Grid>
        </Grid>
      </div> 
      :
    <div className={mainStyle['item-header-expandable']}>
      <Grid container spacing={2} sx={{paddingTop: 0}}>
          <Grid item xs={9} sx={{paddingTop: 0}}>
            {editableTitle ?
              <InputField
                name='Name'
                placeholder={placeholder}
                isEdit={editTitle}
                setIsEdit={setEditTitle}
                isExpanded={isExpanded}
                labelWidth={WIDTH.NONE}
                barWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.THIRD}
                type={INPUT_TYPE.EXPANDABLE_HEADER}
                value={(worksheet as IIndexable)[name + 'Name'] ?? title}
                changed={handleChangeSimple}
                isDisabled={hasCustomerSigned()} />
            : <div className={styles['title']}>{title}</div>}
          </Grid>
            <Grid item xs={3}>
            <div style={{marginTop: editableTitle ? '5px' : 0}}>
                {getArrow()}
              </div>
            </Grid>
        </Grid>
    </div>)

  return (
    <Fragment>
      <div className={getBarWidth()}>
      <div className={`${mainStyle['item']}`}  style={{height: 'fit-content'}}>
        <Collapsible 
          style={{height: '1000px'}}
          trigger={header}
          open={isLastChild || open}
          onOpen={() => setIsExpanded(true)}
          onClose={() => setIsExpanded(false)}
          triggerDisabled={editTitle} >
          {type === EXPANDABLE_TYPE.SIMPLE ?
            <div className={styles['container']} style={{height: 'fit-content'}}>
            <Separator />
            <Grid container spacing={0}>
                <Grid item xs={'auto'} style={{marginRight: 5, marginTop: isPortrait || isMobile ? 5 : 15}}>
                    <div>{i18n('worksheet.general.showFieldOnWorksheet')}</div>
                </Grid>
                <Grid item xs={'auto'} justifyContent="flex-end">
                    <InputField 
                        name='Show'
                        labelText={''}
                        labelWidth={0}
                        barWidth={1}
                        value={(worksheet as IIndexable)['Show' + name] ?? false} 
                        changed={handleChangeSimple}
                        isDisabled={hasCustomerSigned()}
                        type={INPUT_TYPE.CHECKBOX} />
                </Grid>
            </Grid>
            <div className={styles['data']}>
              <InputField 
                name='Detail'
                placeholder='Megjegyzés...'
                barHeight={INPUT_HEIGHT.FULL_SIZE}
                value={(worksheet as IIndexable)[name]}
                changed={handleChangeSimple}
                isDisabled={hasCustomerSigned()}
                labelWidth={WIDTH.NONE}
                barWidth={isMobile ? WIDTH.FULL_LINE - 0.5 : WIDTH.FULL_LINE - 0.2}
                type={INPUT_TYPE.TEXTAREA} />
            </div>
          </div>
          : <Fragment>
              <Separator />
              <ExpandableComplexChildren 
                name={childrenName ?? ''}
                type={childrenType ?? EXPANDABLE_CHILD_TYPE.DEFAULT}
                childIndex={childIndex}
                openModalAction={openModalAction} />
            </Fragment>
          }
        </Collapsible>
      </div>
      </div>
    </Fragment>
  )
}

export default ExpandableBar