
import React, { Fragment, useContext, useEffect, useState } from 'react'
import styles from './ModalDetailContainer.module.scss'
import editIcon from '../../images/edit_icon.png'
import { trimLongString } from '../../utils/HelperFunctions'
import { Tool } from '../../models/Tool'
import { Grid } from '@mui/material'
import { DeviceContext } from '../../context/Contexts'

interface Props {
    tool: Tool
    handleAction: (element: Tool) => void
    selectedElementId: number
    handleSaveClick: () => void
    handleToolEditClick: () => void
}

export const ModalRowTool = ({ handleSaveClick, tool, handleAction, selectedElementId, handleToolEditClick }: Props ) => {
    const [ isSelected, setIsSelected ] = useState(false)
    const { isMobile } = useContext(DeviceContext)
    const trimStringAt = isMobile ? 45 : 90

    useEffect(() => {
        setIsSelected(selectedElementId === tool.Id)
    })

    const handleClick = () => {
        handleAction(tool)
        setIsSelected(true)
    }

    return (
        <Fragment>
            <Grid container spacing={2} 
                className={styles[isMobile ? 'row-mobile' : 'row'] + ' ' + (isSelected ? styles.selected : '')}
                onClick={() => handleClick()}
                onDoubleClick={ () => handleSaveClick()}
                sx={{marginTop: '5px'}} >
                <Grid item xs={2} className={styles.left}>{tool?.Code ?? ''}</Grid>
                <Grid item xs={7} className={styles.center}>
                    {trimLongString(tool?.Name, trimStringAt) ?? ''}
                    <br/>
                    <div className={styles['second-row']}>{tool?.CustomerNameDisplay ?? ''}</div>
                </Grid>
                <Grid item xs={3} className={styles.right} style={{paddingTop: 0}}>
                    <div className={styles['right']}>
                        <img className={styles['edit-icon']} src={editIcon} alt=''
                            onClick={handleToolEditClick}/>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
}