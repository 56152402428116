import { Grid } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { DeviceContext, EditItemContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { Tool } from "../../../models/Tool"
import { ICON_TYPE } from "../../../utils/global"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../../elements/Button"
import InputField, { WIDTH, INPUT_ALIGN, LABEL, INPUT_TYPE } from "../../elements/InputField"
import { IIndexable } from "../../interfaces/IIndexible"
import styles from './ToolModal.module.scss'

type Props = {
    index: number
    fieldName?: string
}

const ToolDataRow = ({ fieldName, index }: Props) => {
    const [ isToolData, setIsToolData ] = useState(false)
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { isMobile } = useContext(DeviceContext)

    const handleDelete = () => {
        let list = (editItem as Tool)?.ToolData ?? []
        const itemIndex = list.indexOf(list[index])
        if (itemIndex > -1)
            list.splice(itemIndex, 1)
        
        setEditItem((prevState: Tool) => ({
            ...prevState,
            ToolData: list
        })) 
    }

    const handleToolChange = (e: any) => {
        
        setEditItem((prevState: Tool) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const handleToolDataChange = (e: any) => {
        let list = (editItem as Tool)?.ToolData ?? []
        let changedItem = (editItem as Tool)?.ToolData[index]
        list[index] = {...changedItem, [e.target.name]: e.target.value}
        setEditItem((prevState: Tool) => ({
            ...prevState,
            ToolData: list
            }))
    }

    useEffect(() => {
        if(index > -1)  {
            setIsToolData(true) 
        }
    }, [])

    return (
        <Grid container spacing={2}>
            
            <Grid item xs={isMobile ? 6 : 4}>
                {!isToolData ? <div className={styles['example-title']} style={{marginTop: isMobile ? 0 : '16px'}}>{i18n(`modal.listType.tool.dataFields.${fieldName}`)}</div> : 
                    <InputField 
                        name='Name'
                        value={(editItem as Tool)?.ToolData[index]?.Name}
                        changed={handleToolDataChange}
                        labelWidth={WIDTH.NONE}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.TEXT} /> }
            </Grid>
           
            <Grid item xs={isMobile ? 6 : 4}>
                {fieldName ? 
                    <InputField 
                        name={fieldName}
                        value={editItem && (editItem as IIndexable)[fieldName]}
                        changed={handleToolChange}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.TEXT} 
                        labelWidth={WIDTH.FULL_LINE} />
                     
                    : <InputField 
                        name='Data' 
                        value={(editItem as Tool)?.ToolData[index]?.Data}
                        changed={handleToolDataChange}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.TEXT} 
                        labelWidth={WIDTH.NONE} /> }
            </Grid>

            <Grid item xs={isMobile ? 11 : 4} sx={{paddingTop: 0}} style={isMobile ? {paddingTop: '3px'} : {paddingTop: '27px'}}>
                {isToolData && <Button 
                    text={i18n('button.toolModal.deleteField')}
                    shade={BUTTON_SHADE.LIGHTRED} 
                    size={ isMobile ? BUTTON_SIZE.WITH_ICON_SMALLER : BUTTON_SIZE.WITH_ICON_LONG}
                    isSpacious={false}
                    handleAction={handleDelete}
                    icon={ICON_TYPE.DELETE} />}
            </Grid>
        </Grid>
    )}

export default ToolDataRow