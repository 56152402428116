import i18n from "../../i18n"
import styles from './WorksheetProducts.module.scss'
import { ICON_TYPE, RELATION_TYPE } from "../../utils/global"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../elements/Button"
import { getNetValue, getGrossValue } from "../../utils/HelperFunctions"
import { Worksheet } from "../../models/Worksheet"
import { useContext } from "react"
import { DeviceContext, QuantityUnitListContext, SelectedWorksheetContext, VatListContext } from "../../context/Contexts"
import { ExternalWork } from "../../models/ExternalWork"
import { TimeSpent } from "../../models/TimeSpent"
import { IIndexable } from "../interfaces/IIndexible"
import Grid from "@mui/material/Grid"
import InputField, { WIDTH, INPUT_ALIGN, LABEL, INPUT_HEIGHT, INPUT_BORDER, INPUT_TYPE, INPUT_LABEL_SIZE } from "../elements/InputField"
 
export interface Props {
    index: number
    handleBoolChange: (e: any) => void
}

export const WorksheetProduct = ({ index, handleBoolChange } : Props) => {
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const {vats} = useContext(VatListContext)
    const {quantityUnits} = useContext(QuantityUnitListContext)
    const { isMobile } = useContext(DeviceContext)

    const item = worksheet.Items[index]

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null

    const handleChange = (e: any) => {
        let list = [...worksheet.Items]
        let changedItem = worksheet.Items[index]
        list[index] = {...changedItem, [e.target.name]: e.target.value}
        setWorksheet((prevState: Worksheet) => ({
            ...prevState,
                 Items: list
        }))
    }

    const deleteItem = () => {
        let indexType: string = ''
        switch (item.RelationType) {
            case (RELATION_TYPE.TIMESPENT):
                indexType = 'TimeSpentList'
                break
            case (RELATION_TYPE.EXTERNALWORK):
                indexType = 'ExternalWorkList'
                break
        }
        if(indexType !== '')
            deleteRelation(indexType)

        let list = worksheet.Items
        const itemIndex = list.indexOf(list[index])
        if (itemIndex > -1)
            list.splice(itemIndex, 1)

            setWorksheet((prevState: Worksheet) => ({
            ...prevState,
            Items: list
        }))
    }

    const deleteRelation = (type: string) => {
        let relationList = (worksheet as IIndexable)[type]
        const worksheetItemRelationId = item.RelationId
        const relatedItemIndex = Object.values(relationList).findIndex(item => (item as ExternalWork | TimeSpent).Id?.toString() === worksheetItemRelationId)
        const relatedItem = relationList[relatedItemIndex]
        
        relationList[relatedItemIndex] = {...relatedItem, IsShownAsWorksheetItem: false}

        setWorksheet((prevState: Worksheet) => ({
            ...prevState,
            [type]: relationList
        }))
    }

    if (isMobile) return (
        <div className={styles['item-detail']}>
            <Grid container spacing={2}>
                <Grid item xs={8.5}>
                    <InputField 
                        name='Name'
                        labelText={i18n(`worksheet.products.details.productName`)}
                        value={item.Name}
                        changed={handleChange} 
                        isDisabled={hasCustomerSigned()}
                        labelWidth={WIDTH.FULL_LINE}
                        labelAlignment={INPUT_ALIGN.LEFT}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.TEXT} />
                </Grid>
                <Grid item xs={2.5} className={styles['checkbox-mobile-fix']} >
                    <div></div>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8.5}>
                    <InputField 
                        name='Quantity'
                        labelText={i18n(`worksheet.products.details.quantity`)}
                        value={item.Quantity}
                        changed={handleChange} 
                        isDisabled={hasCustomerSigned()}
                        labelWidth={WIDTH.FULL_LINE}
                        labelAlignment={INPUT_ALIGN.LEFT}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.NUMBER} />
                </Grid>
                <Grid item xs={2.5} className={styles['checkbox-mobile-fix']} >
                    <div></div>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8.5}>
                    <InputField 
                        name='QuantityUnitId'
                        labelText={i18n(`worksheet.products.details.quantityUnit`)}
                        value={item.QuantityUnitId}
                        options={quantityUnits}
                        changed={handleChange} 
                        isDisabled={hasCustomerSigned()}
                        labelWidth={WIDTH.FULL_LINE}
                        labelAlignment={INPUT_ALIGN.LEFT}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.SELECT} />
                </Grid>
                <Grid item xs={2.5} className={styles['checkbox-mobile-fix']} >
                    <div></div>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8.5}>
                    <InputField 
                        name='UnitPrice'
                        labelText={i18n(`worksheet.products.details.unitPrice`)}
                        value={item.UnitPrice}
                        changed={handleChange} 
                        isDisabled={hasCustomerSigned()}
                        labelWidth={WIDTH.FULL_LINE}
                        labelAlignment={INPUT_ALIGN.LEFT}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.NUMBER} />
                </Grid>
                <Grid item xs={2.5} className={styles['checkbox-mobile-fix']} >
                    <InputField 
                        name='ShowUnitPrice'
                        labelText={'-'}
                        labelPlace={LABEL.DEFAULT}
                        labelWidth={WIDTH.FULL_LINE}
                        value={worksheet.ShowUnitPrice}
                        changed={handleBoolChange}
                        isDisabled={hasCustomerSigned()}
                        barAlignment={INPUT_ALIGN.BOTTOM}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.CHECKBOX} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8.5}>
                    <InputField 
                        name='VatId'
                        labelText={i18n(`worksheet.products.details.vat`)}
                        value={item.VatId}
                        options={vats}
                        changed={handleChange} 
                        isDisabled={hasCustomerSigned()}
                        labelWidth={WIDTH.FULL_LINE}
                        labelAlignment={INPUT_ALIGN.LEFT}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.SELECT} />
                </Grid>
                <Grid item xs={2.5} className={styles['checkbox-mobile-fix']} >
                    <InputField 
                        name='ShowVat'
                        labelText={'-'}
                        labelPlace={LABEL.DEFAULT}
                        labelWidth={WIDTH.FULL_LINE}
                        value={worksheet.ShowVat}
                        changed={handleBoolChange}
                        isDisabled={hasCustomerSigned()}
                        barAlignment={INPUT_ALIGN.BOTTOM}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.CHECKBOX} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8.5}>
                    <InputField 
                        name='NetValueId'
                        labelText={i18n(`worksheet.products.details.netValue`)}
                        value={getNetValue(item)}
                        labelWidth={WIDTH.FULL_LINE}
                        labelAlignment={INPUT_ALIGN.LEFT}
                        barAlignment={INPUT_ALIGN.LEFT}
                        border={INPUT_BORDER.WITHOUT}
                        barWidth={WIDTH.FULL_LINE}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.TEXT} />
                </Grid>
                <Grid item xs={2.5} className={styles['checkbox-mobile-fix']} >
                    <InputField 
                        name='ShowNetValue'
                        labelText={'-'}
                        labelPlace={LABEL.DEFAULT}
                        labelWidth={WIDTH.FULL_LINE}
                        value={worksheet.ShowNetValue}
                        changed={handleBoolChange}
                        isDisabled={hasCustomerSigned()}
                        barAlignment={INPUT_ALIGN.BOTTOM}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.CHECKBOX} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={8.5}>
                    <InputField 
                        name='GrossValueId'
                        labelText={i18n(`worksheet.products.details.grossValue`)}
                        value={getGrossValue(item)}
                        border={INPUT_BORDER.WITHOUT}
                        labelWidth={WIDTH.FULL_LINE}
                        labelAlignment={INPUT_ALIGN.LEFT}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.TEXT} />
                </Grid>
                <Grid item xs={2.5} className={styles['checkbox-mobile-fix']} >
                    <InputField 
                        name='ShowGrossValue'
                        labelText={'-'}
                        labelPlace={LABEL.DEFAULT}
                        labelWidth={WIDTH.FULL_LINE}
                        value={worksheet.ShowGrossValue}
                        changed={handleBoolChange}
                        isDisabled={hasCustomerSigned()}
                        barAlignment={INPUT_ALIGN.BOTTOM}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.CHECKBOX} />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputField 
                        name='Comment'
                        labelText={i18n('worksheet.products.details.comment')}
                        placeholder={i18n('worksheet.products.details.comment')}
                        value={item.Comment}
                        changed={handleChange}
                        isDisabled={hasCustomerSigned()}
                        labelWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.SIXTH}
                        barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        barWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.FIVE_SIXTHS}
                        barHeight={isMobile ? INPUT_HEIGHT.DEFAULT : INPUT_HEIGHT.FULL_SIZE }
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.TEXTAREA}/>
                </Grid>
            </Grid>
        </div>
    )

    return (<>
        <Grid container spacing={1}>
            <Grid item xs={3}>
                <InputField 
                    name='Name'
                    labelText={i18n('worksheet.products.details.productName')}
                    value={item.Name ?? ''}
                    changed={handleChange} 
                    isDisabled={hasCustomerSigned()}
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.TEXT} />
            </Grid>
            <Grid item xs={1.5}>
                <InputField 
                    name='Quantity'
                    labelText={i18n('worksheet.products.details.quantity')}
                    value={item.Quantity ?? ''}
                    changed={handleChange} 
                    isDisabled={hasCustomerSigned()}
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                    barAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.NUMBER} />
            </Grid>
            <Grid item xs={1.5}>
                <InputField 
                    name="QuantityUnitId"
                    value={item.QuantityUnitId}
                    options={quantityUnits}
                    changed={handleChange}
                    isDisabled={hasCustomerSigned()}
                    labelText={i18n('worksheet.products.details.quantityUnit')}
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                    barAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.SELECT} />
            </Grid>
            <Grid item xs={1.5}>
                <InputField 
                    name='UnitPrice'
                    labelText={i18n('worksheet.products.details.unitPrice')}
                    value={item.UnitPrice ?? ''}
                    changed={handleChange} 
                    isDisabled={hasCustomerSigned()}
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                    barAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.NUMBER} />
            </Grid>
            <Grid item xs={1.5}>
                <InputField 
                    name="VatId"
                    value={item.VatId}
                    options={vats}
                    changed={handleChange}
                    isDisabled={hasCustomerSigned()}
                    labelText={i18n('worksheet.products.details.vat')}
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                    barAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.SELECT} />
            </Grid>
            <Grid item xs={1.5}>
                <InputField 
                    name='NetValueId'
                    labelText={i18n('worksheet.products.details.netValue')}
                    value={getNetValue(item)}
                    border={INPUT_BORDER.WITHOUT}
                    labelWidth={WIDTH.FULL_LINE}
                    labelSize={INPUT_LABEL_SIZE.SMALLER}
                    labelAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                    barAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.NUMBER} />
            </Grid>
            <Grid item xs={1.5}>
                <InputField 
                    name='GrossValueId'
                    labelText={i18n(`worksheet.products.details.grossValue`)}
                    value={getGrossValue(item)}
                    border={INPUT_BORDER.WITHOUT}
                    labelWidth={WIDTH.FULL_LINE}
                    labelSize={INPUT_LABEL_SIZE.SMALLER}
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.NUMBER} />
            </Grid>
            <Grid item xs={6}>
                <InputField 
                    name='Comment'
                    labelText={i18n('worksheet.products.details.comment')}
                    placeholder={i18n('worksheet.products.details.comment')}
                    value={item.Comment}
                    changed={handleChange}
                    isDisabled={hasCustomerSigned()}
                    labelWidth={WIDTH.FULL_LINE}
                    barAlignment={INPUT_ALIGN.LEFT}
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE}
                    barHeight={INPUT_HEIGHT.FULL_SIZE}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.TEXTAREA}/>
            </Grid>        
        </Grid>

        <div className={styles['item-detail-button']}>
            <Button 
                isDisabled={hasCustomerSigned()}
                text={i18n('button.worksheetPage.deleteItem')}
                shade={BUTTON_SHADE.LIGHTRED} 
                size={BUTTON_SIZE.WITH_ICON}
                handleAction={() => deleteItem()}
                icon={ICON_TYPE.DELETE} />
        </div>
    </>
    )
}