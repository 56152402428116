import { Separator } from '../Separator'
import styles from './WorksheetSettingsBlock.module.scss'


type Props = {
    header: string
    description: string
    children: JSX.Element
}

const WorksheetSettingsBlock = ({ header, description, children }: Props) => {

    return (
        <div className={styles.container}>
            <div className={styles.header}>{header}</div>
            <Separator />
            <div className={styles.description}>{description}</div>
            
            {children}
        </div>
    )
}

export default WorksheetSettingsBlock