import { Fragment } from "react"
import ExpandableListItem from "./ExpandableListItem"
import ExpandableList, { EXPANDABLE_LIST_TYPE } from "./ExpandableList"
import ToolDataFields from "../../worksheet/WorksheetToolData"
import { MODAL_TYPE } from "../../modal/BaseModal"

type Props = {
    name: string
    type: EXPANDABLE_CHILD_TYPE
    childIndex?: number
    openModalAction?: (type: MODAL_TYPE) => void
}
  
export enum EXPANDABLE_CHILD_TYPE {
    TIMESPENT,
    TIMESPENT_ITEM,
    EXTERNALWORK,
    EXTERNALWORK_ITEM,
    TOOLS,
    DEFAULT
}
  
const ExpandableComplexChildren = ({ name, type, childIndex, openModalAction }: Props) => {
  const getType = () => {
    switch(type) {
        case EXPANDABLE_CHILD_TYPE.TIMESPENT:
            return <ExpandableList type={EXPANDABLE_LIST_TYPE.TIMESPENT} />
        case EXPANDABLE_CHILD_TYPE.TIMESPENT_ITEM:
            return <ExpandableListItem index={childIndex ?? 0} type={EXPANDABLE_LIST_TYPE.TIMESPENT} />
        case EXPANDABLE_CHILD_TYPE.EXTERNALWORK:
            return <ExpandableList type={EXPANDABLE_LIST_TYPE.EXTERNALWORK} />
        case EXPANDABLE_CHILD_TYPE.EXTERNALWORK_ITEM:
            return <ExpandableListItem index={childIndex ?? 0} type={EXPANDABLE_LIST_TYPE.EXTERNALWORK} />
        case EXPANDABLE_CHILD_TYPE.TOOLS:
            return <ToolDataFields openModalAction={openModalAction} />
    }
  }

    return (
        <Fragment>
            {getType()}
        </Fragment>
    )
}

export default ExpandableComplexChildren