import React, { Fragment, useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { DeviceContext } from '../../../context/Contexts';
import { FiltersObject } from '../../../models/Filter';
import { Option, FIELD_NAME, HandleFilterChange, FILTER_TYPE } from '../../../utils/FilterHandler';

const customStyles = {   
    control: (provided: any) => ({
        ...provided,
        '&:focused': {
          outline: 'none',
	        border: '1px solid $primary-color-evenLighter'
        },
        borderRadius: '12px',
        marginTop: '5px',
        width: '106%',
    }),        
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#CEE7FF" : state.isFocused ? 'rgba(189,197,209,.3)' : "white",
        opacity: state.isFocused || state.isSelected ? 1 : 0.5,
     
    }),   
  }

  const customStylesMobile = {   
    control: (provided: any) => ({
        ...provided,
        '&:focused': {
          outline: 'none',
	        border: '1px solid $primary-color-evenLighter'
        },
        borderRadius: '12px',
        marginTop: '5px',
        width: '102%',
    }),        
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#CEE7FF" : state.isFocused ? 'rgba(189,197,209,.3)' : "white",
        opacity: state.isFocused || state.isSelected ? 1 : 0.5,
     
    }),   
  }

type Props = {
    options: Option[]
    label: string
    fieldName: FIELD_NAME
    filterObj: FiltersObject
    setFilterObj: any
    setDateType?: any
    shouldEmpty: boolean
    setShouldEmptyFilters: React.Dispatch<React.SetStateAction<boolean>>
    overrideLableStyle?: boolean
}

const SearchableSelect = ({ options, label, fieldName, filterObj, setFilterObj, shouldEmpty, setShouldEmptyFilters, overrideLableStyle }: Props) => {
  const [ state, setState ] = useState({} as Option)
  const { isMobile, isPortrait } = useContext(DeviceContext)

  const emptySelectField = () => setState(options[0])

  useEffect(() => {
    if(shouldEmpty) {
      setShouldEmptyFilters(false)
      emptySelectField()
    }
  })

  return (
    <Fragment>
      <label style={overrideLableStyle ? {marginBottom: '7px', fontSize: '11px', lineHeight: '13px'} : isPortrait ? {fontSize: '14px'} : {marginBottom: '2px'}}>{label}</label>
      <Select 
        defaultValue={options[0]}
        value={state}
        styles={isMobile ? customStylesMobile : customStyles} 
        options={options} 
        placeholder='' 
        onChange={(e) => {
          HandleFilterChange(FILTER_TYPE.SELECT, e, fieldName, filterObj, setFilterObj)
          if(e !== undefined && e !== null)
            setState(e)
          }}/>
    </Fragment>)
  }

export default SearchableSelect