import { useContext, useState } from "react"
import { DeviceContext, EditItemContext } from "../../../context/Contexts"
import InputField, { INPUT_ALIGN, INPUT_HEIGHT, INPUT_TYPE, LABEL, WIDTH } from "../../elements/InputField"
import { Product } from "../../../models/Product"
import mainStyles from '../tool/ToolModal.module.scss'
import i18n from "../../../i18n"

const DetailTab = () => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const maxLetters = 1500
    const [ letterCount, setLetterCount ] = useState((editItem as Product)?.Description?.length ?? 0)
    
    const handleChange = (e: any) => {
        setEditItem((prev: Product) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
        setLetterCount(e.target.value.length)
    }

    const isTooLong = letterCount > maxLetters

    return(
        <div className={mainStyles[isMobile ? 'tab-container-mobile' : isPortrait ? 'tab-container-portrait' : 'tab-container']}>
            <InputField 
                name='Description' 
                placeholder='Termék leírás'
                value={(editItem as Product)?.Description ?? ''}
                changed={handleChange}
                isTooLong={isTooLong}
                labelWidth={WIDTH.FULL_LINE}
                labelAlignment={INPUT_ALIGN.LEFT}
                barAlignment={INPUT_ALIGN.LEFT}
                barWidth={WIDTH.FULL_LINE}
                barHeight={INPUT_HEIGHT.FULL_SIZE_EXTRA}
                labelPlace={LABEL.DEFAULT}
                type={INPUT_TYPE.TEXTAREA} /> 
            <p>
                <span style={isTooLong ? {color: 'red'} : {}}>{letterCount}</span>{`/${maxLetters}`}
            </p>
        </div>)
}

export default DetailTab