import styles from './Button.module.scss'
import React, { useContext } from 'react'
import { ICON_TYPE } from '../../utils/global'
import { DeviceContext } from '../../context/Contexts'

interface Props {
    text: string
    shade: BUTTON_SHADE
    handleAction: () => void 
    isSpacious?: boolean
    isExtraSpacious?: boolean
    size: BUTTON_SIZE
    icon?: ICON_TYPE
    isDisabled?: boolean
}

export enum BUTTON_SHADE {
    DARKBLUE = 'dark-blue',
    LIGHTBLUE = 'light-blue',
    LIGHTERBLUE = 'lighter-blue',
    DARKRED = 'dark-red',
    LIGHTRED = 'light-red',
}

export enum BUTTON_SIZE {
    FLAT = 'flat',
    NORMAL = 'normal',
    MEDIUM = 'medium',
    LARGE = 'large',
    WITH_ICON = 'with-icon',
    WITH_ICON_LARGE = 'with-icon-large',
    WITH_ICON_SMALL = 'with-icon-small',
    WITH_ICON_SMALLER = 'with-icon-smaller',
    WITH_ICON_LONG = 'with-icon-long'
}

export const Button = ({ text, shade, isSpacious, isExtraSpacious, size, handleAction, icon, isDisabled }: Props) => {
    const { isMobile } = useContext(DeviceContext)
    
    const getClasses = () => {
        let classes = []
    
        if(isSpacious || isSpacious === undefined)  
            classes.push(styles.spacious)
        if(isExtraSpacious)  
            classes.push(styles.spaciousX)
        if(shade === BUTTON_SHADE.LIGHTERBLUE)
            classes.push(styles['color-dark'])
        else 
            classes.push(styles['color-white'])
        classes.push(styles[shade])
        classes.push(styles[size])
        if(size === BUTTON_SIZE.FLAT && isMobile) classes.push(styles['flat-mobile-height'])
        if(isDisabled)
            classes.push(styles.disabled)
        return classes.join(' ')
    }

    const clickAction = () => {
        if(isDisabled)
            return
        else handleAction()
    }
    
    const getIcon = icon && 
        `${styles[icon]} 
        ${(size === BUTTON_SIZE.WITH_ICON_SMALLER || size === BUTTON_SIZE.WITH_ICON_LONG) && styles['small-icon']} 
        ${isDisabled && styles['gray-icon']}
        `

    return (
        <div onClick={clickAction} className={styles.button + ' ' + getClasses()}>
            <div className={getIcon} />
            <div className={shade === BUTTON_SHADE.LIGHTERBLUE ? styles['text-skinny'] : styles['text-normal']}>{text}</div>
        </div>
    )
}