import i18n from "../../i18n"
import { FilterOrder, FiltersObject } from "../../models/Filter"
import { LIST_TYPE } from "./List"
import arrowDownIcon from '../../images/arrow_down.png'
import arrowUpIcon from '../../images/arrow_up.png'
import mainStyles from './List.module.scss'
import { useContext, useEffect, useState } from "react"
import { isObjectUnavailable } from "../../utils/HelperFunctions"
import { DeviceContext } from "../../context/Contexts"
import { TableCell } from "@mui/material"

// export enum COLUMN_ORDER {
//     DEFAULT = -1,
//     UP = 0,
//     DOWN = 1
// }

enum ORDER {
    UP,
    DOWN
}

type Props = {
    filterObj: FiltersObject
    setFilterObj: React.Dispatch<React.SetStateAction<FiltersObject>>
    header: string
    listType: LIST_TYPE
    orderType: string | number | undefined
    useDefaultOrder: boolean
    setUseDefaultOrder: React.Dispatch<React.SetStateAction<boolean>>
}

const ListHeader = ({ filterObj, setFilterObj, header, listType, orderType, useDefaultOrder, setUseDefaultOrder }: Props) => {
    const { isPortrait } = useContext(DeviceContext)
    const order = isObjectUnavailable(filterObj) ? ORDER.DOWN :
        filterObj.Orders.find(o => o.FieldName === header)?.OrderWay
        ?? ORDER.DOWN
    const getArrow = () => 
        order === ORDER.DOWN ? arrowDownIcon : arrowUpIcon
        
    const handleOrderChange = async (orderUpdate: ORDER) => {
        let updatedOrders = [] as FilterOrder[]
        // var needInsert = filterObj.Orders.every(item => item.FieldName !== header)
        // updatedOrders = filterObj.Orders

        // if(useDefaultOrder) {
        //     const newOrder = 
        //     { 
        //         FieldName: header, 
        //         OrderType: orderType,
        //         OrderWay: orderUpdate
        //     } as FilterOrder
        //     updatedOrders = []
        //     updatedOrders.push(newOrder)
        //     setUseDefaultOrder(false)
        // }
        // else if(needInsert) {
            const newOrder = 
            { 
                FieldName: header, 
                OrderType: orderType,
                OrderWay: orderUpdate
            } as FilterOrder
            updatedOrders.push(newOrder)
        // }
        // else {
        //     updatedOrders.forEach((filter) => {
        //         if(filter.FieldName === header){
        //             filter.OrderWay = orderUpdate
        //         }
        //     })
        // }
        setFilterObj((prevstate: any) => ({
        ...prevstate,
        Orders: updatedOrders
        }))
    }

    const handleOrderClick = async () =>{
        if(order === ORDER.DOWN) {
            handleOrderChange(ORDER.UP)
        }
        else {
            handleOrderChange(ORDER.DOWN)
        }
    }

    return (
        <TableCell key={header} style={{padding: '8px', paddingBottom: 15, paddingTop: 15, paddingRight: 20}} align='center'>
            <div className={mainStyles[isPortrait ? 'header-text-smaller' : 'header-text']}>{i18n(`${listType}ListPage.columns.${header}`)}</div>
            {orderType !== undefined ? 
                <img className={mainStyles[isPortrait ? 'arrow-portrait' : 'arrow']} src={getArrow()} alt='' onClick={handleOrderClick} />
                : ''}
        </TableCell>
    )
}

export default ListHeader