import _ from "lodash"
import { Fragment, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../../components/elements/Button"
import LoadingAnimation from "../../components/elements/LoadingAnimation"
import SearchableSelect from "../../components/elements/searchables/SearchableSelect"
import { Separator } from "../../components/elements/Separator"
import WarningMessage from "../../components/elements/WarningMessage"
import { PageHeader } from "../../components/layout/PageHeader"
import { LIST_TYPE, List } from "../../components/lists/List"
import ListFilters from "../../components/lists/ListFilters"
import i18n from "../../i18n"
import { ExternalWork } from "../../models/ExternalWork"
import { FiltersObject } from "../../models/Filter"
import { BACKEND_ROUTE, fetchData, getRequest } from "../../request"
import { GetOptions, CATEGORY, SETTING_TYPE, FIELD_NAME, DATETYPES, HandleFilterChange, FILTER_TYPE, fixEmptyFilters } from "../../utils/FilterHandler"
import { externalWorkListHeaders, externalWorkOrderTypes, INTERNAL_ROUTE } from "../../utils/global"
import { numberWithSeparator, showLoadingScreen } from "../../utils/HelperFunctions"
import * as XLSX from "xlsx"
import styles from './ListPage.module.scss'
import { ExportObject } from "../../models/ExportObject"
import { DeviceContext } from "../../context/Contexts"
import FilterOverlay from "../../components/modal/FilterOverlay"
import { MobileFilterIcon } from "../../utils/MobileFilterIcon"
import InputField, { LABEL, INPUT_BORDER, WIDTH, INPUT_TYPE } from "../../components/elements/InputField"
import { defaultPageFilter_EW, defaultFilterObj_EW, PageFilter_EW } from "../../utils/FilterDefaults"


const ExternalWorkListPage = () => {
    let defaultPageFilterCopy = _.cloneDeep(defaultPageFilter_EW)
    let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_EW)
    const [ pageFilter, setPageFilter ] = useState(defaultPageFilterCopy as PageFilter_EW)
    const [ filterObj, setFilterObj ] = useState(defaultFilterObjCopy as FiltersObject)
    const history = useHistory()
    const [ list, setList ] = useState([] as ExternalWork[])
    const [ warning, setWarning ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(true)
    const [ shouldEmptyFilters, setShouldEmptyFilters ] = useState(false)
    const getDistanceSum = () => numberWithSeparator(list.reduce((partialSum, timeSpent) => partialSum + timeSpent?.Distance, 0))
    const [ useDefaultOrder, setUseDefaultOrder ] = useState(true)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const [ mobileFilterClicked, setMobileFilterClicked ] = useState(false)
    const [editFilters, setEditFilters] = useState(false)


    const handleChangePageFilter = async (field: string, value: string) => {
        await setPageFilter((prevstate: PageFilter_EW) => ({
            ...prevstate,
            [field]: value
        }))
    }

    useEffect(() => {
        if(filterObj.Filters[0]?.length === 0)
            fixEmptyFilters(setFilterObj)
        if(shouldEmptyFilters)
            setTimeout(() => setShouldEmptyFilters(false), 2000);
        showLoadingScreen(true, setIsLoading)
        .then(() => 
            fetchData(filterObj, BACKEND_ROUTE.EXTERNALWORK, setList, setWarning)
            .then(() => getDistanceSum())
            .then(() => showLoadingScreen(false, setIsLoading))
        )
    }, [filterObj])

    const handleChangeDate = async (e: Date, type: string) => {
        const dateString = e.getFullYear() + '-' + ('0'+ (e.getMonth()+1)).slice(-2) + '-' + ('0'+ e.getDate()).slice(-2)
        
        if(type === 'StartDate') {
            await handleChangePageFilter('startDateFilter', dateString)
            HandleFilterChange(FILTER_TYPE.DATE, e, DATETYPES.DATE, filterObj, setFilterObj, dateString, 4)
        }
        else {
            await handleChangePageFilter('closeDateFilter', dateString)
            HandleFilterChange(FILTER_TYPE.DATE, e, DATETYPES.DATE, filterObj, setFilterObj, dateString, 2)
        }
    }

    const handleKeyPressed = async (key: string, name: string, value: string) => {
        if (key === 'Enter') {
            if(name === FIELD_NAME.GENERAL_SEARCH_FIELD)
                HandleFilterChange(FILTER_TYPE.INPUT, value, FIELD_NAME.GENERAL_SEARCH_FIELD, filterObj, setFilterObj, undefined, undefined, LIST_TYPE.EXTERNALWORK)
            else 
                HandleFilterChange(FILTER_TYPE.INPUT, value, name, filterObj, setFilterObj)
            }
    }

    const emptyFilters = async () => {
        let defaultPageFilterCopy = _.cloneDeep(defaultPageFilter_EW)
        await setPageFilter(defaultPageFilterCopy as PageFilter_EW)
        let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_EW)
        await setFilterObj(defaultFilterObjCopy as FiltersObject)
        await setUseDefaultOrder(true)
        await setShouldEmptyFilters(true)
    }

    const handleExport = () => {
        getRequest(BACKEND_ROUTE.EXPORT_EXTERNALWORK, filterObj).then(async function(res) {
            if (res != null || res[0] != null) {
                const response = res as unknown as ExportObject;
                
                const worksheet = XLSX.utils.json_to_sheet(response.ExportData)
                const workbook = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(workbook, worksheet, response.Filename)

                XLSX.utils.sheet_add_aoa(worksheet, [response.Headers], { origin: "A1" });
                XLSX.writeFile(workbook, response.Filename + "_" + Date.now() +".xlsx");
            } 
            }).catch((error) =>{
            console.error(error)
        })
    }

    const openFilters = () => {
        if(!editFilters) {
            setMobileFilterClicked(true)
            if(mobileFilterClicked)
                setEditFilters(true)
            else setEditFilters(filterObj.Filters?.length === 0)
        }
        else 
            setMobileFilterClicked(!mobileFilterClicked)
    }

    return (
    <Fragment>
        {isMobile && <Fragment>
            <MobileFilterIcon 
                clicked={mobileFilterClicked} 
                openFilters={openFilters} 
                editFilters={editFilters} 
                longPressBackspaceCallback={() => setMobileFilterClicked(true)} />
        <FilterOverlay isShown={mobileFilterClicked}  hasFilters={filterObj.Filters?.length > 0} setIsShown={setMobileFilterClicked} editFilters={editFilters} setEditFilters={setEditFilters} deleteFilters={emptyFilters}> 
            <Fragment>
            <InputField 
                    name='generalSearchFilter'
                    labelText={i18n('worksheetListPage.filters.searchList')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.generalSearchFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.GENERAL_SEARCH_FIELD, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='StartDate'
                    labelText={i18n('externalWorkListPage.filters.startDate')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.startDateFilter}
                    changed={(date: Date) => handleChangeDate(date, 'StartDate')}
                    type={INPUT_TYPE.DATE}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='CloseDate'
                    labelText={i18n('externalWorkListPage.filters.closeDate')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.closeDateFilter}
                    changed={(date: Date) => handleChangeDate(date, 'CloseDate')}
                    type={INPUT_TYPE.DATE}
                    barWidth={WIDTH.FULL_LINE} />
                <SearchableSelect 
                    overrideLableStyle
                    options={GetOptions(CATEGORY.SETTINGS, SETTING_TYPE.LICENSEPLATE)} 
                    label={i18n('externalWorkListPage.filters.licensePlateNumber')} 
                    fieldName={FIELD_NAME.LICENSEPLATE} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
                <SearchableSelect 
                    overrideLableStyle
                    options={GetOptions(CATEGORY.SETTINGS, SETTING_TYPE.DRIVER)} 
                    label={i18n('externalWorkListPage.filters.driver')} 
                    fieldName={FIELD_NAME.DRIVER} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
            </Fragment>
        </FilterOverlay></Fragment>}

        <div className='page-margin'>
        {!isMobile && 
            <div className={styles['header-items']}>
                <PageHeader 
                    text={i18n('pageHeaders.externalWorks')} 
                    goBack={() => history.push(INTERNAL_ROUTE.START)} />

                <div className={styles[isPortrait ? 'top-right-portrait' : 'top-right']}>
                    <div className={styles[isPortrait ? 'top-right-text-2-portrait' : 'top-right-text-2']}>
                        {`${i18n('externalWorkListPage.sumText')}: ${getDistanceSum()} ${i18n('externalWorkListPage.sumUnit')}`}
                    </div>
                    <Button 
                        text={i18n('button.externalWorkListPage.export')}
                        shade={BUTTON_SHADE.LIGHTERBLUE} 
                        size={BUTTON_SIZE.MEDIUM}
                        handleAction={() => handleExport()}
                        />
                </div>
                <Separator/>
           </div> }
           {isMobile && 
            <div className={styles['placeholder']}>
            <PageHeader 
                sum={`${i18n('externalWorkListPage.sumText')}: ${getDistanceSum()} ${i18n('externalWorkListPage.sumUnit')}`}
                text={i18n('pageHeaders.externalWorks')} 
                goBack={() => history.push(INTERNAL_ROUTE.START)} 
                />
            </div>
            }
            {!isMobile && 
            <ListFilters filterObj={filterObj} setFilterObj={setFilterObj} defaultFilter={defaultFilterObj_EW} emptyFilters={emptyFilters}>
                <InputField 
                    name='generalSearchFilter'
                    labelText={i18n('worksheetListPage.filters.searchList')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.generalSearchFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.GENERAL_SEARCH_FIELD, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='StartDate'
                    labelText={i18n('externalWorkListPage.filters.startDate')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.startDateFilter}
                    changed={(date: Date) => handleChangeDate(date, 'StartDate')}
                    type={INPUT_TYPE.DATE}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='CloseDate'
                    labelText={i18n('externalWorkListPage.filters.closeDate')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.closeDateFilter}
                    changed={(date: Date) => handleChangeDate(date, 'CloseDate')}
                    type={INPUT_TYPE.DATE}
                    barWidth={WIDTH.FULL_LINE} /> 
                <SearchableSelect 
                    options={GetOptions(CATEGORY.SETTINGS, SETTING_TYPE.LICENSEPLATE)} 
                    label={i18n('externalWorkListPage.filters.licensePlateNumber')} 
                    fieldName={FIELD_NAME.LICENSEPLATE} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
                <SearchableSelect 
                    options={GetOptions(CATEGORY.SETTINGS, SETTING_TYPE.DRIVER)} 
                    label={i18n('externalWorkListPage.filters.driver')} 
                    fieldName={FIELD_NAME.DRIVER} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
            </ListFilters>
            }
            { isLoading ? <LoadingAnimation /> : warning !== null ? <WarningMessage text={warning} isException={true} /> :
                <List 
                    list={list} 
                    columnHeaders={externalWorkListHeaders} 
                    listType={LIST_TYPE.EXTERNALWORK}
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj} 
                    orderTypes={externalWorkOrderTypes}
                    useDefaultOrder={useDefaultOrder}
                    setUseDefaultOrder={setUseDefaultOrder} /> }
        </div>
</Fragment>
)
}

export default ExternalWorkListPage