import styles from '../../pages/WorksheetPage.module.scss'
import { useContext, useState } from "react"
import "react-datepicker/dist/react-datepicker.css"
import i18n from '../../i18n'
import { Worksheet } from '../../models/Worksheet'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../elements/Button'
import { Separator } from '../elements/Separator'
import { CountryListContext, DeviceContext, SelectedWorksheetContext, SettingsContext } from '../../context/Contexts'
import { ISelectableOption } from '../interfaces/ISelectableOption'
import Grid from '@mui/material/Grid'
import InputField, { WIDTH, INPUT_ALIGN, LABEL, INPUT_TYPE } from '../elements/InputField'

type Props = {
    hasMore: () => boolean
}

export const WorksheetDetails = ({ hasMore }: Props) => {
    const { settings } = useContext(SettingsContext)
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { countries } = useContext(CountryListContext)
    const [ showMore, setShowMore ] = useState(hasMore)
    const { isMobile, isPortrait } = useContext(DeviceContext)

    const hasCustomerSigned = () => worksheet.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null

    const handleChange = (e: any) => {
        setWorksheet((prevState: Worksheet) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }
    const handleChangePlaceOfWork = (e: any) => {
        setWorksheet((prevState: Worksheet) => ({
            ...prevState,
                 PlaceOfWork: {
                    ...prevState.PlaceOfWork,
                    [e.target.name]: e.target.value
                }
        }))
    }

    return (
        <div className={styles['item']}>
            {/* Részletek* */}
            <div className={styles['item-header']}>
                <div className={styles['item-header-text']}>{i18n('worksheet.details.header')}</div>
            </div>
            <Separator />
            <div className={isMobile ? styles['content-normal'] : styles['content-half']}>
                {/* Fejléc adatok */}
                <InputField 
                    name='headerData-header'
                    labelText={i18n('worksheet.details.details.headerData.header')}
                    labelPlace={LABEL.FIELD_HEADER} 
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.NONE}
                    type={INPUT_TYPE.EMPTY} />
                <Grid container spacing={2}>
                    <Grid item xs={isMobile ? 6 : 12}>
                        <InputField 
                            name='TypeId'
                            labelText={i18n('worksheet.details.details.headerData.fields.type')}
                            value={worksheet?.TypeId ?? settings?.Types[0]?.Id} 
                            options={settings?.Types as ISelectableOption[]}
                            changed={handleChange}
                            isDisabled={hasCustomerSigned()}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.SIXTH}
                            barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            barWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.FIVE_SIXTHS}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.SELECT} />
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 12} style={!isMobile ? {paddingTop: 0} : {}}>
                        <InputField 
                            name='SubtypeId'
                            labelText={i18n('worksheet.details.details.headerData.fields.subtype')}
                            value={worksheet?.SubtypeId ?? settings?.SubTypes[0]?.Id} 
                            options={settings?.SubTypes as ISelectableOption[]}
                            changed={handleChange}
                            isDisabled={hasCustomerSigned()}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.SIXTH}
                            barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            barWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.FIVE_SIXTHS}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.SELECT} />
                    </Grid>
                </Grid>
                <InputField 
                    name='StatusId'
                    labelText={i18n('worksheet.details.details.headerData.fields.state')}
                    value={worksheet?.StatusId ?? settings?.Statuses[0]?.Id} 
                    options={settings?.Statuses as ISelectableOption[]}
                    changed={handleChange}
                    isDisabled={hasCustomerSigned()}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.SIXTH}
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : WIDTH.FIVE_SIXTHS}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.SELECT} />
              
                {/* Dátumok */}
                <InputField 
                    name='dates-header'
                    labelText={i18n('worksheet.details.details.dates.header')}
                    labelPlace={LABEL.FIELD_HEADER} 
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.NONE}
                    type={INPUT_TYPE.EMPTY} />
                <InputField 
                    name='VoucherDate'
                    labelText={i18n('worksheet.details.details.dates.fields.worksheetCreated')}
                    value={worksheet.VoucherDate} 
                    isDisabled={hasCustomerSigned()}
                    changed={(date: Date) => 
                        setWorksheet((prevState: Worksheet) => ({
                            ...prevState,
                            VoucherDate: date
                        }))}
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7 : WIDTH.TWO_THIRDS}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 5 : WIDTH.THIRD}
                    labelPlace={LABEL.DEFAULT}
                    useDefaultDate={true}
                    type={INPUT_TYPE.DATE} />
                <InputField 
                    name='FulfillmentDate'
                    labelText={i18n('worksheet.details.details.dates.fields.fulfillmentDeadline')}
                    value={worksheet.FulfillmentDate} 
                    isDisabled={hasCustomerSigned()}
                    changed={(date: Date) => 
                        setWorksheet((prevState: Worksheet) => ({
                            ...prevState,
                            FulfillmentDate: date
                        }))}
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7 : WIDTH.TWO_THIRDS}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 5 : WIDTH.THIRD}
                    labelPlace={LABEL.DEFAULT}
                    useDefaultDate={true}
                    type={INPUT_TYPE.DATE} />

                {/* Initial state - doesn't show place of work */}
                {!showMore ? 
                    <Button 
                        text={i18n('button.worksheetPage.showMore')} 
                        shade={ BUTTON_SHADE.LIGHTBLUE}
                        isExtraSpacious={true}
                        size={BUTTON_SIZE.MEDIUM}
                        handleAction={() => setShowMore(true)}
                        />
                    : ''}
            </div>

            {/* Munkavégzés helye */}
            {showMore ? 
                <div className={isMobile ? styles['content-normal'] : styles['content-half']}>
                    <InputField 
                        name='placeOfWork-header'
                        labelText={i18n('worksheet.details.details.placeOfWork.header')}
                        labelPlace={LABEL.FIELD_HEADER} 
                        labelWidth={WIDTH.FULL_LINE}
                        labelAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.NONE}
                        type={INPUT_TYPE.EMPTY} />
                    <InputField 
                        name='CountryId'
                        labelText={i18n('worksheet.details.details.placeOfWork.fields.country')}
                        value={worksheet?.PlaceOfWork?.CountryId ?? 237}
                        options={countries}
                        changed={handleChangePlaceOfWork}
                        isDisabled={hasCustomerSigned()}
                        labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 3 : WIDTH.SIXTH}
                        barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 9 : WIDTH.FIVE_SIXTHS}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.SELECT} />
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 4 : 12}>
                            <InputField 
                                name='Zip'
                                labelText={'Ir. szám'}
                                value={worksheet?.PlaceOfWork?.Zip}
                                changed={handleChangePlaceOfWork}
                                isDisabled={hasCustomerSigned()}
                                labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 3 : WIDTH.SIXTH}
                                barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 9 : WIDTH.FIVE_SIXTHS}
                                barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                                labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                                labelPlace={LABEL.DEFAULT}
                                type={INPUT_TYPE.TEXT} />
                        </Grid>
                        <Grid item xs={isMobile ? 8 : 12} style={!isMobile ? {paddingTop: 0} : {}}>
                            <InputField 
                                name='City'
                                labelText={i18n('worksheet.details.details.placeOfWork.fields.city')}
                                value={worksheet?.PlaceOfWork?.City}
                                changed={handleChangePlaceOfWork}
                                isDisabled={hasCustomerSigned()}
                                labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 3 : WIDTH.SIXTH}
                                barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 9 : WIDTH.FIVE_SIXTHS}
                                barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                                labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                                labelPlace={LABEL.DEFAULT}
                                type={INPUT_TYPE.TEXT} />
                        </Grid>
                    </Grid>
                    <InputField 
                        name='Address'
                        labelText={i18n('worksheet.details.details.placeOfWork.fields.address')}
                        value={worksheet?.PlaceOfWork?.Address}
                        changed={handleChangePlaceOfWork} 
                        isDisabled={hasCustomerSigned()}
                        labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 3 : WIDTH.SIXTH}
                        barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 9 : WIDTH.FIVE_SIXTHS}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.TEXT} />
                    <Button 
                        text={i18n('button.worksheetPage.showLess')} 
                        shade={ BUTTON_SHADE.LIGHTBLUE}
                        size={BUTTON_SIZE.MEDIUM}
                        isExtraSpacious={true}
                        handleAction={() => setShowMore(false)}
                        />
                </div> 
                : <div className={styles['content-half']} />}
            </div>
        )
}