import { Fragment, useContext, useEffect, useState } from "react"
import { QuantityUnitListContext, SelectedWorksheetContext, SettingsContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { ExternalWork } from "../../../models/ExternalWork"
import { TimeSpent } from "../../../models/TimeSpent"
import { Worksheet } from "../../../models/Worksheet"
import { ICON_TYPE } from "../../../utils/global"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../Button"
import { Separator } from "../Separator"
import ExpandableBar, { EXPANDABLE_TYPE, EXPANDABLE_SIZE } from "./ExpandableBar"
import { EXPANDABLE_CHILD_TYPE } from "./ExpandableComplexChildren"
import styles from './ExpandableListItem.module.scss'
import { v4 as uuidv4 } from 'uuid';
import { IIndexable } from "../../interfaces/IIndexible"
import { QuantityUnit } from "../../../models/QuantityUnit"
import { isArrayUnavailable, isObjectUnavailable } from "../../../utils/HelperFunctions"

type Props = {
    type: EXPANDABLE_LIST_TYPE
}

export enum EXPANDABLE_LIST_TYPE {
    TIMESPENT = 'TimeSpentList',
    EXTERNALWORK = 'ExternalWorkList'
}

const ExpandableList = ({ type }: Props) => {
    const { settings } = useContext(SettingsContext)
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { quantityUnits } = useContext(QuantityUnitListContext)

    useEffect(() => {
        if(isArrayUnavailable((worksheet as IIndexable)[type]))
            addItem()
    }, [])
    
    const getDefaultQuantityUnitForTimeSpent = () => {
        const [firstQuantityUnit] = Object.values(quantityUnits)
        return Object.values(quantityUnits).find((quantityUnit: QuantityUnit) => quantityUnit.Name === 'óra')?.Id ?? firstQuantityUnit?.Id ?? 0
    }
    const defaultQuantityUnitIdForTimeSpents = getDefaultQuantityUnitForTimeSpent()

    const addItem = () => {
        let item = {}
        if(type === EXPANDABLE_LIST_TYPE.TIMESPENT) 
            item = {
                Date: new Date(),
                IsShownAsWorksheetItem: false,
                Id: uuidv4(),
                WorkerId: settings?.Workers[0]?.Id,
                QuantityUnitId: defaultQuantityUnitIdForTimeSpents
            }
        else 
            item = {
                Date: new Date(),
                IsShownAsWorksheetItem: false,
                Id: uuidv4(),
                LicensePlateNumberId: settings?.LicensePlates[0]?.Id,
                DriverId: settings?.Drivers[0]?.Id
            }

        setWorksheet((prevState: Worksheet) => ({
            ...prevState,
                [type]: [
                ...(prevState as IIndexable)[type] ?? [], item
                ]
        }))
    }

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null
    
    return (
        <Fragment>
            {(worksheet as IIndexable)[type]?.map((item :any, index: number) =>( 
                <div key={index} style={{display: index === 0 && 
                    ((type === EXPANDABLE_LIST_TYPE.EXTERNALWORK && (item as ExternalWork)?.Address === undefined) ||
                    (type === EXPANDABLE_LIST_TYPE.TIMESPENT && (item as TimeSpent)?.Label === undefined)) ? "none" : "contents"}}>
                    <ExpandableBar 
                        name={type}
                        title={type === EXPANDABLE_LIST_TYPE.EXTERNALWORK ? (item as ExternalWork)?.Address : (item as TimeSpent)?.Label}
                        type={EXPANDABLE_TYPE.COMPLEX}
                        childrenType={type === EXPANDABLE_LIST_TYPE.EXTERNALWORK ? EXPANDABLE_CHILD_TYPE.EXTERNALWORK_ITEM : EXPANDABLE_CHILD_TYPE.TIMESPENT_ITEM}
                        childIndex={index}
                        size={EXPANDABLE_SIZE.NORMAL}
                        editableTitle={false}
                        isLastChild={(worksheet as IIndexable)[type][(worksheet as IIndexable)[type].length - 1] === item} />
                    <Separator light={true} />
                </div>
            )) 
            }
        <div className={styles['button-container-time']}>
            <Button 
                isDisabled={hasCustomerSigned()}
                text={i18n(`button.worksheetPage.add${type}`)}
                shade={BUTTON_SHADE.LIGHTBLUE} 
                size={BUTTON_SIZE.WITH_ICON}
                handleAction={addItem}
                icon={ICON_TYPE.ADD} />
        </div>
        </Fragment>
        )
}

export default ExpandableList