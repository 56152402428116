export type BisnodePartner = {
    Address: string
    City: string
    Name: string
    ShortName: string
    Status: BISNODEPARTNER_STATUS
    TaxNbr: string
    Zip: string
}

export enum BISNODEPARTNER_STATUS {
    CEASED = 'Megszűnt',
    WORKING = 'Működő'
}