import axios from "axios"
import { FiltersObject } from "./models/Filter"
import { RequestResult } from "./models/RequestResult"
import { showErrorMessages } from "./reducers/rootReducer"
import { store } from "./App"
import { Worksheet } from "./models/Worksheet"
import { ISelectableOption } from "./components/interfaces/ISelectableOption"
import { parseJwt } from "./utils/global"

// const baseURL = 'https://kulcs-uzlet-api-test.kulcs-soft.hu'
const baseURL = process.env.REACT_APP_BACKEND_URL

export enum BACKEND_ROUTE {
  COMPANY_DATA = '/worksheet/companyData',
	CUSTOMER = '/worksheet/customer',
  SIMPLEDCUSTOMER = '/worksheet/simpledcustomer',
  PRODUCT = '/worksheet/product',
  VAT = '/worksheet/vat',
  QUANTITYUNIT = '/worksheet/quantityunit',
  COUNTRY = '/worksheet/country',
  WORKSHEET = '/worksheet',
  CUSTOMERCATEGORY = '/worksheet/clientcategory',
  PRODUCTCATEGORY = '/worksheet/productcategory',
  WORKSHEET_TITLES = '/worksheet/lastSettings',
  SETTINGS = '/worksheet/settings',
  TIMESPENT = '/worksheet/timespent',
  EXTERNALWORK = '/worksheet/externalwork',
  TOOL = '/worksheet/tool',
  VOUCHERIMAGE = '/worksheet/generateVoucherImage',
  EXPORT_WORKSHEET = '/worksheet/export/worksheet',
  EXPORT_WORKSHEET_WITH_ITEMS = '/worksheet/export/worksheetWithItems',
  EXPORT_TIMESPENT = '/worksheet/export/timespent',
  EXPORT_EXTERNALWORK = '/worksheet/export/externalwork',
  EXPORT_WORKSHEETS = '/worksheet/generateVoucherImagesByIds',
  VOID_WORKSHEET = '/worksheet/void',
  PAYMENTMETHOD = '/worksheet/paymentMethod',
  CURRENCY = '/worksheet/currency',
  CURRENCY_PRODUCT = '/worksheet/currency/product',
  GET_NEXT_PRODUCT_CODE = '/worksheet/product/nextCode',
  GET_NEXT_CUSTOMER_CODE = '/worksheet/customer/nextCode',
  BISNODE = '/worksheet/bisnode',
  PRINTBYID = '/worksheet/printById',
  SIGNATURE = '/worksheet/signature',
  SEND_BY_KULCSUZLET = '/worksheet/sendEmail',
  DEFAULT_EMAIL = '/worksheet/defaultEmail',
  LAST_EMAIL_PARAMS = '/worksheet/lastEmailParams',
  REMOTEPRINT_PREVIEW_URL = '/worksheet/remoteprinturl',
  SERVICE_HISTORY_XLS = '/worksheet/export/serviceHistory',
  SERVICE_HISTORY_PDF = '/worksheet/export/serviceHistoryPdf',
  CHECK_WORKSHEETS_TO_CREATE_INVOICES = '/worksheet/checkCreateInvoice'
}

export enum PDF_TYPE {
  WORKSHEET = 'munkalap',
  WORKSHEETS = 'munkalapok'
}


export enum REQUEST_METHOD {
  GET,
  POST,
  PATCH
}

export const getRequest = async (url : BACKEND_ROUTE, filter : FiltersObject|null, parameter : null|string = null): Promise<[]> => {
    return new Promise(async (resolve, reject) => {
        let result
        result = axios.get(baseURL + url + (parameter !== null ? parameter : ''), getOptions(filter))
        if (!result) {
            reject(`Response object is undefined - URL: ${baseURL + url} Invalid request`)
          }
          result
            ?.then((res) => {
                let data = res.data as RequestResult;
                if(data.IsSuccess)
                  return resolve(data.ResultValue);
                else{
                  store.dispatch(showErrorMessages(data.ErrorList));
                }
              })
            .catch((error) => {
              reject(`${error} - URL: ${baseURL + url} ${error}`)
            })
      })
}

export const postRequest = async (url : BACKEND_ROUTE, data: string): Promise<[]> => {
    return await kulcsUzletRequest(url, data, REQUEST_METHOD.POST)
}

export const patchRequest = async (url : BACKEND_ROUTE, data: string): Promise<[]> => {
  return await kulcsUzletRequest(url, data, REQUEST_METHOD.PATCH)
}

export const kulcsUzletRequest = async (url: BACKEND_ROUTE, data: string, method: REQUEST_METHOD, setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>): Promise<[]> => {
  return new Promise(async (resolve, reject) => {
        let result
        if(method === REQUEST_METHOD.POST){
          result = axios.post(baseURL + url, data, getOptions(null))
        }else if(method === REQUEST_METHOD.PATCH){
          result = axios.patch(baseURL + url, data, getOptions(null))
        }
        if (!result) {
            reject(`Response object is undefined - URL: ${baseURL + url} Invalid request`)
          }
          result
            ?.then((res) => {
              let data = res.data as RequestResult;
              if(data.IsSuccess)
                return resolve(data.ResultValue);
              else{
                setIsLoading && setIsLoading(false)
                store.dispatch(showErrorMessages(data.ErrorList));
              }
            })
            .catch((error) => {
              reject(`${error} - URL: ${baseURL + url} ${error}`)
            })
    })
}

const getOptions = (filter : FiltersObject|null) => {
  let filterObj = JSON.stringify(filter)
  if(filter !== null){
    for(let i = 0; i < filterObj.length; i++){
      let charCode = filterObj.charCodeAt(i);
      if(charCode > 160){
        let x = filterObj.charCodeAt(i).toString(16)
        x = x.padStart(4, "0")
        let y = (`\\u${x}`)

        filterObj = filterObj.slice(0, i) + y + filterObj.slice(i+1)
        i+=5
      }
    }
  }
  var token = localStorage.getItem('token') as string
  const decodedJwt = parseJwt(token)
    return {
        headers: {
            'Authorization' : token,
            'X-Application-Id': 'pbyAheWV4MjTHcudjFOl1a1dBUsFxHUO',
            'filter': filterObj,
            'X-User-Context': decodedJwt.guid,
            'Content-Type': 'application/json'
        }
    }
}

export const getSettings = (setWorksheet: any) => getRequest(BACKEND_ROUTE.SETTINGS, null)
.then((res) => setWorksheet((prevState: Worksheet) => ({
    ...prevState,
    Settings: res
}))).catch((e) => store.dispatch(showErrorMessages([e])))

export const fetchData = async(filterObj : FiltersObject | null, route: BACKEND_ROUTE, setList: any, setWarning: any, setOptions?: React.Dispatch<React.SetStateAction<ISelectableOption[]>>) => {
  await getRequest(route, filterObj).then(async function(res) { 
      if (res !== null || res[0] !== null){
        if(route === BACKEND_ROUTE.WORKSHEET) {
          let list = res as Worksheet[]
          list = list.map(x => x.Customer = x.CustomerObj ? JSON.parse(x.CustomerObj) : {})
          await setList(res)
        }
        else {
          await setList(res)
        }
        if(setOptions) {
          let list = []
          for(const item of res as any[]) {
            if(route === BACKEND_ROUTE.TOOL) {
              list.push({
                Id: item.Code,
                Name: item.Code
              })
            } 
          await setOptions(list)}
        }
      }
    }).catch((error) =>{
      setWarning(error)
  })
}