import React from "react";
import { CompanyData } from "../models/CompanyData";
import { Country } from "../models/Country";
import { Customer } from "../models/Customer";
import { Product } from "../models/Product";
import { QuantityUnit } from "../models/QuantityUnit";
import { Settings } from "../models/Settings";
import { Tool } from "../models/Tool";
import { Vat } from "../models/Vat";
import { Worksheet } from "../models/Worksheet";

export type SelectedWorksheetProps = { 
    worksheet: Worksheet
    setWorksheet: any
  }

export type VatListProps = {
  vats: Vat[]
}

export type QuantityUnitListProps = {
  quantityUnits: QuantityUnit[]
}

export type CountryListProps = {
  countries: Country[]
}

export type SettingsProps = {
  settings: Settings
  setSettings: any
}

export type EditIemProps = {
  editItem: Tool | Customer | Product
  setEditItem: any
};

export type EditItemIdProps = {
  editItemId: number
  setEditItemId: any 
};

export type CompanyDataProps = {
  companyData: CompanyData
  setCompanyData: any
}

export type DeviceProps = {
  isMobile: boolean
  setIsMobile: any
  isPortrait: boolean
  setIsPortrait: any
}

export type NeedRefreshProps = {
  needRefresh: boolean
  setNeedRefresh: any
}

export const SelectedWorksheetContext = React.createContext<SelectedWorksheetProps>(
  { worksheet: {} as Worksheet, setWorksheet: null })

export const VatListContext = React.createContext<VatListProps>(
  {vats: [] as Vat[]})

export const QuantityUnitListContext = React.createContext<QuantityUnitListProps>(
  {quantityUnits: [] as QuantityUnit[]})

export const CountryListContext = React.createContext<CountryListProps>(
  {countries: [] as Country[]})

export const SettingsContext = React.createContext<SettingsProps>(
  {settings: {} as Settings, setSettings: null})

export const EditItemContext = React.createContext<EditIemProps>(
  {editItem: {} as Tool | Customer | Product, setEditItem: null})

export const EditItemIdContext = React.createContext<EditItemIdProps>(
  {editItemId: -1, setEditItemId: null})

export const CompanyDataContext = React.createContext<CompanyDataProps>(
  {companyData: {} as CompanyData, setCompanyData: null})

export const DeviceContext = React.createContext<DeviceProps>(
  {isMobile: false, setIsMobile: null, isPortrait: false, setIsPortrait: null})

export const NeedRefreshContext = React.createContext<NeedRefreshProps>(
  {needRefresh: false, setNeedRefresh: null})
