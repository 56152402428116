import { ISelectableOption } from "../components/interfaces/ISelectableOption"
import { FilterOrder } from "../models/Filter"
import { DATETYPES } from "./FilterHandler"


export type PageFilter_WS = {
    generalSearchFilter: string
    dateType: DATETYPES
    startDateFilter: string
    closeDateFilter: string
    customerOptions: ISelectableOption[]
    voucherNumberFilter: string
} 

export const defaultPageFilter_WS = {
    generalSearchFilter: '',
    dateType: DATETYPES.VOUCHERDATE,
    startDateFilter: '',
    closeDateFilter: '',
    customerOptions: [] as ISelectableOption[],
    voucherNumberFilter: ''
}

export const defaultFilterObj_WS = { 
    Skip: 0, 
    Take: 10, 
    Orders: [ 
        { 
            FieldName: 'VoucherNumber', 
            OrderWay: 1, 
            OrderType: 0 
        } as FilterOrder
    ], 
    Filters: []
}

export type PageFilter_TOOL = {
    generalSearchFilter: string
    customerOptions: ISelectableOption[]
    codeFilter: string
    nameFilter: string
} 

export const defaultPageFilter_TOOL = {
    generalSearchFilter: '',
    customerOptions: [] as ISelectableOption[],
    codeFilter: '',
    nameFilter: ''
}

export const defaultFilterObj_TOOL = { 
    Skip: 0, 
    Take: 10, 
    Orders: [ 
      { 
        FieldName: 'LastWorksheetDate', 
        OrderWay: 1, 
        OrderType: 0 
      } 
    ], 
    Filters: [] 
}


export type PageFilter_TS = {
    generalSearchFilter: string
    startDateFilter: string
    closeDateFilter: string
    labelFilter: string
} 

export const defaultPageFilter_TS = {
    generalSearchFilter: '',
    startDateFilter: '',
    closeDateFilter: '',
    labelFilter: ''
}

export const defaultFilterObj_TS = { 
    Skip: 0, 
    Take: 10, 
    Orders: [ 
      { 
        FieldName: 'RowVersion', 
        OrderWay: 1, 
        OrderType: 0 
      } 
    ], 
    Filters: [] 
}


export type PageFilter_EW = {
    generalSearchFilter: string
    startDateFilter: string
    closeDateFilter: string
} 

export const defaultPageFilter_EW = {
    generalSearchFilter: '',
    startDateFilter: '',
    closeDateFilter: ''
}

export const defaultFilterObj_EW = { 
    Skip: 0, 
    Take: 10, 
    Orders: [ 
      { 
        FieldName: 'RowVersion', 
        OrderWay: 1, 
        OrderType: 0 
      } 
    ], 
    Filters: [] 
}

export const defaultFilterObj_SH = { 
    Skip: 0, 
    Take: 10, 
    Orders: [ 
        { 
            FieldName: 'VoucherDate', 
            OrderWay: 1, 
            OrderType: 0 
        } as FilterOrder
    ], 
    Filters: []
}