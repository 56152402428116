import styles from './WorksheetSettingsField.module.scss'
import deleteIcon from '../../../images/delete_icon.png'
import { Fragment, useContext } from 'react'
import { DeviceContext, SettingsContext } from '../../../context/Contexts'
import { Setting } from '../../../models/Setting'
import i18n from '../../../i18n'
import { ICON_TYPE } from '../../../utils/global'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../Button'
import { Settings } from '../../../models/Settings'
import { IIndexable } from '../../interfaces/IIndexible'
import InputField, { INPUT_ALIGN, WIDTH, INPUT_TYPE } from '../InputField'
import { Grid } from '@mui/material'

type Props = {
    name?: string
    fieldName: string
    hasChange: boolean
    setHasChange: React.Dispatch<React.SetStateAction<boolean>>
}

const WorksheetSettingsField = ({ name, fieldName, hasChange, setHasChange }: Props) => {
    const { settings, setSettings } = useContext(SettingsContext)
    const settingsField = (settings as IIndexable)[fieldName] as Setting[]
    const fieldCount = (settings as IIndexable)[fieldName]?.length ?? 0
    const maxNumberOfFields = 10
    const { isMobile } = useContext(DeviceContext)

    const handleChange = (e: any, index: number) => {
        if(!hasChange)
            setHasChange(true)
        let list = (settings as IIndexable)[fieldName] ?? []
        let changedItem = (settings as IIndexable)[fieldName][index]
        list[index] = {...changedItem, Name: e.target.value}

        setSettings((prevState: Settings) => ({
            ...prevState,
            [fieldName]: list
        }))
    }

    const addItem = () => {
        setSettings((prevState: Settings) => ({
            ...prevState,
            [fieldName]: [...(prevState as IIndexable)[fieldName] ?? [], {} as Setting]
        }))
    }

    const deleteItem = (index: number) => {
        let list = (settings as IIndexable)[fieldName] ?? []
        const itemIndex = list.indexOf(list[index])
        if (itemIndex > -1)
            list.splice(itemIndex, 1)
        
        setSettings((prevState: Settings) => ({
            ...prevState,
            [fieldName]: list
        }))
    }

    return (
        <div className={isMobile ? styles['field-mobile'] : styles.field}>
            { name && <div className={styles['title']}>{name}</div> }
            
            {settingsField?.map((setting, index) => (
                <Grid container spacing={1} key={index} style={{marginBottom: isMobile ? 7 : 3}}>
                    <Grid item xs={9}>
                        <InputField 
                        name={setting.Name} 
                        value={setting.Name}
                        changed={(e) => handleChange(e, index)}
                        barAlignment={INPUT_ALIGN.LEFT}
                        barWidth={WIDTH.FULL_LINE}
                        type={INPUT_TYPE.TEXT} 
                        labelWidth={WIDTH.NONE} />
                    </Grid>
                    <Grid item xs={2}>
                        <img alt='' src={deleteIcon} className={`${styles['back-button']} ${!isMobile && styles['icon-margin']}`} onClick={() => deleteItem(index)} />
                    </Grid>
                </Grid>
            ))}

            {fieldCount < maxNumberOfFields ? 
                <Button 
                    text={i18n('button.worksheetSettingsPage.addNew')}
                    shade={BUTTON_SHADE.LIGHTBLUE}
                    size={BUTTON_SIZE.WITH_ICON_SMALL}
                    handleAction={() => addItem()}
                    icon={ICON_TYPE.ADD} />
                : '' }
        </div>
    )
}

export default WorksheetSettingsField