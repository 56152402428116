import * as React from 'react'
import { Route, BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'
import Footer from './components/layout/Footer'
import { Header } from './components/layout/Header'
import SignatureModal from './components/modal/signature/SignatureModal'
import AuthPage from './pages/AuthPage'
import ExternalWorkListPage from './pages/list/ExternalWorkListPage'
import TimeSpentListPage from './pages/list/TimeSpentListPage'
import ToolListPage from './pages/list/ToolListPage'
import WorksheetListPage from './pages/list/WorksheetListPage'
import WorksheetPage from './pages/WorksheetPage'
import WorksheetSettingsPage from './pages/WorksheetSettingsPage'
import DeviceCheck from './utils/DeviceCheck'
import { INTERNAL_ROUTE } from './utils/global'
import RequireJwt from './utils/RequireJwt'


class Routes extends React.Component<{}, {}> {
	render() {
		return (
			<Router>
				<Switch>
				<DeviceCheck>
				<Route exact path={INTERNAL_ROUTE.AUTH} component={AuthPage} />
				<RequireJwt>
					<Header />
					<Switch>
						<Route exact path={INTERNAL_ROUTE.START} component={WorksheetListPage} />
						<Route exact path={INTERNAL_ROUTE.WORKSHEET_LIST} component={WorksheetListPage} />
						<Route exact path={INTERNAL_ROUTE.WORKSHEET_BY_ID} component={WorksheetPage} />
						<Route exact path={INTERNAL_ROUTE.NEW_WORKSHEET} component={WorksheetPage} />
						<Route exact path={INTERNAL_ROUTE.COPY_WORKSHEET} component={WorksheetPage} />
						<Route exact path={INTERNAL_ROUTE.WORKSHEET_SETTINGS} component={WorksheetSettingsPage} />
						<Route exact path={INTERNAL_ROUTE.TIMESPENT_LIST} component={TimeSpentListPage} />
						<Route exact path={INTERNAL_ROUTE.EXTERNALWORK_LIST} component={ExternalWorkListPage} />
						<Route exact path={INTERNAL_ROUTE.TOOLS} component={ToolListPage} />
						<Route path='*' exact={true} component={WorksheetListPage} />
					</Switch>
					<Footer />
				</RequireJwt>
				</DeviceCheck>
				</Switch>
			</Router>
		)
	}
}

export default Routes
