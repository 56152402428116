import React, { Fragment, useContext, useEffect, useState } from 'react'
import i18n from '../../i18n'
import { Customer } from '../../models/Customer'
import { Product } from '../../models/Product'
import { WorksheetItem } from '../../models/WorksheetItem'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../elements/Button'
import styles from './BaseModal.module.scss'
import { MODAL_TYPE } from './BaseModal'
import { ModalRowCustomer } from './ModalRowCustomer'
import { ModalRowProduct } from './ModalRowProduct'
import { Worksheet } from '../../models/Worksheet'
import { EditItemIdContext, EditItemContext, VatListContext, NeedRefreshContext, DeviceContext } from '../../context/Contexts'
import { Contact } from '../../models/ContactInfo'
import { RELATION_TYPE } from '../../utils/global'
import ToolModal from './tool/ToolModal'
import { Tool } from '../../models/Tool'
import { ModalRowTool } from './ModalRowTool'
import { REQUEST_METHOD, kulcsUzletRequest, BACKEND_ROUTE } from '../../request'
import { useHistory } from 'react-router-dom'
import Pagination from '../lists/Pagination'
import { FiltersObject } from '../../models/Filter'
import { isArray } from 'lodash'
import CustomerModal from './customer/CustomerModal'
import ProductModal from './product/ProductModal'
import { map_product_worksheetItem } from '../../utils/Mapper'
import SignatureModal from './signature/SignatureModal'
import SendVoucherModal from './email/SendVoucherModal'
 
export interface ModalProps {
    content: Product[] | Customer[] | Tool[] | Tool | Customer | Product
    closeModal: () => void
    selectedElement: Product | Customer | Tool
    setSelectedElement: any
    type: MODAL_TYPE
    setWorksheet: React.Dispatch<React.SetStateAction<Worksheet>>
    openModal: (type: MODAL_TYPE) => void
    filterObj: FiltersObject
    setFilterObj: React.Dispatch<React.SetStateAction<FiltersObject>>
    isShown?: boolean
    shownFromToolModal?: boolean
    openModalOnTop?: (contentType: MODAL_TYPE) => void
    setChangableTitle?: React.Dispatch<React.SetStateAction<string>>
}

const ModalContent = ({ content, closeModal, selectedElement, setSelectedElement, type, setWorksheet, openModal, filterObj, setFilterObj, 
    isShown, shownFromToolModal, openModalOnTop, setChangableTitle }: ModalProps) => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { vats } = useContext(VatListContext);
    const history = useHistory() 
    const itemsPerPage = 10
    const [ currentPageIndex, setCurrentPageIndex ] = useState((filterObj.Skip / itemsPerPage) + 1)
    const { setEditItemId } = useContext(EditItemIdContext)
    const { setNeedRefresh } = useContext(NeedRefreshContext)
    const { isMobile } = useContext(DeviceContext)
    
    const handleElementClick = async (element: {}) => await setSelectedElement(element)
    
    const handleSaveClick = async () => {
        switch (type) {
            case MODAL_TYPE.CUSTOMER: {
                if(shownFromToolModal) {
                    setEditItem((prevstate: Tool) => ({
                        ...prevstate,
                        Customer: selectedElement as Customer,
                        Contact: {
                            ContactName: (selectedElement as Customer).ContactName, 
                            Phone: (selectedElement as Customer).Phone, 
                            Email: (selectedElement as Customer).Email, 
                        } as Contact,
                        CustomerId: (selectedElement as Customer).Id
                    }))
                }
                else
                    setWorksheet(prevState => ({
                        ...prevState,
                        Customer: selectedElement as Customer,
                        Contact: {
                            ContactName: (selectedElement as Customer).ContactName, 
                            Phone: (selectedElement as Customer).Phone, 
                            Email: (selectedElement as Customer).Email, 
                        } as Contact
                    }))
                }
                break
            case MODAL_TYPE.NEWCUSTOMER:
            case MODAL_TYPE.EDITCUSTOMER:
                handleSaveCustomer()
                break
            case MODAL_TYPE.PRODUCT:
                let selectedProduct : Product = selectedElement as Product
                let item : WorksheetItem = {
                    Name: selectedProduct.Name,
                    ProductId: selectedProduct.Id,
                    Quantity: 1,
                    QuantityUnitId: selectedProduct.QuantityUnit,
                    UnitPrice: selectedProduct.UnitPrice,
                    VatId: selectedProduct.Vat,
                    Vat: vats[selectedProduct.Vat],
                    RelationType: RELATION_TYPE.PRODUCT,
                    RelationId: selectedElement.Id.toString()
                }
                setWorksheet(prevState => ({
                    ...prevState,
                         Items: [
                            ...prevState.Items ?? [],
                            item
                         ]
                }))
                break
            case MODAL_TYPE.NEWPRODUCT:
            case MODAL_TYPE.EDITPRODUCT:
                handleSaveProduct()
                break
            case MODAL_TYPE.NEWTOOL:
            case MODAL_TYPE.EDITTOOL:
                await handleSaveTool()
                break
            case MODAL_TYPE.TOOLS:
                setWorksheet(prevState => ({
                    ...prevState,
                    ToolId: selectedElement.Id,
                    Tool: selectedElement as Tool
                }))
                break
        }
        if(type !== MODAL_TYPE.NEWTOOL && 
            type !== MODAL_TYPE.EDITTOOL && 
            type !== MODAL_TYPE.NEWCUSTOMER && 
            type !== MODAL_TYPE.EDITCUSTOMER &&
            type !== MODAL_TYPE.NEWPRODUCT &&
            type !== MODAL_TYPE.EDITPRODUCT)
            closeModal()
    }

    const getPosition = () => {
        switch(type) {
            case MODAL_TYPE.TOOLS:
            case MODAL_TYPE.PRODUCT:
            case MODAL_TYPE.CUSTOMER:
                return isMobile && styles['modal-footer-position']
            case MODAL_TYPE.NEWTOOL:
            case MODAL_TYPE.EDITTOOL:
            case MODAL_TYPE.NEWCUSTOMER:
            case MODAL_TYPE.EDITCUSTOMER:
            case MODAL_TYPE.NEWPRODUCT:
            case MODAL_TYPE.EDITPRODUCT:
                return styles['modal-footer-position']
        }
    }

    const handleSaveTool = async () => {
        if((editItem as Tool).Customer) {
            (editItem as Tool).CustomerId = (editItem as Tool).Customer.Id;
            (editItem as Tool).ContactObj = JSON.stringify((editItem as Tool).Contact);
        }
        (editItem as Tool).ServiceHistoryObj = JSON.stringify((editItem as Tool).ServiceHistory);
        (editItem as Tool).ToolDataObj = JSON.stringify((editItem as Tool).ToolData);
        let request_method = editItem.Id ? REQUEST_METHOD.PATCH : REQUEST_METHOD.POST;

        await kulcsUzletRequest(BACKEND_ROUTE.TOOL, JSON.stringify(editItem), request_method).then((res) => {
            if (res !== null || res[0] !== null) {
                closeModal()
                if(window.location.pathname == '/worksheet'){
                    setWorksheet(prevState => ({
                        ...prevState,
                        ToolId: selectedElement.Id,
                        Tool: selectedElement as Tool
                    }))
                }
                else
                    history.go(0)
                setNeedRefresh(true)
                setEditItem({} as Tool)
            } 
        }).catch((error) =>{
            console.error(error);
        })
    }

    const trimDashes = async () => {
        let customer = editItem as Customer
        if((editItem as Customer).BankAccount?.charAt((editItem as Customer).BankAccount?.length - 1) === '-'){
            customer = {...customer, BankAccount: customer.BankAccount.slice(0, -1)}
        }
        if((editItem as Customer).TaxNumber?.charAt((editItem as Customer).TaxNumber?.length - 1) === '-') {
            customer = {...customer, TaxNumber: customer.TaxNumber.slice(0, -1)}
        }
        if((editItem as Customer).GroupId?.charAt((editItem as Customer).GroupId?.length - 1) === '-') {
            customer = {...customer, GroupId: customer.GroupId.slice(0, -1)}
        }
        customer = {...customer, SWIFT: customer.SWIFT?.trim()}
        customer = {...customer, IBAN: customer.IBAN?.trim()}
        await setEditItem(customer)
        return customer
    }

    const handleSaveCustomer = async () => {
        let request_method = editItem.Id ? REQUEST_METHOD.PATCH : REQUEST_METHOD.POST
        let customer = await trimDashes()

        await kulcsUzletRequest(BACKEND_ROUTE.CUSTOMER, JSON.stringify(customer), request_method).then(async (res) => {
            if (res !== null || res[0] !== null) {
                closeModal()
                if(window.location.pathname == '/worksheet'){
                    await setWorksheet(prevState => ({
                        ...prevState,
                        CustomerId: editItem.Id,
                        Customer: editItem as Customer
                    }))
                }
                else
                    history.go(0)
                await setNeedRefresh(true)
                await setEditItem({} as Customer)
            } 
        }).catch((error) =>{
            console.error(error);
        })
    }

    const handleSaveProduct = async () => {
        let request_method = editItem.Id ? REQUEST_METHOD.PATCH : REQUEST_METHOD.POST
        let product = editItem as Product
        let item = map_product_worksheetItem(product, vats[product.Vat])

        await kulcsUzletRequest(BACKEND_ROUTE.PRODUCT, JSON.stringify(product), request_method).then((res) => {
            if (res !== null || res[0] !== null) {
                item.RelationId = (res as unknown as Product).Id.toString()
                closeModal()
                setWorksheet((prevState: Worksheet) => ({
                    ...prevState,
                    Items: [...prevState.Items ?? [] as WorksheetItem[], item]
                }))
                setNeedRefresh(true)
                setEditItem({} as Product)
            } 
        }).catch((error) =>{
            console.error(error);
        })
    }

    const getPageCount = () => {
        if(isArray(content) && content[0]?.TotalCount !== undefined) {
            return Math.ceil(content[0]?.TotalCount / itemsPerPage)
        }
        else return 0
    }

    return (
        <div className={styles['content-container']} >
            {type === MODAL_TYPE.NEWTOOL ? <ToolModal  openModal={openModal} openModalOnTop={openModalOnTop} isShown={isShown !== undefined ? isShown : false} /> 
            : type === MODAL_TYPE.EDITTOOL ? <ToolModal openModal={openModal} openModalOnTop={openModalOnTop} isShown={isShown !== undefined ? isShown : false} /> 
            : type === MODAL_TYPE.NEWCUSTOMER ? <CustomerModal  openModal={openModal} isShown={isShown !== undefined ? isShown : false} />
            : type === MODAL_TYPE.EDITCUSTOMER ? <CustomerModal openModal={openModal} isShown={isShown !== undefined ? isShown : false} />
            : type === MODAL_TYPE.NEWPRODUCT ? <ProductModal  openModal={openModal} isShown={isShown !== undefined ? isShown : false} />
            : type === MODAL_TYPE.EDITPRODUCT ? <ProductModal openModal={openModal} isShown={isShown !== undefined ? isShown : false} /> 
            : type === MODAL_TYPE.SIGNATURE ? <SignatureModal setModalTitle={setChangableTitle} isShown={isShown !== undefined ? isShown : false} /> 
            : type === MODAL_TYPE.EMAIL ? <SendVoucherModal closeModal={closeModal} /> 
            : <Fragment>
                <div className={styles[(window.screen?.availHeight > 800 && isMobile) ? 'scroll-view-medium' : isMobile ? 'scroll-view-mobile' : 'scroll-view']} > 
                {Array.isArray(content) && content.map((element) => (
                    type === MODAL_TYPE.PRODUCT ? 
                    <ModalRowProduct 
                        handleSaveClick={handleSaveClick} 
                        key={element.Id} 
                        product={element as Product} 
                        handleAction={handleElementClick} 
                        selectedElementId={selectedElement.Id}
                        handleProductEditClick={() => {
                            setEditItem(element as Product)
                            openModal(MODAL_TYPE.EDITPRODUCT)
                        }} />
                    : type === MODAL_TYPE.TOOLS ?
                        <ModalRowTool 
                            handleSaveClick={handleSaveClick} 
                            key={element.Id} 
                            tool={element as Tool} 
                            handleAction={handleElementClick} 
                            selectedElementId={selectedElement.Id}
                            handleToolEditClick={() => {
                                setEditItemId(element.Id)
                                openModal(MODAL_TYPE.EDITTOOL)
                            }} />
                    : <ModalRowCustomer 
                        handleSaveClick={handleSaveClick} 
                        key={element.Id} 
                        customer={element as Customer} 
                        handleAction={handleElementClick} 
                        selectedElementId={selectedElement.Id}
                        handleCustomerEditClick={() => {
                            setEditItem(element as Customer)
                            openModal(MODAL_TYPE.EDITCUSTOMER)
                        }} />
                ))}
            </div>
            </Fragment>
            }
            {(type !== MODAL_TYPE.NEWTOOL && type !== MODAL_TYPE.EDITTOOL && type !== MODAL_TYPE.NEWCUSTOMER && type !== MODAL_TYPE.EDITCUSTOMER && 
                type !== MODAL_TYPE.NEWPRODUCT && type !== MODAL_TYPE.EDITPRODUCT && type !== MODAL_TYPE.SIGNATURE && type !== MODAL_TYPE.EMAIL) &&
                <Pagination needRefresh centerDivs itemsPerPage={itemsPerPage} currentPageIndex={currentPageIndex} setIndex={setCurrentPageIndex} pageCount={getPageCount()} setFilterObj={setFilterObj} />}
        
            {type !== MODAL_TYPE.SIGNATURE && type !== MODAL_TYPE.EMAIL && 
                <div className={`${styles['action-items']} ${styles['modal-footer']} ${getPosition()}`}>
                    <Button 
                        text={i18n('button.general.close')}
                        shade={BUTTON_SHADE.DARKBLUE} 
                        size={BUTTON_SIZE.NORMAL}
                        handleAction={closeModal} />
                    <Button 
                        text={i18n('button.general.save')}
                        shade={BUTTON_SHADE.LIGHTBLUE} 
                        size={BUTTON_SIZE.NORMAL}
                        handleAction={handleSaveClick} />
                </div>}
        </div>
    )
}

export default ModalContent