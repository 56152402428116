import { Grid } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react"
import { DeviceContext, SelectedWorksheetContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { Email } from "../../../models/Email"
import styles from './SendVoucherModal.module.scss'
import InputField, { LABEL, INPUT_TYPE, INPUT_HEIGHT, INPUT_ALIGN } from "../../elements/InputField"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../../elements/Button"
import { IIndexable } from "../../interfaces/IIndexible"
import { ICON_TYPE } from "../../../utils/global"
import { BACKEND_ROUTE, getRequest, kulcsUzletRequest, REQUEST_METHOD } from "../../../request"
import { store } from "../../../App"
import { showErrorMessages } from "../../../reducers/rootReducer"

type Props = {
    closeModal: () => void
}

enum RESULT {
    SUCCESS = 'Az email küldése sikeres volt!',
    ERROR = 'Az email küldése nem volt sikeres! Kérjük próbálja meg újra.'
}

const SendVoucherModal = ({ closeModal }: Props) => {
    const { isMobile } = useContext(DeviceContext)
    const { worksheet } = useContext(SelectedWorksheetContext)
    const [ email, setEmail ] = useState({} as Email)
    const [ needDefault, setNeedDefault ] = useState(false)
    const [ emailResult, setEmailResult ] = useState(null)

    const getDefaultEmail = async () => {
        await getRequest(BACKEND_ROUTE.DEFAULT_EMAIL, null)
        .then((res) => {
            if (res !== null || res[0] !== null) {
                const defaultEmail = res as unknown as any
                setEmail((prev: Email) => ({
                    ...prev,
                    Subject: defaultEmail?.Subject ?? '',
                    Content: defaultEmail?.Body ?? ''
                }))
            }
        })
        .catch((error) => store.dispatch(showErrorMessages([error])))
    }

    const getLastEmailParams = async () => {
        await getRequest(BACKEND_ROUTE.LAST_EMAIL_PARAMS, null)
        .then((res) => {
            if (res !== null || res[0] !== null) {
                const defaultEmail = res as unknown as any
                setEmail((prev: Email) => ({
                    ...prev,
                    Subject: defaultEmail?.Subject ?? '',
                    Content: defaultEmail?.Body ?? ''
                }))
            }
            else 
                getDefaultEmail()
        })
        .catch((error) => {
            store.dispatch(showErrorMessages([error]))
            getDefaultEmail()
        })
    }

    useEffect(() => {
        getLastEmailParams()
    }, [])

    useEffect(() => {
        if(needDefault) {
            getDefaultEmail()
            setNeedDefault(false)
        }
    }, [needDefault])

    const handleChange = (e: any) => {
        setEmail(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleChangeBoolean = (e: any) => {
        setEmail(prev => ({
            ...prev,
            [e.target.name]: !(prev as IIndexable)[e.target.name]
        }))
    }

    const resetToDefaultSettings = () => {
        setNeedDefault(true)
    }

    const handleSendByKulcsUzlet = async () => {
        email.WorksheetId = worksheet.Id
        await kulcsUzletRequest(BACKEND_ROUTE.SEND_BY_KULCSUZLET, JSON.stringify(email), REQUEST_METHOD.POST).then( async (res) => {
            if(res !== null || res[0] !== null)
                setEmailResult(RESULT.SUCCESS as any)
        })
        .catch(e => setEmailResult(RESULT.ERROR as any))
    }

    const handleSendByGmail = async () => {
        var data = {
            Subject: email.Subject,
            Body: email.Content,
            WorksheetId: worksheet.Id
        }
        await kulcsUzletRequest(BACKEND_ROUTE.REMOTEPRINT_PREVIEW_URL, JSON.stringify(data), REQUEST_METHOD.POST).then( async (res) => {
            if(res !== null || res[0] !== null) {
                let body = (res as any).Body as string
                body += '\r\n\r\n'
                body += `Munkalap megtekintése: ${(res as any).PreviewUrl}`
                var gmailUrlBase = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1'
                var url = gmailUrlBase + '&to=' + email.EmailAddress + '&su=' + encodeURIComponent((res as any).Subject) + '&body=' + encodeURIComponent(body)
                setEmailResult(RESULT.SUCCESS as any)
                window.open(url, '_blank')
            }
        })
        .catch(e => {
            store.dispatch(showErrorMessages([e]))
            setEmailResult(RESULT.ERROR as any)})
    }

    return (
        emailResult !== null ? <Fragment>
        <div>{emailResult}</div>
        <Grid container justifyContent="center" className={styles['modal-footer']}>
            <Button 
                text={'Bezárás'} 
                shade={BUTTON_SHADE.DARKBLUE} 
                handleAction={closeModal} 
                size={BUTTON_SIZE.FLAT} />
            {emailResult !== RESULT.SUCCESS && <Button 
                text={'Vissza'} 
                shade={BUTTON_SHADE.LIGHTBLUE} 
                handleAction={() => setEmailResult(null)} 
                size={BUTTON_SIZE.FLAT} />}
        </Grid>
    </Fragment>
    : <Fragment>
        <Grid container spacing={0.5}>
            <Grid item xs={12}>
                <InputField 
                    name='EmailAddress'
                    labelText={i18n('modal.listType.email.fields.emailAddress')}
                    placeholder={i18n('modal.listType.email.placeHolders.emailAddress')}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXT} 
                    barWidth={isMobile ? 12 : 9} 
                    labelWidth={isMobile ? 12 : 3} 
                    value={email.EmailAddress ?? ''}
                    changed={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <InputField 
                    name='Subject'
                    labelText={i18n('modal.listType.email.fields.subject')}
                    placeholder={i18n('modal.listType.email.placeHolders.subject')}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXT} 
                    barWidth={isMobile ? 12 : 9} 
                    labelWidth={isMobile ? 12 : 3} 
                    value={email.Subject ?? ''}
                    changed={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <InputField 
                    name='Content'
                    labelText={i18n('modal.listType.email.fields.content')}
                    placeholder='Kedves [Partner neve],&#10;Önnek bizonylata érkezett [Cegnev] partnerétől!&#x0a;Esetlegesen felmerülő kérdéseivel forduljon bizalommal hozzám:&#10;Pelda Petra&#10;teszt@teszt.hu'
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXTAREA} 
                    barWidth={isMobile ? 12 : 9} 
                    labelWidth={isMobile ? 12 : 3} 
                    barHeight={isMobile ? INPUT_HEIGHT.DEFAULT : INPUT_HEIGHT.FULL_SIZE}
                    value={email.Content ?? ''}
                    changed={handleChange}
                />
            </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
            <Button 
                text={i18n('button.emailModal.defaultSetting')} 
                shade={BUTTON_SHADE.DARKBLUE} 
                handleAction={resetToDefaultSettings} 
                size={BUTTON_SIZE.FLAT} />
        </Grid>
        <div className={styles[isMobile ? 'field-info-mobile' : 'field-info']}>{i18n('modal.listType.email.fieldInfo')}</div>
        <Grid container spacing={0} sx={{paddingBottom: isMobile ? 0 : '15px'}}>
            {/* <Grid item xs={4}>
                <InputField 
                    name='DownloadPDF'
                    labelText={i18n('modal.listType.email.downloadPDF')}
                    labelPlace={LABEL.DEFAULT}
                    barAlignment={INPUT_ALIGN.MIDDLE}
                    labelAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                    type={INPUT_TYPE.CHECKBOX} 
                    barWidth={12} 
                    labelWidth={12}  
                    value={email.DownloadPDF ?? false}
                    changed={handleChangeBoolean}
                />
            </Grid> */}
            <Grid item xs={4}>
                <InputField 
                    name='NeedCopy'
                    labelText={i18n('modal.listType.email.needCopy')}
                    labelPlace={LABEL.DEFAULT}
                    barAlignment={INPUT_ALIGN.MIDDLE}
                    labelAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                    type={INPUT_TYPE.CHECKBOX} 
                    barWidth={12} 
                    labelWidth={12}  
                    value={email.NeedCopy ?? false}
                    changed={handleChangeBoolean}
                />
            </Grid>
            {/* <Grid item xs={4}>
                <InputField 
                    name='CustomerSignature'
                    labelText={i18n('modal.listType.email.customerSignature')}
                    labelPlace={LABEL.DEFAULT}
                    labelAlignment={INPUT_ALIGN.ALMOST_MIDDLE}
                    barAlignment={INPUT_ALIGN.MIDDLE}
                    type={INPUT_TYPE.CHECKBOX} 
                    barWidth={12} 
                    labelWidth={12} 
                    value={email.CustomerSignature ?? false}
                    changed={handleChangeBoolean}
                />
            </Grid> */}
        </Grid>
        <Grid container justifyContent="center" className={styles['modal-footer']}>
            <Button 
                text={i18n('button.general.close')} 
                shade={BUTTON_SHADE.DARKBLUE} 
                handleAction={closeModal} 
                size={BUTTON_SIZE.FLAT} />
            <Button 
                text={i18n('button.emailModal.gmail')} 
                shade={BUTTON_SHADE.DARKRED} 
                handleAction={handleSendByGmail} 
                icon={ICON_TYPE.GMAIL}
                size={isMobile ? BUTTON_SIZE.WITH_ICON_SMALLER : BUTTON_SIZE.WITH_ICON_SMALL} />
            <Button 
                text={i18n('button.emailModal.withKulcsUzlet')} 
                shade={BUTTON_SHADE.LIGHTBLUE} 
                handleAction={handleSendByKulcsUzlet} 
                size={BUTTON_SIZE.FLAT} />
        </Grid>
    </Fragment>)
}

export default SendVoucherModal