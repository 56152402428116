import i18n from "../../i18n"
import { Customer } from "../../models/Customer"
import { ICON_TYPE } from "../../utils/global"
import styles from '../../pages/WorksheetPage.module.scss'
import { isObjectUnavailable } from "../../utils/HelperFunctions"
import { Fragment, useContext } from "react"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../elements/Button"
import { Separator } from "../elements/Separator"
import { MODAL_TYPE } from "../modal/BaseModal"
import { Worksheet } from "../../models/Worksheet"
import { DeviceContext, SelectedWorksheetContext, EditItemContext } from "../../context/Contexts"
import { Contact } from "../../models/ContactInfo"
import CustomerFields from "./CustomerFields"
import { Tool } from "../../models/Tool"

export interface Props {
    openModal: (type: MODAL_TYPE) => void
    showFromToolModal: boolean
}

export const WorksheetCustomer = ({ openModal, showFromToolModal }: Props) => {
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    
    const deleteCustomer = () => {
        if(showFromToolModal) 
            setEditItem((prevState: Tool) => ({
                ...prevState,
                Customer: {} as Customer,
                CustomerContact: {} as Contact
            }))
        else 
            setWorksheet((prevState: Worksheet) => ({
                ...prevState,
                Customer: {} as Customer,
                Contact: {} as Contact
            }))
    }

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null
        

    const ActionButtons = 
        <div className={isMobile ? styles['content-normal'] : styles['content-half']}>
            <div className={showFromToolModal ? styles['input-container-customer'] : ''}>
                <Button 
                    isDisabled={hasCustomerSigned()}
                    text={i18n('button.worksheetPage.chooseCustomer')}
                    shade={BUTTON_SHADE.LIGHTBLUE} 
                    size={BUTTON_SIZE.WITH_ICON}
                    handleAction={() => openModal && openModal(MODAL_TYPE.CUSTOMER)}
                    icon={ICON_TYPE.ADD} />
            </div>
            <div className={showFromToolModal ? styles['input-container-customer'] : ''}>
                <Button 
                    isDisabled={hasCustomerSigned()}
                    text={i18n('button.worksheetPage.deleteCustomer')}
                    shade={BUTTON_SHADE.LIGHTRED} 
                    size={BUTTON_SIZE.WITH_ICON}
                    handleAction={() => deleteCustomer()}
                    icon={ICON_TYPE.DELETE} />
            </div>
        </div>

    return (
        <Fragment>
            <div className={showFromToolModal ? '' : styles['item']}>
            {/* Megrendelő adatok* */}
            {!showFromToolModal ? 
                <Fragment>
                    <div className={styles['item-header']}>
                        <div className={styles['item-header-text']}>{i18n('worksheet.customer.header')}</div>
                    </div>
                    <Separator />
                </Fragment> : ''
            }
                
                { (showFromToolModal && isObjectUnavailable((editItem as Tool).Customer)) || (!showFromToolModal && isObjectUnavailable(worksheet.Customer)) ?
                    // Initial state - no customer
                    <Button 
                        text={i18n('button.worksheetPage.chooseCustomer')}
                        shade={BUTTON_SHADE.LIGHTBLUE}
                        size={BUTTON_SIZE.WITH_ICON}
                        handleAction={() => {
                            openModal(MODAL_TYPE.CUSTOMER)}
                        }
                        icon={ICON_TYPE.ADD} /> 
                    // with customer
                    : <Fragment>
                        {showFromToolModal ? <div className={styles[isMobile ? 'tab-container-tool-mobile' : 'tab-container-tool']}>
                            <CustomerFields showFromWorksheet={!showFromToolModal} actionItems={ActionButtons}/>
                        </div> : <CustomerFields showFromWorksheet={!showFromToolModal} actionItems={ActionButtons}/>}
                        
                      </Fragment> }
                </div>
            
        </Fragment>
    )
}