import { ErrorModalModel } from "../models/ErrorModalModel";

const initState : ErrorModalModel = {
    errors: [],
    isShown: false
}

export enum ErrorReducerAction {
    CLOSE_MODAL,
    SHOW_ERRORS
}

type Action<P> = {
    type: ErrorReducerAction,
    payload: P
}

type ActionCreator<P> = (p: P) => Action<P>;

export const closeErrorModal: ActionCreator<boolean> = payload => ({
    type: ErrorReducerAction.CLOSE_MODAL,
    payload
})

export const showErrorMessages: ActionCreator<string[]> = payload => ({
    type: ErrorReducerAction.SHOW_ERRORS,
    payload
})

const rootReducer = (state: ErrorModalModel = initState, action: any) => {
    switch(action.type){
        case ErrorReducerAction.CLOSE_MODAL:
            return {
                errors: [],
                isShown: false
            }
        case ErrorReducerAction.SHOW_ERRORS:
            return {
                errors: action.payload,
                isShown: true
            }
    }
    return state;
}

export default rootReducer;