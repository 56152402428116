import { Fragment, useContext, useEffect, useState } from "react"
import { store } from "../../../App"
import { EditItemIdContext, SettingsContext, EditItemContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { Tool } from "../../../models/Tool"
import { ToolData } from "../../../models/ToolData"
import { showErrorMessages } from "../../../reducers/rootReducer"
import { BACKEND_ROUTE, getRequest } from "../../../request"
import { toolModalTabs } from "../../../utils/global"
import LoadingAnimation from "../../elements/LoadingAnimation"
import { Separator } from "../../elements/Separator"
import { WorksheetCustomer } from "../../worksheet/WorksheetCustomer"
import { MODAL_TYPE } from "../BaseModal"
import CommentTab from "./CommentTab"
import ServiceHistoryTab from "./ServiceHistoryTab"
import ToolDataTab from "./ToolDataTab"
import styles from './ToolModal.module.scss'

const enum TAB {
    TOOLDATA = 'toolData',
    CUSTOMERDATA = 'customerData',
    COMMENT = 'comment',
    SERVICEHISTORY = 'serviceHistory'
}

type Props = {
    id?: number
    isShown: boolean
    openModal: (type: MODAL_TYPE) => void
    openModalOnTop?: (type: MODAL_TYPE) => void
}


//TODO: edit or new
const ToolModal = ({ id, isShown, openModal, openModalOnTop }: Props) => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const [ activeIndex, setActiveIndex ] = useState(0)
    const [ activeTabName, setActiveTabName ] = useState(toolModalTabs[0])
    const { editItemId } = useContext(EditItemIdContext)
    const { settings, setSettings } = useContext(SettingsContext)
    const [ isLoading, setIsLoading ] = useState(true)

    const openTab = (tab: string, index: number) => {
        setActiveIndex(index)
        setActiveTabName(tab)
    }

    const getToolById = async () => {
        await getRequest(BACKEND_ROUTE.TOOL, null, '/'+ editItemId).then(async function(res) { 
            if (res !== null || res[0] !== null) {
                let toolItem = res as unknown as Tool;
                toolItem.Contact = toolItem.ContactObj ?  JSON.parse(toolItem.ContactObj) : {};
                setEditItem(toolItem)
            } 
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
        })
    }

    const getSettings = async () => {
        if(isShown) {
            await getRequest(BACKEND_ROUTE.SETTINGS, null)
            .then((res) => setSettings(res))
            .catch((e) => store.dispatch(showErrorMessages([e])))
            if(editItemId === -1 && settings?.ToolDataNames?.length > 0) {
                let list = (editItem as Tool)?.ToolData ?? []
                for(const setting of settings.ToolDataNames) {
                    list.push({
                        Name: setting.Name,
                        Data: ''
                    } as ToolData)
                }
                setEditItem((prevstate: Tool) => ({    
                    ...prevstate,
                    ToolData: list
                }))
            }
        }  
    }

    const getEditableItem = async () => {
        if(editItemId > -1) 
            await getToolById()
        else if (!id) 
            await setEditItem({} as Tool)
    }

    const showLoadingScreenInitially = async () => await setIsLoading(true)

    useEffect(() => {
        showLoadingScreenInitially().then(() => getSettings().then(() => setIsLoading(false)))
        getEditableItem()
        openTab(toolModalTabs[0], 0)
    }, [isShown])

    const getActiveTab = () => {
        switch(activeTabName) {
            case TAB.TOOLDATA:
                return isLoading ? <LoadingAnimation /> : <ToolDataTab />
            case TAB.CUSTOMERDATA:
                return <WorksheetCustomer openModal={openModalOnTop ? openModalOnTop : openModal} showFromToolModal={true} />
            case TAB.COMMENT:
                return <CommentTab />
            case TAB.SERVICEHISTORY:
                return <ServiceHistoryTab />
        }
    }

    return (
        <Fragment>
            <div className={styles['tab-header-container']}>
                {toolModalTabs.map((tab, index) => (
                    <div className={`${styles['tab-header']} ${activeIndex === index ? styles['active'] : ''}`} key={index} onClick={() => openTab(tab, index)}>
                        {i18n(`modal.listType.tool.tabs.${tab}`)}
                    </div>
                ))}
            </div>
            <Separator fancy={true} activeIndex={activeIndex} tabs={toolModalTabs.length} />
            {getActiveTab()}
        </Fragment>
    )
}

export default ToolModal