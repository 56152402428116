import React, { Fragment, useContext, useEffect, useState } from 'react'
import ModalContent from './ModalContent'
import styles from './BaseModal.module.scss'
import i18n from '../../i18n'
import { Product } from '../../models/Product'
import { Customer } from '../../models/Customer'
import closeIcon from '../../images/close_icon.png'
import { BACKEND_ROUTE, getRequest } from '../../request'
import { FiltersObject } from '../../models/Filter'
import { Separator } from '../elements/Separator'
import { EditItemIdContext, DeviceContext, SelectedWorksheetContext, EditItemContext, NeedRefreshContext } from '../../context/Contexts'
import { Category } from '../../models/Category'
import { loadCategoryList, showLoadingScreen } from '../../utils/HelperFunctions'
import WarningMessage from '../elements/WarningMessage'
import { Tool } from '../../models/Tool'
import LoadingAnimation from '../elements/LoadingAnimation'
import _ from 'lodash'
import { fixEmptyFilters } from '../../utils/FilterHandler'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../elements/Button'
import { Grid } from '@mui/material'
import { ICON_TYPE } from '../../utils/global'
import { store } from '../../App'
import { showErrorMessages } from '../../reducers/rootReducer'
 
export interface ModalProps {
	isShown: boolean
    setIsShown: React.Dispatch<React.SetStateAction<boolean>>
    setSideMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>
    type: MODAL_TYPE,
    openModal: (type: MODAL_TYPE) => void
    overrideClose?: () => void
    openSecondModal?: (type: MODAL_TYPE) => void
    openThirdModal?: (type: MODAL_TYPE) => void
}

export enum MODAL_TYPE {
    CUSTOMER = 'customer',
    NEWCUSTOMER = 'customer.new',
    EDITCUSTOMER = 'customer.edit',
    PRODUCT = 'product',
    NEWPRODUCT = 'product.new',
    EDITPRODUCT = 'product.edit',
    NEWTOOL = 'tool',
    EDITTOOL = 'edit-tool',
    TOOLS = 'tools',
    SIGNATURE = 'signature',
    EMAIL = 'email'
}

const defaultFilterObj = { 
        Skip: 0,
        Take: 10,
        Orders: [
        {
            FieldName: 'Name',
            OrderWay: 1,
            OrderType: 0
        }
        ],
        Filters: [
        [
            {
            FieldName: 'Deleted',
            FilterValue: 0,
            OperatorType: 10,
            FieldType: 0
            }
        ]
        ]
} as FiltersObject

const BaseModal = ({ isShown, setIsShown, type, openModal, overrideClose, openSecondModal, openThirdModal, setSideMenuOpen }: ModalProps) => {
    const [ selectedElement, setSelectedElement ] = useState<Customer | Product | Tool>({} as Product)
    const [ searchPhrase, setSearchPhrase ] = useState('')
    const [ selectedCategory, setSelectedCategory ] = useState('')
    const [ dataArray, setDataArray ] = useState<Customer[] | Product[]  | Tool[]>([])
    const [ dataSingle, setDataSingle ] = useState<Customer | Product  | Tool>({} as Tool)
    const [ warning, setWarning ] = useState(null)
    const { setWorksheet } = useContext(SelectedWorksheetContext)
    const [ categoryList, setCategoryList ] = useState([] as Category[])
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { setEditItemId } = useContext(EditItemIdContext)
    const [ isLoading, setIsLoading ] = useState(true)
    let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj)
    const [ filterObj, setFilterObj ] = useState(defaultFilterObjCopy)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const { needRefresh, setNeedRefresh } = useContext(NeedRefreshContext)
    const [ changableTitle, setChangableTitle ] = useState('')

    const getData = async () => {
        if(isShown) 
            switch(type) {
                case MODAL_TYPE.PRODUCT:
                    await fetchData(BACKEND_ROUTE.PRODUCT)
                    loadCategoryList(BACKEND_ROUTE.PRODUCTCATEGORY, setCategoryList);
                    break
                case MODAL_TYPE.CUSTOMER:
                    await fetchData(BACKEND_ROUTE.CUSTOMER)
                    loadCategoryList(BACKEND_ROUTE.CUSTOMERCATEGORY, setCategoryList);
                    break
                case MODAL_TYPE.TOOLS:
                    await fetchData(BACKEND_ROUTE.TOOL)
                    break
            }
    }

    const getModalBodySize = () => {
        let modalBodyClass = ''
        switch(type) {
            case MODAL_TYPE.PRODUCT:
            case MODAL_TYPE.CUSTOMER:
            case MODAL_TYPE.TOOLS:
                modalBodyClass = styles[isMobile ? 'modal-body-list-mobile' : 'modal-body-list']
                break
            case MODAL_TYPE.NEWCUSTOMER:
            case MODAL_TYPE.EDITCUSTOMER:
            case MODAL_TYPE.NEWPRODUCT:
            case MODAL_TYPE.EDITPRODUCT:
                modalBodyClass =  styles[isMobile ? 'modal-body-mobile' : isPortrait ? 'modal-body-customer-portrait' : 'modal-body-customer']
                break
            case MODAL_TYPE.NEWTOOL:
            case MODAL_TYPE.EDITTOOL:
                modalBodyClass =  styles[isMobile ? 'modal-body-mobile' : isPortrait ? 'modal-body-tool-portrait' : 'modal-body-tool']
                break
            case MODAL_TYPE.SIGNATURE:
                modalBodyClass =  styles[isMobile ? 'modal-body-sign-mobile' : isPortrait ? 'modal-body-sign-portrait' : 'modal-body-sign']
                break
            case MODAL_TYPE.EMAIL:
                modalBodyClass =  styles[isMobile ? 'modal-body-email-mobile' : isPortrait ? 'modal-body-email-portrait' : 'modal-body-email']
                break
        }
        return `${styles['modal-body-container']} ${modalBodyClass}`
    }

    useEffect(() => {
        if(!isShown) {
            return
        }
        var app = document.getElementById('app')
        var page = document.getElementById('page-margin')
        if(isShown && app && page) {
            document.body.style.overflow = 'hidden'
            app.style.overflow = 'hidden'
            page.style.overflow = 'hidden'
        }
        if(filterObj?.Filters[0]?.length === 0)
            fixEmptyFilters(setFilterObj)
        showLoadingScreen(true, setIsLoading)
        .then(async () => {
            await getData()
            showLoadingScreen(false, setIsLoading)
        })
    }, [isShown])

    useEffect(() => {
        if(needRefresh) {
            if(filterObj?.Filters[0]?.length === 0)
                fixEmptyFilters(setFilterObj)
            showLoadingScreen(true, setIsLoading)
            .then(async () => {
                await getData()
                await setNeedRefresh(false)
                await showLoadingScreen(false, setIsLoading)
            }).catch((e) => {
                store.dispatch(showErrorMessages([e]))
                showLoadingScreen(false, setIsLoading)
            })
        }
    }, [needRefresh])

    const fetchData = async (backendRoute: BACKEND_ROUTE) => {
        await getRequest(backendRoute, filterObj).then(async function(res) { 
            if (res !== null || res[0] !== null){
                await setDataArray(res)
            }
          }).catch((error) =>{
            setWarning(error) 
        })
    }

    const closeModal = () => {
        if(overrideClose !== undefined)
            overrideClose()
        else {
            setIsShown(false)
            setSideMenuOpen && setSideMenuOpen(false)
            setTimeout(() => {
                setEditItem({} as Tool)
                setSelectedElement({} as Customer)
                setEditItemId(-1)
                setSearchPhrase('')
                setSelectedCategory('')
                setWarning(null)
            setFilterObj(defaultFilterObjCopy)
                setChangableTitle('')
            }, 2000); 
        }
    }

    const SimpleListModal = () => {
        return (<Fragment>
            <Separator />
            {type === MODAL_TYPE.TOOLS && openSecondModal && 
            <Grid container spacing={1}>
                <Grid item xs={12} style={{marginTop: '5px', marginBottom: '5px'}}>
                    <Button text={'Új eszköz hozzáadása'} 
                        shade={BUTTON_SHADE.LIGHTBLUE}  
                        handleAction={() => openSecondModal(MODAL_TYPE.NEWTOOL)} 
                        icon={ICON_TYPE.OTHER}
                        isSpacious={false}
                        size={BUTTON_SIZE.WITH_ICON_LARGE} />
                </Grid>
            </Grid>}
            {type === MODAL_TYPE.CUSTOMER && openSecondModal && 
            <Grid container spacing={1}>
                <Grid item xs={12} style={{marginTop: '5px', marginBottom: '5px'}}>
                    <Button text={'Új partner hozzáadása'} 
                        shade={BUTTON_SHADE.LIGHTBLUE}  
                        handleAction={() => openSecondModal(MODAL_TYPE.NEWCUSTOMER)} 
                        icon={ICON_TYPE.OTHER}
                        size={BUTTON_SIZE.WITH_ICON_LARGE} />
                </Grid>
            </Grid>}
            {type === MODAL_TYPE.PRODUCT && openSecondModal && 
            <Grid container spacing={1}>
                <Grid item xs={12} style={{marginTop: '5px', marginBottom: '5px'}}>
                    <Button text={'Új termék hozzáadása'} 
                        shade={BUTTON_SHADE.LIGHTBLUE}  
                        handleAction={() => openSecondModal(MODAL_TYPE.NEWPRODUCT)} 
                        icon={ICON_TYPE.OTHER}
                        size={BUTTON_SIZE.WITH_ICON_LARGE} />
                </Grid>
            </Grid>}
            <ModalContent shownFromToolModal={overrideClose ? true : false} 
                closeModal={closeModal}
                content={dataArray} 
                filterObj={filterObj} 
                setFilterObj={setFilterObj} 
                selectedElement={selectedElement} setSelectedElement={setSelectedElement} 
                type={type} setWorksheet={setWorksheet} openModal={openSecondModal ? openSecondModal : openModal}
                setChangableTitle={setChangableTitle}   />
        </Fragment>)
    }

    const getModal = () => {
        switch(type) {
            case(MODAL_TYPE.EDITTOOL):
                return (<ModalContent closeModal={closeModal} content={dataSingle} 
                    filterObj={filterObj} 
                    openModalOnTop={openThirdModal} 
                    setFilterObj={setFilterObj} 
                    selectedElement={selectedElement} setSelectedElement={setSelectedElement} 
                    type={type} setWorksheet={setWorksheet} openModal={openModal} isShown={isShown} />)
            case(MODAL_TYPE.NEWTOOL):
                return (<ModalContent closeModal={closeModal} content={dataArray}
                    filterObj={filterObj} 
                    openModalOnTop={openThirdModal} 
                    setFilterObj={setFilterObj} 
                    selectedElement={selectedElement} setSelectedElement={setSelectedElement}
                    type={type} setWorksheet={setWorksheet} openModal={openModal} isShown={isShown} />)
            default:
                return SimpleListModal()
        }
    }

    return (
        <Fragment>
            <div className={styles.modal + ' ' + (isShown ? styles.open : '')}>
                    <div className={styles["modal-overlay"]} onClick={closeModal} ></div>
                    <div className={styles["modal-card"]}>
                        <div className={getModalBodySize()}>
                            <Grid container spacing={0} width='100%'>
                                <Grid item width={'calc(100% - 40px)'} >
                                    <div className={styles['header-text']}>{changableTitle !== '' ? changableTitle : i18n(`modal.listType.${type}.title`)}</div>
                                </Grid>
                                <Grid item xs={'auto'} justifyContent="flex-end">
                                    <img className={styles['headerIcon']} src={closeIcon} alt='' onClick={closeModal}/>
                                </Grid>
                            </Grid>
                            { isLoading ? <LoadingAnimation /> : warning !== null ? <WarningMessage text={warning} isException={true} /> : getModal()}
                        </div>
                    </div>
                </div>
		</Fragment>
    )
}

export default BaseModal