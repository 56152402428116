import useLongPress from './LongPress';
import { ReactComponent as FilterIcon } from '../images/svg/filter-icon-mobile.svg'
import styles from './MobileFilterIcon.module.scss'
import { useEffect } from 'react';

type Props = {
  openFilters: () => void
  longPressBackspaceCallback: (() => void) | undefined
  clicked: boolean
  editFilters: boolean
}

export function MobileFilterIcon ({ clicked, openFilters, editFilters, longPressBackspaceCallback }: Props) {
  const backspaceLongPress = useLongPress(longPressBackspaceCallback, 0)

  useEffect(() => {
    document.addEventListener('contextmenu', event => event.preventDefault());
  }, [])

  return (
    !editFilters ? <FilterIcon 
      className={`${styles['original']} ${clicked ? styles['moved'] : styles['not-first-render']}`}
      onClick={openFilters}
      // {...backspaceLongPress} 
      /> : <></>
  )
}