import React, { Fragment, useContext, useEffect, useState } from 'react'
import i18n from '../i18n'
import BaseModal, { MODAL_TYPE } from '../components/modal/BaseModal'
import { useHistory, useParams } from 'react-router-dom'
import { PageHeader } from '../components/layout/PageHeader'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../components/elements/Button'
import { DeviceContext, SelectedWorksheetContext, SettingsContext } from '../context/Contexts'
import { defaultWorksheet, INTERNAL_ROUTE } from '../utils/global'
import { BACKEND_ROUTE, getRequest, kulcsUzletRequest, postRequest, REQUEST_METHOD } from '../request'
import { Worksheet } from '../models/Worksheet';
import { base64toBlob, getWorksheetTitles, isObjectUnavailable } from '../utils/HelperFunctions';
import ExpandableBar, { EXPANDABLE_TYPE, EXPANDABLE_SIZE } from '../components/elements/expandables/ExpandableBar'
import { EXPANDABLE_CHILD_TYPE } from '../components/elements/expandables/ExpandableComplexChildren'
import { WorksheetDetails } from '../components/worksheet/WorksheetDetails'
import { WorksheetProducts } from '../components/worksheet/WorksheetProducts'
import WarningMessage from '../components/elements/WarningMessage'
import { Tool } from '../models/Tool'
import { WorksheetCustomer } from '../components/worksheet/WorksheetCustomer'
import LoadingAnimation from '../components/elements/LoadingAnimation'
import styles from './WorksheetPage.module.scss'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid';
import { ExternalWork } from '../models/ExternalWork'
import { TimeSpent } from '../models/TimeSpent'
import { WorksheetItem } from '../models/WorksheetItem'
import { store } from '../App'
import { showErrorMessages } from '../reducers/rootReducer'

type WorksheetByIdParams = {
    id: number
}

const WorksheetPage = () => {
    const history = useHistory()
    const params = useParams()
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { settings, setSettings } = useContext(SettingsContext)
    const [ warning, setWarning ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(true)
    const { isMobile } = useContext(DeviceContext)

    const [ modalType, setModalType ] = useState(MODAL_TYPE.PRODUCT)
    const [ secondModalType, setSecondModalType ] = useState(MODAL_TYPE.NEWTOOL)
    const [ thirdModalType, setThirdModalType ] = useState(MODAL_TYPE.CUSTOMER)
    const [ isShownFirstModal, setIsShownFirstModal ] = useState(false)
    const [ isShownSecondModal, setIsShownSecondModal ] = useState(false)
    const [ isShownThirdModal, setIsShownThirdModal ] = useState(false)

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null
        
    const openFirstModal = ( contentType: MODAL_TYPE ) => {
        setModalType(contentType)
        setIsShownFirstModal(true)
    }

    const openSecondModal = async (contentType: MODAL_TYPE) => {
        setSecondModalType(contentType)
        setIsShownSecondModal(true)
    }

    const openThirdModal = async (contentType: MODAL_TYPE) => { 
        setThirdModalType(contentType)
        setIsShownThirdModal(true)
    }

    const closetThirdModal = async () => {
        setIsShownThirdModal(false)}

    const getDetailsState = () => worksheet?.PlaceOfWork?.CountryId !== undefined || 
        worksheet?.PlaceOfWork?.Zip !== undefined || 
        worksheet?.PlaceOfWork?.City !== undefined || 
        worksheet?.PlaceOfWork?.Address !== undefined

    const handleSave = () => {
        setData()
        worksheet.ExternalWorkListObj = JSON.stringify(worksheet.ExternalWorkList);
        worksheet.TimeSpentListObj = JSON.stringify(worksheet.TimeSpentList);
        let request_method = worksheet.Id && worksheet.Id > -1 ? REQUEST_METHOD.PATCH : REQUEST_METHOD.POST
        kulcsUzletRequest(BACKEND_ROUTE.WORKSHEET, JSON.stringify(worksheet), request_method).then(async function(res) {
            if (res !== null || res[0] !== null) {
                history.push('/')
                setWorksheet(defaultWorksheet)
            } 
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
        })
    }

    const handleShowPreview = () => {
        setData()
        worksheet.CustomerObj = JSON.stringify(worksheet.Customer); 
        postRequest(BACKEND_ROUTE.VOUCHERIMAGE, JSON.stringify(worksheet)).then(async function(res) {
            if (res != null || res[0] != null) {
                const blob = base64toBlob(res as unknown as string)
                const url = URL.createObjectURL(blob)
                window.open(url)
            } 
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
        })
    }

    const setData = () => {
        if(worksheet.ExternalWorkList?.length > 0 && worksheet.ExternalWorkList[0].Address === undefined) 
            worksheet.ExternalWorkList.shift()
        if(worksheet.TimeSpentList?.length > 0 && worksheet.TimeSpentList[0].Label === undefined) 
            worksheet.TimeSpentList.shift()

        if(worksheet.Customer)
            worksheet.CustomerId = worksheet.Customer.Id
        if(isObjectUnavailable(worksheet.Tool))
            worksheet.ToolId = 0
        if(worksheet.SubtypeId === undefined && settings?.SubTypes[0]?.Id) 
            worksheet.SubtypeId = settings?.SubTypes[0]?.Id 
        if(worksheet.TypeId === undefined && settings?.Types[0]?.Id) 
            worksheet.TypeId = settings?.Types[0]?.Id
        if(worksheet.StatusId === undefined && settings?.Statuses[0]?.Id) 
            worksheet.StatusId = settings?.Statuses[0]?.Id
        worksheet.ContactObj = JSON.stringify(worksheet.Contact)
        return worksheet
    }

    const fetchTitles = async () => {
        if(worksheet === defaultWorksheet)
            getWorksheetTitles(setWorksheet)
    }
    const getWorksheetById = async (id : number) => {
        await getRequest(BACKEND_ROUTE.WORKSHEET, null, '/'+ id).then(async function(res) { 
            if (res !== null || res[0] !== null) {
                let ws = res as unknown as Worksheet
                ws.Customer = ws.CustomerObj ? JSON.parse(ws.CustomerObj) : {}
                ws.Contact = ws.ContactObj ? JSON.parse(ws.ContactObj) : {}
                
                if(window.location.pathname.includes('new')) {
                    const { Id, RowVersion, VoucherNumber, PrintedDateTime, InvoicedDateTime,
                         IssuerSignedDateTime, CustomerSignedDateTime, VoidedDateTime, SentDateTime, ...rest } = ws
                    rest.FulfillmentDate = new Date()
                    rest.VoucherDate = new Date()
                    let newTsList = [] as TimeSpent[]
                    for (const ts of rest.TimeSpentList) {
                        let newTs = {
                            Id: uuidv4() as any,
                            Label: ts.Label,
                            Duration: ts.Duration,
                            WorkerId: ts.WorkerId,
                            QuantityUnitId: ts.QuantityUnitId,
                            RelationId: ts.RelationId,
                            IsShownAsWorksheetItem: false,
                            Comment: ts.Comment,
                            Date: new Date()
                        } as TimeSpent
                        newTsList.push(newTs)
                    }
                    let newEwList = [] as ExternalWork[]
                    for (const ew of rest.ExternalWorkList) {
                        let newEw = {
                            Id: uuidv4() as any,
                            LicensePlateNumberId: ew.LicensePlateNumberId,
                            DriverId: ew.DriverId,
                            Address: ew.Address,
                            Distance: ew.Distance,
                            Date: new Date(),
                            RelationId: ew.RelationId,
                            IsShownAsWorksheetItem: false
                        } as ExternalWork
                        newEwList.push(newEw)
                    }
                    let newItemList = [] as WorksheetItem[]
                    for (const i of rest.Items) {
                        if(i.RelationType === 'PRODUCT') {
                           let newItem = {
                                Name: i.Name,
                                Quantity: i.Quantity,
                                QuantityUnitId: i.QuantityUnitId,
                                UnitPrice: i.UnitPrice,
                                VatId: i.VatId,
                                ProductId: i.ProductId,
                                RelationId: i.RelationId,
                                RelationType: i.RelationType,
                                Comment: i.Comment,
                                Vat: i.Vat
                            } as WorksheetItem
                            newItemList.push(newItem) 
                        }
                    }
                    // const updatedTs = rest.TimeSpentList.map(({ Id = uuidv4(), WorksheetId = undefined, WorksheetNumber = undefined, Date = undefined, RowVersion = undefined, ...rest3 }) => rest3)
                    // const updatedEw = rest.ExternalWorkList.map(({ Id = uuidv4(), WorksheetId = undefined, WorksheetNumber = undefined, Date = undefined, RowVersion = undefined, ...rest4 }) => rest4)
                    // rest.Items = rest.Items.map(({ Id = undefined, WorksheetId = undefined, ...rest2 }) => rest2)
                    rest.Items = newItemList
                    rest.TimeSpentList = newTsList
                    rest.ExternalWorkList = newEwList

                    setWorksheet(rest)
                }
                else setWorksheet(ws)
            } 
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
        })
    }
    
    const getSettings = async () => {
        await getRequest(BACKEND_ROUTE.SETTINGS, null)
                .then(async (res) => await setSettings(res))
                .catch((e) => store.dispatch(showErrorMessages([e]))) 
    }

    const setDefaultSettings = async () => {
        await getSettings().then(async () => {
            await setWorksheet((prevState: Worksheet) => ({ 
                ...prevState, 
                SubtypeId: settings?.SubTypes[0]?.Id,
                TypeId: settings?.Types[0]?.Id,
                StatusId: settings?.Statuses[0]?.Id,
                Tool: {} as Tool,

            }))  }
        )
    }

    const getData = async () => {
        await fetchTitles()
        await getSettings()

        if(params && Object.keys(params).length !== 0){
            let wsParams = params as WorksheetByIdParams
            await getWorksheetById(wsParams.id)
        }
        else if(!isObjectUnavailable(worksheet)) {
            let defaultWsCopy = _.cloneDeep(defaultWorksheet)
            await setWorksheet(defaultWsCopy)
            await setDefaultSettings()
        }
    }

    useEffect(() => {
        getData().then(() => setIsLoading(false))
    }, [])

    const handleBackClick = () => {
        let defaultWsCopy = _.cloneDeep(defaultWorksheet)
        setWorksheet(defaultWsCopy)
        history.push(INTERNAL_ROUTE.WORKSHEET_LIST)
    }

    useEffect(() => {
        const body = document.querySelector('body');
        if (body !== null) 
            body.style.overflow = isShownFirstModal ? 'hidden' : 'auto';
      }, [isShownFirstModal])

    return (
        <Fragment>
        <div className='page-margin'>
            {isMobile && 
                <PageHeader 
                    position='ws-page'
                    text={params && Object.keys(params).length !== 0 && !window.location.pathname.includes('new') ? i18n('pageHeaders.editWorksheet') : i18n('pageHeaders.newWorksheet')} 
                    sum={!isObjectUnavailable(worksheet) && !window.location.pathname.includes('new') ? worksheet.VoucherNumber : ''}
                    goBack={handleBackClick} />
            }
            {!isMobile && <div className={styles['header-items']}>
                <PageHeader 
                    text={params && Object.keys(params).length !== 0 && !window.location.pathname.includes('new') ? i18n('pageHeaders.editWorksheet') : i18n('pageHeaders.newWorksheet')} 
                    goBack={handleBackClick}/>
            </div>}
            
            <BaseModal 
                isShown={isShownFirstModal}
                setIsShown={setIsShownFirstModal}
                type={modalType}
                openModal={openFirstModal}
                openSecondModal={openSecondModal} />
            <BaseModal 
                isShown={isShownSecondModal}
                setIsShown={setIsShownSecondModal}
                type={secondModalType} 
                openModal={openSecondModal}
                openThirdModal={openThirdModal} />
            <BaseModal 
                overrideClose={closetThirdModal}
                isShown={isShownThirdModal}
                setIsShown={setIsShownThirdModal}
                type={MODAL_TYPE.CUSTOMER}
                openModal={openThirdModal} />

            {isLoading ? <LoadingAnimation /> : 
            warning !== null ? <WarningMessage text={warning} isException={true} /> :
            <Fragment>
                <WorksheetCustomer openModal={openFirstModal} showFromToolModal={false} />
                <WorksheetDetails hasMore={getDetailsState}/> 

                <ExpandableBar 
                    name='Tool'
                    title={i18n('worksheet.toolData.header')}
                    placeholder={i18n('worksheet.toolData.header')}
                    type={EXPANDABLE_TYPE.COMPLEX}
                    open={!isObjectUnavailable(worksheet?.Tool)}
                    childrenType={EXPANDABLE_CHILD_TYPE.TOOLS}
                    size={EXPANDABLE_SIZE.NORMAL}
                    editableTitle={false} 
                    openModalAction={openFirstModal} />
            
                <ExpandableBar 
                    name='ErrorDetail'
                    open={worksheet?.ShowErrorDetail}
                    title={i18n('worksheet.errorDescription.header')}
                    placeholder={i18n('worksheet.errorDescription.header')}
                    type={EXPANDABLE_TYPE.SIMPLE} 
                    size={EXPANDABLE_SIZE.NORMAL}
                    editableTitle={true} />
            
                <ExpandableBar 
                    name='FixDetail'
                    open={worksheet?.ShowFixDetail}
                    title={i18n('worksheet.repairDescription.header')}
                    placeholder={i18n('worksheet.repairDescription.header')}
                    type={EXPANDABLE_TYPE.SIMPLE}
                    size={EXPANDABLE_SIZE.NORMAL}
                    editableTitle={true} />

                <ExpandableBar 
                    name='TimeSpent'
                    open={worksheet?.TimeSpentList?.length > 0}
                    title={i18n('worksheet.timeSpent.header')}
                    type={EXPANDABLE_TYPE.COMPLEX}
                    childrenType={EXPANDABLE_CHILD_TYPE.TIMESPENT}
                    size={isMobile ? EXPANDABLE_SIZE.NORMAL : EXPANDABLE_SIZE.HALF}
                    editableTitle={false} />

                <ExpandableBar 
                    name='ExternalWork'
                    open={worksheet?.ExternalWorkList?.length > 0}
                    title={i18n('worksheet.externalWork.header')}
                    type={EXPANDABLE_TYPE.COMPLEX}
                    childrenType={EXPANDABLE_CHILD_TYPE.EXTERNALWORK}
                    size={isMobile ? EXPANDABLE_SIZE.NORMAL : EXPANDABLE_SIZE.HALF}
                    editableTitle={false} />
                
                <WorksheetProducts openModal={openFirstModal} /> 

                <ExpandableBar 
                    name='Comment'
                    open={worksheet?.ShowComment}
                    title={i18n('worksheet.comment.header')}
                    type={EXPANDABLE_TYPE.SIMPLE}
                    size={EXPANDABLE_SIZE.NORMAL}
                    editableTitle={false} />

            <div className={styles['action-items']} >
                <Button 
                    text={i18n('button.worksheetPage.preview')} 
                    shade={BUTTON_SHADE.DARKBLUE} 
                    handleAction={handleShowPreview} 
                    size={BUTTON_SIZE.NORMAL} />
                <Button 
                    isDisabled={hasCustomerSigned()}
                    text={i18n('button.general.save')} 
                    shade={BUTTON_SHADE.LIGHTBLUE} 
                    handleAction={handleSave} 
                    size={BUTTON_SIZE.NORMAL} />
            </div>
            </Fragment> }
        </div></Fragment>
    )
}

export default WorksheetPage