import React, { Fragment, useEffect, useState } from 'react'
import styles from './FilterOverlay.module.scss'
import { ReactComponent as FilterDeleteIcon } from '../../images/svg/filter-icon-delete.svg'
import { ReactComponent as FilterCloseIcon } from '../../images/svg/arrow-down.svg'
import { Separator } from '../elements/Separator'
import i18n from '../../i18n'
 
export interface ModalProps {
	isShown: boolean
    setIsShown: React.Dispatch<React.SetStateAction<boolean>>
    editFilters: boolean
    setEditFilters: React.Dispatch<React.SetStateAction<boolean>>
    deleteFilters: () => void
    children: JSX.Element
    hasFilters: boolean
}

const FilterOverlay = ({ isShown, setIsShown, editFilters, setEditFilters, deleteFilters, children, hasFilters }: ModalProps) => {

    const closeModal = () => {
        if(!editFilters)
            setIsShown(false)
        setEditFilters(false)
    }

    const closeEditFilters = () => setEditFilters(false)

    const deleteFiltersOverride = () => {
        deleteFilters()
        closeModal()
    }

    useEffect(() =>{
        setHidden()
    }, [isShown])

    const setHidden = () => {
        if (isShown) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "scroll";
        }
      };
    
    return (
        <Fragment>
            <div className={styles.modal + ' ' + (isShown ? styles.open : '')}>
                <div className={!editFilters ? styles["modal-overlay"] : styles["filter-overlay"]} onClick={closeModal} />
                <div className={styles['filter-description']} onClick={() => setEditFilters(true)}>{i18n('mobileFilters.edit')}</div>
                {hasFilters && <Fragment><FilterDeleteIcon className={styles['delete-icon']} onClick={deleteFiltersOverride} />
                <div className={styles['delete-description']} onClick={deleteFiltersOverride}>{i18n('mobileFilters.delete')}</div></Fragment>}
                <div className={`${styles['content']} ${editFilters ? styles.show : styles.hide}`}>
                    <div className={styles['filter-header']}>
                        <FilterCloseIcon onClick={closeEditFilters} className={styles['center-item']} />
                        <div className={styles['center-title']}>{i18n('mobileFilters.title')}</div>
                        <Separator />
                    </div>
                    <div className={styles['scrollable-content']}>
                        {children}
                    </div>
                </div>
            </div>
		</Fragment>
    )
}

export default FilterOverlay