import React, { Fragment, useContext, useEffect, useState } from 'react'
import i18n from '../../i18n'
import { useHistory } from 'react-router-dom'
import { Worksheet } from '../../models/Worksheet'
import { Button, BUTTON_SHADE, BUTTON_SIZE } from '../../components/elements/Button'
import { Separator } from '../../components/elements/Separator'
import { PageHeader } from '../../components/layout/PageHeader'
import { LIST_TYPE, List } from '../../components/lists/List'
import { INTERNAL_ROUTE, worksheetListHeaders, worksheetOrderTypes } from '../../utils/global'
import { BACKEND_ROUTE, fetchData, getRequest, kulcsUzletRequest, PDF_TYPE,  postRequest,  REQUEST_METHOD } from '../../request'
import ListFilters from '../../components/lists/ListFilters'
import styles from './ListPage.module.scss'
import WarningMessage from '../../components/elements/WarningMessage'
import LoadingAnimation from '../../components/elements/LoadingAnimation'
import { FiltersObject } from '../../models/Filter'
import SearchableSelect from '../../components/elements/searchables/SearchableSelect'
import { GetOptions, CATEGORY, SETTING_TYPE, FIELD_NAME, HandleFilterChange, FILTER_TYPE, fixEmptyFilters } from '../../utils/FilterHandler'
import { base64toBlob, desktopCheck, downloadPDF, numberWithSeparator, showLoadingScreen } from '../../utils/HelperFunctions'
import _ from 'lodash'
import * as XLSX from "xlsx"
import { ExportObject } from '../../models/ExportObject'
import SideMenu from '../../components/modal/SideMenu'
import { DeviceContext, SelectedWorksheetContext } from '../../context/Contexts'
import  {MobileFilterIcon}  from '../../utils/MobileFilterIcon'
import FilterOverlay from '../../components/modal/FilterOverlay'
import ConfirmationModal from '../../components/modal/ConfirmationModal'
import InputField, { LABEL, WIDTH, INPUT_BORDER, INPUT_TYPE, INPUT_HEIGHT } from '../../components/elements/InputField'
import { defaultFilterObj_WS, defaultPageFilter_WS, PageFilter_WS } from '../../utils/FilterDefaults'
import BaseModal, { MODAL_TYPE } from '../../components/modal/BaseModal'
import { error } from 'console'
import { store } from '../../App'
import { showErrorMessages } from '../../reducers/rootReducer'
import Grid from '@mui/material/Grid'
import { ISelectableOption } from '../../components/interfaces/ISelectableOption'

export enum GROUP_ACTION_WS {
    INVOICE = 'Tömeges számlázás',
    EXPORT = 'Munkalapok exportálása',
    EXPORT_WITH_ITEMS = 'Munkalap tételes export',
    GROUP_EXPORT = 'Tömeges PDF export'
}

export enum CHECKMARK {
    NONE = 'xmark',
    SUCCESS = 'checkmark',
    ERROR = 'x-mark'
}

const WorksheetListPage = () => {
    const history = useHistory()
    let defaultPageFilterCopy = _.cloneDeep(defaultPageFilter_WS)
    let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_WS)
    const [ pageFilter, setPageFilter ] = useState(defaultPageFilterCopy as PageFilter_WS)
    const [ filterObj, setFilterObj ] = useState(defaultFilterObjCopy as FiltersObject)
    const [ list, setList ] = useState([] as Worksheet[])
    const [ warning, setWarning ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(true)
    const [ useDefaultOrder, setUseDefaultOrder ] = useState(true)
    const [ shouldEmptyFilters, setShouldEmptyFilters ] = useState(false)
    const [ showExportColumn, setShowExportColumn ] = useState(true)
    const [ checkedIds, setCheckedIds ] = useState([] as number[])
    const [ isPaneOpen, setIsPaneOpen ] = useState(false)
    const { worksheet } = useContext(SelectedWorksheetContext)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const [ mobileFilterClicked, setMobileFilterClicked ] = useState(false)
    const [ isShownConfirmationModal, setIsShownConfirmationModal ] = useState(false)
    const [ isShownSignatureModal, setIsShownSignatureModal ] = useState(false)
    const [ isShownEmailModal, setIsShownEmailModal ] = useState(false)
    const [ modalText, setModalText ] = useState("")
    const [ modalCallback, setModalCallback ] = useState(() => {})
    const [ editFilters, setEditFilters ] = useState(false)
    const groupActions = [ GROUP_ACTION_WS.EXPORT,
        GROUP_ACTION_WS.EXPORT_WITH_ITEMS, GROUP_ACTION_WS.GROUP_EXPORT, GROUP_ACTION_WS.INVOICE] 
    const [ showGroupActionOptions, setShowGroupActionOptions ] = useState(false)
    const [ showCheckMark, setShowCheckMark ] = useState(CHECKMARK.NONE)

    const handleChangePageFilter = async (field: string, value: string) => {
        await setPageFilter((prevstate: PageFilter_WS) => ({
            ...prevstate,
            [field]: value
        }))
    }

    const getCustomers = async () => {
        await getRequest(BACKEND_ROUTE.SIMPLEDCUSTOMER, null).then( async (res) => {
            if(res !== null || res[0] !== null) {
                await setPageFilter((prevstate: PageFilter_WS) => ({
                    ...prevstate,
                    customerOptions: res
                }))
            }
        })
        .catch(e => store.dispatch(showErrorMessages([e])))
    }

    useEffect(() => {
        if(filterObj.Filters[0]?.length === 0)
            fixEmptyFilters(setFilterObj)
        showLoadingScreen(true, setIsLoading)
        .then(() => {
            if(pageFilter.customerOptions?.length < 1)
                getCustomers()
            fetchData(filterObj, BACKEND_ROUTE.WORKSHEET, setList, setWarning)
            .then(() => showLoadingScreen(false, setIsLoading))
        })
    }, [filterObj])

    const goToNewWorksheetPage = () => history.push(INTERNAL_ROUTE.NEW_WORKSHEET)

    const handleChangeDate = async (e: Date, type: string) => {
        const dateString = e.getFullYear() + '-' + ('0'+ (e.getMonth()+1)).slice(-2) + '-' + ('0'+ e.getDate()).slice(-2)
        
        if(type === 'StartDate') {
            await handleChangePageFilter('startDateFilter', dateString)
            HandleFilterChange(FILTER_TYPE.DATE, e, pageFilter.dateType, filterObj, setFilterObj, dateString, 4)
        }
        else {
            await handleChangePageFilter('closeDateFilter', dateString)
            HandleFilterChange(FILTER_TYPE.DATE, e, pageFilter.dateType, filterObj, setFilterObj, dateString, 2)
        }
    }

    const handleKeyPressed = async (key: string, name: string, value: string) => {
        if (key === 'Enter') {
            if(name === FIELD_NAME.GENERAL_SEARCH_FIELD)
                HandleFilterChange(FILTER_TYPE.INPUT, value, FIELD_NAME.GENERAL_SEARCH_FIELD, filterObj, setFilterObj, undefined, undefined, LIST_TYPE.WORKSHEET)
            else 
                HandleFilterChange(FILTER_TYPE.INPUT, value, name, filterObj, setFilterObj)
        }
    }

    const emptyFilters = async () => {
        let defaultPageFilterCopy = _.cloneDeep(defaultPageFilter_WS)
        await setPageFilter(defaultPageFilterCopy as PageFilter_WS)
        let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_WS)
        await setFilterObj(defaultFilterObjCopy as FiltersObject)
        await setUseDefaultOrder(true)
        await setShouldEmptyFilters(true)
    }

    const handleWorksheetExport = async () => {
        await kulcsUzletRequest(BACKEND_ROUTE.EXPORT_WORKSHEET, JSON.stringify(checkedIds), REQUEST_METHOD.POST, setIsLoading)
        .then(async function(res) {    
            if (res != null || res[0] != null) {
                const response = res as unknown as ExportObject;
                writeToXls(response)
                closeGroupAction()
            } 
            }).catch((error) => {
                setIsLoading(false)
                setShowCheckMark(CHECKMARK.ERROR)
                store.dispatch(showErrorMessages([error]))
        })
    }

    const handleWorksheetExportWithItems = async () => {
        await kulcsUzletRequest(BACKEND_ROUTE.EXPORT_WORKSHEET_WITH_ITEMS, JSON.stringify(checkedIds), REQUEST_METHOD.POST, setIsLoading)
            .then(async function(res) {
            if (res != null || res[0] != null) {
                const response = res as unknown as ExportObject
                writeToXls(response)
                closeGroupAction()
            } 
            }).catch((error) => {
                setShowCheckMark(CHECKMARK.ERROR)
                setIsLoading(false)
                store.dispatch(showErrorMessages([error]))
        })
    }

    const writeToXls = (response: ExportObject) => {
        const worksheet = XLSX.utils.json_to_sheet(response.ExportData)
                const workbook = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(workbook, worksheet, response.Filename)
                XLSX.utils.sheet_add_aoa(worksheet, [response.Headers], { origin: "A1" })
                XLSX.writeFile(workbook, response.Filename + "_" + Date.now() +".xlsx")
    }

    const exportWorksheetsPDF = async (ids: number[]) => {
        setIsLoading(true)
        await kulcsUzletRequest(BACKEND_ROUTE.EXPORT_WORKSHEETS, JSON.stringify(ids), REQUEST_METHOD.POST, setIsLoading)
            .then(async function(res) {
                if (res !== null) {
                    const dateToday = new Date()
                    const random5digitNumber = Math.floor(Math.random()*90000) + 10000
                    const dateString = dateToday.getFullYear() + '-' + ('0'+ (dateToday.getMonth()+1)).slice(-2) + '-' + ('0'+ dateToday.getDate()).slice(-2)
                    const pdfHint = `${dateString}_${random5digitNumber}`
                    downloadPDF(res as unknown as string, PDF_TYPE.WORKSHEETS, pdfHint)
                    const blob = base64toBlob(res as unknown as string)
                    const url = URL.createObjectURL(blob)
                    window.open(url)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                setShowCheckMark(CHECKMARK.ERROR)
                setIsLoading(false)
                store.dispatch(showErrorMessages([error]))
            }
        )
    }

    const generateVoucherImagesByIds = async () => {
        if(checkedIds?.length === 0) {
            setIsLoading(false)
            store.dispatch(showErrorMessages(['Legalább egy munkalapot jelöljön ki a letöltéshez!']))
            return
        }
        const chunkSize = 4;
        let lisst = checkedIds
        for (let i = 0; i < lisst.length; i += chunkSize) {
            const chunk = lisst.slice(i, i + chunkSize);
            await exportWorksheetsPDF(chunk)
        }
    }

    const createInvoiceFromWorksheets = async () => {
        await kulcsUzletRequest(BACKEND_ROUTE.CHECK_WORKSHEETS_TO_CREATE_INVOICES, JSON.stringify(checkedIds), REQUEST_METHOD.POST, setIsLoading)
            .then(async function(res) {
                if(res) {
                    var list = encodeURIComponent(JSON.stringify(checkedIds))
                    let url = process.env.REACT_APP_KULCS_UZLET_URL as string + 'worksheet/invoice/multiple/' + list
                    window.location.replace(url)
                    setShowCheckMark(CHECKMARK.SUCCESS)
                }
            })
            .catch((error) => {
                setShowCheckMark(CHECKMARK.ERROR)
                setIsLoading(false)
                store.dispatch(showErrorMessages([error]))
            }
        )
    }

    const closeSlidingPane = () => setIsPaneOpen(false)

    const handleDownloadPdf = () => {
        setIsPaneOpen(false)
        setIsLoading(true)
        let id = worksheet.Id;
        getRequest(BACKEND_ROUTE.VOUCHERIMAGE, null, '/' + id).then(async function(res) {
            if (res != null || res[0] != null) {
                downloadPDF(res as unknown as string, PDF_TYPE.WORKSHEET, worksheet.VoucherNumber)
                setIsLoading(false)
            } 
          }).catch((error) =>{
            setIsLoading(false)
            store.dispatch(showErrorMessages([error]))
        })
    }

    function printPage (url: string) {
        const windowSize = 'height=800,width=1000'
        var myWindow = window.open(url, "PRINT", windowSize) as any
            myWindow.onload = function() {
                myWindow.focus()
                myWindow.print()
            }
    }

    const openWorksheet = () => {
        history.push(`/worksheet/${worksheet.Id}`)
        window.location.reload()
    }

    const handlePrint = () => {
        setIsPaneOpen(false)
        setIsLoading(true)

        getRequest(BACKEND_ROUTE.PRINTBYID, null, '/' + worksheet.Id).then(async function(res) {
            if (res != null || res[0] != null) {
                const blob = base64toBlob(res as unknown as string)
                const url = URL.createObjectURL(blob)
                if(desktopCheck()) printPage(url)
                else downloadPDF(res as unknown as string, PDF_TYPE.WORKSHEET, worksheet.VoucherNumber)
                setIsLoading(false)
            }
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
            setIsLoading(false)
        })
    }

    const handleCopy = () => {
        history.push(`/worksheet/new/${worksheet.Id}`)
        window.location.reload()
    }

    const openSignatureModal = () => {
        setIsPaneOpen(false)
        setIsShownSignatureModal(true)
    }

    const openEmailModal = () => {
        setIsPaneOpen(false)
        setIsShownEmailModal(true)
    }

    const handleShowPreview = async () => {
        setIsPaneOpen(false)
        setIsLoading(true)

        getRequest(BACKEND_ROUTE.VOUCHERIMAGE, null, '/' + worksheet.Id).then(async function(res) {
            if (res != null || res[0] != null) {
                const blob = base64toBlob(res as unknown as string)
                const url = URL.createObjectURL(blob)
                setIsLoading(false)
                window.open(url)
            }
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
            setIsLoading(false)
        })
    }

    const handleMakeInvoice = async() => {
        setIsPaneOpen(false)
        setIsLoading(true)
        let url = process.env.REACT_APP_KULCS_UZLET_URL as string + 'worksheet/invoice/' + worksheet.Id
        window.location.replace(url)
    }

    const handleMakeQuote = async() => {
        setIsPaneOpen(false)
        setIsLoading(true)
        let url = process.env.REACT_APP_KULCS_UZLET_URL as string + 'worksheet/quote/' + worksheet.Id
        window.location.replace(url)
    }

    const handleVoidConfirmation = async() => {
        setIsPaneOpen(false)
        setModalText(i18n('modal.confirmation.sureToVoid'))
        setModalCallback(() => handleVoid)
        setIsShownConfirmationModal(true)
    }

    const handleVoid = async() => {
        getRequest(BACKEND_ROUTE.VOID_WORKSHEET, null, '/' + worksheet.Id).then(async function(res) {
            if (res != null || res[0] != null) {
                window.location.reload()
            } 
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
            setIsLoading(false)
        })
    }

    const openFilters = () => {
        if(!editFilters) {
            setMobileFilterClicked(true)
            if(mobileFilterClicked)
                setEditFilters(true)
            else setEditFilters(filterObj.Filters?.length === 0)
        }
        else 
            setMobileFilterClicked(!mobileFilterClicked)
    }

    const closeGroupAction = async () => {
        await setShowCheckMark(CHECKMARK.SUCCESS)
    }

    const handleGroupDownloadClick = async (selectedGroupAction: GROUP_ACTION_WS) => {
        if(checkedIds?.length === 0) {
            store.dispatch(showErrorMessages(['Legalább egy munkalapot jelöljön ki a letöltéshez!']))
            return
        }
        await setShowGroupActionOptions(false)
        await showLoadingScreen(true, setIsLoading)
        .then(() => {
            switch (selectedGroupAction) {
                case GROUP_ACTION_WS.EXPORT:
                    handleWorksheetExport().then(() => setIsLoading(false))
                    break;
                case GROUP_ACTION_WS.EXPORT_WITH_ITEMS:
                    handleWorksheetExportWithItems().then(() => setIsLoading(false))
                    break;
                case GROUP_ACTION_WS.GROUP_EXPORT:
                    generateVoucherImagesByIds().then(() => closeGroupAction().then(() => setIsLoading(false))).catch(e => store.dispatch(showErrorMessages([e])))
                    break;
                case GROUP_ACTION_WS.INVOICE:
                    createInvoiceFromWorksheets().then(() => setIsLoading(false)).catch(e => store.dispatch(showErrorMessages([e])))
                    break;
                default:
                    break;
            }
        })
        .catch(e => {
            setIsLoading(false)
            store.dispatch(showErrorMessages([e]))
        })
    }

    function myFunction() {
        setShowGroupActionOptions(!showGroupActionOptions)
        // document.getElementById("myDropdown")?.classList.toggle("show");
      }

    useEffect(() => {
        if(showCheckMark !== CHECKMARK.NONE) {
            setTimeout(() => {
                setShowCheckMark(CHECKMARK.NONE)
            }, 2500)
        }
    }, [showCheckMark])

    // useEffect(() => {
    //     window.onclick = function(event) {
    //         if (!(event?.target as any).matches('.dropbtn')) {
    //           var dropdowns = document.getElementsByClassName("dropdown-content");
    //           var i;
    //           for (i = 0; i < dropdowns.length; i++) {
    //             var openDropdown = dropdowns[i];
    //             if (openDropdown.classList.contains('show')) {
    //               openDropdown.classList.remove('show');
    //             }
    //           }
    //         }
    //       }
    // })
    
    return (
    <Fragment>
    {isMobile && <Fragment>
        {!isShownSignatureModal && 
            <MobileFilterIcon 
                clicked={mobileFilterClicked} 
                openFilters={openFilters} 
                editFilters={editFilters} 
                longPressBackspaceCallback={() => setMobileFilterClicked(true)} />}
        <FilterOverlay isShown={mobileFilterClicked} hasFilters={filterObj.Filters?.length > 0} setIsShown={setMobileFilterClicked} editFilters={editFilters} setEditFilters={setEditFilters} deleteFilters={emptyFilters}>
            <Fragment>
                <InputField 
                    name='generalSearchFilter'
                    labelText={i18n('worksheetListPage.filters.searchList')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.generalSearchFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.GENERAL_SEARCH_FIELD, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='voucherNumberFilter'
                    labelText={i18n('worksheetListPage.filters.voucherNumber')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.voucherNumberFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.VOUCHERNUMBER, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='StartDate'
                    labelText={i18n('worksheetListPage.filters.startDate')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.startDateFilter}
                    changed={(date: Date) => handleChangeDate(date, 'StartDate')}
                    type={INPUT_TYPE.DATE}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='CloseDate'
                    labelText={i18n('worksheetListPage.filters.closeDate')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.closeDateFilter}
                    changed={(date: Date) => handleChangeDate(date, 'CloseDate')}
                    type={INPUT_TYPE.DATE}
                    barWidth={WIDTH.FULL_LINE} /> 
                <SearchableSelect 
                    overrideLableStyle={true}
                    options={GetOptions(CATEGORY.CUSTOMERS, SETTING_TYPE.NONE, pageFilter.customerOptions)} 
                    label={i18n('worksheetListPage.filters.customer')}
                    fieldName={FIELD_NAME.CUSTOMER} 
                    filterObj={filterObj}
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
                <SearchableSelect 
                    overrideLableStyle
                    options={GetOptions(CATEGORY.SETTINGS, SETTING_TYPE.STATUS)} 
                    label={i18n('worksheetListPage.filters.state')} 
                    fieldName={FIELD_NAME.STATUS} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
                <SearchableSelect 
                    overrideLableStyle
                    options={GetOptions(CATEGORY.DATETYPES, SETTING_TYPE.NONE)} 
                    label={i18n('worksheetListPage.filters.dateType')} 
                    fieldName={FIELD_NAME.DATETYPE} 
                    filterObj={filterObj} 
                    setFilterObj={setPageFilter}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
            </Fragment> 
        </FilterOverlay></Fragment> }
        <SideMenu 
            mainMenu={false}
            isPaneOpen={isPaneOpen} 
            closeSlidingPane={closeSlidingPane} 
            title={worksheet.VoucherNumber}
            openAction={openWorksheet}
            printAction={handlePrint}
            copyAction={handleCopy}
            signAction={openSignatureModal}
            sendAction={openEmailModal}
            openPreview={handleShowPreview}
            pdfExport={handleDownloadPdf}
            makeInvoice={handleMakeInvoice}
            makeOffer={handleMakeQuote}
            deleteAction={handleVoidConfirmation}
            worksheet={worksheet}
            />
        <BaseModal 
            isShown={isShownSignatureModal}
            setIsShown={setIsShownSignatureModal}
            type={MODAL_TYPE.SIGNATURE}
            openModal={openSignatureModal} 
            setSideMenuOpen={setIsPaneOpen} />
        <BaseModal 
            isShown={isShownEmailModal}
            setIsShown={setIsShownEmailModal}
            type={MODAL_TYPE.EMAIL}
            openModal={openEmailModal} 
            setSideMenuOpen={setIsPaneOpen} />
        <ConfirmationModal
            isShown={isShownConfirmationModal}
            setIsShown={setIsShownConfirmationModal}
            headerText={i18n('modal.errorHeader')}
            callback={modalCallback}
            text={modalText}
            />
        <div className={`page-margin`}>
            {!isMobile && 
            <div className={styles['header-items']}>
                <PageHeader 
                    text={i18n('pageHeaders.worksheets')} 
                    goBack={() => {
                        setIsLoading(true)
                        window.location.replace(process.env.REACT_APP_KULCS_UZLET_URL as string)
                    }} />
                <div className={styles[isPortrait ? 'top-right-portrait' : 'top-right']}>
                    <div className={styles[isPortrait ? 'top-right-text-2-portrait' : 'top-right-text-2']}>
                    {`${i18n('worksheetListPage.sumText')}: ${numberWithSeparator((list[0] as Worksheet)?.TotalCount ?? 0)} ${i18n('worksheetListPage.sumUnit')}`}
                    </div>
                        {/* <Button
                            text={i18n('button.worksheetListPage.export')}
                            shade={BUTTON_SHADE.LIGHTERBLUE} 
                            size={BUTTON_SIZE.MEDIUM}
                            handleAction={() => handleWorksheetExport()} />
                        <Button
                            text={i18n('button.worksheetListPage.exportWithItems')}
                            shade={BUTTON_SHADE.LIGHTERBLUE} 
                            size={BUTTON_SIZE.MEDIUM}
                            handleAction={() => handleWorksheetExportWithItems()} />
                        <Button 
                            text={i18n('button.worksheetListPage.groupExport')}
                            shade={BUTTON_SHADE.LIGHTERBLUE} 
                            size={BUTTON_SIZE.MEDIUM}
                            handleAction={() => setShowExportColumn(prev => !prev)} /> */}
                </div>
                <Separator/>
            </div> }
            {isMobile && 
            <div className={styles['placeholder']}>
            <PageHeader 
                sum={`${i18n('worksheetListPage.sumText')}: ${numberWithSeparator((list[0] as Worksheet)?.TotalCount ?? 0)} ${i18n('worksheetListPage.sumUnit')}`}
                text={i18n('pageHeaders.worksheets')} 
                goBack={() => {
                    setIsLoading(true)
                    window.location.replace(process.env.REACT_APP_KULCS_UZLET_URL as string)
                }} 
                button={<Button 
                    text={i18n('button.worksheetListPage.newWorksheet')}
                    shade={BUTTON_SHADE.LIGHTBLUE} 
                    size={BUTTON_SIZE.NORMAL}
                    handleAction={goToNewWorksheetPage} />}
                />
            </div>}
            
            {!isMobile && 
            <>
            <ListFilters filterObj={filterObj} setFilterObj={setPageFilter} defaultFilter={defaultFilterObj_WS} emptyFilters={emptyFilters}>
                <InputField 
                    name='generalSearchFilter'
                    labelText={i18n('worksheetListPage.filters.searchList')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.generalSearchFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.GENERAL_SEARCH_FIELD, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='voucherNumberFilter'
                    labelText={i18n('worksheetListPage.filters.voucherNumber')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.voucherNumberFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.VOUCHERNUMBER, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <SearchableSelect 
                    options={GetOptions(CATEGORY.CUSTOMERS, SETTING_TYPE.NONE, pageFilter.customerOptions)} 
                    label={i18n('worksheetListPage.filters.customer')}
                    fieldName={FIELD_NAME.CUSTOMER} 
                    filterObj={filterObj}
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
                <SearchableSelect 
                    options={GetOptions(CATEGORY.SETTINGS, SETTING_TYPE.STATUS)} 
                    label={i18n('worksheetListPage.filters.state')} 
                    fieldName={FIELD_NAME.STATUS} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />

                <SearchableSelect 
                    options={GetOptions(CATEGORY.DATETYPES, SETTING_TYPE.NONE)} 
                    label={i18n('worksheetListPage.filters.dateType')} 
                    fieldName={FIELD_NAME.DATETYPE} 
                    filterObj={filterObj} 
                    setFilterObj={setPageFilter}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
                <InputField 
                    name='StartDate'
                    labelText={i18n('worksheetListPage.filters.startDate')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.startDateFilter}
                    changed={(date: Date) => handleChangeDate(date, 'StartDate')}
                    type={INPUT_TYPE.DATE}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='CloseDate'
                    labelText={i18n('worksheetListPage.filters.closeDate')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.closeDateFilter}
                    changed={(date: Date) => handleChangeDate(date, 'CloseDate')}
                    type={INPUT_TYPE.DATE}
                    barWidth={WIDTH.FULL_LINE} /> 
            </ListFilters> 

            <div className={styles['button-container']}>
                <Grid container justifyContent="flex-end" style={{marginTop: 10, paddingRight: 4}} spacing={1} >
                    <Grid item xs={'auto'}>
                        {showCheckMark !== CHECKMARK.ERROR && 
                            <img className={`${showCheckMark !== CHECKMARK.NONE ? styles['show'] : styles['dont-show']} ${styles['checkmark']}`} 
                            src={require(`../../images/svg/checkmark.svg`)?.default} alt='' />}
                        {showCheckMark !== CHECKMARK.SUCCESS && 
                            <img className={`${showCheckMark !== CHECKMARK.NONE ? styles['show'] : styles['dont-show']} ${styles['checkmark']}`} 
                            src={require(`../../images/svg/x-mark.svg`)?.default} alt='' />}
                    </Grid>
                    {/* {!isPortrait && <Grid item xs={3} />} */}
                    {/* {selectedGroupAction !== GROUP_ACTION_WS.DEFAULT && 
                    <Grid item xs={'auto'}>
                        <Button 
                            text={i18n('Kijelöltek letöltése')}
                            shade={BUTTON_SHADE.DARKBLUE} 
                            size={BUTTON_SIZE.MEDIUM}
                            handleAction={handleGroupDownloadClick} />
                    </Grid>} */}
                    <Grid item xs={'auto'} height={45} >
                    <div className={styles["dropdown"]}>
                        <Button 
                            text={'Csoportos műveletek'}
                            shade={BUTTON_SHADE.LIGHTBLUE} 
                            size={BUTTON_SIZE.MEDIUM}
                            handleAction={myFunction} />
                        {showGroupActionOptions && <div id="myDropdown" className={styles["dropdown-content"]}>
                            {groupActions.map((option, index) => 
                                    <span onClick={() => handleGroupDownloadClick(option)} key={index}>{option}</span>
                                )}
                        </div>}
                    </div>
                        {/* <select 
                            className={styles['group-action-select']} 
                            name={'GroupAction'} 
                            id={'GroupAction'} 
                            value={selectedGroupAction ?? (groupActions ? groupActions[0] : '')}
                            onChange={handleChangeGroupAction} 
                            >
                            {(groupActions as ISelectableOption[]).map((option, index) => 
                                <option key={index} value={option.Id}>{option.Name}</option>
                            )}
                        </select> */}
                    </Grid>
                    <Grid item xs={'auto'}>
                        <Button 
                            text={i18n('button.worksheetListPage.newWorksheet')}
                            shade={BUTTON_SHADE.LIGHTBLUE} 
                            size={BUTTON_SIZE.MEDIUM}
                            handleAction={goToNewWorksheetPage} />
                    </Grid>
                </Grid>
            </div> 
            </>}

            { isLoading ? <LoadingAnimation /> : warning !== null ? <WarningMessage text={warning} isException={true} /> :
                <List 
                    setIsPaneOpen={setIsPaneOpen}
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    showExportColumn={showExportColumn}
                    list={list} 
                    columnHeaders={worksheetListHeaders} 
                    listType={LIST_TYPE.WORKSHEET} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj} 
                    orderTypes={worksheetOrderTypes}
                    useDefaultOrder={useDefaultOrder}
                    setUseDefaultOrder={setUseDefaultOrder} /> }
        </div>
    </Fragment>
    )
}

export default WorksheetListPage