import _ from "lodash"
import { Fragment, useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../../components/elements/Button"
import LoadingAnimation from "../../components/elements/LoadingAnimation"
import SearchableSelect from "../../components/elements/searchables/SearchableSelect"
import { Separator } from "../../components/elements/Separator"
import WarningMessage from "../../components/elements/WarningMessage"
import { PageHeader } from "../../components/layout/PageHeader"
import { LIST_TYPE, List } from "../../components/lists/List"
import ListFilters from "../../components/lists/ListFilters"
import i18n from "../../i18n"
import { ExportObject } from "../../models/ExportObject"
import { FiltersObject } from "../../models/Filter"
import { TimeSpent } from "../../models/TimeSpent"
import { BACKEND_ROUTE, fetchData, getRequest } from "../../request"
import { GetOptions, CATEGORY, SETTING_TYPE, FIELD_NAME, DATETYPES, FILTER_TYPE, HandleFilterChange, fixEmptyFilters } from "../../utils/FilterHandler"
import { INTERNAL_ROUTE, timeSpentListHeaders, timeSpentOrderTypes } from "../../utils/global"
import { numberWithSeparator, showLoadingScreen } from "../../utils/HelperFunctions"
import * as XLSX from "xlsx"
import styles from './ListPage.module.scss'
import FilterOverlay from "../../components/modal/FilterOverlay"
import { MobileFilterIcon } from "../../utils/MobileFilterIcon"
import InputField, { LABEL, INPUT_BORDER, WIDTH, INPUT_TYPE } from "../../components/elements/InputField"
import { defaultPageFilter_TS, defaultFilterObj_TS, PageFilter_TS } from "../../utils/FilterDefaults"
import { DeviceContext } from "../../context/Contexts"
// import ErrorBoundary from "../../utils/ErrorHandling"

const TimeSpentListPage = () => {
    let defaultPageFilterCopy = _.cloneDeep(defaultPageFilter_TS)
    let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_TS)
    const [ pageFilter, setPageFilter ] = useState(defaultPageFilterCopy as PageFilter_TS)
    const [ filterObj, setFilterObj ] = useState(defaultFilterObjCopy as FiltersObject)
    const history = useHistory()
    const [ list, setList ] = useState([] as TimeSpent[])
    const [ warning, setWarning ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(true)
    const [ useDefaultOrder, setUseDefaultOrder ] = useState(true)
    const [ shouldEmptyFilters, setShouldEmptyFilters ] = useState(false)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const [ mobileFilterClicked, setMobileFilterClicked ] = useState(false)
    const [editFilters, setEditFilters] = useState(false)
    const [ error, setError ] = useState(null)

    const getDurationSum = () => numberWithSeparator(list.reduce((partialSum, timeSpent) => partialSum + timeSpent?.Duration, 0))
    
    const handleChangePageFilter = async (field: string, value: string) => {
        await setPageFilter((prevstate: PageFilter_TS) => ({
            ...prevstate,
            [field]: value
        }))
    }

    useEffect(() => {
        if(filterObj.Filters[0]?.length === 0)
            fixEmptyFilters(setFilterObj)
        if(shouldEmptyFilters)
            setTimeout(() => setShouldEmptyFilters(false), 2000);
        showLoadingScreen(true, setIsLoading)
        .then(() => 
            fetchData(filterObj, BACKEND_ROUTE.TIMESPENT, setList, setWarning)
            .then(() => getDurationSum())
            .then(() => showLoadingScreen(false, setIsLoading))
        )
    }, [filterObj])

    const handleChangeDate = async (e: Date, type: string) => {
        const dateString = e.getFullYear() + '-' + ('0'+ (e.getMonth()+1)).slice(-2) + '-' + ('0'+ e.getDate()).slice(-2)
        
        if(type === 'StartDate') {
            await handleChangePageFilter('startDateFilter', dateString)
            HandleFilterChange(FILTER_TYPE.DATE, e, DATETYPES.DATE, filterObj, setFilterObj, dateString, 4)
        }
        else {
            await handleChangePageFilter('closeDateFilter', dateString)
            HandleFilterChange(FILTER_TYPE.DATE, e, DATETYPES.DATE, filterObj, setFilterObj, dateString, 2)
        }
    }

    const handleKeyPressed = async (key: string, name: string, value: string) => {
        if (key === 'Enter') {
            if(name === FIELD_NAME.GENERAL_SEARCH_FIELD)
                HandleFilterChange(FILTER_TYPE.INPUT, value, FIELD_NAME.GENERAL_SEARCH_FIELD, filterObj, setFilterObj, undefined, undefined, LIST_TYPE.TIMESPENT)
            else 
                HandleFilterChange(FILTER_TYPE.INPUT, value, name, filterObj, setFilterObj)
            }
    }

    const emptyFilters = async () => {
        let defaultPageFilterCopy = _.cloneDeep(defaultPageFilter_TS)
        await setPageFilter(defaultPageFilterCopy as PageFilter_TS)
        let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_TS)
        await setFilterObj(defaultFilterObjCopy as FiltersObject)
        await setUseDefaultOrder(true)
        await setShouldEmptyFilters(true)
    }

    const handleExport = () => {
        getRequest(BACKEND_ROUTE.EXPORT_TIMESPENT, filterObj).then(async function(res) {
            if (res != null || res[0] != null) {
                const response = res as unknown as ExportObject;

                const worksheet = XLSX.utils.json_to_sheet(response.ExportData)
                const workbook = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(workbook, worksheet, response.Filename)

                XLSX.utils.sheet_add_aoa(worksheet, [response.Headers], { origin: "A1" });
                XLSX.writeFile(workbook, response.Filename + "_" + Date.now() +".xlsx");
            } 
            }).catch((error) =>{
                setIsLoading(false)
                setWarning(error)
        })
    }

    const openFilters = () => {
        if(!editFilters) {
            setMobileFilterClicked(true)
            if(mobileFilterClicked)
                setEditFilters(true)
            else setEditFilters(filterObj.Filters?.length === 0)
        }
        else 
            setMobileFilterClicked(!mobileFilterClicked)
    }

    
    // function ErrorFallback({error, resetErrorBoundary}: any) {
    //     console.log(error)
    //     return (
    //     <div role="alert">
    //         <p>Something went wrong:</p>
    //         <pre>{error.message}</pre>
    //         <button onClick={resetErrorBoundary}>Try again</button>
    //     </div>
    //     )
    // }

    // const mockFetch = () => {
    //     return new Promise(resolve => {
    //       setTimeout(() => {
    //           throw new Error('💥 CABOOM 💥');
    //       }, 1000);
    //     });
    //   };

    return (
    <Fragment>
        {/* <ErrorBoundary> */}
        {/* <button onClick={mockFetch}>KEZELVE?</button> */}
        {isMobile && <Fragment>
            <MobileFilterIcon 
                clicked={mobileFilterClicked} 
                openFilters={openFilters} 
                editFilters={editFilters} 
                longPressBackspaceCallback={() => setMobileFilterClicked(true)} />
        <FilterOverlay isShown={mobileFilterClicked}  hasFilters={filterObj.Filters?.length > 0} setIsShown={setMobileFilterClicked} editFilters={editFilters} setEditFilters={setEditFilters} deleteFilters={emptyFilters}> 
            <Fragment>
            <InputField 
                name='generalSearchFilter'
                labelText={i18n('worksheetListPage.filters.searchList')}
                labelPlace={LABEL.FILTER}
                border={INPUT_BORDER.ROUNDED}
                type={INPUT_TYPE.TEXT} 
                labelWidth={WIDTH.FULL_LINE} 
                value={pageFilter.generalSearchFilter}
                changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.GENERAL_SEARCH_FIELD, e.target.value)}
                barWidth={WIDTH.FULL_LINE} />
            <InputField 
                name='labelFilter'
                labelText={i18n('timeSpentListPage.filters.name')}
                labelPlace={LABEL.FILTER}
                border={INPUT_BORDER.ROUNDED}
                type={INPUT_TYPE.TEXT} 
                labelWidth={WIDTH.FULL_LINE} 
                value={pageFilter.labelFilter}
                changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.LABEL, e.target.value)}
                barWidth={WIDTH.FULL_LINE} />
            <InputField 
                name='StartDate'
                labelText={i18n('timeSpentListPage.filters.startDate')}
                labelPlace={LABEL.FILTER}
                border={INPUT_BORDER.ROUNDED}
                labelWidth={WIDTH.FULL_LINE} 
                value={pageFilter.startDateFilter}
                changed={(date: Date) => handleChangeDate(date, 'StartDate')}
                type={INPUT_TYPE.DATE}
                barWidth={WIDTH.FULL_LINE} />
            <InputField 
                name='CloseDate'
                labelText={i18n('timeSpentListPage.filters.closeDate')}
                labelPlace={LABEL.FILTER}
                border={INPUT_BORDER.ROUNDED}
                labelWidth={WIDTH.FULL_LINE} 
                value={pageFilter.closeDateFilter}
                changed={(date: Date) => handleChangeDate(date, 'CloseDate')}
                type={INPUT_TYPE.DATE}
                barWidth={WIDTH.FULL_LINE} /> 
            <SearchableSelect 
                overrideLableStyle
                options={GetOptions(CATEGORY.SETTINGS, SETTING_TYPE.WORKER)} 
                label={i18n('timeSpentListPage.filters.worker')} 
                fieldName={FIELD_NAME.WORKER} 
                filterObj={filterObj} 
                setFilterObj={setFilterObj}
                shouldEmpty={shouldEmptyFilters}
                setShouldEmptyFilters={setShouldEmptyFilters} />
        </Fragment>
        </FilterOverlay></Fragment>
        }
    <div className='page-margin'>
        {!isMobile && 
        <div className={styles['header-items']}>
            {isPortrait ? <div>
            <PageHeader 
                text={i18n('pageHeaders.timeSpents')} 
                goBack={() => history.push(INTERNAL_ROUTE.START)} />
            </div> : <PageHeader 
                text={i18n('pageHeaders.timeSpents')} 
                goBack={() => history.push(INTERNAL_ROUTE.START)} />}
            <div className={styles[isPortrait ? 'top-right-portrait' : 'top-right']}>
                <div className={styles[isPortrait ? 'top-right-text-2-portrait' : 'top-right-text-2']}>
                    {`${i18n('timeSpentListPage.sumText')}: ${getDurationSum()} ${i18n('timeSpentListPage.sumUnit')}`}
                </div>
                <Button 
                    text={i18n('button.timeSpentListPage.export')}
                    shade={BUTTON_SHADE.LIGHTERBLUE} 
                    size={BUTTON_SIZE.MEDIUM}
                    handleAction={() => handleExport()}
                    />
            </div>
        <Separator/>
        </div> }
        {isMobile && 
            <div className={styles['placeholder']}>
            <PageHeader 
                sum={`${i18n('timeSpentListPage.sumText')}: ${getDurationSum()} ${i18n('timeSpentListPage.sumUnit')}`}
                text={i18n('pageHeaders.timeSpents')} 
                goBack={() => history.push(INTERNAL_ROUTE.START)} 
                />
            </div>
            }
        {!isMobile && 
        <ListFilters filterObj={filterObj} setFilterObj={setFilterObj} defaultFilter={defaultFilterObj_TS} emptyFilters={emptyFilters} >
            <InputField 
                name='generalSearchFilter'
                labelText={i18n('worksheetListPage.filters.searchList')}
                labelPlace={LABEL.FILTER}
                border={INPUT_BORDER.ROUNDED}
                type={INPUT_TYPE.TEXT} 
                labelWidth={WIDTH.FULL_LINE} 
                value={pageFilter.generalSearchFilter}
                changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.GENERAL_SEARCH_FIELD, e.target.value)}
                barWidth={WIDTH.FULL_LINE} />
            <InputField 
                name='labelFilter'
                labelText={i18n('timeSpentListPage.filters.name')}
                labelPlace={LABEL.FILTER}
                border={INPUT_BORDER.ROUNDED}
                type={INPUT_TYPE.TEXT} 
                labelWidth={WIDTH.FULL_LINE} 
                value={pageFilter.labelFilter}
                changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.LABEL, e.target.value)}
                barWidth={WIDTH.FULL_LINE} />
            <InputField 
                name='StartDate'
                labelText={i18n('timeSpentListPage.filters.startDate')}
                labelPlace={LABEL.FILTER}
                border={INPUT_BORDER.ROUNDED}
                labelWidth={WIDTH.FULL_LINE} 
                value={pageFilter.startDateFilter}
                changed={(date: Date) => handleChangeDate(date, 'StartDate')}
                type={INPUT_TYPE.DATE}
                barWidth={WIDTH.FULL_LINE} />
            <InputField 
                name='CloseDate'
                labelText={i18n('timeSpentListPage.filters.closeDate')}
                labelPlace={LABEL.FILTER}
                border={INPUT_BORDER.ROUNDED}
                labelWidth={WIDTH.FULL_LINE} 
                value={pageFilter.closeDateFilter}
                changed={(date: Date) => handleChangeDate(date, 'CloseDate')}
                type={INPUT_TYPE.DATE}
                barWidth={WIDTH.FULL_LINE} /> 
            <SearchableSelect 
                options={GetOptions(CATEGORY.SETTINGS, SETTING_TYPE.WORKER)} 
                label={i18n('timeSpentListPage.filters.worker')} 
                fieldName={FIELD_NAME.WORKER} 
                filterObj={filterObj} 
                setFilterObj={setFilterObj}
                shouldEmpty={shouldEmptyFilters}
                setShouldEmptyFilters={setShouldEmptyFilters} />
        </ListFilters> }
        
        { isLoading ? <LoadingAnimation /> : warning !== null ? <WarningMessage text={warning} isException={true} /> :
                <List 
                    list={list} 
                    columnHeaders={timeSpentListHeaders} 
                    listType={LIST_TYPE.TIMESPENT} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj} 
                    orderTypes={timeSpentOrderTypes}
                    useDefaultOrder={useDefaultOrder}
                    setUseDefaultOrder={setUseDefaultOrder} /> }
        </div>
        {/* </ErrorBoundary> */}
    </Fragment>
    )
}

export default TimeSpentListPage