import { Fragment, useContext } from "react"
import { DeviceContext, SelectedWorksheetContext } from "../../context/Contexts"
import i18n from "../../i18n"
import { ICON_TYPE } from "../../utils/global"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../elements/Button"
import { MODAL_TYPE } from "../modal/BaseModal"
import styles from '../../pages/WorksheetPage.module.scss'
import { Tool } from "../../models/Tool"
import { isArrayUnavailable, isObjectUnavailable } from "../../utils/HelperFunctions"
import { Worksheet } from "../../models/Worksheet"
import InputField, { WIDTH, INPUT_ALIGN, LABEL, INPUT_TYPE } from "../elements/InputField"

type Props = {
    openModalAction?: (type: MODAL_TYPE) => void
}

const ToolDataFields = ({ openModalAction }: Props) => {
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { isMobile, isPortrait } = useContext(DeviceContext)

    const deleteTool = () => 
        setWorksheet((prevState: Worksheet) => ({
            ...prevState,
            Tool: {} as Tool
        }))

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null

    const getToolDataRows = () => {
        const base = 4
        const threshold = 10
        const toolDataList = worksheet?.Tool?.ToolData
        const listLenght = toolDataList?.length ?? 0
        
        let blocks = []
        let block = []
        
        if(listLenght > base)
            for (let i = base; i < listLenght; i++) {
                if(toolDataList[i] !== null) {
                    block.push(
                        <InputField 
                            key={i}
                            name='Name'
                            labelText={toolDataList[i].Name}
                            value={toolDataList[i].Data} 
                            labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 3 : WIDTH.SIXTH}
                            barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 9 : WIDTH.FIVE_SIXTHS}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.TEXT} />)
                }
            }
            blocks.push( <div className={!isMobile ? styles['content-half'] : ''} key={1}>{block}</div> )
        return blocks
    }

    const getToolDataRowsFirstFour = () => {
        let threshold = 4
        let block = []
        const toolDataList = worksheet?.Tool?.ToolData
        const listLenght = toolDataList?.length ?? 0
        
        if(!isObjectUnavailable(worksheet?.Tool) && !isArrayUnavailable(worksheet?.Tool?.ToolData) && worksheet?.Tool?.ToolData !== null) {
            for (let i = 0; i < listLenght && i < threshold; i++) {
                if(toolDataList[i] !== null) {
                    block.push(
                        <InputField 
                            key={i}
                            name='Name'
                            labelText={toolDataList[i].Name}
                            value={toolDataList[i].Data} 
                            labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 3 : WIDTH.SIXTH}
                            barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 9 : WIDTH.FIVE_SIXTHS}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.TEXT} />)
                    }
                }
            }
        return block
    }

    return (
        !isObjectUnavailable(worksheet.Tool) ? 
            <Fragment>
                <div className={!isMobile ? styles['content-half'] : ''}>
                    <InputField 
                        name='Name'
                        labelText={i18n('worksheet.toolData.details.name')}
                        value={worksheet?.Tool?.Name} 
                        labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 3 : WIDTH.SIXTH}
                        barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 9 : WIDTH.FIVE_SIXTHS}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.TEXT} />
                    <InputField 
                        name='Code'
                        labelText={i18n('worksheet.toolData.details.code')}
                        value={worksheet?.Tool?.Code} 
                        labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                        labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 3 : WIDTH.SIXTH}
                        barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 9 : WIDTH.FIVE_SIXTHS}
                        labelPlace={LABEL.DEFAULT}
                        type={INPUT_TYPE.TEXT} />
                    {getToolDataRowsFirstFour()}
                </div>
                {getToolDataRows()}
                <div className='align-left' >
                    <Button 
                        text={i18n('button.worksheetPage.chooseTool')}
                        shade={BUTTON_SHADE.LIGHTBLUE} 
                        isExtraSpacious={isMobile}
                        size={BUTTON_SIZE.WITH_ICON}
                        handleAction={() => openModalAction && openModalAction(MODAL_TYPE.TOOLS)}
                        isDisabled={hasCustomerSigned()}
                        icon={ICON_TYPE.ADD} />
                <Button 
                    text={i18n('button.worksheetPage.deleteTool')}
                    shade={BUTTON_SHADE.LIGHTRED} 
                    isExtraSpacious={isMobile}
                    size={BUTTON_SIZE.WITH_ICON}
                    handleAction={deleteTool}
                    isDisabled={hasCustomerSigned()}
                    icon={ICON_TYPE.DELETE} />
                </div>
            </Fragment>

            : <div className='align-left' >
                <Button 
                    isDisabled={hasCustomerSigned()}
                    text={i18n('button.worksheetPage.chooseTool')}
                    shade={BUTTON_SHADE.LIGHTBLUE} 
                    size={BUTTON_SIZE.WITH_ICON}
                    handleAction={() => openModalAction && openModalAction(MODAL_TYPE.TOOLS)}
                    icon={ICON_TYPE.ADD} />
            </div> 
    )
}

export default ToolDataFields