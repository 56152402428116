import { Fragment, useContext } from "react"
import styles from './ListFilters.module.scss'
import closeIcon from '../../images/filter-delete.png'
import { FiltersObject } from "../../models/Filter"
import { deleteFilters } from "../../utils/FilterHandler"
import { DeviceContext } from "../../context/Contexts"
import { Grid } from "@mui/material"

export enum PAGE_TYPE {
    WORKSHEETLIST,
    EXTERNALWORKLIST,
    TIMESPENTLIST,
    TOOLLIST
}

type Props = {
    children: JSX.Element[]
    filterObj: FiltersObject
    setFilterObj: any
    defaultFilter: FiltersObject
    emptyFilters: any | undefined
}

const ListFilters = ({ children, setFilterObj, defaultFilter, emptyFilters }: Props) => {
    const { isPortrait } = useContext(DeviceContext)
    const itemWidth = isPortrait ? 4 : 3
    return (
        <Fragment>
            <Grid container spacing={1} className={styles['filters']}>
                {children.map((child: JSX.Element, index: number) => (
                    // index <= cutAtIndexPerRow ? 
                    <Grid item xs={itemWidth} className={styles['filter']} key={index}>
                        {child}
                    </Grid>
                    // : ''
                ))}
                <img 
                    className={styles['deleteFiltersIcon']} 
                    src={closeIcon} alt='' 
                    onClick={() => deleteFilters(defaultFilter, setFilterObj, emptyFilters) }/>
            </Grid>
            {/* <div className={styles['filters']}>
                {children.map((child: JSX.Element, index: number) => (
                    index > cutAtIndexPerRow ? 
                        <div className={styles['filter']} key={index}>
                            {child}
                        </div>
                        : ''
                ))}
            </div> */}
            {/* <div className={styles['filters']}>
                {children.map((child: JSX.Element, index: number) => (
                    index > cutAtIndexPerRow + cutAtIndexPerRow + 1 ? 
                        <div className={styles['filter']} key={index}>
                            {child}
                        </div>
                        : ''
                ))}
            </div> */}
        </Fragment>
    )
}

export default ListFilters