import { Fragment, useState } from "react";
import i18n from "../../i18n";
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../elements/Button";
import { Separator } from "../elements/Separator";
import styles from './BaseModal.module.scss'

export interface Props {
	isShown: boolean
    setIsShown: React.Dispatch<React.SetStateAction<boolean>>
    headerText: string
    text: string
    callback: any
}

const ConfirmationModal = ({ isShown, setIsShown, headerText, text, callback }: Props) => {

    const okModal = () => {
        callback()
        setIsShown(false)
    }

    const cancelModal = () => {
        setIsShown(false)
    }

    return (
        <Fragment>
            <div className={styles.modal + ' ' + (isShown ? styles.open : '')}>
                <div className={styles["modal-overlay"]}  ></div>
                <div className={styles["modal-card"]}>
                    <div className={styles["modal-body"]}>
                        <h2>
                            {headerText}
                        </h2>
                        <Separator />
                        <div>
                            {text}
                        </div>
                        <div className={`${styles['action-items']} ${styles['modal-footer']}`}>
                            <Button
                                text={i18n('button.general.ok')}
                                shade={BUTTON_SHADE.DARKRED}
                                size={BUTTON_SIZE.NORMAL}
                                handleAction={okModal} />
                            <Button 
                                text={i18n('button.general.close')}
                                shade={BUTTON_SHADE.LIGHTBLUE}
                                size={BUTTON_SIZE.NORMAL}
                                handleAction={cancelModal} />
                        </div>
                    </div>
                </div>
            </div>
		</Fragment>
    )
}

export default ConfirmationModal;