import styles from './PaginationButton.module.scss'

type Props = {
    text: string
    isDisabled: boolean
    action: () => void
    verySpacious?: boolean
}

const PaginationButton = ({ text, isDisabled, action, verySpacious }: Props) => {

    return (
        <button disabled={isDisabled} className={`${styles['button']} ${styles[verySpacious ? 'very-spacious' : 'spacious']} ${isDisabled ? styles.disabled : styles.active}`} onClick={action}>{text}</button>
    )
}

export default PaginationButton