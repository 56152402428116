import { LIST_TYPE } from "../components/lists/List"
import { Filter } from "../models/Filter"

// TODO ": any" is not seem to be the best solution
export const GenericSearchFilters : any = (type : LIST_TYPE, filterValue : number | string) =>{
    switch(type){
        case LIST_TYPE.WORKSHEET:
            return [
                [{
                  FieldName: "VoucherNumber",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "Comment",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "ContactObj",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "CustomerObj",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "ErrorDetailName",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "FixDetailName",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "ErrorDetail",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "FixDetail",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "Tool",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter]];
            
        case LIST_TYPE.EXTERNALWORK:
            return [
                [{
                  FieldName: "Address",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter]];
            
        case LIST_TYPE.TIMESPENT:
            return [
                [{
                  FieldName: "Label",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                    FieldName: "Comment",
                    FilterValue: filterValue,
                    OperatorType: 11,
                    FieldType: 0,
                    genericSearchField: true
                  } as Filter]];
            
        case LIST_TYPE.TOOL:
            return [
                [{
                  FieldName: "Name",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "Code",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "ToolDatas",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                    FieldName: "Comment",
                    FilterValue: filterValue,
                    OperatorType: 11,
                    FieldType: 0,
                    genericSearchField: true
                  } as Filter],
                [{
                  FieldName: "CustomerNameDisplay",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter],
                [{
                  FieldName: "CustomerContactObj",
                  FilterValue: filterValue,
                  OperatorType: 11,
                  FieldType: 0,
                  genericSearchField: true
                } as Filter]];
           
    }
}