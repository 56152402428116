import React from 'react'
import styles from './Separator.module.scss'

export const Separator = ({ light = false, fancy = false, activeIndex = 0, tabs = 0, mainMenu = false }) => {
    const style = light ? 'separator-light' : mainMenu ? 'separator-main-menu' : 'separator'

    const getFancySeparator = () => {
        var fancySeparator = [];
        for (var i = 0; i < tabs; i++) {
            fancySeparator.push(
                <div className={tabs === 3 ? styles['progress-three'] : styles['progress']} key={i}>
                    <div className={activeIndex === i ? styles['active'] : styles['passive']} />
                </div>
            )
        }
        return fancySeparator
    }

    return (
        fancy ? <div>{getFancySeparator()}</div> : <div className={styles[style]}></div>
    )
}