import { Fragment, useContext, useState } from "react"
import styles from './ToolModal.module.scss'
import i18n from "../../../i18n"
import { Tool } from "../../../models/Tool"
import { EditItemContext, DeviceContext } from "../../../context/Contexts"
import InputField, { WIDTH, INPUT_ALIGN, LABEL, INPUT_TYPE, INPUT_HEIGHT } from "../../elements/InputField"

const CommentTab = () => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const maxLetters = 1500
    const [ letterCount, setLetterCount ] = useState(editItem?.Comment?.length ?? 0)
    const { isMobile, isPortrait } = useContext(DeviceContext)

    const setComment = (e: any) => {
        setEditItem((prevState: Tool) => ({
            ...prevState,
            Comment: e.target.value
        }))
        setLetterCount(e.target.value.length)
    }

    const isTooLong = letterCount > maxLetters

    return (
        <div className={styles[isMobile ? 'tab-container-mobile' : isPortrait ? 'tab-container-portrait' : 'tab-container']}>
            <InputField 
                name='Comment' 
                placeholder={i18n('worksheet.products.details.comment')}
                value={editItem.Comment ?? ''}
                changed={setComment}
                isTooLong={isTooLong}
                labelWidth={WIDTH.FULL_LINE}
                labelAlignment={INPUT_ALIGN.LEFT}
                barAlignment={INPUT_ALIGN.LEFT}
                barWidth={WIDTH.FULL_LINE}
                barHeight={INPUT_HEIGHT.FULL_SIZE_EXTRA}
                labelPlace={LABEL.DEFAULT}
                type={INPUT_TYPE.TEXTAREA} /> 
            <p>
                <span style={isTooLong ? {color: 'red'} : {}}>{letterCount}</span>{`/${maxLetters}`}
            </p>
        </div>
    )
}

export default CommentTab