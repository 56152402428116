import { useState, useEffect, Fragment, useContext } from "react"
import { store } from "../../../App"
import { EditItemContext } from "../../../context/Contexts"
import { BisnodePartner, BISNODEPARTNER_STATUS } from "../../../models/BisnodePartner"
import { Customer } from "../../../models/Customer"
import { showErrorMessages } from "../../../reducers/rootReducer"
import { BACKEND_ROUTE, getRequest } from "../../../request"
import styles from './CompanySearcharbleSelect.module.scss'

type Props = {
    parentId: string
}

const CompanySearcharbleSelect = ({ parentId }: Props ) => {
    const [ options, setOptions ] = useState([] as BisnodePartner[])
    const { editItem, setEditItem } = useContext(EditItemContext)
    const [ isFocused, setIsFocused ] = useState(false)
    const [ shouldClose, setShouldClose ] = useState(false)
    const [ parentWidth, setParentWidth ] = useState(0)

    useEffect(() => setParentWidth(document.getElementById(parentId)?.offsetWidth ?? 0), [])

    useEffect(() => {
        setIsFocused(true)
        if((editItem as Customer)?.Name?.length > 2) {
            getRequest(BACKEND_ROUTE.BISNODE, null, '/'+ (editItem as Customer)?.Name).then(async function(res) { 
                if (res !== null || res[0] !== null) {
                    let partners = res as BisnodePartner[]
                    let filtered = partners.filter(partner => partner.Status !== BISNODEPARTNER_STATUS.CEASED) ?? []
                    setOptions(filtered)
                }
                }).catch((error) =>{
                    store.dispatch(showErrorMessages([error]))
            })
        }
        
    }, [(editItem as Customer)?.Name]) 
    
    const isShown = () => isFocused && (editItem as Customer)?.Name?.length > 0 && options?.length > 0

    function format(mask: string, number: string | number) {
        var s = '' + number, r = ''
        for (var im = 0, is = 0; im < mask.length && is < s.length; im++) {
          r += mask.charAt(im) === 'X' ? s.charAt(is++) : mask.charAt(im)
        }
        return r
     }  

    const selectPartner = async (partner: BisnodePartner) => {
        await setEditItem((prev: Customer) => ({
            ...prev,
            Name: partner.ShortName ?? partner.Name ?? '',
            CentralStreet: partner.Address ?? '',
            CentralCity: partner.City ?? '',
            CentralZip: partner.Zip ?? '',
            TaxNumber: format('XXXXXXXX-X-XX', partner.TaxNbr) ?? ''
        }))
        await setShouldClose(true)
    }

    useEffect(() => {
        if(shouldClose) {
            setIsFocused(!shouldClose)
            setShouldClose(false)
        }
    }, [shouldClose])

    return (<Fragment>
           {isShown() && <ul className={styles['option-container']} style={{width: parentWidth}}>
                {options.map((bisnodePartner, index) => (
                    <li className={styles.item} key={index} onClick={() => selectPartner(bisnodePartner)}>
                        <div ><b>{bisnodePartner?.ShortName ?? ''}</b></div>
                        <div>{bisnodePartner?.Name ?? ''}</div>
                        <span ><b>Adószám: </b>{bisnodePartner?.TaxNbr ?? ''} <b>Státusz:</b> {bisnodePartner?.Status ?? ''} </span>
                        <div ><b>Cím: </b>{bisnodePartner?.Address ?? ''}</div>
                    </li>
                ))}
            </ul>}
    </Fragment>)
}

export default CompanySearcharbleSelect