import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { Fragment, useContext } from "react"
import { store } from "../../../App"
import { DeviceContext, QuantityUnitListContext, SelectedWorksheetContext, SettingsContext, VatListContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { ExternalWork } from "../../../models/ExternalWork"
import { QuantityUnit } from "../../../models/QuantityUnit"
import { TimeSpent } from "../../../models/TimeSpent"
import { Vat } from "../../../models/Vat"
import { Worksheet } from "../../../models/Worksheet"
import { WorksheetItem } from "../../../models/WorksheetItem"
import { showErrorMessages } from "../../../reducers/rootReducer"
import { ICON_TYPE, RELATION_TYPE } from "../../../utils/global"
import { IIndexable } from "../../interfaces/IIndexible"
import { ISelectableOption } from "../../interfaces/ISelectableOption"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../Button"
import InputField, { WIDTH, INPUT_ALIGN, LABEL, INPUT_TYPE, INPUT_HEIGHT } from "../InputField"
import mainStyles from './ExpandableComplexChildren.module.scss'
import { EXPANDABLE_LIST_TYPE } from "./ExpandableList"
import styles from './ExpandableListItem.module.scss'

export interface Props {
    index: number
    type: EXPANDABLE_LIST_TYPE
}

const ExpandableListItem = ({ index, type }: Props) => {
    const { settings } = useContext(SettingsContext)
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { quantityUnits } = useContext(QuantityUnitListContext)
    const vatList = useContext(VatListContext)
    const item = index !== -1 ? (worksheet as IIndexable)[type][index] : {}
    const { isMobile, isPortrait } = useContext(DeviceContext)
    
    const getDefaultVat = () => {
        const [firstVat] = Object.values(vatList.vats)
        return Object.values(vatList.vats).find((vat: Vat) => vat.Name === '27%') ?? firstVat
    }

    const getDefaultQuantityUnitId = (quantityName: string) => {
        const [firstQuantityUnit] = Object.values(quantityUnits)
        return Object.values(quantityUnits).find((quantityUnit: QuantityUnit) => quantityUnit.Name === quantityName)?.Id ?? firstQuantityUnit.Id
    }

    enum CHANGED_PROP {
        BOOLEAN, 
        DATE,
        STRING
    } 

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null

    const changeStatusInProducts = () => {
        let worksheetItem = {} as WorksheetItem
        switch(type) {
            case(EXPANDABLE_LIST_TYPE.TIMESPENT):
                worksheetItem = {
                    Name: (item as TimeSpent).Label ?? '',
                    ProductId: index,
                    Comment: (item as TimeSpent).Comment ?? '',
                    Quantity: (item as TimeSpent).Duration ?? 1,
                    // óra default 
                    QuantityUnitId: (item as TimeSpent).QuantityUnitId ?? getDefaultQuantityUnitId('óra'),
                    UnitPrice: 0,
                    // 27% ÁFA default
                    VatId: getDefaultVat().Id,
                    Vat: getDefaultVat(),
                    RelationType: RELATION_TYPE.TIMESPENT,
                    RelationId: (item as TimeSpent).Id?.toString()
                }
            break
            case(EXPANDABLE_LIST_TYPE.EXTERNALWORK):
                worksheetItem = {
                    Name: (item as ExternalWork).Address ?? '',
                    ProductId: index,
                    Quantity: (item as ExternalWork).Distance ?? 1,
                    // km default
                    QuantityUnitId: getDefaultQuantityUnitId('km'),
                    UnitPrice: 0,
                    VatId: getDefaultVat().Id,
                    Vat: getDefaultVat(),
                    RelationType: RELATION_TYPE.EXTERNALWORK,
                    RelationId: (item as ExternalWork).Id?.toString()
                }
            break
        }
        //async problem
        if(!item.IsShownAsWorksheetItem)
            setWorksheet((prevState: Worksheet) => ({
                ...prevState,
                Items: [ ...prevState.Items ?? [], worksheetItem ]
            }))
        else 
            deleteItem(true)
    }

    const handleChange = async (e: any, propType: CHANGED_PROP) => {
        let list = [...(worksheet as IIndexable)[type] ?? []]
        let changedItem = (worksheet as IIndexable)[type][index]
        
        switch(propType) {
            case CHANGED_PROP.STRING:
                list[index] = {...changedItem, [e.target.name]: e.target.value}
                break
            case CHANGED_PROP.BOOLEAN:
                if(type === EXPANDABLE_LIST_TYPE.EXTERNALWORK && ((changedItem as ExternalWork).Address === undefined
                    || (changedItem as ExternalWork).Address === ''
                    || (changedItem as ExternalWork).Distance?.toString() === ''
                    || (changedItem as ExternalWork).Distance === undefined)) {
                    store.dispatch(showErrorMessages(['A kiszállási cím és a távolság mezők kitöltése kötelező!']))
                    break
                }
                if(type === EXPANDABLE_LIST_TYPE.TIMESPENT && ((changedItem as TimeSpent).Label === undefined
                    || (changedItem as TimeSpent).Label === '' 
                    || (changedItem as TimeSpent).Duration?.toString() === ''
                    || (changedItem as TimeSpent).Duration === undefined)) {
                    store.dispatch(showErrorMessages(['A munka megnevezése és az időtartam mezők kitöltése kötelező!']))
                    break
                }
                list[index] = {...changedItem, IsShownAsWorksheetItem: !changedItem.IsShownAsWorksheetItem}
                changeStatusInProducts()
                break
            case CHANGED_PROP.DATE:
                list[index] = {...changedItem, Date: e as Date}
                break
        }
        await setWorksheet((prevState: Worksheet) => ({
            ...prevState,
            [type]: list
        }))
    }
    
    const deleteItem = (isWorksheetItem: boolean) => {
        let expandableItemList = (worksheet as IIndexable)[type]
        let expandableItemId = expandableItemList[index].Id
        
        const expandableItemIndex = expandableItemList.indexOf(expandableItemList[index])
        if (expandableItemIndex > -1)
            expandableItemList.splice(expandableItemIndex, 1)
        
        setWorksheet((prevState: Worksheet) => ({
            ...prevState,
            [type]: expandableItemList
        }))

        // delete from worksheet items too
        if(isWorksheetItem) {
            let worksheetItemList = (worksheet as IIndexable).Items
            const worksheetItemIndex = Object.values(worksheetItemList).findIndex(item => (item as WorksheetItem).RelationId === expandableItemId)
           
            if (worksheetItemIndex > -1)
                worksheetItemList.splice(worksheetItemIndex, 1)
            
            setWorksheet((prevState: Worksheet) => ({
                ...prevState,
                Items: worksheetItemList
            }))
        }
    }

    return (
        <Fragment>
            <div className={mainStyles['data']}>
                <Grid container spacing={0}>
                    <Grid item xs={'auto'} style={{marginRight: 5, marginTop: isPortrait || isMobile ? 5 : 15}}>
                        <div>{i18n('worksheet.externalWork.detail.showOnWorksheet')}</div>
                    </Grid>
                    <Grid item xs={'auto'} justifyContent="flex-end">
                        <InputField 
                            labelText={''}
                            labelWidth={0}
                            barWidth={1}
                            name='IsShownAsWorksheetItem'
                            value={item?.IsShownAsWorksheetItem} 
                            changed={(e: boolean) => handleChange(e, CHANGED_PROP.BOOLEAN)}
                            isDisabled={hasCustomerSigned()}
                            type={INPUT_TYPE.CHECKBOX} />
                    </Grid>
                </Grid>
            </div>
            {type === EXPANDABLE_LIST_TYPE.EXTERNALWORK ?
            <Fragment>
                <InputField 
                    name='LicensePlateNumberId'
                    labelText={i18n('worksheet.externalWork.detail.plateNumber')}
                    labelPlace={LABEL.DEFAULT} 
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 4 : 3}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7.25 : 8.7}
                    type={INPUT_TYPE.SELECT}
                    value={item?.LicensePlateNumberId ?? settings?.LicensePlates[0]?.Id}
                    options={settings?.LicensePlates as ISelectableOption[]}
                    changed={(e) => handleChange(e, CHANGED_PROP.STRING)}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem} /> 
                <InputField 
                    name='DriverId'
                    labelText={i18n('worksheet.externalWork.detail.driver')}
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 4 : 3}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7.25 : 8.7}
                    type={INPUT_TYPE.SELECT}
                    value={item?.DriverId ?? settings?.Drivers[0]?.Id}
                    options={settings?.Drivers as ISelectableOption[]}
                    changed={(e) => handleChange(e, CHANGED_PROP.STRING)}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem} /> 
                <InputField 
                    name='Address'
                    labelText={i18n('worksheet.externalWork.detail.address')}
                    labelPlace={LABEL.DEFAULT} 
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 4 : 3}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7.7 : 8.7}
                    type={INPUT_TYPE.TEXT}
                    value={item?.Address}
                    changed={(e) => handleChange(e, CHANGED_PROP.STRING)}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem} /> 
                <InputField 
                    name='Distance'
                    labelText={i18n('worksheet.externalWork.detail.distance')}
                    labelPlace={LABEL.DEFAULT} 
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 4 : 3}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7.7 : 8.7}
                    type={INPUT_TYPE.NUMBER}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem}
                    value={item?.Distance}
                    changed={(e) => handleChange(e, CHANGED_PROP.STRING) || item?.IsShownAsWorksheetItem} /> 
                <InputField 
                    name='Date'
                    labelText={i18n('worksheet.externalWork.detail.date')}
                    labelPlace={LABEL.DEFAULT} 
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 4 : 3}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7.7 : 8.7}
                    type={INPUT_TYPE.DATE}
                    value={item?.Date}
                    useDefaultDate={true}
                    changed={(date: Date) => handleChange(date, CHANGED_PROP.DATE)}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem} /> 
                </Fragment>
            :
            <Fragment>
                <InputField 
                    name='WorkerId'
                    labelText={i18n('worksheet.timeSpent.detail.working')}
                    labelPlace={LABEL.DEFAULT} 
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 4 : 3}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7.25 : 8.7}
                    type={INPUT_TYPE.SELECT}
                    value={item.WorkerId ?? settings?.Workers[0]?.Id}
                    options={settings?.Workers as ISelectableOption[]}
                    changed={(e) => handleChange(e, CHANGED_PROP.STRING)}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem} /> 
                <InputField 
                    name='Label'
                    labelText={i18n('worksheet.timeSpent.detail.name')}
                    labelPlace={LABEL.DEFAULT} 
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 4 : 3}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7.7 : 8.7}
                    type={INPUT_TYPE.TEXT}
                    value={item?.Label}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem}
                    changed={(e) => handleChange(e, CHANGED_PROP.STRING) || item?.IsShownAsWorksheetItem} />
                <InputField 
                    name='Date'
                    labelText={i18n('worksheet.timeSpent.detail.date')}
                    labelPlace={LABEL.DEFAULT} 
                    labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 4 : 3}
                    barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 7.7 : 8.7}
                    type={INPUT_TYPE.DATE}
                    value={item?.Date}
                    useDefaultDate={true}
                    changed={(date: Date) => handleChange(date, CHANGED_PROP.DATE)}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem} /> 
                {/* //időtartam + unit */}
                <Grid container spacing={0}>
                    <Grid item xs={8.5}>
                        <InputField 
                            name='Duration'
                            labelText={i18n('worksheet.timeSpent.detail.duration')}
                            labelPlace={LABEL.DEFAULT} 
                            labelAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            barAlignment={isMobile ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 5.4 : WIDTH.SIXTH + 2.2}
                            barWidth={isMobile ? WIDTH.FULL_LINE : isPortrait ? 6.6 : WIDTH.FIVE_SIXTHS - 2.3}
                            type={INPUT_TYPE.NUMBER}
                            value={item?.Duration}
                            changed={(e) => handleChange(e, CHANGED_PROP.STRING)} 
                            isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem} /> 
                    </Grid>
                    <Grid item xs={3} style={isMobile ? {} : {marginTop: '-17px', marginLeft: '12px'}}>
                        <select 
                            disabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem}
                            style={{background: hasCustomerSigned() || item?.IsShownAsWorksheetItem ? 'rgba(219, 219, 219, 1)' : 'white'}}
                            className={styles['second-input-mobile']}
                            name="QuantityUnitId"
                            value={item?.QuantityUnitId ?? Object.values(quantityUnits as ISelectableOption[])[0] ?? ''} 
                            onChange={(e) => handleChange(e, CHANGED_PROP.STRING)} >
                            {Object.values(quantityUnits as ISelectableOption[]).map((option) => 
                                <option key={option.Id} value={option.Id}>{option.Name}</option>
                            )}
                        </select>
                    </Grid>
                </Grid>
                <InputField 
                    name='Comment'
                    labelText={[i18n('worksheet.timeSpent.detail.comment'), i18n('worksheet.timeSpent.detail.commentPlus')]}
                    value={item?.Comment}
                    changed={(e) => handleChange(e, CHANGED_PROP.STRING)}
                    isDisabled={hasCustomerSigned() || item?.IsShownAsWorksheetItem} 
                    labelPlace={LABEL.DEFAULT} 
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.FULL_LINE - 0.3}
                    labelWidth={WIDTH.FULL_LINE}
                    placeholder='Megjegyzés...'
                    barHeight={INPUT_HEIGHT.FULL_SIZE}
                    type={INPUT_TYPE.TEXTAREA} />
            </Fragment>
        }
            <Button 
                isDisabled={hasCustomerSigned()} 
                text={i18n(`button.worksheetPage.delete${type}`)}
                shade={BUTTON_SHADE.LIGHTRED} 
                size={BUTTON_SIZE.WITH_ICON}
                handleAction={() => {
                    deleteItem(true) // delete from workheet items too
                }}
                icon={ICON_TYPE.DELETE} />
        </Fragment>
    )
}

export default ExpandableListItem