import { Worksheet } from "../../models/Worksheet"
import { Fragment, useContext, useEffect, useState } from "react"
import { v4 as uuidv4 } from 'uuid';
import styles from './WorksheetStatusIcons.module.scss'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DeviceContext } from "../../context/Contexts";
import { styled } from "@mui/material";

type Props = {
    ws: Worksheet
}

enum TYPE {
    INVOICE = 'Számlázva',
    PRINT = 'Nyomtatva',
    VOID = 'Rontott',
    SIGN = 'Aláírt',
    BID = 'Ajánlat kiállítva',
    MAIL = 'Kiküldve'
}

const WorksheetStatusIcons = ({ ws }: Props) => {
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const [ images, setImages ] = useState([] as JSX.Element[])
    const touchDelay = 10
    const iconClass = isMobile ? styles['smaller-icon'] : styles.icon
    const placeholderClass = isMobile ? styles['smaller-placeholder'] : styles.placeholder

    const CustomTooltip = styled(Tooltip)(({  }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: "#508FD9"
    }
    }));
    
    const importIcons = async () => {
        let iconsToAdd = [] as JSX.Element[]

        // const hasOnlySentDate = (isMobile || isPortrait) && ws.InvoicedDateTime === null && ws.VoidedDateTime === null && ws.PrintedDateTime === null && ws.CustomerSignedDateTime === null
        //     && ws.QuotedDateTime === null && ws.SentDateTime !== null
        
        // if(isMobile) {
        //     if(!hasOnlySentDate) {
        //         iconsToAdd.push(
        //             <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.INVOICE} enterTouchDelay={touchDelay} key={uuidv4() + TYPE.INVOICE} >
        //                 {ws.InvoicedDateTime !== null ? <img className={iconClass} src={require('../../images/svg/status/invoice.svg')?.default}alt='' />
        //                 : <div className={placeholderClass}></div>}
        //             </CustomTooltip>
        //         )
        //         iconsToAdd.push(
        //             <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.VOID} enterTouchDelay={touchDelay} key={uuidv4() + TYPE.VOID}>
        //                 {ws.VoidedDateTime !== null ? <img className={iconClass} src={require('../../images/svg/status/void.svg')?.default} alt='' />
        //                 : <div className={placeholderClass}></div>}
        //             </CustomTooltip>
        //         )
        //         iconsToAdd.push(
        //             <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.PRINT} enterTouchDelay={touchDelay} key={uuidv4()} >
        //                 {ws.PrintedDateTime !== null ? <img className={iconClass} src={require('../../images/svg/status/print.svg')?.default} alt='' />
        //                 : <div className={placeholderClass}></div>}
        //             </CustomTooltip>
        //         )
        //         iconsToAdd.push(
        //             <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.SIGN} enterTouchDelay={touchDelay} key={uuidv4()} >
        //                 {ws.CustomerSignedDateTime !== null ? <img className={iconClass} src={require('../../images/svg/status/sign.svg')?.default} alt='' />
        //                 : <div className={placeholderClass}></div>}
        //             </CustomTooltip>
        //         )
        //         iconsToAdd.push(
        //             <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.BID} enterTouchDelay={touchDelay} key={uuidv4()} >
        //                 {ws.QuotedDateTime !== null ? <img className={iconClass} src={require('../../images/svg/status/bid.svg')?.default} alt='' />
        //                 : <div className={placeholderClass}></div>}
        //             </CustomTooltip>
        //         )
        //     }
        //     iconsToAdd.push(
        //         <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.MAIL} enterTouchDelay={touchDelay} key={uuidv4()} >
        //             {ws.SentDateTime !== null ? <img className={iconClass} src={require('../../images/svg/status/mail.svg')?.default} alt='' />
        //             : isMobile || isPortrait ? <></> : <div className={placeholderClass}></div>}
        //         </CustomTooltip>
        //     )
            
        //     const dates = [ ws.SentDateTime, ws.QuotedDateTime, ws.CustomerSignedDateTime, ws.PrintedDateTime, ws.VoidedDateTime, ws.InvoicedDateTime ]
        //     if(!dates.every(function(n){ return n === null }))
        //         setImages(iconsToAdd)
        // }
        // else {
            if(ws.InvoicedDateTime !== null)
                iconsToAdd.push(
                    <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.INVOICE} enterTouchDelay={touchDelay} key={uuidv4() + TYPE.INVOICE} >
                        <img className={iconClass} src={require('../../images/svg/status/invoice.svg')?.default}alt='' />
                    </CustomTooltip>
                )
            if(ws.VoidedDateTime !== null)
                iconsToAdd.push(
                    <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.VOID} enterTouchDelay={touchDelay} key={uuidv4() + TYPE.VOID}>
                        <img className={iconClass} src={require('../../images/svg/status/void.svg')?.default} alt='' />
                    </CustomTooltip>
                )
            if(ws.PrintedDateTime !== null)
                iconsToAdd.push(
                    <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.PRINT} enterTouchDelay={touchDelay} key={uuidv4()} >
                        <img className={iconClass} src={require('../../images/svg/status/print.svg')?.default} alt='' />
                    </CustomTooltip>
                )
            if(ws.CustomerSignedDateTime !== null)
                iconsToAdd.push(
                    <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.SIGN} enterTouchDelay={touchDelay} key={uuidv4()} >
                        <img className={iconClass} src={require('../../images/svg/status/sign.svg')?.default} alt='' />
                    </CustomTooltip>
                )
            if(ws.QuotedDateTime !== null)
                iconsToAdd.push(
                    <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.BID} enterTouchDelay={touchDelay} key={uuidv4()} >
                        <img className={iconClass} src={require('../../images/svg/status/bid.svg')?.default} alt='' />
                    </CustomTooltip>
                )
            if(ws.SentDateTime !== null)
                iconsToAdd.push(
                    <CustomTooltip followCursor={isMobile} disableFocusListener title={TYPE.MAIL} enterTouchDelay={touchDelay} key={uuidv4()} >
                        <img className={iconClass} src={require('../../images/svg/status/mail.svg')?.default} alt='' />
                    </CustomTooltip>
                )
            setImages(iconsToAdd)
        // }
        
	}
    
    useEffect(() => {
        importIcons()
		
		return () => {
		setImages([])
		}
	}, [])

    return <Fragment>{images}</Fragment>
}

export default WorksheetStatusIcons