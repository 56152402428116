import { Card, CardContent, Grid } from "@mui/material"
import { useContext } from "react"
import { EditItemContext, VatListContext } from "../../../context/Contexts"
import { Product } from "../../../models/Product"
import { ProductCurrencyPrice } from "../../../models/ProductCurrencyPrice"
import { Vat } from "../../../models/Vat"
import { loadProductCurrencyList } from "../../../utils/HelperFunctions"
import InputField, { INPUT_TYPE } from "../../elements/InputField"
import mainStyles from '../tool/ToolModal.module.scss'

type Props = {
    index: number
    currencyId: number
    name: string
    sign: string
}

const CurrencyPriceCard = ({index, currencyId, name, sign } : Props) => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { vats } = useContext(VatListContext)

    const handleChange = async (e: any) => {

        var vatId = (editItem as Product)?.Vat
        var vat : Vat = vats[vatId] as Vat
        var isNetPrice = e.target.name === 'NetPrice';

        var x = editItem as Product
        let list = [...x.CurrencyPrices]
        let changedItem = (editItem as Product).CurrencyPrices[index]
        let priceValue = isNetPrice ? (e.target.value * (1 + (vat.Rate/100))) : (e.target.value / (1 + (vat.Rate/100)))
        list[index] = {
            ...changedItem, 
            [e.target.name]: e.target.value,
            [isNetPrice ? 'GrossPrice' : 'NetPrice']: Number(priceValue.toFixed(6))
        }
        setEditItem((prev: Product) => ({
            ...prev,
            CurrencyPrices: list
        }))
    }

    return(
        // <Grid item xs={1}>
            <Card className={mainStyles['currencyPriceCard']}>
                <CardContent>
                    <div>
                        Deviza: {name} ({sign})
                    </div>
                    <InputField 
                        name='NetPrice'
                        labelText={'Nettó devizás ár'} 
                        changed={handleChange}
                        value={(editItem as Product)?.CurrencyPrices[index]?.NetPrice ?? ''}
                        labelWidth={4} 
                        barWidth={7} 
                        type={INPUT_TYPE.NUMBER} 
                        />
                    <InputField 
                        name='GrossPrice'
                        labelText={'Bruttó devizás ár'} 
                        changed={handleChange}
                        value={(editItem as Product)?.CurrencyPrices[index]?.GrossPrice ?? ''}
                        labelWidth={4} 
                        barWidth={7} 
                        type={INPUT_TYPE.NUMBER} 
                        />
                </CardContent>
            </Card>
        // </Grid>
        )
}

export default CurrencyPriceCard