import { Fragment, useContext } from "react"
import i18n from "../../i18n"
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import styles from './BaseModal.module.scss'
import { DeviceContext } from "../../context/Contexts";
import SideMenuButton from "./SideMenuButton";
import { Worksheet } from "../../models/Worksheet";

type Props = {
    isPaneOpen: boolean
    closeSlidingPane: () => void
    title?: string
    openPreview?: () => void
    openAction?: () => void
    sendAction?: () => void
    signAction?: () => void
    makeOffer?: () => void
    makeInvoice?: () => void
    printAction?: () => void
    pdfExport?: () => void
    copyAction?: () => void
    deleteAction?: () => void
    mainMenu: boolean
    contents?: React.ReactNode
    worksheet?: Worksheet
}

const SideMenu = ({ contents, mainMenu, isPaneOpen, closeSlidingPane, title, openPreview, openAction, 
    sendAction, signAction, makeOffer, makeInvoice, printAction, pdfExport, copyAction, deleteAction, worksheet }: Props) => {
    const { isMobile, isPortrait } = useContext(DeviceContext)
    
    const checkSignatures = () => (worksheet?.CustomerSignedDateTime !== undefined && worksheet?.IssuerSignedDateTime !== undefined) 
        && (worksheet?.CustomerSignedDateTime !== null && worksheet?.IssuerSignedDateTime !== null)

    return (
        <Fragment>
            <SlidingPane
                className={isMobile ? styles['slide-in-pane-custom-mobile'] : styles['slide-in-pane-custom']}
                isOpen={isPaneOpen}
                title={title}
                width={isMobile ? '75%' : isPortrait ? '40%' : '30%'}
                hideHeader={true}
                onRequestClose={closeSlidingPane}
            >
                <div className={styles['pane-header']}>{title}</div>
                {mainMenu && 
                <Fragment>
                    {contents}
                    <div className= {styles['pane-close']}>
                        <img className={styles['pane-menu-icon-close']} src={require(`../../images/svg/panel-icons/close.svg`)?.default} onClick={closeSlidingPane} />
                        <div className={styles['pane-menu-title-close']} onClick={pdfExport}>{i18n(`sideMenu.close`)}</div>
                    </div>
                </Fragment>}
                {!mainMenu &&
                <Fragment>
                    <div style={{paddingBottom: '50px'}}>
                    <SideMenuButton onClick={openPreview} buttonText={i18n(`sideMenu.preview`)} isDisabled={false}
                        buttonPicSrc={require(`../../images/svg/panel-icons/preview-icon.svg`)?.default} />
                    <SideMenuButton onClick={openAction} buttonText={i18n(`sideMenu.open`)} isDisabled={false}
                        buttonPicSrc={require(`../../images/svg/panel-icons/open-icon.svg`)?.default}/>
                    <SideMenuButton onClick={sendAction} buttonText={i18n(`sideMenu.send`)} isDisabled={false}
                        buttonPicSrc={require(`../../images/svg/panel-icons/send-icon.svg`)?.default}/>
                    <SideMenuButton onClick={signAction} buttonText={i18n(`sideMenu.sign`)} isDisabled={checkSignatures()} 
                        buttonPicSrc={require(`../../images/svg/panel-icons/sign-icon.svg`)?.default}/>
                    <SideMenuButton onClick={makeOffer} buttonText={i18n(`sideMenu.offer`)} isDisabled={worksheet?.VoidedDateTime != null || worksheet?.InvoicedDateTime != null}
                        buttonPicSrc={require(`../../images/svg/panel-icons/offer-icon.svg`)?.default}/>
                    <SideMenuButton onClick={makeInvoice} buttonText={i18n(`sideMenu.invoice`)} isDisabled={worksheet?.VoidedDateTime != null ? true : worksheet?.InvoicedDateTime != null}
                        buttonPicSrc={require(`../../images/svg/panel-icons/invoice-icon.svg`)?.default}/>
                    <SideMenuButton onClick={printAction} buttonText={i18n(`sideMenu.print`)} isDisabled={false}
                        buttonPicSrc={require(`../../images/svg/panel-icons/print-icon.svg`)?.default}/>
                    <SideMenuButton onClick={pdfExport} buttonText={i18n(`sideMenu.pdf`)} isDisabled={false}
                        buttonPicSrc={require(`../../images/svg/panel-icons/pdf-icon.svg`)?.default}/>
                    <SideMenuButton onClick={copyAction} buttonText={i18n(`sideMenu.copy`)} isDisabled={false}
                        buttonPicSrc={require(`../../images/svg/panel-icons/copy-icon.svg`)?.default}/>
                    <SideMenuButton onClick={deleteAction} buttonText={i18n(`sideMenu.delete`)} isDisabled={worksheet?.VoidedDateTime != null}
                        buttonPicSrc={require(`../../images/svg/panel-icons/delete-icon.svg`)?.default}/>
                    </div>
                <div className= {styles['pane-close']}>
                    <img className={styles['pane-menu-icon-close']} src={require(`../../images/svg/panel-icons/close.svg`)?.default} onClick={closeSlidingPane} />
                    <div className={styles['pane-menu-title-close']}>{i18n(`sideMenu.close`)}</div>
                </div>
                </Fragment>}
            </SlidingPane>
		</Fragment>
    )
}

export default SideMenu