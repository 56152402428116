import { Worksheet } from "../models/Worksheet";

export enum ICON_TYPE {
	SEARCH = 'search-icon',
    ADD = 'addIcon',
    DELETE = 'deleteIcon',
    INFO = 'infoIcon',
    FILTER_DELETE = 'filterDeleteIcon',
	OTHER = 'someIcon',
	GMAIL = 'gmailIcon'
}

export enum INTERNAL_ROUTE {
	START = '/',
	AUTH = '/auth',
	WORKSHEET_LIST = '/worksheets',
	WORKSHEET_BY_ID = '/worksheet/:id',
	COPY_WORKSHEET = '/worksheet/new/:id',
	NEW_WORKSHEET = '/worksheet',
	WORKSHEET_SETTINGS = '/worksheet-settings',
	TIMESPENT_LIST = '/timespent',
	EXTERNALWORK_LIST = '/externalwork',
	TOOLS = '/tools/:open'
}

export enum RELATION_TYPE {
	PRODUCT = "PRODUCT",
	TIMESPENT = "TIMESPENT",
	EXTERNALWORK = "EXTERNALWORK"
}

export enum FORMAT {
	DATE = 'yyyy.MM.dd.'
}

export const defaultWorksheet = {
	FulfillmentDate: new Date(),
	VoucherDate: new Date()
} as Worksheet

export const parseJwt = (token : string) => {
	try {
		return JSON.parse(atob(token.split(".")[1]));
	} catch (e) {
		return null;
	}
  };


export const worksheetListHeaders = ['StatusId', 'VoucherNumber', 'CustomerId', 'VoucherDate', /*'StateId',*/ 'menu']
export const worksheetOrderTypes = [ undefined, 1, undefined, 0, /*undefined,*/ undefined]
export const timeSpentListHeaders = ['WorksheetId', 'Label', 'WorkerId', 'Date', 'Duration', 'lookUp']
export const timeSpentOrderTypes = [ 1, 1, undefined, 0, 0, undefined]
export const externalWorkListHeaders = ['WorksheetId', 'LicensePlateNumber', 'DriverId', 'Address', 'Distance', 'Date', 'lookUp']
export const externalWorkOrderTypes = [ 1, undefined, undefined, 1, 0, 0, undefined]
export const toolListHeaders = ['Code', 'Name', 'CustomerId', 'WorksheetCount', 'LastWorksheetDate', 'menu']
export const toolOrderTypes = [ 1, 1, undefined, 0, 0, undefined]
export const serviceHistoryHeaders = ['VoucherNumber', 'VoucherDate', 'FulfillmentDate', 'lookUp']
export const serviceHistoryOrderTypes = [ 1, 0, 0, undefined]

export const worksheetListFilters = ['voucherNumber', 'customer', 'state', 'status', 'dateType', 'startDate', 'closeDate']
export const timeSpentListFilters = ['voucherNumber', 'name', 'worker', 'startDate', 'closeDate']
export const externalWorkListFilters = ['voucherNumber', 'licensePlateNumber', 'driver', 'startDate', 'closeDate']
export const toolListFilters = ['name', 'customer']

export const toolModalTabs = ['toolData', 'customerData', 'comment', 'serviceHistory']
export const customerModalTabs = ['base', 'detail', 'other']
export const productModalTabs = ['base', 'detail', 'currencyPrice']