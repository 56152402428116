import { Grid } from "@mui/material"
import { Fragment, useContext } from "react"
import { EditItemContext, DeviceContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { Tool } from "../../../models/Tool"
import { ToolData } from "../../../models/ToolData"
import { ICON_TYPE } from "../../../utils/global"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../../elements/Button"
import ToolDataRow from "./ToolDataRow"
import styles from './ToolModal.module.scss'

const ToolDataTab = () => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const toolDataThreshold = 10
    const { isMobile, isPortrait } = useContext(DeviceContext)
   
    const addItem = () => {
        setEditItem((prevState: Tool) => ({
            ...prevState,
            ToolData: [...prevState.ToolData ?? [], {} as ToolData]
        }))
    }

    return (
        <div className={styles[isMobile ? 'tab-container-tool-mobile' : 'tab-container-tool']}>
            <Grid container spacing={0} sx={{ paddingBottom: isMobile ? '15px' : 0, paddingTop: isMobile ? '10px' : 0, paddingRight: isMobile ? '15px' : isPortrait ? '70px' : 0 }}>
                {/* <Grid item xs={12} > */}
                    <ToolDataRow fieldName='Name' index={-1} />
                    <ToolDataRow fieldName='Code' index={-1}  />
                    {(editItem as Tool)?.ToolData?.map((toolData, index) => (
                        <ToolDataRow  index={index} key={index} />))}
                {/* </Grid>     */}
                <Grid item xs={12}>
                    {((editItem as Tool)?.ToolData?.length ?? 0) < toolDataThreshold ? 
                        <Button 
                            text={i18n('button.worksheetSettingsPage.addNew')}
                            shade={BUTTON_SHADE.LIGHTBLUE}
                            size={BUTTON_SIZE.WITH_ICON_SMALL}
                            handleAction={() => addItem()}
                            icon={ICON_TYPE.ADD} />
                    : ''}
                    </Grid>
            </Grid>
        </div>
    )
}

export default ToolDataTab