import React, { useEffect, useState } from 'react'
import './App.scss'
import { CompanyDataContext, CountryListContext, EditItemIdContext, EditItemIdProps, DeviceContext, DeviceProps, QuantityUnitListContext, SelectedWorksheetContext, SelectedWorksheetProps, SettingsContext, SettingsProps, EditItemContext, EditIemProps, VatListContext, NeedRefreshContext, NeedRefreshProps } from './context/Contexts'
import { Country } from './models/Country'
import { QuantityUnit } from './models/QuantityUnit'
import { Vat } from './models/Vat'
import { BACKEND_ROUTE, getRequest } from './request'
import Routes from './Routes'
import { defaultWorksheet } from './utils/global'
import { loadMasterData } from './utils/HelperFunctions'
import { createStore} from "redux"
import { Provider } from 'react-redux'
import rootReducer from './reducers/rootReducer'
import { CompanyData } from './models/CompanyData'
import ErrorModal from './components/modal/ErrorModal'
import { Setting } from './models/Setting'
import { Settings } from './models/Settings'
import { Tool } from './models/Tool'

export const store = createStore(rootReducer);

function App() {
  const [ worksheet, setWorksheet ] = useState(defaultWorksheet)
  const [ vatList, setVatList ] = useState([] as Vat[])
  const [ quantityUnitList, setQuantitUnitList] = useState([] as QuantityUnit[])
  const [ countryList, setCountryList ] = useState([] as Country[])
  const [ settings, setSettings ] = useState({
    Statuses: [] as Setting[],
    Types: [] as Setting[],
    SubTypes: [] as Setting[],
    Drivers: [] as Setting[],
    LicensePlates: [] as Setting[],
    Workers: [] as Setting[],
    ToolDataNames: [] as Setting[]
  } as Settings)
  const [ tool, setTool ] = useState({} as Tool)
  const [ editItemId, setEditItemId ] = useState(-1)
  const [companyData, setCompanyData] = useState({} as CompanyData)
  const [ isMobile, setIsMobile ] = useState(false)
  const [ isPortrait, setIsPortrait ] = useState(false)
  const [ needRefresh, setNeedRefresh ] = useState(false)

  const fetchDatas = async () => {
    loadMasterData(BACKEND_ROUTE.VAT, setVatList, {} as Vat )
    loadMasterData(BACKEND_ROUTE.QUANTITYUNIT, setQuantitUnitList, {} as QuantityUnit )
    loadMasterData(BACKEND_ROUTE.COUNTRY, setCountryList, {} as Country )
    loadMasterData(BACKEND_ROUTE.COUNTRY, setCountryList, {} as Country )
    await getRequest(BACKEND_ROUTE.COMPANY_DATA, null).then(async function(res) { 
      if (res !== null || res[0] !== null) {
              let companyData = res as unknown as CompanyData
              setCompanyData(companyData)
      } 
      }).catch((error) =>{
              console.error(error);
      })
  }

  useEffect(() => {
      fetchDatas();
  }, [])
    
  return (
    <Provider store={store}>
      <div className="App">
        <CompanyDataContext.Provider value={{ companyData, setCompanyData }}>
          <VatListContext.Provider value={{vats: vatList}}>
            <QuantityUnitListContext.Provider value={{ quantityUnits: quantityUnitList }}>
              <CountryListContext.Provider value={{countries: countryList}}>
                <SelectedWorksheetContext.Provider value={{ worksheet, setWorksheet } as SelectedWorksheetProps}>
                  <SettingsContext.Provider value={{ settings, setSettings } as SettingsProps}>
                    <EditItemContext.Provider value={{ editItem: tool, setEditItem: setTool } as EditIemProps}>
                      <EditItemIdContext.Provider value={{ editItemId, setEditItemId } as EditItemIdProps}>
                        <DeviceContext.Provider value={{ isMobile, setIsMobile, isPortrait, setIsPortrait } as DeviceProps}>
                          <NeedRefreshContext.Provider value={{ needRefresh, setNeedRefresh } as NeedRefreshProps}>
                            <Routes />
                            <ErrorModal />
                          </NeedRefreshContext.Provider> 
                        </DeviceContext.Provider> 
                      </EditItemIdContext.Provider> 
                    </EditItemContext.Provider> 
                  </SettingsContext.Provider> 
                </SelectedWorksheetContext.Provider> 
              </CountryListContext.Provider>
            </QuantityUnitListContext.Provider>
          </VatListContext.Provider>
        </CompanyDataContext.Provider>
      </div>
    </Provider>
  )
}

export default App