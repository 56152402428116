import { Grid } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react"
import { EditItemContext, DeviceContext } from "../../../context/Contexts"
import { Category } from "../../../models/Category"
import { Currency } from "../../../models/Currency"
import { Customer } from "../../../models/Customer"
import { PaymentMethod } from "../../../models/PaymentMethod"
import InputField, { WIDTH, INPUT_TYPE } from "../../elements/InputField"
import mainStyles from '../tool/ToolModal.module.scss'

type Props = {
    categoryList: Category[]
    paymentMethodList: PaymentMethod[]
    currencyList: Currency[]
}

const DetailTab = ({ categoryList, paymentMethodList, currencyList }: Props) => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const [ paymentMethodDelayDayDisabled, setPaymentMethodDelayDayDisabled ] = useState(false)
    const { isMobile } = useContext(DeviceContext)
    const [selectedImage, setSelectedImage] = useState(null)

    useEffect(() =>{
        let pm = paymentMethodList.find(p => p.Id === Number((editItem as Customer)?.PaymentMethod))
        setPaymentMethodDelayDayDisabled(pm?.ImmediatePay === 1)
    }, [])

    const handleChange = (e: any) => {
        if (e.target.name === 'PaymentMethod') {
            let pm = paymentMethodList.find(p => p.Id === Number(e.target.value))
            setPaymentMethodDelayDayDisabled(pm?.ImmediatePay === 1)
            setEditItem((prev: Customer) => ({
                ...prev,
                PaymentMethodDelayDay: 0
            }))
        }
        setEditItem((prev: Customer) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }
    
    return (<Fragment>
        <div className={mainStyles['tab-container-customer']}>
           
                <Grid container spacing={isMobile ? 1 : 0} sx={{ paddingBottom: isMobile ? '15px' : 0, paddingTop: isMobile ? '10px' : 0 }}>
                <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='PaymentMethod'
                            labelText='Fizetési mód' 
                            type={INPUT_TYPE.SELECT} 
                            options={paymentMethodList}
                            changed={handleChange}
                            value={(editItem as Customer)?.PaymentMethod ?? ''}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='DefaultCurrency'
                            labelText={'Alapért. deviza'}
                            changed={handleChange}
                            options={currencyList}
                            value={(editItem as Customer)?.DefaultCurrency ?? ''}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7}
                            type={INPUT_TYPE.SELECT} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='PaymentMethodDelayDay'
                            labelText={'Egyedi fizetési határidő'} 
                            changed={handleChange}
                            min={0}
                            isDisabled={paymentMethodDelayDayDisabled}
                            value={(editItem as Customer)?.PaymentMethodDelayDay ?? ''}
                            type={INPUT_TYPE.NUMBER} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='Category1'
                            labelText={'Partner kategória'}
                            changed={handleChange}
                            options={categoryList}
                            hasEmptyOption={true}
                            value={(editItem as Customer)?.Category1 ?? categoryList[0]}
                            type={INPUT_TYPE.SELECT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='DiscountPercent'
                            labelText={'Kedvezmény'}
                            changed={handleChange}
                            value={(editItem as Customer)?.DiscountPercent ?? ''}
                            type={INPUT_TYPE.NUMBER} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='Webpage'
                            labelText={'Weboldal'}
                            changed={handleChange}
                            value={(editItem as Customer)?.Webpage ?? ''}
                            type={INPUT_TYPE.TEXT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='Comment'
                            labelText={'Megjegyzések'}
                            changed={handleChange}
                            value={(editItem as Customer)?.Comment ?? ''}
                            type={INPUT_TYPE.TEXTAREA} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    {/* <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='ProductPhoto'
                            labelText={'Fotó'}
                            changed={(event: any) => {
                                const reader = new FileReader()
                                const fileByteArray: any[] = []
                                reader.readAsArrayBuffer(event.target.files[0]);
                                reader.onloadend = (evt: any) => {
                                    if (evt.target.readyState === FileReader.DONE) {
                                    const arrayBuffer = evt.target.result,
                                        array = new Uint8Array(arrayBuffer);
                                    for (const a of array as any) {
                                        fileByteArray.push(a);
                                    }
                                    // setSelectedImage(fileByteArray)
                                    }
                                }
                                var file = event.target.files[0]
                                setSelectedImage(file)
                            }}
                            type={INPUT_TYPE.IMAGE} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            
                            border={INPUT_BORDER.WITHOUT}
                            barWidth={8} />
                        {selectedImage && (
                                <div>
                            <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                            <br />
                            <button onClick={()=>setSelectedImage(null)}>Remove</button>
                            </div>
                        )} 
                    </Grid> */}
                </Grid>
           
        </div>
    </Fragment>)
}

export default DetailTab