import { Fragment, useContext, useEffect, useState } from "react"
import i18n from "../../../i18n"
import { Category } from "../../../models/Category"
import { Vat } from "../../../models/Vat"
import { BACKEND_ROUTE } from "../../../request"
import { productModalTabs } from "../../../utils/global"
import { loadCategoryList, loadCurrencyList, loadNextCode, loadProductCurrencyList, loadQuantityUnitList, loadVatList } from "../../../utils/HelperFunctions"
import LoadingAnimation from "../../elements/LoadingAnimation"
import { Separator } from "../../elements/Separator"
import { MODAL_TYPE } from "../BaseModal"
import BaseTab from "./BaseTab"
import CurrencyPriceTab from "./CurrencyPriceTab"
import DetailTab from "./DetailTab"
import mainStyles from '../tool/ToolModal.module.scss'
import { Product } from "../../../models/Product"
import { EditItemContext } from "../../../context/Contexts"
import { QuantityUnit } from "../../../models/QuantityUnit"
import { Currency } from "../../../models/Currency"
import { ProductCurrencyPrice } from "../../../models/ProductCurrencyPrice"

type Props = {
    id?: number
    isShown: boolean
    openModal: (type: MODAL_TYPE) => void
    
}

const enum TAB {
    BASE = 'base',
    DETAIL = 'detail',
    OTHER = 'currencyPrice'
}



const ProductModal = ({ id, isShown, openModal }: Props ) => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const [ activeTabName, setActiveTabName ] = useState('')
    const [ activeIndex, setActiveIndex ] = useState(0)
    const [ isLoading, setIsLoading ] = useState(true)
    const [ nextProductCode, setNextProductCode ] = useState('')
    const [ categoryList, setCategoryList ] = useState([] as Category[])
    const [ vatList, setVatList ] = useState([] as Vat[])
    const [ quantityUnitList, setQuantityUnitList ] = useState([] as QuantityUnit[])
    const [ currencyList, setCurrencyList ] = useState([] as Currency[])
    const [ productCurrencyList, setProductCurrencyList ] = useState([] as ProductCurrencyPrice[])


    const showLoadingScreenInitially = async () => await setIsLoading(true)

    const openTab = (tab: string, index: number) => {
        setActiveIndex(index)
        setActiveTabName(tab)
    }

    useEffect(() => {
        showLoadingScreenInitially().then(() =>setIsLoading(false))
        openTab(productModalTabs[0], 0)
        loadCategoryList(BACKEND_ROUTE.PRODUCTCATEGORY, setCategoryList);
        loadVatList(setVatList)
        loadQuantityUnitList(setQuantityUnitList)
        loadCurrencyList(setCurrencyList, true)
        if(editItem.Id > 0)
            loadProductCurrencyList(setProductCurrencyList, editItem.Id)
        else
            loadNextCode(setNextProductCode, BACKEND_ROUTE.GET_NEXT_PRODUCT_CODE)
    }, [isShown])

    useEffect(() => {
        setEditItem((prev: Product) => ({
            ...prev,
            QuantityUnit: prev.QuantityUnit ?? quantityUnitList[0]?.Id,
            Vat: prev.Vat ?? vatList[2]?.Id,
            UnitPrice: prev.UnitPrice ?? 0,
            GrossUnitPrice: prev.GrossUnitPrice ?? 0,
            CurrencyPrices: prev.CurrencyPrices?.length > 0 ? prev.CurrencyPrices : productCurrencyList,
            Code: prev.Code?.length > 0 ? prev.Code : nextProductCode.toString()
        }))
    }, [vatList, quantityUnitList, productCurrencyList, nextProductCode])

    useEffect(() => {
        if(editItem.Id == undefined || editItem.Id == null){
            if(Object.keys(currencyList)?.length > 0){
                var cpList = [] as ProductCurrencyPrice[]
                    for(const [key, value] of Object.entries(currencyList)){
                        if(value.InternalCode !== 'forint' ){
                            var item : ProductCurrencyPrice = {
                                CurrencyId: value.Id
                                }
                            cpList.push(item)    
                        }
                    }
                setEditItem((prev: Product) => ({
                    ...prev,
                    CurrencyPrices: cpList
                }))
            }
        }
    }, [currencyList])


    const getActiveTab = () => {
        switch(activeTabName) {
            case TAB.BASE:
                return isLoading ? <LoadingAnimation /> : <BaseTab categoryList={categoryList} />
            case TAB.DETAIL:
                return <DetailTab />
            case TAB.OTHER:
                return <CurrencyPriceTab currencyList={currencyList}/>
        }
    }
    return (<Fragment>
        <div className={mainStyles['tab-header-container']}>
            {productModalTabs.map((tab, index) => (
                <div className={`${mainStyles['tab-header-customer']} ${activeIndex === index ? mainStyles['active'] : ''}`} key={index} onClick={() => openTab(tab, index)}>
                    {i18n(`modal.listType.product.new.tabs.${tab}`)}
                </div>
            ))}
        </div>
        <Separator fancy={true} activeIndex={activeIndex} tabs={productModalTabs.length} />
        {getActiveTab()}
    </Fragment>)
}

export default ProductModal;