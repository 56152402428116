import _ from "lodash"
import { Fragment, useContext, useEffect, useState } from "react"
import { store } from "../../../App"
import { EditItemContext, DeviceContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { FiltersObject } from "../../../models/Filter"
import { ServiceData } from "../../../models/ServiceData"
import { Tool } from "../../../models/Tool"
import { showErrorMessages } from "../../../reducers/rootReducer"
import { BACKEND_ROUTE, kulcsUzletRequest, REQUEST_METHOD } from "../../../request"
import { defaultFilterObj_SH } from "../../../utils/FilterDefaults"
import { serviceHistoryHeaders, serviceHistoryOrderTypes } from "../../../utils/global"
import { base64toBlob, showLoadingScreen } from "../../../utils/HelperFunctions"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../../elements/Button"
import WarningMessage from "../../elements/WarningMessage"
import { List, LIST_TYPE } from "../../lists/List"
import styles from './ToolModal.module.scss'
import * as XLSX from "xlsx"
import { ExportObject } from "../../../models/ExportObject"
import LoadingAnimation from "../../elements/LoadingAnimation"

const ServiceHistoryTab = () => {
    // let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_SH)
    const [ hasServiceHistory, setHasServiceHistory ] = useState(false)
    const [ serviceHistory, setServiceHistory ] = useState([] as ServiceData[])
    const { editItem } = useContext(EditItemContext)
    const [ filterObj, setFilterObj ] = useState({} as FiltersObject)
    const [ useDefaultOrder, setUseDefaultOrder ] = useState(true)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const [ showExportColumn, setShowExportColumn ] = useState(!isMobile)
    const [ checkedIds, setCheckedIds ] = useState([] as number[])
    const [ isLoading, setIsLoading ] = useState(false)

    
    useEffect(() => {
        if((editItem as Tool)?.ServiceHistory?.length > 0) {
            setHasServiceHistory(true)
            setServiceHistory((editItem as Tool).ServiceHistory)
        }
    }, [])

    const handlePDFexport = async () => {
        if(checkedIds?.length > 0) {
            await setIsLoading(true)
            await kulcsUzletRequest(BACKEND_ROUTE.SERVICE_HISTORY_PDF, JSON.stringify(checkedIds), REQUEST_METHOD.POST).then(async function(res) {
                if (res !== null || res[0] !== null) {
                    const blob = base64toBlob(res as unknown as string)
                    const url = URL.createObjectURL(blob)
                    setIsLoading(false)
                    window.open(url)
                } 
              }).catch((error) =>{
                setIsLoading(false)
                store.dispatch(showErrorMessages([error]))
            })
        }
        if(checkedIds?.length === 0) {
            setIsLoading(false)
            store.dispatch(showErrorMessages(['Legalább egy elem kijelölése kötelező!']))
            return
        }
        setIsLoading(false)
    }

    const handleXLSexport = async () => {
        if(checkedIds?.length > 0) {
            await setIsLoading(true)
            await kulcsUzletRequest(BACKEND_ROUTE.SERVICE_HISTORY_XLS, JSON.stringify(checkedIds), REQUEST_METHOD.POST).then(async function(res) {
                if (res !== null || res[0] !== null) {
                    const response = res as unknown as ExportObject;
                
                    const worksheet = XLSX.utils.json_to_sheet(response.ExportData)
                    const workbook = XLSX.utils.book_new()
                    XLSX.utils.book_append_sheet(workbook, worksheet, response.Filename)

                    XLSX.utils.sheet_add_aoa(worksheet, [response.Headers], { origin: "A1" });
                    XLSX.writeFile(workbook, response.Filename + "_" + Date.now() +".xlsx");
                    setIsLoading(false)
                } 
              }).catch((error) =>{
                setIsLoading(false)
                store.dispatch(showErrorMessages([error]))
            })
        }
        if(checkedIds?.length === 0) {
            setIsLoading(false)
            store.dispatch(showErrorMessages(['Legalább egy elem kijelölése kötelező!']))
            return
        }
    }

    return (
        <Fragment>
            {isLoading ? <LoadingAnimation /> : hasServiceHistory ? <Fragment>
                {!isMobile && <Fragment>
                    <Button 
                    text={'Szervizkönyv PDF export'}
                        shade={BUTTON_SHADE.LIGHTBLUE} 
                        size={BUTTON_SIZE.MEDIUM}
                        handleAction={() => showLoadingScreen(true, setIsLoading).then(() => handlePDFexport())} />
                    <Button 
                        text={'Szervizkönyv XLS export'}
                        shade={BUTTON_SHADE.LIGHTBLUE} 
                        size={BUTTON_SIZE.MEDIUM}
                        handleAction={() => showLoadingScreen(true, setIsLoading).then(() => handleXLSexport())} />
                        </Fragment>}
                <div className={styles[isMobile ? 'tab-container-mobile' : isPortrait ? 'tab-container-portrait' : 'tab-container']}>
                <List 
                    list={serviceHistory} 
                    checkedIds={checkedIds}
                    setCheckedIds={setCheckedIds}
                    showExportColumn={showExportColumn}
                    columnHeaders={serviceHistoryHeaders} 
                    listType={LIST_TYPE.SERVICEHISTORY}  
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj} 
                    orderTypes={serviceHistoryOrderTypes}
                    useDefaultOrder={useDefaultOrder}
                    setUseDefaultOrder={setUseDefaultOrder} />
                    </div>
            </Fragment> :
                <WarningMessage title={i18n('warnings.tool.noWsTitle')} text={i18n('warnings.tool.noWs')} isException={true} />}
        </Fragment>
    )
}

export default ServiceHistoryTab