import { Fragment, useContext, useEffect } from 'react'
import editIcon from '../../images/edit_icon.png'
import DatePicker, { registerLocale } from "react-datepicker"
import { DeviceContext } from '../../context/Contexts'
import { FORMAT } from '../../utils/global'
import styles from './InputField.module.scss'
import hu from "date-fns/locale/hu"
import { ISelectableOption } from '../interfaces/ISelectableOption'
import Grid from '@mui/material/Grid'
import CompanySearcharbleSelect from './searchables/CompanySearchableSelect'
import { v4 as uuidv4 } from 'uuid'
import { LABEL_PLACEMENT } from './SwitchInput'


type Props = {
	name: string
	labelText?: string | string[]
	placeholder?: string
	value?: any
	changed?: (e: any) => void
	onKeyDown?: (e: any) => void
	labelPlace?: LABEL
	type: INPUT_TYPE
	options?: ISelectableOption[]
	hasEmptyOption?: boolean
	isExpanded?: boolean
	isEdit?: boolean
	setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>
	secondInput?: JSX.Element
	border?: INPUT_BORDER
	isTooLong?: boolean
	useDefaultDate?: boolean
    labelWidth: WIDTH
    barWidth: WIDTH
    labelAlignment?: INPUT_ALIGN
    barAlignment?: INPUT_ALIGN
    barHeight?: INPUT_HEIGHT
	obligatory?: boolean
	max?: number
	validation?: INPUT_VALIDATION
	isDisabled?: boolean
	min?: number
	labelSize?: INPUT_LABEL_SIZE
	withCompanySearch?: boolean
}

export enum INPUT_VALIDATION {
	IBAN = 1,
	BANK_ACCOUNT = 2,
	TAX_NUMBER_GROUP_ID = 3
} 

export enum INPUT_HEIGHT {
    DEFAULT = '',
    FULL_SIZE = 'full-height',
	FULL_SIZE_EXTRA = 'full-height-extra',
	BUTTON = 'button-height'
}

export enum LABEL {
	FIELD_HEADER = 'label-field-header',
	FILTER = 'label-filter',
    DEFAULT = 'label',
}

export enum INPUT_BORDER {
	WITH_BORDER = 'with-border',
	ROUNDED = 'rounded-border',
	WITHOUT = 'no-border',
	RED = 'red-border'
}

export enum INPUT_TYPE {
	TEXT = 'text',
	NUMBER = 'number',
	TEXTAREA = 'text-area',
	CHECKBOX = 'checkbox',
	SELECT = 'select',
	EDITABLE_NAME = 'name-edit',
	EDITABLE_NAME_NARROW = 'name-edit-narrow',
	EMPTY = '',
	DATE = 'date',
	EXPANDABLE_HEADER = 'expandable',
	IMAGE = 'image'
}

export enum WIDTH {
    FULL_LINE = 12,
    FIVE_SIXTHS = 10,
    TWO_THIRDS = 8,
    HALF = 6,
    THIRD = 4,
    SIXTH = 2,
    NONE = 0
}

export enum INPUT_ALIGN {
    BOTTOM = 'align-bottom',
    RIGHT = 'align-right',
    LEFT = 'align-left',
    ALMOST_MIDDLE = 'align-almost-middle',
	MIDDLE = 'align-middle'
}

export enum INPUT_LABEL_SIZE {
	SMALLER = 'label-size-smaller',
	DEFAULT = 'default'
}

const InputField = ({ labelText, placeholder, name, changed, onKeyDown, value,
    labelPlace, type, options, hasEmptyOption, isExpanded, isEdit, setIsEdit, secondInput, border, 
    isTooLong, useDefaultDate, labelWidth, barWidth, labelAlignment, barAlignment, 
	barHeight, obligatory, max, validation, isDisabled, min, labelSize, withCompanySearch }: Props) => {
    registerLocale("hu", hu)
	const { isMobile, isPortrait } = useContext(DeviceContext)
	const id = uuidv4() + 'inputfield'
	
    const getInputType = () => type === INPUT_TYPE.NUMBER ? 'number' : 'text'

    const getClasses = () => 
		`${styles.input} 
		${!isMobile && labelPlace === LABEL.FILTER && styles['filter-height']}
        ${styles[type]} 
        ${barHeight && styles[barHeight]}
        ${!changed || isDisabled && styles.disabled} 
		${isDisabled && styles.disabled}
		${isExpanded && styles.expanded} 
		${border && styles[border]}`

	if(options && !options.length)
		options = Object.values(options);
	
	const CheckBox = 
		<input 
			className={getClasses()} 
			type="checkbox" 
			id={name}
			value={value ?? false} 
			name={name} 
			checked={value ?? false}
			onChange={changed} 
			disabled={isDisabled || !changed}
			readOnly={!changed}
		/>

	function format(e:any){
		if(validation && validation === INPUT_VALIDATION.BANK_ACCOUNT && max && e.target.value.length < max && e.target.value.length > 1) {
			var val = e.target.value.split('-').join('')
			if(val.length > 16){
				e.target.value = val.slice(0, 8) + '-' + val.slice(8, 16) + '-' + val.slice(16, val.length)
			}
			else if(val.length > 8){
				e.target.value = val.slice(0, 8) + '-' + val.slice(8, val.length)
			}
			else if(val.length > 7){
				e.target.value = val.slice(0, 8)
			}
			return true
		}
		else if(validation && validation === INPUT_VALIDATION.IBAN && max && e.target.value.length < max && e.target.value.length > 1) {
			var val = e.target.value.split(' ').join('')
			let list = val.match(/.{1,4}/g);
			e.target.value = list.join(' ');
			return true
		}
		else if(validation && validation === INPUT_VALIDATION.TAX_NUMBER_GROUP_ID && max && e.target.value.length < max && e.target.value.length > 1) {
			var val = e.target.value.split('-').join('')
			if(val.length > 9){
				e.target.value = val.slice(0, 8) + '-' + val.slice(8, 9) + '-' + val.slice(9, val.length)
			}
			else if(val.length > 8){
				e.target.value = val.slice(0, 8) + '-' + val.slice(8, val.length)
			}
			else if(val.length > 7){
				e.target.value = val.slice(0, 8)
			}
			return true
		}
		else{
			return false
		}
	}

	const Text = <Fragment>
		<input
			id={id}
			maxLength={max}
			onInput={format}
			onKeyPress={format}
			className={getClasses()}
			type={getInputType()}
			min={min}
			name={name}
			placeholder={placeholder ? placeholder + '...' : ''}
			onChange={changed}
			onKeyDown={onKeyDown}
			value={value ?? ''}
			readOnly={isDisabled || !changed}
		/>
		{withCompanySearch && <CompanySearcharbleSelect parentId={id} />}
	</Fragment>

	const TextArea = 
		<textarea
			maxLength={max}
			className={`${getClasses()} ${Array.isArray(labelText) ? styles['fix-absolute-height'] : ''} ${isTooLong ? styles['too-long'] : ''}`}
			name={name}
			placeholder={placeholder}
			onChange={changed}
			value={value ?? ''}
			readOnly={!changed}
		/>

	const Select = 
		<select 
			className={getClasses()} 
			name={name ?? ''} 
			id={name} 
			value={value ?? (options ? options[0] : '')}
			onChange={(e) => changed && changed(e)} >
				{hasEmptyOption && 
				<option value={''}>-Üres-</option> }
				{options && options.map((option, index) => 
					<option key={index} value={option.Id}>{option.Name}</option>
				)}
		</select>
	
	const EditableName = 
		<Fragment>
			<input 
				className={getClasses() + ' ' + styles['disabled']} 
				value={value ?? ''} 
				readOnly={true} />
			
			<img className={styles['edit-icon']} 
				src={editIcon} 
				alt='' 
				onClick={() => console.log('edit customer modal')} />
		</Fragment>

	const DateInput = 
		<DatePicker 
			name={name}
			className={getClasses()}
			locale="hu" 
			selected={value ? new Date(value) : useDefaultDate ? new Date() : null} 
			onChange={(date: Date) => changed && changed(date)}
			dateFormat={FORMAT.DATE} />

	const ExpandableHeader = 
		<input
			className={getClasses()}
			type={getInputType()}
			name={name}
			placeholder={placeholder}
			onChange={changed}
			value={getInputType() === 'text' ? value ?? '' : value ?? null}
			readOnly={!changed}
			onBlur={() => setIsEdit && setIsEdit(false)}
			onFocus={() => setIsEdit && setIsEdit(true)}
		/>

	const Image = 
		<input
			className={getClasses()}
			type="file"
			name={name}
			onChange={changed}
		/>

	const getInputArea = () => {
		switch(type) {
			case INPUT_TYPE.TEXT:
			case INPUT_TYPE.NUMBER:
				return Text
			case INPUT_TYPE.TEXTAREA:
				return TextArea
			case INPUT_TYPE.CHECKBOX:
				return CheckBox
			case INPUT_TYPE.SELECT:
				return Select
			case INPUT_TYPE.EDITABLE_NAME:
			case INPUT_TYPE.EDITABLE_NAME_NARROW:
				return EditableName
			case INPUT_TYPE.DATE:
				return DateInput
			case INPUT_TYPE.EXPANDABLE_HEADER:
				return ExpandableHeader
			case INPUT_TYPE.IMAGE:
				return Image
			case INPUT_TYPE.EMPTY:
				break
		}
	}

    const getLabelClass = `${labelPlace ? (isMobile ? `${styles[`${labelPlace}-mobile`]}` : isPortrait ? `${styles[`${labelPlace}-portrait`]}` : styles[labelPlace]) : ''} ${labelSize ? styles[labelSize] : ''}`

	const getLabel = () => 
		Array.isArray(labelText) ? 
			<label className={getLabelClass} style={labelText[0] === '-' ? {color: 'transparent'} : {}}
            htmlFor={name}>
				{labelText[0]}
				{<br/>}
				<label className={`${styles.bold} ${styles['smaller-font']}`} style={labelText[1] === '-' ? {color: 'transparent'} : {}} htmlFor={name}>{labelText[1]}</label>
			</label>
		: <label className={getLabelClass} style={labelText === '-' ? {color: 'transparent'} : {}} htmlFor={name}>{labelText + (obligatory ? '*' : '')}</label>
	
    const getLabelContainerClass = `${labelAlignment ? styles[`${labelAlignment}-label`] : ''}`

    const getBarContainerClass = `${barAlignment ? styles[`${barAlignment}-bar`] : ''}`

	return (
		<Fragment>
			{/* {label && getLabel()}
			{type !== TYPE.EMPTY && 
			<div className={`${styles['input-container']}}`}>
				{getInputArea()}
				{secondInput}
			</div>} */}
            <Grid container spacing={isMobile ? 0 : 0} style={{marginTop: isMobile || labelPlace === LABEL.FILTER || type === INPUT_TYPE.EXPANDABLE_HEADER ? 0 : '10px'}} >
                {labelText && <Grid item xs={labelWidth} className={getLabelContainerClass}>
                {labelText && getLabel()}
                </Grid>}
                <Grid item xs={barWidth} >
                    {type !== INPUT_TYPE.EMPTY && 
                    <div className={getBarContainerClass}>
                        {getInputArea()}
                        {secondInput}
                    </div>}
                </Grid>
            </Grid>

		</Fragment>
	)
}

export default InputField