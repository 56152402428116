import { useHistory, useLocation } from 'react-router-dom'
import LoadingAnimation from '../components/elements/LoadingAnimation';

const AuthPage = () => {
    const search = useLocation().search
    const history = useHistory()

    let url = new URLSearchParams(search).get('url')
    const jwt = new URLSearchParams(search).get('token')
    if(jwt){
        localStorage.removeItem('token')
        localStorage.setItem('token', jwt as string)
        if(!url)
            url = '/worksheets'
        history.push('/' + url)
    }
    else{
        // átirányítani kulcs-üzletre?
    }

    return (
        <LoadingAnimation />
    )
}

export default AuthPage