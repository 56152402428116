import { Box, Grid } from "@mui/material"
import { Fragment, useContext } from "react"
import { EditItemContext, DeviceContext } from "../../../context/Contexts"
import { Customer } from "../../../models/Customer"
import InputField, { WIDTH, INPUT_TYPE, INPUT_VALIDATION } from "../../elements/InputField"
import mainStyles from '../tool/ToolModal.module.scss'
import { ReactComponent as InfoIcon } from '../../../images/svg/info-icon.svg'
import { findCity } from "../../../utils/ZipCodes"
import { Separator } from "../../elements/Separator"


const OtherTab = () => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const taxNumInfoLink = 'https://kulcsuzlet.freshdesk.com/support/solutions/articles/47001174430-hiba%C3%BCzenetek#Helytelen-ad%C3%B3sz%C3%A1m/-csoportazonos%C3%ADt%C3%B3/-EU-ad%C3%B3sz%C3%A1m-form%C3%A1tum'
    const { isMobile } = useContext(DeviceContext)

    const handleChange = async (e: any) => {
        if(e.target.name === 'DeliveryZip') {
            if((editItem as Customer)?.CentralCountry === 237 || (editItem as Customer)?.CentralCountry === undefined) {
                let city = findCity(Number(e.target.value))
                await setEditItem((prev: Customer) => ({
                    ...prev,
                    DeliveryZip: e.target.value,
                    DeliveryCity: city
                }))
            }
        }
        else {
            await setEditItem((prev: Customer) => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }

    return (<Fragment>
        <div className={mainStyles['tab-container-customer']}>
                <Grid container spacing={isMobile ? 1 : 0} sx={{ paddingBottom: isMobile ? '15px' : 0, paddingTop: isMobile ? '10px' : 0 }}>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='BankAccount'
                            labelText='Bankszámla' 
                            type={INPUT_TYPE.TEXT} 
                            changed={handleChange}
                            value={(editItem as Customer)?.BankAccount ?? ''}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7}
                            validation={INPUT_VALIDATION.BANK_ACCOUNT}
                            max={26} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='GroupId'
                            labelText={'Csoport azonosító'}
                            changed={handleChange}
                            max={13}
                            validation={INPUT_VALIDATION.TAX_NUMBER_GROUP_ID}
                            value={(editItem as Customer)?.GroupId ?? ''}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7}
                            type={INPUT_TYPE.TEXT} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <Grid container>
                            <Grid item xs={isMobile ? 9.5 : 10.7}>
                                <InputField 
                                    name='EUTaxNumber'
                                    labelText={'EU adószám'} 
                                    changed={handleChange}
                                    max={15}
                                    isDisabled={((editItem as Customer)?.IsPrivatePerson) as any ?? false}
                                    value={(editItem as Customer)?.EUTaxNumber ?? ''}
                                    type={INPUT_TYPE.TEXT} 
                                    labelWidth={isMobile ? WIDTH.FULL_LINE : 3.9} 
                                    barWidth={isMobile ? WIDTH.FULL_LINE : 7.5} />
                            </Grid>
                            <Grid item xs={isMobile ? 1.8 : 0.6}>
                                <Box display="flex" justifyContent="flex-end">
                                    <InfoIcon style={{marginTop: isMobile ? '25px' : '10px' }} onClick={() => window.open(taxNumInfoLink, '_blank')} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='OOCTaxNumber'
                            max={100}
                            labelText={'Közösségen kívüli adószám'}
                            changed={handleChange}
                            isDisabled={((editItem as Customer)?.IsPrivatePerson) as any ?? false}
                            value={(editItem as Customer)?.OOCTaxNumber ?? ''}
                            type={INPUT_TYPE.TEXT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                    <InputField 
                            name='IBAN'
                            max={39}
                            labelText={'IBAN'}
                            validation={INPUT_VALIDATION.IBAN}
                            changed={handleChange}
                            value={(editItem as Customer)?.IBAN ?? ''}
                            type={INPUT_TYPE.TEXT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            name='SWIFT'
                            labelText={'SWIFT kód'}
                            changed={handleChange}
                            value={(editItem as Customer)?.SWIFT ?? ''}
                            type={INPUT_TYPE.TEXT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={11.7}>
                        <Separator />
                        <div className={mainStyles['delivery-title']}>{'Szállítási adatok'}</div>     
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : 12}>
                        <InputField 
                            name='DeliveryName'
                            labelText={'Név'}
                            changed={handleChange}
                            value={(editItem as Customer)?.DeliveryName ?? ''}
                            type={INPUT_TYPE.TEXT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 1} 
                            barWidth={isMobile ? 11 : 10.6} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : 6}>
                        <InputField 
                            name='DeliveryZip'
                            labelText={'Ir. szám'}
                            changed={handleChange}
                            value={(editItem as Customer)?.DeliveryZip ?? ''}
                            type={INPUT_TYPE.NUMBER} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : 6}>
                        <InputField 
                            name='DeliveryCity'
                            labelText={'Település'}
                            changed={handleChange}
                            value={(editItem as Customer)?.DeliveryCity ?? ''}
                            type={INPUT_TYPE.TEXT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                            barWidth={isMobile ? 11 : 7.7} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : 12}>
                        <InputField 
                            name='DeliveryStreet'
                            labelText={'Cím'}
                            changed={handleChange}
                            value={(editItem as Customer)?.DeliveryStreet ?? ''}
                            type={INPUT_TYPE.TEXT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 1} 
                            barWidth={isMobile ? 11 : 10.6} />
                    </Grid>
                </Grid>
        </div>
    </Fragment>)
}

export default OtherTab