import _ from "lodash"
import { Fragment, useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../../components/elements/Button"
import LoadingAnimation from "../../components/elements/LoadingAnimation"
import SearchableSelect from "../../components/elements/searchables/SearchableSelect"
import { Separator } from "../../components/elements/Separator"
import WarningMessage from "../../components/elements/WarningMessage"
import { PageHeader } from "../../components/layout/PageHeader"
import { List, LIST_TYPE } from "../../components/lists/List"
import ListFilters from "../../components/lists/ListFilters"
import BaseModal, { MODAL_TYPE } from "../../components/modal/BaseModal"
import { EditItemIdContext, DeviceContext } from "../../context/Contexts"
import i18n from "../../i18n"
import { FiltersObject } from "../../models/Filter"
import { Tool } from "../../models/Tool"
import { getRequest, BACKEND_ROUTE, fetchData } from "../../request"
import { GetOptions, CATEGORY, SETTING_TYPE, FIELD_NAME, FILTER_TYPE, HandleFilterChange, fixEmptyFilters } from "../../utils/FilterHandler"
import { INTERNAL_ROUTE, toolListHeaders, toolOrderTypes } from "../../utils/global"
import { numberWithSeparator, showLoadingScreen } from "../../utils/HelperFunctions"
import styles from './ListPage.module.scss'
import FilterOverlay from "../../components/modal/FilterOverlay"
import { MobileFilterIcon } from "../../utils/MobileFilterIcon"
import InputField, { LABEL, INPUT_BORDER, WIDTH, INPUT_TYPE } from "../../components/elements/InputField"
import { defaultPageFilter_TOOL, defaultFilterObj_TOOL, PageFilter_TOOL } from "../../utils/FilterDefaults"
import { store } from "../../App"
import { showErrorMessages } from "../../reducers/rootReducer"


type Params = {
    open: string
}

const ToolListPage = () => {
    let defaultPageFilterCopy = _.cloneDeep(defaultPageFilter_TOOL)
    let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_TOOL)
    const [ pageFilter, setPageFilter ] = useState(defaultPageFilterCopy as PageFilter_TOOL)
    const [ filterObj, setFilterObj ] = useState(defaultFilterObjCopy as FiltersObject)
    const history = useHistory()
    const params = useParams()
    const [ list, setList ] = useState([])
    const [ isShownTool, setIsShownTool ] = useState((params as Params).open === 'true')
    const [ isShownCustomer, setIsShownCustomer ] = useState(false)
    const [ modalType, setModalType ] = useState(MODAL_TYPE.NEWTOOL)
    const [ warning, setWarning ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(true)
    const { editItemId } = useContext(EditItemIdContext)
    const [ useDefaultOrder, setUseDefaultOrder ] = useState(true)
    const [ shouldEmptyFilters, setShouldEmptyFilters ] = useState(false)
    const { isMobile } = useContext(DeviceContext)
    const [ mobileFilterClicked, setMobileFilterClicked ] = useState(false)
    const [editFilters, setEditFilters] = useState(false)

    const handleChangePageFilter = async (field: string, value: string) => {
        await setPageFilter((prevstate: PageFilter_TOOL) => ({
            ...prevstate,
            [field]: value
        }))
    }

    const getCustomers = async () => {
        await getRequest(BACKEND_ROUTE.SIMPLEDCUSTOMER, null).then( async (res) => {
            if(res !== null || res[0] !== null) {
                await setPageFilter((prevstate: PageFilter_TOOL) => ({
                    ...prevstate,
                    customerOptions: res
                }))
            }
        })
        .catch(e => store.dispatch(showErrorMessages([e])))
    }
    
    const openModal = async ( type: MODAL_TYPE ) => {
        setModalType(type)
        type === MODAL_TYPE.CUSTOMER ? setIsShownCustomer(true) : setIsShownTool(true)
    }

    const closeSecondModal = async () => setIsShownCustomer(false)

    useEffect(() => {
        if(filterObj.Filters[0]?.length === 0)
            fixEmptyFilters(setFilterObj)
        if(shouldEmptyFilters)
            setTimeout(() => setShouldEmptyFilters(false), 2000);
        showLoadingScreen(true, setIsLoading)
        .then(() => {
            if(pageFilter.customerOptions?.length < 1)
                getCustomers()
            fetchData(filterObj, BACKEND_ROUTE.TOOL, setList, setWarning)
            .then(() => showLoadingScreen(false, setIsLoading))
        })
    }, [filterObj])

    const getCount = () => numberWithSeparator((list[0] as Tool)?.TotalCount ?? 0)

    const handleKeyPressed = async (key: string, name: string, value: string) => {
        if (key === 'Enter') {
            if(name === FIELD_NAME.GENERAL_SEARCH_FIELD)
                HandleFilterChange(FILTER_TYPE.INPUT, value, FIELD_NAME.GENERAL_SEARCH_FIELD, filterObj, setFilterObj, undefined, undefined, LIST_TYPE.TOOL)
            else 
                HandleFilterChange(FILTER_TYPE.INPUT, value, name, filterObj, setFilterObj)
        }
    }

    const emptyFilters = async () => {
        let defaultPageFilterCopy = _.cloneDeep(defaultPageFilter_TOOL)
        await setPageFilter(defaultPageFilterCopy as PageFilter_TOOL)
        let defaultFilterObjCopy = _.cloneDeep(defaultFilterObj_TOOL)
        await setFilterObj(defaultFilterObjCopy as FiltersObject)
        await setUseDefaultOrder(true)
        await setShouldEmptyFilters(true)
    }

    useEffect(() => {
        const body = document.querySelector('body');
        if (body !== null) 
            body.style.overflow = isShownCustomer ? 'hidden' : 'auto';
      }, [isShownCustomer])

    useEffect(() => {
    const body = document.querySelector('body');
    if (body !== null) 
        body.style.overflow = isShownTool ? 'hidden' : 'auto';
    }, [isShownTool])

    const openFilters = () => {
        if(!editFilters) {
            setMobileFilterClicked(true)
            if(mobileFilterClicked)
                setEditFilters(true)
            else setEditFilters(filterObj.Filters?.length === 0)
        }
        else 
            setMobileFilterClicked(!mobileFilterClicked)
    }

    return (
    <Fragment>
        {!isShownTool && isMobile && <Fragment>
            <MobileFilterIcon 
                clicked={mobileFilterClicked} 
                openFilters={openFilters} 
                editFilters={editFilters} 
                longPressBackspaceCallback={() => setMobileFilterClicked(true)} />
        <FilterOverlay isShown={mobileFilterClicked}  hasFilters={filterObj.Filters?.length > 0} setIsShown={setMobileFilterClicked} editFilters={editFilters} setEditFilters={setEditFilters} deleteFilters={emptyFilters}> 
            <Fragment>
            <InputField 
                    name='generalSearchFilter'
                    labelText={i18n('worksheetListPage.filters.searchList')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.generalSearchFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.GENERAL_SEARCH_FIELD, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='codeFilter'
                    labelText={i18n('toolListPage.filters.toolCode')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.codeFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.TOOLCODE, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='nameFilter'
                    labelText={i18n('toolListPage.filters.name')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.nameFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.NAME, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <SearchableSelect 
                    overrideLableStyle
                    options={GetOptions(CATEGORY.CUSTOMERS, SETTING_TYPE.NONE, pageFilter.customerOptions)} 
                    label={i18n('toolListPage.filters.customer')} 
                    fieldName={FIELD_NAME.CUSTOMER} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
            </Fragment>
        </FilterOverlay></Fragment>}
    
        <BaseModal 
            isShown={isShownTool}
            setIsShown={setIsShownTool}
            type={editItemId > -1 ? MODAL_TYPE.EDITTOOL : MODAL_TYPE.NEWTOOL}
            openModal={openModal} />
        <BaseModal 
            overrideClose={closeSecondModal}
            isShown={isShownCustomer}
            setIsShown={setIsShownCustomer}
            type={MODAL_TYPE.CUSTOMER}
            openModal={openModal} />

        <div className='page-margin'>
        {!isMobile && 
            <div className={styles['header-items']}>
                <PageHeader 
                    text={i18n('pageHeaders.tools')} 
                    goBack={() => history.push(INTERNAL_ROUTE.START)} />
                    
                <div className={styles['top-right']}>
                    <div className={styles['top-right-text-2']}>
                        {`${i18n('toolListPage.sumText')}: ${getCount()} ${i18n('toolListPage.sumUnit')}`}
                    </div>
                </div>
                <Separator/>
           </div>}
           {isMobile && 
            <div className={styles['placeholder']}>
            <PageHeader 
                sum={`${i18n('toolListPage.sumText')}: ${getCount()} ${i18n('toolListPage.sumUnit')}`}
                text={i18n('pageHeaders.tools')} 
                goBack={() => history.push(INTERNAL_ROUTE.START)} 
                button={
                    <Button 
                        text={i18n('button.toolListPage.addNewShort')}
                        shade={BUTTON_SHADE.LIGHTBLUE} 
                        size={BUTTON_SIZE.NORMAL}
                        handleAction={() => openModal(MODAL_TYPE.NEWTOOL)} />
                }
                />
            </div>
            }
            {!isMobile && 
            <><ListFilters filterObj={filterObj} setFilterObj={setFilterObj} defaultFilter={defaultFilterObj_TOOL} emptyFilters={emptyFilters} >
                <InputField 
                    name='generalSearchFilter'
                    labelText={i18n('worksheetListPage.filters.searchList')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.generalSearchFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.GENERAL_SEARCH_FIELD, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='codeFilter'
                    labelText={i18n('toolListPage.filters.toolCode')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.codeFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.TOOLCODE, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <InputField 
                    name='nameFilter'
                    labelText={i18n('toolListPage.filters.name')}
                    labelPlace={LABEL.FILTER}
                    border={INPUT_BORDER.ROUNDED}
                    type={INPUT_TYPE.TEXT} 
                    labelWidth={WIDTH.FULL_LINE} 
                    value={pageFilter.nameFilter}
                    changed={(e: any) => handleChangePageFilter(e.target.name, e.target.value)}
                    onKeyDown={(e) => handleKeyPressed(e.key, FIELD_NAME.NAME, e.target.value)}
                    barWidth={WIDTH.FULL_LINE} />
                <SearchableSelect 
                    options={GetOptions(CATEGORY.CUSTOMERS, SETTING_TYPE.NONE, pageFilter.customerOptions)} 
                    label={i18n('toolListPage.filters.customer')} 
                    fieldName={FIELD_NAME.CUSTOMER} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj}
                    shouldEmpty={shouldEmptyFilters}
                    setShouldEmptyFilters={setShouldEmptyFilters} />
            </ListFilters>

            <div className={styles['add-new-button']}>
                <Button 
                    text={i18n('button.toolListPage.addNew')}
                    shade={BUTTON_SHADE.LIGHTBLUE} 
                    size={BUTTON_SIZE.MEDIUM}
                    handleAction={() => openModal(MODAL_TYPE.NEWTOOL)} />
            </div></>
            }
            
            { isLoading ? <LoadingAnimation /> : warning !== null ? <WarningMessage text={warning} isException={true} /> :
                <List 
                    list={list} 
                    columnHeaders={toolListHeaders} 
                    listType={LIST_TYPE.TOOL} 
                    openModal={openModal} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj} 
                    orderTypes={toolOrderTypes} 
                    useDefaultOrder={useDefaultOrder}
                    setUseDefaultOrder={setUseDefaultOrder} /> }
        </div>
    </Fragment>
    )
}

export default ToolListPage