import { useContext } from "react"
import { IIndexable } from "../components/interfaces/IIndexible"
import { ISelectableOption } from "../components/interfaces/ISelectableOption"
import { LIST_TYPE } from "../components/lists/List"
import { SettingsContext } from "../context/Contexts"
import { Filter, FiltersObject } from "../models/Filter"
import { GenericSearchFilters } from "./GenericSearchFilters"

const allOptions = 'Összes'

export enum FIELD_NAME {
    CUSTOMER = 'CustomerId',
    STATUS = "StatusId",
    WORKER = "WorkerId",
    DRIVER = "DriverId",
    LICENSEPLATE = "LicensePlateNumberId",
    VOUCHERNUMBER = 'VoucherNumber',
    NAME = 'Name',
    DATETYPE = 'DateType',
    LABEL = 'Label',
    TOOLCODE = 'Code',
    GENERAL_SEARCH_FIELD = 'GeneralSearchField',
}

export enum SETTING_TYPE {
  NONE,
  STATUS = "Statuses",
  TYPE = "Types",
  SUBTYPE = "SubTypes",
  WORKER = "Workers",
  DRIVER = "Drivers",
  LICENSEPLATE = "LicensePlates",
  TOOLDATANAME = "ToolDataNames",
} 

export enum CATEGORY {
  SETTINGS,
  CUSTOMERS,
  VOUCHERNUMBERS,
  TOOLS,
  DATETYPES
}

export enum DATETYPES {
  VOUCHERDATE = 'VoucherDate',
  FULFILLMENTDATE = 'FulfillmentDate',
  DATE = 'Date'
}

export type Option = {
  value: string | number
  label: string
}

export const getDefaultFilters = async (filterObj: FiltersObject, setFilterObj: any) => 
  filterObj.Filters[0] ??
    setFilterObj((prevstate : FiltersObject) => ({
        ...prevstate,
        Filters: []
    }))

export const GetOptions = (category: CATEGORY, type: SETTING_TYPE, options?: ISelectableOption[]) => {
  const { settings } = useContext(SettingsContext)
  let result = [] as Option[]

  if(category === CATEGORY.SETTINGS) {
    const options = (settings as IIndexable)[type]
    result.push({
      value: allOptions,
      label: allOptions
    })
    for(const option of options) {
      result.push({
        value: option.Id,
        label: option.Name
      } as Option)
    }
  }
  else if((category === CATEGORY.CUSTOMERS || category === CATEGORY.VOUCHERNUMBERS  || category === CATEGORY.TOOLS) && options) {
    result.push({
      value: allOptions,
      label: allOptions
    })
    for(const option of options) {
      result.push({
        value: option?.Id,
        label: option?.Name
      } as Option)
    }
  }

  else if(category === CATEGORY.DATETYPES) {
    result.push(
      {
        value: DATETYPES.VOUCHERDATE,
        label: 'Kelte'
      } as Option
    )
    result.push(
      {
        value: DATETYPES.FULFILLMENTDATE,
        label: 'Teljesítés'
      } as Option
    )
  }
  return result
}

export enum FILTER_TYPE {
  SELECT,
  DATE,
  INPUT
}

export const HandleFilterChange = async (filterType: FILTER_TYPE, e: any, fieldName: string, filterObj: FiltersObject, 
  setFilterObj: any, dateValue?: string, operatorType?: number, listType? : LIST_TYPE) => { 

  if(fieldName === FIELD_NAME.DATETYPE)
      await setFilterObj((prevstate: any) => ({
        ...prevstate,
        dateType: e.value
      }))
  else {
    let updatedFilters: Filter[][]

    if(!e || e?.value === allOptions || e === '') {
      updatedFilters = filterObj.Filters
      if(updatedFilters.length === 0) 
        return
      else {
        var i = updatedFilters.length
          while (i--) {
            var j = updatedFilters[i].length
            while(j--){
              if (updatedFilters[i][j].FieldName === fieldName) { 
                updatedFilters[i].splice(j, 1);
              }
            }
          }
      }
    } 

    else if(filterType === FILTER_TYPE.SELECT) {
      
        var needInsert = filterObj.Filters.every(filterItem => filterItem.every(item => item.FieldName !== fieldName))
        updatedFilters = filterObj.Filters
        if(needInsert) {
          const newFilter = 
            { 
              FieldName: fieldName, 
              FilterValue: e.value, 
              OperatorType: 0, 
              FieldType: 0 
            }
          if(updatedFilters[0]){
            updatedFilters.forEach((item, index)=> {
              updatedFilters[index].push(newFilter)
            })
          }else{
            updatedFilters.push([newFilter])
          }
        }
        else {
          updatedFilters.forEach((item) => {
            item.forEach((filter) => {
              if(filter.FieldName === fieldName){
                filter.FilterValue = e.value
              }
            })
          })
        }
    }

    else if(filterType === FILTER_TYPE.DATE && dateValue && operatorType) {
      var needInsert = filterObj.Filters.every(filterItem => filterItem.every(item => 
        item.FieldName !== fieldName || (item.FieldName === fieldName && item.OperatorType !== operatorType)))
      
      updatedFilters = filterObj.Filters

      if(needInsert) {
        const newFilter = 
        { 
          FieldName: fieldName, 
          FilterValue: dateValue, 
          OperatorType: operatorType, 
          FieldType: 1 
        } 
        if(updatedFilters[0]){
          updatedFilters.forEach((item, index)=>{
            updatedFilters[index].push(newFilter)
          })
        }else{
          updatedFilters.push([newFilter])
        }
      }
      else {
        updatedFilters.forEach((item) => {
          item.forEach((filter) => {
            if(filter.FieldName === fieldName && filter.OperatorType === operatorType){
              filter.FilterValue = dateValue
            }
          })
        })
      }
    }

    else if(filterType === FILTER_TYPE.INPUT) {
      var needInsert = true
      
      filterObj.Filters.forEach((item) => {
        item.forEach((filter) => {
          if(!filter.genericSearchField && filter.FieldName === fieldName){
            needInsert = false
          }
        })
      })

      updatedFilters = filterObj.Filters
      if(needInsert) {
        if(fieldName === FIELD_NAME.GENERAL_SEARCH_FIELD && listType !== undefined){

          var i = updatedFilters.length
          while (i--) {
            var j = updatedFilters[i].length
            while(j--){
              if (updatedFilters[i][j].genericSearchField) { 
                updatedFilters[i].splice(j, 1);
              }
            }
          }

          let filters = GenericSearchFilters(listType, e)

          if(updatedFilters[0]){
            for (let i = 0; i < filters.length; i++) {
              let basicFilters = updatedFilters[0]
              filters[i] = filters[i].concat(basicFilters)
            }
          }
          updatedFilters = filters
          
        }else{
          const newFilter = 
          { 
            FieldName: fieldName,
            FilterValue: e,
            OperatorType: 11,
            FieldType: 0
          }
          
          if(updatedFilters[0]){
            updatedFilters.forEach((item, index)=>{
              updatedFilters[index].push(newFilter)
            })
          }else{
            updatedFilters.push([newFilter])
          }
        }
      }
      else {
        updatedFilters.forEach((item) => {
          item.forEach((filter) => {
            if(!filter.genericSearchField && filter.FieldName === fieldName){
              filter.FilterValue = e
            }
          })
        })
      }
      
    }
    
    await setFilterObj((prevstate: any) => ({
      ...prevstate,
      Filters: updatedFilters[0].length === 0 ? [] : updatedFilters
    }))
  }
}

  export const fixEmptyFilters = async (setFilterObj: React.Dispatch<React.SetStateAction<FiltersObject>>) => {
    await setFilterObj((prevState: any) => ({
      ...prevState,
      Filters: []
    }))
  }

  export const deleteFilters = async (filterObj: FiltersObject, setFilterObj: any, emptyFilters : any) => {
      emptyFilters()
  }