import { Product } from "../models/Product";
import { Vat } from "../models/Vat";
import { WorksheetItem } from "../models/WorksheetItem";
import { RELATION_TYPE } from "./global";

export const map_product_worksheetItem = (product : Product, vat : Vat) =>{
    var item : WorksheetItem = {
        Name: product.Name,
        ProductId: product.Id,
        Quantity: 1,
        QuantityUnitId: product.QuantityUnit,
        UnitPrice: product.UnitPrice,
        VatId: product.Vat,
        Vat: vat,
        RelationType: RELATION_TYPE.PRODUCT,
        RelationId: product.Id?.toString()
    }
    return item
}