import { Fragment, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styles from './Header.module.scss'
import React from 'react'
import {ReactComponent as ServiceModul_Logo} from '../../images/topMenu/service_modul_logo.svg'
import {ReactComponent as Worksheets_Logo} from '../../images/topMenu/worksheets_logo.svg'
import {ReactComponent as NewWorksheet_Logo} from '../../images/topMenu/new_worksheet.svg'
import {ReactComponent as Settings_Logo} from '../../images/topMenu/worksheet_settings.svg'
import {ReactComponent as ExternalWork_Logo} from '../../images/topMenu/external_work.svg'
import {ReactComponent as Timespent_Logo} from '../../images/topMenu/timespent.svg'
import {ReactComponent as Tools_Logo} from '../../images/topMenu/tools.svg'
import {ReactComponent as Back_Arrow_Logo} from '../../images/topMenu/back-arrow.svg'
import { CompanyDataContext, DeviceContext } from '../../context/Contexts'
import SideMenu from '../modal/SideMenu'
import { Separator } from '../elements/Separator'
import { INTERNAL_ROUTE } from '../../utils/global'

export const Header = () => { 
    const {companyData} = useContext(CompanyDataContext)
    const history = useHistory()
    const goBackToKeyBusiness = () =>{
        setIsTopPaneOpen(false)
        window.location.replace(process.env.REACT_APP_KULCS_UZLET_URL as string)
    }
    const [isTopPaneOpen, setIsTopPaneOpen] = useState(false)
    const { isMobile } = useContext(DeviceContext)

    const goToPage = (url : string) => {
        setIsTopPaneOpen(false)
        history.push(url)
    }

    const openTopMenu = () => {
        setIsTopPaneOpen(!isTopPaneOpen)
    }

    const closeSlidingPane = () => {
        setIsTopPaneOpen(false)
    }

    const menuItems = (<div className={styles['nav_menu']}>
        <ul>
        <li className={styles['complicated']} onClick={() => goToPage('/worksheet')}>
            <NewWorksheet_Logo />
            <span>
                Új Munkalap
            </span>
            
        </li>
        <Separator mainMenu={true} />
        <li onClick={() => goToPage('/worksheets')}>
            <Worksheets_Logo />
            <span>
                Munkalapok
            </span>
        </li>
        <Separator mainMenu={true} />
        <li onClick={() => goToPage('/timespent')}>
            <Timespent_Logo />
            <span>
                Időráfordítások
            </span>
        </li>
        <Separator mainMenu={true} />
        <li onClick={() => goToPage('/externalwork')}>
            <ExternalWork_Logo />
            <span>
                Külső munkavégzés
            </span>
        </li>
        <Separator mainMenu={true} />
        <li onClick={() => goToPage('/tools/false')}>
            <Tools_Logo />
            <span>
                Eszközök
            </span>
        </li>
        <Separator mainMenu={true} />
        <li onClick={() => goToPage('/worksheet-settings')}>
            <Settings_Logo /> 
            <span>
                Beállítások
            </span>
        </li>
        <Separator mainMenu={true} />
        <li onClick={goBackToKeyBusiness}>
            <Back_Arrow_Logo style={{fill: '#508FD9'}} /> 
            <span>
                Vissza a Kulcs-Üzletre
            </span>
        </li>
    </ul>
</div>)

    if(!isMobile) return (
        <div className={styles['header-container']}>
            <img className={styles['ks-logo']} src={require('../../images/svg/ks-logo.svg')?.default} alt='' onClick={goBackToKeyBusiness} />
            <div className={`${styles['company-name']} ${companyData.CompanyName?.length >= 26 ? styles['company-name-long'] : ''}`} onClick={goBackToKeyBusiness} >{companyData.CompanyName ?? ''}</div>
            <div className={`${styles['top_menu']} ${isTopPaneOpen ? styles['show'] : styles['hide']}`}>
                {menuItems}
                <ServiceModul_Logo className={styles['icon']} onClick={openTopMenu}/>
            </div>
        </div>
    )
    else return (
        <Fragment>
            <SideMenu 
                mainMenu={true}
                isPaneOpen={isTopPaneOpen} 
                closeSlidingPane={closeSlidingPane} 
                contents={<div className={styles['top_menu-mobile']}>{menuItems}</div>}
                />
            {(window.location.pathname !== INTERNAL_ROUTE.NEW_WORKSHEET && !window.location.pathname.includes('/worksheet/')) 
            && <div className={styles['header-container-mobile']}>
                <ServiceModul_Logo className={
                    `${styles['icon-mobile']} ${isTopPaneOpen ? styles['icon-mobile-moved'] : styles['icon-slide-back']}`} onClick={openTopMenu}/>
                </div>}
        </Fragment>)
}