import { Fragment, useContext, useEffect, useState } from "react"
import { Worksheet } from "../../models/Worksheet"
import { isObjectUnavailable } from "../../utils/HelperFunctions"
import { ListItem } from "./ListItem"
import mainStyles from './List.module.scss'
import { TimeSpent } from "../../models/TimeSpent"
import { ExternalWork } from "../../models/ExternalWork"
import { ServiceData } from "../../models/ServiceData"
import { Tool } from "../../models/Tool"
import { MODAL_TYPE } from "../modal/BaseModal"
import { FiltersObject } from "../../models/Filter"
import ListHeader from "./ListHeader"
import Pagination from "./Pagination"
import { store } from "../../App"
import { showErrorMessages } from "../../reducers/rootReducer"
import { DeviceContext } from "../../context/Contexts"
import InputField, { WIDTH, INPUT_ALIGN, LABEL, INPUT_TYPE } from "../elements/InputField"
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
// @ts-ignore
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

export interface Props {
    list: Worksheet[] | TimeSpent[] | ExternalWork[] | Tool[] | ServiceData[]
    columnHeaders: string[]
    listType: LIST_TYPE
    filterObj: FiltersObject
    setFilterObj: React.Dispatch<React.SetStateAction<FiltersObject>>
    orderTypes: any[]
    openModal?: (type: MODAL_TYPE) => void
    useDefaultOrder: boolean
    setUseDefaultOrder: React.Dispatch<React.SetStateAction<boolean>>
    showExportColumn?: boolean
    checkedIds?: number[]
    setCheckedIds?: React.Dispatch<React.SetStateAction<number[]>>
    setIsPaneOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export enum LIST_TYPE {
    WORKSHEET = 'worksheet',
    TIMESPENT = 'timeSpent',
    EXTERNALWORK = 'externalWork',
    TOOL = "tool",
    SERVICEHISTORY = "serviceHistory"
}

export const List = ({ checkedIds, setCheckedIds, showExportColumn, list, columnHeaders, orderTypes, 
    listType, filterObj, setFilterObj, openModal, useDefaultOrder, setUseDefaultOrder, setIsPaneOpen }: Props) => {
    const [ selectedItemId, setSelectedItemId ] = useState(0)
    const itemsPerPage = 10
    const maxChecked = listType === LIST_TYPE.SERVICEHISTORY ? list?.length : 10
    const [ allChecked, setAllChecked ] = useState(checkedIds?.length === maxChecked)
    const [ currentPageIndex, setCurrentPageIndex ] = useState((filterObj.Skip / itemsPerPage) + 1)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    
    const handleAllCheckedChange = (e: any) => {
        let shouldCheckAll = !allChecked
        let updatedList = checkedIds ?? []
        let currentList = []
        
        if(shouldCheckAll){
            if(updatedList.length < maxChecked) {
                let currentPageIds = (list as Worksheet[]).map(ws => ws.Id).filter(id => !updatedList.includes(id))
                
                for(const id of currentPageIds) {
                    if((currentList.length + updatedList.length) < maxChecked)
                        currentList.push(id)
                }
            }
            else {
                store.dispatch(showErrorMessages([`A maximálisan kijelölhető mennyiség ${maxChecked} munkalap.`]))
                shouldCheckAll = allChecked
            }
        }
        else {
            let currentPageIds = (list as Worksheet[]).map(ws => ws.Id)
            if(updatedList)
                updatedList = updatedList.filter(id => !currentPageIds.includes(id))
        }
        
        if(updatedList && setCheckedIds)
            setCheckedIds([...updatedList, ...currentList])
        setAllChecked(shouldCheckAll)
    }

    const getHeaders = () => {
		const headers = [] as React.ReactNode[]
		for (let i = 0; i < columnHeaders.length; i++) {
			headers.push(
                <ListHeader 
                    key={i} 
                    filterObj={filterObj} 
                    setFilterObj={setFilterObj} 
                    header={columnHeaders[i]} 
                    listType={listType} 
                    orderType={orderTypes[i]}
                    useDefaultOrder={useDefaultOrder}
                    setUseDefaultOrder={setUseDefaultOrder} />
            )
		}
        showExportColumn && headers.push((
            <TableCell align="left" key={columnHeaders.length + 2} style={{padding: 8}}>
                <InputField 
                    name='allCheck'
                    value={allChecked} 
                    changed={handleAllCheckedChange}
                    labelWidth={7}
                    labelText={isPortrait ? '' : 'Kijelölés: '}
                    barAlignment={INPUT_ALIGN.MIDDLE}
                    labelAlignment={INPUT_ALIGN.RIGHT}
                    barWidth={isPortrait ? 12 : 4}
                    labelPlace={LABEL.FIELD_HEADER}
                    type={INPUT_TYPE.CHECKBOX} />  
            </TableCell>))
		return headers
	}

    const getPageCount = () => {
        if(list[0]?.TotalCount !== undefined) {
            return Math.ceil(list[0]?.TotalCount / itemsPerPage)
        }
        else return 0
    }

    if(!isMobile) return (
        <Fragment>
            <TableContainer >
                <Table sx={{ width: '100%' }} aria-label="simple table" className={mainStyles['list-container']} >
                    <TableHead>
                    <TableRow className={mainStyles['row-header']} >
                        {getHeaders()}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map((item) => 
                            !isObjectUnavailable(item) ?
                            <ListItem 
                                setIsPaneOpen={setIsPaneOpen}
                                checkedIds={checkedIds ?? []} 
                                setCheckedIds={setCheckedIds ?? null} 
                                showExportColumn={showExportColumn} 
                                key={item.Id} item={item} 
                                selectedId={selectedItemId} 
                                setSelectedId={setSelectedItemId} 
                                listType={listType} 
                                openModal={openModal}
                                listLength={list?.length} /> 
                            : ''
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!isObjectUnavailable(filterObj) && 
                <Pagination itemsPerPage={itemsPerPage} currentPageIndex={currentPageIndex} setIndex={setCurrentPageIndex} pageCount={getPageCount()} setFilterObj={setFilterObj} />}
        </Fragment>
    )
    else return (
        <div className={mainStyles['list-container-mobile']}>
            <ResponsiveMasonry
                columnsCountBreakPoints={{300: 1, 605: 2}} >
                <Masonry columnsCount={2} gutter={'20px'}>
                    {list.map((item) => 
                        !isObjectUnavailable(item) ?
                        <ListItem 
                            setIsPaneOpen={setIsPaneOpen}
                            checkedIds={checkedIds ?? []} 
                            setCheckedIds={setCheckedIds ?? null} 
                            showExportColumn={showExportColumn} 
                            key={item.Id} item={item} 
                            selectedId={selectedItemId} 
                            setSelectedId={setSelectedItemId} 
                            listType={listType} 
                            openModal={openModal}
                            listLength={list?.length} />
                        : ''
                    )}
                </Masonry>
          </ResponsiveMasonry>
            {!isObjectUnavailable(filterObj) && 
                <Pagination itemsPerPage={itemsPerPage} currentPageIndex={currentPageIndex} setIndex={setCurrentPageIndex} pageCount={getPageCount()} setFilterObj={setFilterObj} />}
        </div>
    )
}