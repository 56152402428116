import { Box, Grid, Tooltip } from "@mui/material"
import { Fragment, useContext } from "react"
import { DeviceContext, EditItemContext, QuantityUnitListContext, VatListContext } from "../../../context/Contexts"
import { Product } from "../../../models/Product"
import InputField, { INPUT_TYPE, WIDTH } from "../../elements/InputField"
import { ReactComponent as InfoIcon } from '../../../images/svg/info-icon.svg'
import mainStyles from '../tool/ToolModal.module.scss'
import { Category } from "../../../models/Category"
import { Vat } from "../../../models/Vat"

type Props = {
    categoryList: Category[]
}

const BaseTab = ({ categoryList } : Props) => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { isMobile, isPortrait } = useContext(DeviceContext)
    const { vats } = useContext(VatListContext)
    const { quantityUnits } = useContext(QuantityUnitListContext)

    const handleChange = async (e: any) => {
        if(e.target.name === 'Vat'){
            var vat : Vat = vats[e.target.value] as Vat

            let list = [...(editItem as Product).CurrencyPrices]
            list.forEach((element, index) => {
                if(element.NetPrice){
                    let changedItem = (editItem as Product).CurrencyPrices[index]
                    list[index] = {
                        ...changedItem,
                        GrossPrice: changedItem.NetPrice != undefined ? Number((changedItem.NetPrice * (1 + (vat.Rate/100))).toPrecision(6)) : undefined
                    }
                }
            });

            await setEditItem((prev: Product) => ({
                ...prev,
                [e.target.name]: e.target.value,
                GrossUnitPrice: (editItem as Product)?.UnitPrice * (1 + (vat.Rate/100)),
                CurrencyPrices: list
            }))
        }
        else if(e.target.name === 'UnitPrice'){
            var vatId = (editItem as Product)?.Vat
            var vat : Vat = vats[vatId] as Vat
            setEditItem((prev: Product) => ({
                ...prev,
                UnitPrice: e.target.value > 0 ? e.target.value : 0,
                GrossUnitPrice: e.target.value * (1 + (vat.Rate/100))
            }))
        }
        else if(e.target.name === 'GrossUnitPrice'){
            var vatId = (editItem as Product)?.Vat
            var vat : Vat = vats[vatId] as Vat
            setEditItem((prev: Product) => ({
                ...prev,
                GrossUnitPrice: e.target.value > 0 ? e.target.value : 0,
                UnitPrice: e.target.value / (1 + (vat.Rate/100))
            }))
        }else{
            await setEditItem((prev: Product) => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }

    const handleChangeWebshopIntegration = async() => {
        await setEditItem((prev: Product) => ({
            ...prev,
            WebShopIntegration: prev.WebShopIntegration !== 1 ? 1 : 0
        }))
    }

    const handleChangeDigitalProduct = async() => {
        await setEditItem((prev: Product) => ({
            ...prev,
            IsDigital: prev.IsDigital !== 1 ? 1 : 0
        }))
    }
    const handleChangeHasStockBalance = async() =>{
        await setEditItem((prev: Product) => ({
            ...prev,
            HasStockBalance: prev.HasStockBalance !== 1 ? 1 : 0
        }))
    }
    
    return(<Fragment>
        <div className={mainStyles[isMobile ? 'tab-container-mobile' : isPortrait ? 'tab-container-portrait' : 'tab-container']}>
            <Grid container spacing={isMobile ? 1 : 0} sx={{ paddingBottom: isMobile ? '15px' : 0, paddingTop: isMobile ? '10px' : 0 }}>
                <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <InputField 
                            obligatory
                            name='Name'
                            max={140}
                            changed={handleChange}
                            labelText='Név' 
                            value={(editItem as Product)?.Name ?? ''}
                            type={INPUT_TYPE.TEXT} 
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                            barWidth={isMobile ? 11 : 7} />
                        <InputField 
                            name='QuantityUnit'
                            labelText={'Mértékegység'}
                            value={(editItem as Product)?.QuantityUnit ?? quantityUnits[0]}
                            changed={handleChange}
                            options={quantityUnits}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                            barWidth={isMobile ? 11 : 7}
                            type={INPUT_TYPE.SELECT} />
                        <InputField 
                            name='UnitPrice'
                            labelText={'Nettó egységár'} 
                            changed={handleChange}
                            value={(editItem as Product)?.UnitPrice ?? 0}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                            barWidth={isMobile ? 11 : 7} 
                            type={INPUT_TYPE.NUMBER} />
                        <InputField 
                                name='GrossUnitPrice'
                                labelText={'Bruttó egységár'} 
                                changed={handleChange}
                                value={(editItem as Product)?.GrossUnitPrice ?? 0}
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                                barWidth={isMobile ? 11 : 7} 
                                type={INPUT_TYPE.NUMBER} />
                        <InputField 
                            name='Vat'
                            labelText={'ÁFA'}
                            value={(editItem as Product)?.Vat ?? vats[0]}
                            changed={handleChange}
                            options={vats}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                            barWidth={isMobile ? 11 : 7}
                            type={INPUT_TYPE.SELECT} />
                        <InputField 
                            name='HasStockBalance'
                            labelText={'Készlet gazdálkodás'}
                            changed={handleChangeHasStockBalance}
                            value={(editItem as Product)?.HasStockBalance  === 1}
                            type={INPUT_TYPE.CHECKBOX}
                            labelWidth={isMobile ? 12 : 4} 
                            barWidth={isMobile ? 2 : 0.5} />
                        {(editItem as Product)?.HasStockBalance == 1 &&
                            <Fragment>
                                <InputField 
                                    name='MinimumQuantity'
                                    labelText={'Minimális készletszint'} 
                                    changed={handleChange}
                                    value={(editItem as Product)?.MinimumQuantity ?? 0}
                                    labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                                    barWidth={isMobile ? 11 : 7} 
                                    type={INPUT_TYPE.NUMBER} />
                                <InputField 
                                    name='StockBalance'
                                    labelText={'Készlet'} 
                                    changed={handleChange}
                                    value={(editItem as Product)?.StockBalance ?? 0}
                                    labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                                    barWidth={isMobile ? 11 : 7} 
                                    isDisabled={editItem.Id > 0}
                                    type={INPUT_TYPE.NUMBER} />
                                <InputField 
                                    name='LastPurchasedPrice'
                                    labelText={'Beszerzési ár'} 
                                    changed={handleChange}
                                    value={(editItem as Product)?.LastPurchasedPrice ?? 0}
                                    labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                                    barWidth={isMobile ? 11 : 7} 
                                    type={INPUT_TYPE.NUMBER} />
                            </Fragment>
                        }
                        <InputField 
                            name='Comment'
                            labelText={'Megjegyzés'}
                            value={(editItem as Product)?.Comment}
                            changed={handleChange}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                            barWidth={isMobile ? 11 : 7}
                            max={255}
                            type={INPUT_TYPE.TEXTAREA} />
                    </Grid>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                        <Grid container>
                            <Grid item xs={isMobile ? 10 : 10}>
                                <InputField 
                                    name='Code'
                                    max={30}
                                    labelText={'Termékkód'}
                                    changed={handleChange}
                                    value={(editItem as Product)?.Code ?? ''}
                                    type={INPUT_TYPE.TEXT} 
                                    labelWidth={isMobile ? 4.8 : 4.8} 
                                    barWidth={isMobile ? 11 : 7} />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip followCursor={isMobile} disableFocusListener title={'Maximum 30 karakteres egyedi azonosító'} enterTouchDelay={50}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <InfoIcon style={{marginTop: isMobile ? '25px' : '10px' }} />
                                    </Box>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <InputField 
                            name='Category1'
                            labelText={'Termék kategória'}
                            changed={handleChange}
                            options={categoryList}
                            hasEmptyOption={true}
                            value={(editItem as Product)?.Category1 ?? categoryList[0]}
                            type={INPUT_TYPE.SELECT}
                            labelWidth={isMobile ? 11 : 4} 
                            barWidth={isMobile ? 11 : 7} />
                        <InputField 
                            name='WebShopIntegration'
                            labelText={'Webshop termék'}
                            changed={handleChangeWebshopIntegration}
                            value={(editItem as Product)?.WebShopIntegration === 1}
                            type={INPUT_TYPE.CHECKBOX}
                            labelWidth={isMobile ? 12 : 4} 
                            barWidth={isMobile ? 2 : 0.5} />
                        <InputField 
                            name='IsDigital'
                            labelText={'Digitális termék'}
                            changed={handleChangeDigitalProduct}
                            value={(editItem as Product)?.IsDigital === 1}
                            type={INPUT_TYPE.CHECKBOX}
                            labelWidth={isMobile ? 12 : 4} 
                            barWidth={isMobile ? 2 : 0.5} />
                        {(editItem as Product)?.IsDigital == 1 &&
                            <InputField 
                                name='DigitalLink'
                                labelText={'Termék URL'} 
                                changed={handleChange}
                                value={(editItem as Product)?.DigitalLink ?? ''}
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 4} 
                                barWidth={isMobile ? 11 : 7} 
                                type={INPUT_TYPE.TEXT} />
                        }
                    </Grid>
                </Grid>
            </div>
    </Fragment>)
}

export default BaseTab