import { Fragment, useContext, useEffect, useState } from "react"
import { Prompt, useHistory } from "react-router-dom"
import { store } from "../App"
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../components/elements/Button"
import LoadingAnimation from "../components/elements/LoadingAnimation"
import WorksheetSettingsBlock from "../components/elements/settings/WorksheetSettingBlockx"
import WorksheetSettingsField from "../components/elements/settings/WorksheetSettingsField"
import WarningMessage from "../components/elements/WarningMessage"
import { PageHeader } from "../components/layout/PageHeader"
import { DeviceContext, SettingsContext } from "../context/Contexts"
import i18n from "../i18n"
import { showErrorMessages } from "../reducers/rootReducer"
import { BACKEND_ROUTE, getRequest, kulcsUzletRequest, REQUEST_METHOD } from "../request"
import { INTERNAL_ROUTE } from "../utils/global"
import styles from './WorksheetSettingsPagex.module.scss'


const WorksheetSettingsPage = () => {
    const history = useHistory()
    const { settings, setSettings } = useContext(SettingsContext)
    const [ warning, setWarning ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(true)
    const [ hasChange, setHasChange] = useState(false)
    const { isMobile } = useContext(DeviceContext)

    const alertUser = (e : any) => {
        e.preventDefault()
        e.returnValue = ''
    }

    const handleSaveClick = () => {
        window.removeEventListener('beforeunload', alertUser)
        setHasChange(false);
        kulcsUzletRequest(BACKEND_ROUTE.SETTINGS, JSON.stringify(settings), REQUEST_METHOD.POST)
            .then(async function(res) {
                if (res !== null || res[0] !== null)
                    history.push('/')
            })
          .catch((error) =>
            setWarning(error)
        )
    }

    useEffect(() => {
        const getData = () => getRequest(BACKEND_ROUTE.SETTINGS, null)
            .then(async (res) => {
                if(res !== null) 
                    await setSettings(res)
                setIsLoading(false)
            }
            ).catch((e) => store.dispatch(showErrorMessages([e])))
        getData()
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])
    
    return (
        <div className='page-margin'>
            <Prompt
                when={hasChange}
                message='Biztosan elhagyja a webhelyet? Ha mentés nélkül visszalép, módosításait nem menti a rendszer.'
            />
            {isMobile && 
                <div className={styles['placeholder']}>
                    <PageHeader 
                        text={i18n('pageHeaders.worksheetSettings')} 
                        goBack={() => history.push(INTERNAL_ROUTE.START)} />
                </div>
            }
            {!isMobile && <PageHeader 
                        text={i18n('pageHeaders.worksheetSettings')} 
                        goBack={() => history.push(INTERNAL_ROUTE.START)} />}
            { isLoading ? <LoadingAnimation /> : warning !== null ? <WarningMessage text={warning} isException={true} /> :
                <Fragment>
                    <WorksheetSettingsBlock 
                        header={i18n('worksheetSettingsPage.details.header')}
                        description={i18n('worksheetSettingsPage.details.description')}>
                        <div className={styles.fields}>
                            <WorksheetSettingsField name={i18n('worksheetSettingsPage.details.items.state')} fieldName='Statuses' hasChange={hasChange} setHasChange={setHasChange} />
                            <WorksheetSettingsField name={i18n('worksheetSettingsPage.details.items.type')} fieldName='Types' hasChange={hasChange} setHasChange={setHasChange} />
                            <WorksheetSettingsField name={i18n('worksheetSettingsPage.details.items.subtype')} fieldName='SubTypes' hasChange={hasChange} setHasChange={setHasChange} />
                        </div>
                    </WorksheetSettingsBlock>

                    <WorksheetSettingsBlock 
                        header={i18n('worksheetSettingsPage.timeSpentExternalWork.header')}
                        description={i18n('worksheetSettingsPage.timeSpentExternalWork.description')}>
                        <div className={styles.fields}>
                            <WorksheetSettingsField name={i18n('worksheetSettingsPage.timeSpentExternalWork.items.working')} fieldName='Workers' hasChange={hasChange} setHasChange={setHasChange} />
                            <WorksheetSettingsField name={i18n('worksheetSettingsPage.timeSpentExternalWork.items.driver')} fieldName='Drivers' hasChange={hasChange} setHasChange={setHasChange} />
                            <WorksheetSettingsField name={i18n('worksheetSettingsPage.timeSpentExternalWork.items.plateNumber')} fieldName='LicensePlates' hasChange={hasChange} setHasChange={setHasChange} />
                        </div>
                    </WorksheetSettingsBlock>

                    <WorksheetSettingsBlock 
                        header={i18n('worksheetSettingsPage.tool.header')}
                        description={i18n('worksheetSettingsPage.tool.description')}>
                        <div className={styles.fields}>
                            <WorksheetSettingsField fieldName='ToolDataNames' hasChange={hasChange} setHasChange={setHasChange} />
                        </div>
                    </WorksheetSettingsBlock>
                        
                    <div className={styles['action-items']} >
                        <Button 
                            text={i18n('button.general.save')}
                            shade={BUTTON_SHADE.LIGHTBLUE} 
                            size={BUTTON_SIZE.MEDIUM}
                            handleAction={handleSaveClick} />
                    </div> 
                </Fragment> }
        </div>
    )
}

export default WorksheetSettingsPage