import { Fragment } from "react"
import LoadingAnimation from "../components/elements/LoadingAnimation";
import { parseJwt } from "./global";

type Props = {
	children: React.ReactNode
}

const RequireJwt = ({ children }: Props) => {
    var isExpired = true;

    if(localStorage.getItem('token')){
        const token = localStorage.getItem('token') as string;
        const decodedJwt = parseJwt(token)
        if (decodedJwt.exp * 1000 > Date.now()) {
            isExpired = false
          }
        else{
            isExpired = true
        }

    }else{
        isExpired = true
    }
    if(isExpired){
        window.location.href = process.env.REACT_APP_KULCS_UZLET_URL as string
    }

    return (
        <Fragment>
            {!isExpired ? ( children ) : ( <LoadingAnimation /> )}
        </Fragment>
    )
}

export default RequireJwt