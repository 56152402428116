import i18n from "../../i18n"
import styles from '../../pages/WorksheetPage.module.scss'
import { useContext } from "react"
import { CountryListContext, DeviceContext, SelectedWorksheetContext, EditItemContext } from "../../context/Contexts"
import { Worksheet } from "../../models/Worksheet"
import { Tool } from "../../models/Tool"
import Grid from "@mui/material/Grid"
import InputField, { INPUT_ALIGN, LABEL, INPUT_TYPE, WIDTH } from "../elements/InputField"

type Props = {
    showFromWorksheet: boolean
    actionItems?: JSX.Element
}

const CustomerFields = ({ showFromWorksheet, actionItems }: Props) => {
    const { worksheet, setWorksheet } = useContext(SelectedWorksheetContext)
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { countries } = useContext(CountryListContext)
    const { isMobile, isPortrait } = useContext(DeviceContext)

    const hasCustomerSigned = () => worksheet?.CustomerSignedDateTime !== undefined && worksheet?.CustomerSignedDateTime !== null

    const handleChange = (e: any) => {
        if (showFromWorksheet)
            setWorksheet((prevState: Worksheet) => ({
                ...prevState,
                Contact: {
                    ...prevState.Contact,
                    [e.target.name]: e.target.value
                }
            }))
        else 
            setEditItem((prevstate: Tool) => ({
                ...prevstate,
                CustomerContact: {
                    ...prevstate.Contact,
                    [e.target.name]: e.target.value
                }
            }))
    }

    const countryName = 
        showFromWorksheet && worksheet?.Customer?.CentralCountry 
            ? countries[worksheet.Customer.CentralCountry].Name
            : (editItem as Tool)?.Customer?.CentralCountry 
            ? countries[(editItem as Tool)?.Customer?.CentralCountry].Name 
            : ''

    return (
        <div className={styles['customer-container']}>
            {/* Alapadatok */}
            <div className={isMobile ? styles['content-normal'] : styles['content-half']}>
                <InputField 
                    name='baseData-header'
                    labelText={i18n('worksheet.customer.details.baseData.header')}
                    labelPlace={LABEL.FIELD_HEADER} 
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.NONE}
                    type={INPUT_TYPE.EMPTY} />        
                <InputField 
                    name='Name'
                    labelText={i18n('worksheet.customer.details.baseData.fields.name')}
                    value={showFromWorksheet ? worksheet?.Customer?.Name : (editItem as Tool)?.Customer?.Name} 
                    barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXT}/>
                    {/* type={showFromWorksheet ? InputType.EDITABLE_NAME : InputType.EDITABLE_NAME_NARROW} /> */}
            
                <Grid container spacing={2}> 
                    <Grid item xs={isMobile ? 4 : 12}>
                        <InputField 
                            name='IsPrivatePerson'
                            labelText={i18n('worksheet.customer.details.baseData.fields.privatePerson')}
                            value={showFromWorksheet ? worksheet?.Customer?.IsPrivatePerson : (editItem as Tool)?.Customer?.IsPrivatePerson === 1} 
                            barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 8 : showFromWorksheet ? 9 : 8}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 4 : showFromWorksheet ? 3 : 4}
                            barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.CHECKBOX} />
                    </Grid>
                    <Grid item xs={isMobile ? 8 : 12} style={!isMobile ? {paddingTop: 0} : {}}>
                        <InputField 
                            name='TaxNumber'
                            labelText={i18n('worksheet.customer.details.baseData.fields.taxNumber')}
                            value={showFromWorksheet ? worksheet?.Customer?.TaxNumber : (editItem as Tool)?.Customer?.TaxNumber} 
                            barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.TEXT} />
                    </Grid>
                </Grid>
            </div>
            
            {/* Kapcsolattartó */}
            <div className={isMobile ? styles['content-normal'] : styles['content-half']}>
                <InputField 
                    name='contactInfo-header'
                    labelText={i18n('worksheet.customer.details.contactInfo.header')}
                    labelPlace={LABEL.FIELD_HEADER} 
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.NONE}
                    type={INPUT_TYPE.EMPTY} />
                <InputField 
                    name='ContactName'
                    labelText={i18n('worksheet.customer.details.contactInfo.fields.name')}
                    isDisabled={hasCustomerSigned()}
                    changed={handleChange} 
                    value={showFromWorksheet ? worksheet?.Contact?.ContactName : (editItem as Tool)?.Contact?.ContactName ?? ''} 
                    labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXT} />
                <InputField 
                    name='Phone'
                    labelText={i18n('worksheet.customer.details.contactInfo.fields.phone')}
                    changed={handleChange} 
                    isDisabled={hasCustomerSigned()}
                    value={showFromWorksheet ? worksheet?.Contact?.Phone : (editItem as Tool)?.Contact?.Phone ?? ''} 
                    labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXT} />
                <InputField 
                    name='Email'
                    labelText={i18n('worksheet.customer.details.contactInfo.fields.email')}
                    changed={handleChange} 
                    isDisabled={hasCustomerSigned()}
                    value={showFromWorksheet ? worksheet?.Contact?.Email : (editItem as Tool)?.Contact?.Email ?? ''} 
                    labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXT} />
            </div> 

            {/* Számlázási cím */}
            <div className={isMobile ? styles['content-normal'] : styles['content-half']}>
                <InputField 
                    name='centralAddress-header'
                    labelText={i18n('worksheet.customer.details.centralAddress.header')}
                    labelPlace={LABEL.FIELD_HEADER} 
                    labelWidth={WIDTH.FULL_LINE}
                    labelAlignment={INPUT_ALIGN.LEFT}
                    barWidth={WIDTH.NONE}
                    type={INPUT_TYPE.EMPTY} />
                <InputField 
                    name='centralAddress-country'
                    labelText={i18n('worksheet.customer.details.centralAddress.fields.country')}
                    value={countryName} 
                    labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXT} />
                <Grid container spacing={2}>
                    <Grid item xs={isMobile ? 4 : 12}>
                        <InputField 
                            name='CentralZip'
                            labelText={i18n('worksheet.customer.details.centralAddress.fields.zip')}
                            value={showFromWorksheet ? worksheet?.Customer?.CentralZip : (editItem as Tool)?.Customer?.CentralZip} 
                            barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.TEXT} />
                    </Grid>
                    <Grid item xs={isMobile ? 8 : 12} style={!isMobile ? {paddingTop: 0} : {}}>
                        <InputField 
                            name='CentralCity'
                            labelText={i18n('worksheet.customer.details.centralAddress.fields.city')}
                            value={showFromWorksheet ? worksheet?.Customer?.CentralCity : (editItem as Tool)?.Customer?.CentralCity} 
                            barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                            barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                            labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                            labelPlace={LABEL.DEFAULT}
                            type={INPUT_TYPE.TEXT} />
                    </Grid>
                </Grid>
                <InputField 
                    name='CentralStreet'
                    labelText={i18n('worksheet.customer.details.centralAddress.fields.address')}
                    value={showFromWorksheet ? worksheet?.Customer?.CentralStreet : (editItem as Tool)?.Customer?.CentralStreet} 
                    labelAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barAlignment={isMobile || !showFromWorksheet ? INPUT_ALIGN.LEFT : INPUT_ALIGN.RIGHT}
                    barWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 9 : showFromWorksheet ? WIDTH.FIVE_SIXTHS : 9}
                    labelWidth={isMobile ? WIDTH.FULL_LINE : showFromWorksheet && isPortrait ? 3 : showFromWorksheet ? WIDTH.SIXTH : 3}
                    labelPlace={LABEL.DEFAULT}
                    type={INPUT_TYPE.TEXT} />
            </div> 
            {actionItems}
        </div>
    )
}

export default CustomerFields