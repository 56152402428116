import styles from './PageHeader.module.scss'
import { useContext } from 'react'
import { DeviceContext } from '../../context/Contexts'
import { Grid } from '@mui/material'
import { Separator } from '../elements/Separator'

export interface Props {
    text: string
    goBack: () => void
    position?: string
    sum?: string
    button?: React.ReactNode
}

export const PageHeader = ({ text, goBack, position, sum, button }: Props) => {
    const { isMobile } = useContext(DeviceContext)
    
    if(!isMobile) return (
        <div className={position === 'right' ? styles.right : styles.none}>
            <img src={require('../../images/svg/arrow-back.svg')?.default} className={`${styles['back-button']} ${position === 'right' ? styles.rightButton : ''}`} onClick={goBack} />
            <div className={`${styles['text']} ${position === 'right' ? styles.rightText : ''}`}>{text}</div>
        </div>
    )
    else return ( 
        <div className={`${styles['header-mobile']} ${position !== 'ws-page' ? styles['position-fix'] : ''}`}>
            <Grid container spacing={2}>
                <Grid item >
                    <img src={require('../../images/svg/arrow-back-mobile.svg')?.default} onClick={goBack} className={styles['back-icon-mobile']}/>
                </Grid>
                <Grid item >
                    <div>
                        <div className={styles['header-title']}>{text}</div>
                        <div  className={styles['header-sum']}>{sum}</div>
                    </div>
                </Grid>
                {button && 
                    <Grid item>
                        {button}
                    </Grid>
                }
            </Grid>
            <Separator/>
        </div>
    )
}