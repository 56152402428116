import { FormControlLabel, Switch, styled, FormLabel } from "@mui/material"

type Props = {
    handleChange: (e: any) => Promise<void>
    defaultValue: any
    label: string
    value: any
    name: string
    labelPosition?: LABEL_PLACEMENT
}

export const enum LABEL_PLACEMENT {
    TOP = 'top',
    START = 'start',
    BOTTOM = 'bottom',
    END = 'end'
}

const SwitchInput = ({ handleChange, defaultValue, label, value, name, labelPosition }: Props) => {

    const StyledSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
        ))(({ theme }) => ({
            width: 42,
            height: 26,
            padding: 0,
            '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#007aff' : '#007aff',
                opacity: 1,
                border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#000',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
            },
            '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            },
            '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            },
        }))
      

    return (
        
        <FormControlLabel 
            control={<StyledSwitch sx={{ m: 1 }} />}
            label={
                <FormLabel style={{color: '#29333c', marginTop: '8px'}} component="legend">{label}</FormLabel>
           }
            name={name}  checked={value ?? defaultValue}
            onChange={handleChange}
            labelPlacement={labelPosition ?? 'end'} />
    )
}

export default SwitchInput