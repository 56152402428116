import { Fragment, useContext, useEffect } from "react";
import styles from './BaseModal.module.scss'
import closeIcon from '../../images/close_icon.png'
import i18n from '../../i18n'
import { useSelector, useDispatch } from 'react-redux'
import { ErrorModalModel } from "../../models/ErrorModalModel";
import { closeErrorModal } from "../../reducers/rootReducer";
import { Separator } from "../elements/Separator";
import { Button, BUTTON_SHADE, BUTTON_SIZE } from "../elements/Button";
import Grid from "@mui/material/Grid";
import { DeviceContext } from "../../context/Contexts";

const ErrorModal = () =>{
    const dispatch = useDispatch();
    const isShown = useSelector((state: ErrorModalModel) => state.isShown)
    const errors = useSelector((state: ErrorModalModel) => state.errors)
    const { isMobile } = useContext(DeviceContext)

    const closeModal = () => dispatch(closeErrorModal(false));

    return (
        <Fragment>
            <div className={styles.modal + ' ' + (isShown ? styles.open : '')}>
                <div className={styles["modal-overlay"]}  ></div>
                <div className={styles["modal-card"]}>
                    <div className={styles["modal-body"]}>
                        <div className={styles["modal-header"]}>
                            <Grid container spacing={1} >
                                <Grid item xs={isMobile ? 10 : 11}>
                                <div className={styles['header-text']}>{i18n('modal.errorHeader')}</div>
                                </Grid>
                                <Grid item xs={1}>
                                <img className={styles['headerIcon']} src={closeIcon} alt='' onClick={closeModal}/>
                                </Grid>
                            </Grid>
                        </div>
                        <Separator />
                        <div>
                        {errors.map((item, index) =>(
                            <div key={index}>
                                <div>{item}</div>
                                <Separator light={true} />
                            </div>
                            ))}
                        </div>
                        <div className={styles['center-button']}>
                            <Button 
                                text={i18n('button.general.ok')}
                                shade={BUTTON_SHADE.LIGHTBLUE} 
                                size={BUTTON_SIZE.NORMAL}
                                handleAction={closeModal} />
                        </div>
                        
                    </div>
                </div>
            </div>
		</Fragment>
    )
}

// const mapStateToProps = (state : ErrorModalModel) => {
//     return {
//         errors: state.errors,
//         isShown: state.isShown
//     }
// }

// export default connect(mapStateToProps)(ErrorModal);
export default ErrorModal;