import { Fragment, useContext, useState } from 'react'
import { DeviceContext, NeedRefreshContext } from '../../context/Contexts'
import i18n from '../../i18n'
import { FiltersObject } from '../../models/Filter'
import PaginationButton from '../elements/PaginationButton'
import styles from './Pagination.module.scss'

type Props = {
    itemsPerPage: number
    pageCount: number
    currentPageIndex: number
    setIndex: React.Dispatch<React.SetStateAction<number>>
    setFilterObj: React.Dispatch<React.SetStateAction<FiltersObject>>
    centerDivs?: boolean
    needRefresh?: boolean
}

const Pagination = ({ itemsPerPage, currentPageIndex, pageCount, setIndex, setFilterObj, centerDivs, needRefresh }: Props) => {
    const text = `.oldal - összesen: ${pageCount} oldal`
    const [ userIndexInput, setUserIndexInput ] = useState(currentPageIndex)
    const { isMobile } = useContext(DeviceContext)
    const { setNeedRefresh } = useContext(NeedRefreshContext)

    const changeIndex = (e: any) => {
        setUserIndexInput(e.target.value)
    }

    const updateFilters = async (e: any, manual: boolean, next: boolean) => {
        if(e.key === 'Enter' && manual) {
            if(parseInt(e.target.value) > 0 && parseInt(e.target.value) % 1 === 0) {
                setIndex(userIndexInput)
                await setFilterObj((prev) => ({
                    ...prev,
                    Skip: prev.Take * (e.target.value - 1)
                }))
                needRefresh && await setNeedRefresh(true)
            } 
        }
        else if(next) {
            setIndex((prev) => prev + 1)
            setFilterObj((prev) => ({
                ...prev,
                Skip: prev.Skip + itemsPerPage
            }))
            needRefresh && await setNeedRefresh(true)
        }
        else if(!manual && !next) {
            setIndex((prev) => prev - 1)
            setFilterObj((prev) => ({
                ...prev,
                Skip: prev.Skip - itemsPerPage
            }))
            needRefresh && await setNeedRefresh(true)
        } 
    }

    return (
        <div className={styles['container']}>
            {isMobile ? 
            <div className={styles[centerDivs ? 'center-buttons' : '']}>
                <input type='number' min="0" step="1" value={userIndexInput} onChange={changeIndex} onKeyDown={(e) => updateFilters(e, true, false)} max={pageCount} className={styles['input']} />
                <div className={styles['text']}>{text}</div>
            </div> 
            : <Fragment>
                <input type='number' min="0" step="1" value={userIndexInput} onChange={changeIndex} onKeyDown={(e) => updateFilters(e, true, false)} max={pageCount} className={styles['input']} />
                <div className={styles['text']}>{text}</div>
            </Fragment> }    
            <div className={styles[centerDivs && isMobile ? 'center-buttons' : 'buttons']}>
                <PaginationButton text={i18n('pagination.previous')} verySpacious={centerDivs && isMobile} isDisabled={currentPageIndex <= 1} action={() => updateFilters('', false, false)} />
                <PaginationButton text={i18n('pagination.next')} verySpacious={centerDivs && isMobile}  isDisabled={currentPageIndex === pageCount} action={() => updateFilters('', false, true)} />
            </div>
        </div>)
}

export default Pagination