import { Grid } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react"
import { store } from "../../../App"
import { EditItemContext, EditItemIdContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { Category } from "../../../models/Category"
import { Currency } from "../../../models/Currency"
import { Customer } from "../../../models/Customer"
import { PaymentMethod } from "../../../models/PaymentMethod"
import { Tool } from "../../../models/Tool"
import { showErrorMessages } from "../../../reducers/rootReducer"
import { getRequest, BACKEND_ROUTE } from "../../../request"
import { customerModalTabs, toolModalTabs } from "../../../utils/global"
import { loadCategoryList, loadPaymentMethodList, loadCurrencyList, loadNextCode } from "../../../utils/HelperFunctions"
import LoadingAnimation from "../../elements/LoadingAnimation"
import { Separator } from "../../elements/Separator"
import SwitchInput from "../../elements/SwitchInput"
import { MODAL_TYPE } from "../BaseModal"
import mainStyles from '../tool/ToolModal.module.scss'
import BaseTab from "./BaseTab"
import DetailTab from "./DetailTab"
import OtherTab from "./OtherTab"

type Props = {
    isShown: boolean
    openModal: (type: MODAL_TYPE) => void
    
}

const enum TAB {
    BASE = 'base',
    DETAIL = 'detail',
    OTHER = 'other'
}

const CustomerModal = ({ isShown }: Props ) => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const [ activeIndex, setActiveIndex ] = useState(0)
    const [ activeTabName, setActiveTabName ] = useState(toolModalTabs[0])
    const [ isLoading, setIsLoading ] = useState(true)
    const [ categoryList, setCategoryList ] = useState([] as Category[])
    const [ paymentMethodList, setPaymentMethodList ] = useState([] as PaymentMethod[])
    const [ currencyList, setCurrencyList ] = useState([] as Currency[])
    const footNote1 = '** Amennyiben külföldi partner EU adószámát vagy Közösségen kívüli adószámát szeretnéd rögzíteni, azt a 3. lapon tedd meg!'
    const footNote2 = '* Kötelező mező'
    const [ nextCustomerCode, setNextCustomerCode ] = useState('')

    const openTab = (tab: string, index: number) => {
        setActiveIndex(index)
        setActiveTabName(tab)
    }

    const showLoadingScreenInitially = async () => await setIsLoading(true)

    const getCustomerById = async () => {
        await getRequest(BACKEND_ROUTE.CUSTOMER, null, '/'+ editItem?.Id).then(async function(res) { 
            if (res !== null || res[0] !== null) {
                let customerItem = res as unknown as Customer;
                setEditItem(customerItem)
            } 
          }).catch((error) =>{
            store.dispatch(showErrorMessages([error]))
        })
    }

    const getEditableItem = async () => {
        if(editItem?.Id > -1) 
            await getCustomerById()
        else {
            loadNextCode(setNextCustomerCode, BACKEND_ROUTE.GET_NEXT_CUSTOMER_CODE)
            setEditItem({} as Customer)
        }
    }

    useEffect(() => {
        showLoadingScreenInitially().then(() =>setIsLoading(false))
        getEditableItem()
        openTab(customerModalTabs[0], 0)
        loadCategoryList(BACKEND_ROUTE.CUSTOMERCATEGORY, setCategoryList);
        loadPaymentMethodList(setPaymentMethodList)
        loadCurrencyList(setCurrencyList)

    }, [isShown])

    useEffect(() => {
        setEditItem((prev: Customer) => ({
            ...prev,
            PaymentMethod: prev.PaymentMethod === undefined ? paymentMethodList[0]?.Id : prev.PaymentMethod,
            PaymentMethodDelayDay: prev.PaymentMethodDelayDay === undefined ? 0 : prev.PaymentMethodDelayDay,
            IsPrivatePerson: prev.IsPrivatePerson === undefined ? 0 : prev.IsPrivatePerson,
            Code: prev.Code?.length > 0 ? prev.Code : nextCustomerCode.toString()
        }))
    }, [paymentMethodList, nextCustomerCode])


    const getActiveTab = () => {
        switch(activeTabName) {
            case TAB.BASE:
                return isLoading ? <LoadingAnimation /> : <BaseTab />
            case TAB.DETAIL:
                return <DetailTab categoryList={categoryList} paymentMethodList={paymentMethodList} currencyList={currencyList} />
            case TAB.OTHER:
                return <OtherTab />
        }
    }

    const handleChangePrivatePersonStatus = async() => {
        await setEditItem((prev: Customer) => ({
            ...prev,
            IsPrivatePerson: prev.IsPrivatePerson === 0 ? 1 : 0,
            TaxNumber: !prev.IsPrivatePerson ? '' : prev.TaxNumber,
            EUTaxNumber: !prev.IsPrivatePerson ? '' : prev.EUTaxNumber,
            OOCTaxNumber:  !prev.IsPrivatePerson ? '' : prev.OOCTaxNumber
        }))
    }

    return (<Fragment>
        <div className={mainStyles['tab-header-container']}>
            {customerModalTabs.map((tab, index) => (
                <div className={`${mainStyles['tab-header-customer']} ${activeIndex === index ? mainStyles['active'] : ''}`} key={index} onClick={() => openTab(tab, index)}>
                    {i18n(`modal.listType.customer.new.tabs.${tab}`)}
                </div>
            ))}
        </div>
        <Separator fancy={true} activeIndex={activeIndex} tabs={customerModalTabs.length} />
        <Grid container spacing={0} justifyContent="flex-end" >
                <SwitchInput 
                    handleChange={handleChangePrivatePersonStatus} 
                    defaultValue={(editItem as Customer)?.IsPrivatePerson === 1} 
                    label={"Magánszemély"} 
                    value={(editItem as Customer)?.IsPrivatePerson === 1} 
                    name={"IsPrivatePerson"} />
        </Grid>
        {getActiveTab()}
        <div style={{fontSize: 'smaller'}}>{footNote1}</div>
        <div style={{fontSize: 'smaller'}}>{footNote2}</div>
    </Fragment>)
}

export default CustomerModal