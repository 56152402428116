import { Box, Grid, Tooltip } from "@mui/material"
import { Fragment, useContext } from "react"
import { CountryListContext, EditItemContext, DeviceContext } from "../../../context/Contexts"
import i18n from "../../../i18n"
import { Customer } from "../../../models/Customer"
import InputField, { INPUT_TYPE, INPUT_VALIDATION, WIDTH } from "../../elements/InputField"
import mainStyles from '../tool/ToolModal.module.scss'
import { ReactComponent as InfoIcon } from '../../../images/svg/info-icon.svg'
import { findCity } from "../../../utils/ZipCodes"


const BaseTab = () => {
    const { editItem, setEditItem } = useContext(EditItemContext)
    const { countries } = useContext(CountryListContext)
    const { isMobile } = useContext(DeviceContext)
    const taxNumInfoLink = 'https://kulcsuzlet.freshdesk.com/support/solutions/articles/47001174430-hiba%C3%BCzenetek#Helytelen-ad%C3%B3sz%C3%A1m/-csoportazonos%C3%ADt%C3%B3/-EU-ad%C3%B3sz%C3%A1m-form%C3%A1tum'

    const handleChange = async (e: any) => {
        if(e.target.name === 'CentralZip' && ((editItem as Customer)?.CentralCountry === 237 || (editItem as Customer)?.CentralCountry === undefined)) {
            let city = findCity(Number(e.target.value))
            setEditItem((prev: Customer) => ({
                ...prev,
                CentralZip: e.target.value,
                CentralCity: city
            }))
        }
        else {
            await setEditItem((prev: Customer) => ({
                ...prev,
                [e.target.name]: e.target.value
            }))
        }
    }

    return (<Fragment>
        <div className={mainStyles['tab-container-customer']}>
                <Grid container spacing={isMobile ? 1 : 0} sx={{ paddingBottom: isMobile ? '15px' : 0, paddingTop: isMobile ? '10px' : 0 }}>
                    <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            <InputField 
                                obligatory
                                withCompanySearch
                                name='Name'
                                max={140}
                                changed={handleChange}
                                labelText='Név' 
                                value={(editItem as Customer)?.Name ?? ''}
                                type={INPUT_TYPE.TEXT} 
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                                barWidth={isMobile ? 11 : 7.7} />
                        </Grid>
                        <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            <InputField 
                                name='CentralCountry'
                                labelText={i18n('worksheet.details.details.placeOfWork.fields.country')}
                                value={(editItem as Customer)?.CentralCountry ?? 237}
                                changed={handleChange}
                                options={countries}
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                                barWidth={isMobile ? 11 : 7.7}
                                type={INPUT_TYPE.SELECT} />
                        </Grid>
                        <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            {isMobile ? 
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <InputField 
                                    obligatory
                                    name='CentralZip'
                                    labelText={'Ir. szám'} 
                                    changed={handleChange}
                                    value={(editItem as Customer)?.CentralZip ?? ''}
                                    type={INPUT_TYPE.NUMBER} 
                                    labelWidth={WIDTH.FULL_LINE} 
                                    barWidth={11} />
                                </Grid>
                                <Grid item xs={7.6}>
                                    <InputField 
                                        obligatory
                                        name='CentralCity'
                                        labelText={'Település'} 
                                        changed={handleChange}
                                        value={(editItem as Customer)?.CentralCity ?? ''}
                                        type={INPUT_TYPE.TEXT} 
                                        labelWidth={WIDTH.FULL_LINE} 
                                        barWidth={11} />
                                </Grid>
                            </Grid>
                            : <InputField 
                                obligatory
                                name='CentralZip'
                                labelText={'Ir. szám'} 
                                changed={handleChange}
                                value={(editItem as Customer)?.CentralZip ?? ''}
                                type={INPUT_TYPE.NUMBER} 
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                                barWidth={isMobile ? 11 : 7.7} />}
                        </Grid>
                        <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            <Grid container>
                                <Grid item xs={isMobile ? 9.5 : 10.7}>
                                    <InputField 
                                        name='Code'
                                        max={20}
                                        labelText={'Partnerkód'}
                                        changed={handleChange}
                                        value={(editItem as Customer)?.Code ?? ''}
                                        type={INPUT_TYPE.TEXT} 
                                        labelWidth={isMobile ? WIDTH.FULL_LINE : 3.9} 
                                        barWidth={isMobile ? WIDTH.FULL_LINE : 7.5} />
                                </Grid>
                                <Grid item xs={isMobile ? 1.5 : 0.6}>
                                    <Tooltip followCursor={isMobile} disableFocusListener title={'Maximum 20 karakteres egyedi azonosító'} enterTouchDelay={50}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <InfoIcon style={{marginTop: isMobile ? '25px' : '10px' }} />
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!isMobile && <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            <InputField 
                                obligatory
                                name='CentralCity'
                                labelText={'Település'} 
                                changed={handleChange}
                                value={(editItem as Customer)?.CentralCity ?? ''}
                                type={INPUT_TYPE.TEXT} 
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                                barWidth={isMobile ? 11 : 7.7} />
                        </Grid>}
                        <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}> 
                            <Grid container>
                                <Grid item xs={isMobile ? 9.5 : 10.7}>
                                    <InputField 
                                        name='TaxNumber'
                                        labelText={'Magyar adószám**'}
                                        changed={handleChange}
                                        max={13}
                                        validation={INPUT_VALIDATION.TAX_NUMBER_GROUP_ID}
                                        isDisabled={((editItem as Customer)?.IsPrivatePerson) as any ?? false}
                                        value={(editItem as Customer)?.TaxNumber ?? ''}
                                        type={INPUT_TYPE.TEXT} 
                                        labelWidth={isMobile ? WIDTH.FULL_LINE : 3.9} 
                                        barWidth={isMobile ? WIDTH.FULL_LINE : 7.5} />
                                </Grid>
                                <Grid item xs={isMobile ? 1.8 : 0.6}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <InfoIcon style={{marginTop: isMobile ? '25px' : '10px' }} onClick={() => window.open(taxNumInfoLink, '_blank')} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            <InputField 
                                obligatory
                                name='CentralStreet'
                                labelText={'Cím'} 
                                changed={handleChange}
                                value={(editItem as Customer)?.CentralStreet ?? ''}
                                type={INPUT_TYPE.TEXT} 
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                                barWidth={isMobile ? 11 : 7.7} />
                        </Grid>
                        <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            <InputField 
                                name='Phone'
                                labelText={'Telefonszám'}
                                changed={handleChange}
                                value={(editItem as Customer)?.Phone ?? ''}
                                type={INPUT_TYPE.TEXT} 
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                                barWidth={isMobile ? 11 : 7.7} />
                        </Grid>
                        <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            <InputField 
                                name='ContactName'
                                labelText={'Kapcsolattartó'} 
                                changed={handleChange}
                                value={(editItem as Customer)?.ContactName ?? ''}
                                type={INPUT_TYPE.TEXT} 
                                labelWidth={isMobile ? WIDTH.FULL_LINE : 3.5} 
                                barWidth={isMobile ? 11 : 7.7} />
                        </Grid>
                        <Grid item xs={isMobile ? WIDTH.FULL_LINE : WIDTH.HALF}>
                            <Grid container>
                                <Grid item xs={isMobile ? 9.5 : 10.7}>
                                    <InputField 
                                        name='Email'
                                        labelText={'E-mail'}
                                        max={100}
                                        changed={handleChange}
                                        value={(editItem as Customer)?.Email ?? ''}
                                        type={INPUT_TYPE.TEXT} 
                                        labelWidth={isMobile ? WIDTH.FULL_LINE : 3.9} 
                                        barWidth={isMobile ? WIDTH.FULL_LINE : 7.5} />
                                </Grid>
                                <Grid item xs={isMobile ? 1.8 : 0.6}>
                                    <Tooltip followCursor={isMobile} disableFocusListener title={'Több e-mail cím is megadható pontosvesszővel (;) elválasztva'} enterTouchDelay={50}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <InfoIcon style={{marginTop: isMobile ? '25px' : '10px' }} />
                                        </Box>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </div>
    </Fragment>)
}

export default BaseTab