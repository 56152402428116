import styles from './ListItemCard.module.scss'
import { ReactComponent as RightArrow } from '../../images/svg/right-arrow-big.svg'
import { Separator } from "../elements/Separator"
import Grid from '@mui/material/Grid'
import { Fragment, useContext } from 'react'
import { SelectedWorksheetContext } from '../../context/Contexts'
import { Worksheet } from '../../models/Worksheet'
import WorksheetStatusIcons from './WorksheetStatusIcons'


type Props = {
    topRightHeader: string
    bottomLeftHeader: string
    bottomRightHeader: string
    topLeft: string
    topRight: string
    bottomLeft: string
    bottomRight: string
    handleArrowClick: () => void
    worksheet?: Worksheet
}

const ListItemCard = ({ topRightHeader, bottomLeftHeader, bottomRightHeader, topLeft, topRight, bottomLeft, bottomRight, handleArrowClick, worksheet }: Props) => {

    return (
        <div className={styles['card']}>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <div className={styles['top-left-header']}>{topLeft}</div>
                    <Separator />
                    {worksheet && 
                        <WorksheetStatusIcons ws={worksheet} />}
                </Grid>
                <Grid item xs={4}>
                    <div className={styles['header']}>{topRightHeader}</div>
                    <div className={styles['content']}>{topRight}</div>
                </Grid>
                <Grid item xs={7}>
                    <div className={styles['header']}>{bottomLeftHeader}</div>
                    <div className={styles['content']}>{bottomLeft}</div>
                </Grid>
                <Grid item xs={4}>
                    <div className={styles['header']}>{bottomRightHeader}</div>
                    <div className={styles['content']}>{bottomRight}</div>
                </Grid>
                <RightArrow onClick={handleArrowClick} className={styles['arrow']} />
            </Grid>
        </div>
    )
}

export default ListItemCard