import { Grid } from "@mui/material"
import { Fragment, useContext, useEffect } from "react"
import { DeviceContext, EditItemContext } from "../../../context/Contexts"
import { Currency } from "../../../models/Currency"
import { Product } from "../../../models/Product"
import mainStyles from '../tool/ToolModal.module.scss'
import CurrencyPriceCard from "./CurrencyPriceCard"

type Props = {
    currencyList: Currency[]
}

const CurrencyPriceTab = ({ currencyList } : Props) => {
    const { editItem } = useContext(EditItemContext)
    const { isMobile, isPortrait } = useContext(DeviceContext)

    return(<Fragment>
        <div className={mainStyles[isMobile ? 'tab-container-mobile' : isPortrait ? 'tab-container-portrait' : 'tab-container']}>
            <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                {(editItem as Product)?.CurrencyPrices?.map((item, index) => (
                    currencyList[item.CurrencyId].InternalCode !== 'forint' ?
                    <Grid item sm={6} md={isMobile ? 12 : 4} key={index}>
                        <div key={index}>
                            <CurrencyPriceCard index={index} currencyId={item.CurrencyId} sign={currencyList[item.CurrencyId].Sign} name={currencyList[item.CurrencyId].Name} />
                        </div>
                    </Grid> :
                    null
                    )) }
            </Grid> 
        </div>
    </Fragment>)
}

export default CurrencyPriceTab