import { Fragment, useContext, useEffect } from "react"
import { DeviceContext } from "../context/Contexts"
import { mobileCheck } from "./HelperFunctions"
import useWindowDimensions from "./WindowDimestions"

type Props = {
	children: React.ReactNode
}

const DeviceCheck = ({ children }: Props) => {
    const { setIsMobile, setIsPortrait } = useContext(DeviceContext)
    const { height, width } = useWindowDimensions()

    useEffect(() => {
        setIsMobile(width <= 790)
        setIsPortrait(width >= 790 && height >= width)
    }, [height, width])

    return (
        <Fragment>
            {( children )}
        </Fragment>
    )
}

export default DeviceCheck